import {
	Flex,
	Text,
	Icon,
	Card,
	Tooltip,
	Checkbox,
	Button,
} from '@chakra-ui/react';
import {
	RiRefreshLine,
	RiEdit2Line,
	RiDeleteBin7Line,
	RiLockUnlockLine,
	RiLockLine,
} from 'react-icons/ri';

interface CardActionsProps {
	title: string;
	onEdit?: () => void;
	onRemove?: () => void;
	onRegenerate?: () => Promise<void>;
	onLock?: () => void;
	isSelected?: boolean;
	disabledActions?: boolean;
	isLocked?: boolean;
	onSelectChange?: (isSelected: boolean) => void;
	showCheckbox?: boolean;
	borderRadiusValues?: string;
	mb?: string;
}

const CardActions = ({
	title,
	onEdit,
	onRegenerate,
	onRemove,
	onLock,
	isSelected = false,
	disabledActions,
	isLocked,
	onSelectChange,
	showCheckbox = false,
	borderRadiusValues = '10px',
	mb = '0px',
}: CardActionsProps) => {
	const disabledStyles = {
		opacity: 0.4,
		cursor: 'not-allowed',
	};

	return (
		<Card
			boxShadow="md"
			maxH="sm"
			borderRadius={borderRadiusValues}
			bg={isLocked ? 'gray.200' : isSelected ? '#F75A23' : 'white'}
			py={0.5}
			mb={mb}
		>
			<Flex
				px={4}
				py={0.5}
				justifyContent="space-between"
				alignItems="center"
				color={isSelected ? 'white' : 'black'}
			>
				<Flex alignItems="center">
					{/* {showCheckbox && (
            <Checkbox
              isChecked={isSelected}
              onChange={(e) => onSelectChange?.(e.target.checked)}
              mr={2}
              sx={{
                '& .chakra-checkbox__control': {
                  bg: 'white',
                  borderColor: 'gray.200',
                },
                '& .chakra-checkbox__control[data-checked]': {
                  bg: 'white',
                  borderColor: 'white',
                  color: '#F75A23',
                },
              }}
            />
          )} */}
					<Text size="md" color={isSelected ? 'white' : 'black'}>
						{title}
					</Text>
				</Flex>
				<Flex gap={3} mb={-1}>
					{onEdit && (
						// <Tooltip label="Edit" borderRadius={100} px={3}>
						// 	<span data-testid="edit-btn">
						// 		<Icon
						// 			as={RiEdit2Line}
						// 			onClick={!disabledActions ? onEdit : undefined}
						// 			boxSize={5}
						// 			cursor={disabledActions ? 'not-allowed' : 'pointer'}
						// 			color={isSelected ? 'white' : 'black'}
						// 			{...(disabledActions && disabledStyles)}
						// 		/>
						// 	</span>
						// </Tooltip>
						<Button
							data-testid="edit-btn"
							display="flex"
							p={2}
							leftIcon={<RiEdit2Line size={18} />}
							justifyContent="left"
							variant="unstyled"
							onClick={!disabledActions ? onEdit : undefined}
							cursor={disabledActions ? 'not-allowed' : 'pointer'}
							color={isSelected ? 'white' : 'black'}
							fontWeight={500}
							{...(disabledActions && disabledStyles)}
						>
							<Text color={isSelected ? 'white' : 'black'}>Edit</Text>
						</Button>
					)}

					{onRegenerate && (
						// <Tooltip label="Regenerate" px={3} borderRadius={100}>
						// 	<span data-testid="regenerate-btn">
						// 		<Icon
						// 			onClick={!disabledActions ? onRegenerate : undefined}
						// 			as={RiRefreshLine}
						// 			boxSize={5}
						// 			cursor={disabledActions ? 'not-allowed' : 'pointer'}
						// 			color={isSelected ? 'white' : 'black'}
						// 			{...(disabledActions && disabledStyles)}
						// 		/>
						// 	</span>
						// </Tooltip>
						<Button
							data-testid="regenerate-btn"
							display="flex"
							p={2}
							onClick={!disabledActions ? onRegenerate : undefined}
							leftIcon={<RiRefreshLine size={18} />}
							justifyContent="left"
							variant="unstyled"
							cursor={disabledActions ? 'not-allowed' : 'pointer'}
							fontWeight={500}
							color={isSelected ? 'white' : 'black'}
							{...(disabledActions && disabledStyles)}
						>
							<Text color={isSelected ? 'white' : 'black'}>Regenerate</Text>
						</Button>
					)}
					{onRemove && (
						<Button
							data-testid="remove-btn"
							display="flex"
							p={2}
							onClick={onRemove}
							leftIcon={<RiDeleteBin7Line size={18} />}
							justifyContent="left"
							variant="unstyled"
							color={isSelected ? 'white' : 'black'}
							fontWeight={500}
						>
							<Text color={isSelected ? 'white' : 'black'}>Remove</Text>
						</Button>
						// <Tooltip label="Remove" px={3} borderRadius={100}>
						// 	<span data-testid="remove-btn">
						// 		<Icon
						// 			onClick={onRemove}
						// 			as={RiDeleteBin7Line}
						// 			boxSize={5}
						// 			cursor="pointer"
						// 			color={isSelected ? 'white' : 'black'}

						// 		/>
						// 	</span>
						// </Tooltip>
					)}
					{onLock && (
						<Tooltip
							label={isLocked ? 'Unlock' : 'Lock'}
							px={3}
							borderRadius={100}
						>
							<span data-testid="lock-icon">
								<Icon
									onClick={onLock}
									as={isLocked ? RiLockLine : RiLockUnlockLine}
									boxSize={5}
									cursor="pointer"
									color={isSelected ? 'white' : 'black'}
								/>
							</span>
						</Tooltip>
					)}
				</Flex>
			</Flex>
		</Card>
	);
};

export default CardActions;
