import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const UploadIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M8 0L13.0667 4.95383L11.8133 6.18794L8.88889 3.31993V11.6545H7.11111V3.31993L4.17778 6.18794L2.92444 4.95383L8 0ZM16 10.7854L15.9822 13.836C15.9822 15.0353 14.9867 16 13.76 16H2.22222C0.986667 16 0 15.0266 0 13.8273V10.7854H1.77778V13.8273C1.77778 14.0706 1.97333 14.2618 2.22222 14.2618H13.76C14.0089 14.2618 14.2044 14.0706 14.2044 13.8273L14.2222 10.7854H16Z"
				fill={color ? (color as string) : '#6D6D6D'}
			/>
		</Icon>
	);
};

export default UploadIcon;
