import { FC } from 'react';
import { Box, BoxProps, Image, ImageProps } from '@chakra-ui/react';

interface FusionLoadingProps {
	isLoading: boolean;
	boxProps?: BoxProps;
	imageProps?: ImageProps;
}

const FusionLoading: FC<FusionLoadingProps> = ({
	isLoading,
	boxProps,
	imageProps,
}) => {
	return (
		<Box
			w="full"
			justifyContent="center"
			display={isLoading ? 'flex' : 'none'}
			{...boxProps}
		>
			<Image h="120px" src="/fusion-loader.gif" {...imageProps} />
		</Box>
	);
};

export default FusionLoading;
