import React, { createContext, useState } from 'react';

interface MenuContextProps {
	expandedMenus: { [key: string]: boolean };
	setExpandedMenus: React.Dispatch<
		React.SetStateAction<{ [key: string]: boolean }>
	>;
}

export const MenuContext = createContext<MenuContextProps>({
	expandedMenus: {},
	setExpandedMenus: () => {},
});

interface MenuProviderProps {
	children: React.ReactNode;
}

export const MenuProvider: React.FC<MenuProviderProps> = ({ children }) => {
	const [expandedMenus, setExpandedMenus] = useState<{
		[key: string]: boolean;
	}>({});

	return (
		<MenuContext.Provider value={{ expandedMenus, setExpandedMenus }}>
			{children}
		</MenuContext.Provider>
	);
};
