import React, {
	createContext,
	useContext,
	useEffect,
	useState,
	ReactNode,
} from 'react';
import {
	getAccountIntegrations,
	saveSelectedPages,
} from 'src/services/integration';
import UserContext from 'src/contexts/UserContext';
import { toastError, toastSuccess } from 'src/services/toast';
import { IIntegration, IPage } from 'src/lib/schemas';

interface MyIntegrationsContextProps {
	integrations: IIntegration | null;
	isLoading: boolean;
	isSaving: boolean;
	shouldLaunch: boolean;
	setShouldLaunch: (val: boolean) => void;
	mapPages: (pages: any[]) => IPage[];
	handleSaveSelectedPages: (
		tokens: any,
		pages: IPage[],
		adAccount: any,
		redirectedFromFB?: boolean,
		igPages?: IPage[],
	) => Promise<void>;
	getSelectedPagesIds: () => string[];
	getSelectedIGAccountsIds: () => string[];
	setIntegrations: (value: IIntegration | null) => void;
}

const MyIntegrationsContext = createContext<
	MyIntegrationsContextProps | undefined
>(undefined);

interface MyIntegrationsProviderProps {
	children: ReactNode;
}

export const MyIntegrationsProvider = ({
	children,
}: MyIntegrationsProviderProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [shouldLaunch, setShouldLaunch] = useState(false);
	const [integrations, setIntegrations] = useState<IIntegration | null>(null);
	const { user } = useContext(UserContext);

	const getSelectedPagesIds = (): string[] => {
		if (!integrations) return [];
		return (
			integrations.social?.fb?.attributes?.pagesToPublishContent?.map(
				(page: any) => page.id,
			) || []
		);
	};

	const getSelectedIGAccountsIds = (): string[] => {
		if (!integrations) return [];
		return (
			integrations.social?.fb?.attributes?.instagramPagesToPublish?.map(
				(page: any) => page.id,
			) || []
		);
	};

	const handleSaveSelectedPages = async (
		tokens: any,
		pages: IPage[],
		adAccount: any,
		redirectedFromFB?: boolean,
		igPages?: IPage[],
	): Promise<void> => {
		if (!user || !integrations) return;

		const { instagramPagesToPublish, ...restAttributes } =
			integrations.social?.fb?.attributes || {};

		const updatedItem: any = {
			enabled: true,
			attributes: {
				...restAttributes,
				pagesToPublishContent: pages,
				adAccounts: [adAccount],
			},
		};

		if (igPages && igPages.length > 0) {
			const instagramPagesToPublishData = igPages.map((page) => ({
				id: page.id,
				username: page.name,
				image: page.image ?? '',
				access_token: tokens.accessToken,
			}));

			updatedItem.attributes.instagramPagesToPublish =
				instagramPagesToPublishData;
		}
		setIsSaving(true);

		try {
			const data = await saveSelectedPages(user.account, updatedItem);
			setIntegrations(data);
			toastSuccess('Your changes have been saved');
			redirectedFromFB && setShouldLaunch(true);
		} catch (error: any) {
			toastError(error);
		}
		setIsSaving(false);
	};

	const handleFetchIntegrations = async (): Promise<void> => {
		if (!user) return;

		setIsLoading(true);
		try {
			const data = await getAccountIntegrations(user.account);
			setIntegrations(data);
		} catch (error: any) {
			toastError(error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (!user) return;
		handleFetchIntegrations();
	}, [user]);

	const mapPages = (pages: any[]): IPage[] =>
		pages.map((page: any) => {
			return {
				id: page.id,
				name: page.name,
				image: page.picture?.data?.url,
				access_token:
					page.access_token ??
					integrations?.social?.fb?.attributes?.tokens?.accessToken ??
					'',
			};
		});

	return (
		<MyIntegrationsContext.Provider
			value={{
				integrations,
				isLoading,
				isSaving,
				shouldLaunch,
				setShouldLaunch,
				setIntegrations,
				mapPages,
				handleSaveSelectedPages,
				getSelectedPagesIds,
				getSelectedIGAccountsIds,
			}}
		>
			{children}
		</MyIntegrationsContext.Provider>
	);
};

export const useMyIntegrations = (): MyIntegrationsContextProps => {
	const context = useContext(MyIntegrationsContext);
	if (context === undefined) {
		throw new Error('useMyIntegrations must be within MyIntegrationsProvider');
	}
	return context;
};
