import { formatDistance, differenceInDays, format, isValid } from 'date-fns';

export function delay(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export const convertTimzoneToGMT = (timezone: string) => {
	const date = new Date().toLocaleString('en-US', {
		timeZone: timezone,
	});
	const newDate = new Date(date).toString();
	const indexOfGTM = newDate.indexOf('GMT');
	const GMT = newDate.slice(indexOfGTM);
	return GMT;
};

export const findMinDate = (dates: Date[]) => {
	const datesAsNumbers = dates.map((date) => new Date(date).getTime());
	const date = new Date(Math.min.apply(null, datesAsNumbers));
	return isValid(date) ? date : new Date();
};

export const findMaxDate = (dates: Date[]) => {
	const datesAsNumbers = dates.map((date) => new Date(date).getTime());
	const date = new Date(Math.max.apply(null, datesAsNumbers));
	return isValid(date) ? date : new Date();
};

export const humanizeTime = (time: Date) => {
	const formattedDate = new Date(time);
	const dateToday = new Date();
	const differnce = differenceInDays(dateToday, formattedDate);
	if (differnce > 7) {
		return `on ${format(formattedDate, 'dd-MM-yy')}`;
	}

	return formatDistance(formattedDate, dateToday, { addSuffix: true });
};
