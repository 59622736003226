import React, { useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { useRoutes, useLocation } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import '@fontsource/inter/400.css';
import theme from './theme';
import getRoutes from './routes';
import Scripts from './components/Scripts';
import UserContext from './contexts/UserContext';
import FusionLoading from './components/common/FusionLoading';
import { ToastContainer, toastError } from 'src/services/toast';
import {
	CampaignConfigProvider,
	AccountConfigProvider,
	AssistantChatProvider,
} from './contexts';
import TemplateProvider from './contexts/templates/TemplatesProvider';
import authService from './services/auth';
import useMyProfile from './hooks/useMyProfile';
import { ICompanyAccount, IUser } from './lib/schemas';
import { getCompanyAccount } from './services/account';
import ScrollToTop from './components/common/ScrollToTop';
import { MyIntegrationsProvider } from './contexts/integration/IntegrationContext';
import { MenuProvider } from './contexts/app/menuContext';
import { IMenuItem, getMenu } from './services/menu';
interface CustomWindow extends Window {
	dataLayer: Array<Record<string, any>>;
	newrelic: any;
	intercomSettings: any;
}
declare const window: CustomWindow;

const App: React.FC = () => {
	const [user, setUser] = useState<IUser | null>(authService.getCurrentUser());
	const [account, setAccount] = useState<ICompanyAccount | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const routes = useRoutes(getRoutes(user));
	const profile = useMyProfile();
	const [navList, setNavList] = useState<IMenuItem[]>([]); 

	useEffect(() => {
		if (user?.account == null) {
			return;
		}
		const isInternalUser =
			user?.email.endsWith('fusionads.ai') ||
			user?.email.endsWith('fusionos.ai') ||
			user?.email.endsWith('fusioncloud.ai');

		window.dataLayer.push({
			event: 'identify',
			user_id: user?.id,
			email: user?.email,
			name: user?.name,
			account_id: user?.account,
			is_internal_user: isInternalUser,
		});

		window.intercomSettings = {
			app_id: 'xdw1c26p',
			user_id: user?.id,
			email: user?.email,
			name: user?.name,
			company: {
				company_id: user?.account,
			},
			is_internal_user: isInternalUser,
		};

		if (typeof window.Intercom === 'function') {
			if (user.isEmailVerified) {
				window.Intercom('boot', window.intercomSettings);
			} else {
				window.Intercom('shutdown');
			}
		}

		if (window.newrelic && typeof window.newrelic.setUserId == 'function') {
			window.newrelic.setUserId(user?.id);
			window.newrelic.setCustomAttribute('email', user?.email);
			if (isInternalUser) {
				window.newrelic.pauseReplay();
			}
		}
	}, [user]);

	useEffect(() => {
		amplitude.setUserId(user?.id);
	}, [user]);

	useEffect(() => {
		if (!profile.user) return;
		setUser(profile.user);
	}, [profile.user]);

	const fetchCompanyAccount = async () => {
		if (!user) return;
		try {
			const data = await getCompanyAccount(user.account);
			setAccount(data);
		} catch (error) {
			toastError(error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (!user) return;
		fetchCompanyAccount();
	}, [user]);
	const fetchMenu = async () => {
		try {
			const menuData = await getMenu();
			setNavList(menuData);
		} catch (error) {
			console.error('Error fetching menu:', error);
		}
	};

	useEffect(() => {
		if (user) {
			fetchCompanyAccount();
			fetchMenu();
		}
	}, [user]);
	return (
		<>
			<UserContext.Provider
				value={{ user, account, isLoading, navList, setUser, setAccount, setNavList }}
			>
				<MenuProvider>
					<CampaignConfigProvider>
						<AccountConfigProvider>
							<AssistantChatProvider>
								<TemplateProvider>
									<MyIntegrationsProvider>
										<Scripts />
										<ChakraProvider theme={theme}>
											{profile?.isLoading ? (
												<FusionLoading
													boxProps={{ mt: 8 }}
													isLoading={Boolean(profile?.isLoading)}
												/>
											) : (
												routes
											)}
										</ChakraProvider>
									</MyIntegrationsProvider>
								</TemplateProvider>
							</AssistantChatProvider>
						</AccountConfigProvider>
					</CampaignConfigProvider>
				</MenuProvider>
			</UserContext.Provider>
			<ToastContainer />
		</>
	);
};

export default App;
