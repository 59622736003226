import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { StringInputHook } from 'src/components/common/form';
import { validateWebsiteUrl } from 'src/lib/utils';
import ScrapedInfoForm from './ScrapedInfoForm';
import { IScrappingDataResponse, IScrappingFullData } from 'src/lib/schemas/scrapping';
import {
	IGetDomainDataSchema,
	GetDomainDataSchema,
} from 'src/lib/schemas/scrap/getDomainData';
import { geFullUrlData, getData, scrapeData } from 'src/services/scraping';
import ThreeDotsAnimation from 'src/components/assistantChat/animation/ThreeDotsAnimation';
import useRoute from 'src/hooks/useRoute';
import http from 'src/services/http';

interface IDomainNotFound {
	prevDomain?: string;
	onDomainSubmit?: (newDomain: string) => void;
	onDataSubmit?: any;
	withManualOption?: boolean;
}

const DomainForm = ({
	prevDomain,
	withManualOption,
	onDomainSubmit,
	onDataSubmit,
}: IDomainNotFound) => {
	const [showForm, setShowForm] = useState(false);
	const [domainData, setDomainData] = useState<IScrappingFullData>();
	const [domainNotFound, setDomainNotFound] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const { removeUrlPrefix } = useRoute();
	const formMethods = useForm<IGetDomainDataSchema>({
		resolver: zodResolver(GetDomainDataSchema),
	});
	const { setValue, setError, clearErrors, watch, setFocus, formState } =
		formMethods;
	const websiteLink = watch('websiteLink');

	const fetchData = async (url:string) => {
		try {
			const { callback } = await scrapeData(url);
			processCallback(callback);
		} catch (error) {
			setDomainNotFound(true);
			setIsDataLoading(false);
		}
	};


	const processCallback = async (
		callbackUrl: string) => {
		try {
			const response = await http.get(callbackUrl);
			const { status, body } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(
					() => processCallback(callbackUrl),
					1500,
				);
			} else if (status === 'error' || status === 'failed') {
				setIsDataLoading(false);
				setDomainNotFound(true);
			} else if (status === 'successful') {
				const output = body.output
				setDomainData(output);
				setValue('websiteLink', output.url);
				setDomainNotFound(false);
				//onSuccess(body);
				setIsDataLoading(false);
			}
		} catch (e) {
			setDomainNotFound(true);
			setIsDataLoading(false);
		}
	};
	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (websiteLink === '') {
			setError('websiteLink', { message: 'Website URL is required' });
			return;
		}
		const validatedUrl = validateWebsiteUrl(websiteLink);
		if(!validatedUrl.isValid){
			return false
		}
		setIsDataLoading(true);
		setShowForm(true);
		setDomainData(undefined);
		onDomainSubmit && onDomainSubmit(removeUrlPrefix(validatedUrl.updatedUrl));
		await fetchData(decodeURIComponent(validatedUrl.updatedUrl));
	};

	const handleWebsiteLinkChange = (e: any, updateValue = false) => {
		const url = e.target.value;
		const { isValid, updatedUrl } = validateWebsiteUrl(url);
		if (!isValid) {
			setError('websiteLink', { message: 'Please enter a valid URL' });
			return;
		}
		if (updateValue) {
			setValue('websiteLink', updatedUrl);
		}
		clearErrors('websiteLink');
	};

	return (
		<FormProvider {...formMethods}>
			<form onSubmit={handleSubmit}>
				<Flex
					justifyContent={{ base: 'center', md: 'flex-start' }}
					flexDirection={{ base: 'column', md: 'row' }}
					gap={2}
					alignItems={{ base: 'stretch', md: 'flex-end' }}
				>
					<StringInputHook
						name="websiteLink"
						label="Website"
						inputProps={{ w: { base: '100%', md: '300px', lg: '400px' } }}
						placeholder={prevDomain ?? 'http://'}
						formControlProps={{
							onBlur: (e) => handleWebsiteLinkChange(e, true),
							onChange: handleWebsiteLinkChange,
							w: { base: '100%', md: 'auto' },
						}}
						required
						withErrorMessage={false}
					/>
					<Button
						variant="orangeSolid"
						w={{ base: '100%', md: '130px', lg: '150px' }}
						mt={{ base: 2, md: 0 }}
						type="submit"
					>
						Obtain Info
					</Button>
					{withManualOption && !showForm && (
						<Button
							variant="blankOutline"
							w={{ base: '100%', md: '130px', lg: '150px' }}
							mt={{ base: 2, md: 0 }}
							onClick={() => {
								setShowForm(true);
								setFocus('websiteLink');
							}}
						>
							Enter Manually
						</Button>
					)}
					{isDataLoading && (
						<Flex pb="8px">
							<ThreeDotsAnimation />
						</Flex>
					)}
					{domainNotFound && !isDataLoading && (
						<Text color="red">
							Unable to obtain business information. Please enter it manually or
							try a new website.
						</Text>
					)}
				</Flex>
				<FormControl isInvalid={Boolean(formState.errors['websiteLink'])}>
					<FormErrorMessage>
						{formState.errors['websiteLink']?.message}
					</FormErrorMessage>
				</FormControl>
				<Box mt={5}>
					{showForm && (
						<ScrapedInfoForm
							isDataLoading={isDataLoading}
							data={domainData}
							onDataSubmit={onDataSubmit}
							websiteLink={websiteLink}
							errors={formState.errors}
						/>
					)}
				</Box>
			</form>
		</FormProvider>
	);
};

export default DomainForm;
