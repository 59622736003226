import {
	Heading,
	Box,
	Text,
	Link,
	FormControl,
	FormErrorMessage,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import MultiLevelDropdown from 'src/components/common/MultiLevelDropdown';

interface AdAccountsProps {
	title: string;
	isLoading: boolean;
	accounts?: any[];
	selectedAccount?: any;
	onSelect: (account: any) => void;
	error?: string;
}

const AdAccounts = ({
	title,
	accounts,
	selectedAccount,
	isLoading,
	onSelect,
	error,
}: AdAccountsProps) => {
	const [options, setOptions] = useState<any[]>();

	useEffect(() => {
		if (accounts) {
			setOptions([{ name: "I don't need paid ads", id: null }, ...accounts]);
		}
	}, [accounts]);

	if (isEmpty(accounts)) {
		return (
			<Text>
				In order that you can create ads, you need to create an ad account in
				Facebook{' '}
				<Link
					href="https://www.facebook.com/business/tools/ads-manager"
					isExternal
					color="blue.500"
				>
					here
				</Link>
				.
			</Text>
		);
	}

	const initialValue = selectedAccount || options?.[1] || options?.[0];

	return (
		<Box>
			<Heading fontSize="16px" fontWeight="400" lineHeight="20px" mb={4}>
				{title}
				<Box as="span" color="#e53e3e" ml={1}>
					*
				</Box>
			</Heading>
			<FormControl isInvalid={Boolean(error)}>
				{options && (
					<MultiLevelDropdown
						items={options}
						onSelect={onSelect}
						initialValue={initialValue}
						isLoading={isLoading}
					/>
				)}
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Box>
	);
};

export default AdAccounts;
