import { Container, Flex } from '@chakra-ui/react';

import { AuthHeader } from 'src/components/auth';
import SetPasswordForm from 'src/components/auth/SetPasswordForm';

const AcceptInvitation = () => {
	return (
		<Container maxW="md">
			<Flex direction="column" gap={12}>
				<AuthHeader
					title="Set your password"
					subTitle="To confirm your account, indicate your password"
				/>
				<SetPasswordForm />
			</Flex>
		</Container>
	);
};

export default AcceptInvitation;
