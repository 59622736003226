import { FC, useContext, useEffect } from 'react';
import { Avatar } from '@chakra-ui/react';
import UserContext from 'src/contexts/UserContext';

interface UserLogoProps {
	size?: {
		w: number | string;
		h: number | string;
	};
	fontSize?: string;
}

const UserLogo: FC<UserLogoProps> = ({ size, fontSize = '15px' }) => {
	const { user } = useContext(UserContext);

	useEffect(() => {
		const initialsElements = document.getElementsByClassName(
			'chakra-avatar__initials css-1ebyn6',
		);
		Array.from(initialsElements).forEach((initial) => {
			(initial as HTMLElement).style.fontSize = fontSize;
		});
	}, [fontSize]);

	return <Avatar fontSize="15px" name={user?.name} {...size} />;
};

export default UserLogo;
