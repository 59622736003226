import {
	ImageInputWidget,
	NumberInputWidget,
	PromotionInputWidget,
	SelectInputWidget,
	StringArrayInputWidget,
	StringInputWidget,
	TargetInputWidget,
	TextAreaInputWidget,
} from '../widgets/input';

export const renderInputWidget = (type: string) => {
	switch (type) {
		case 'String':
			return StringInputWidget;
		case 'TextArea':
			return TextAreaInputWidget;
		case 'Number':
			return NumberInputWidget;
		case 'Tone[]':
			return SelectInputWidget;
		case 'Audience[]':
			return SelectInputWidget;
		case 'Product[]':
		case 'Catalog[]':
			return TargetInputWidget;
		case 'Promotion':
			return PromotionInputWidget;
		case 'Image':
			return ImageInputWidget;
		case 'FlatImage':
			return ImageInputWidget;
		case 'String[]':
			return StringArrayInputWidget;
		default:
			return null;
	}
};
