import {
	ICampaign,
	ICampaignConfig,
	ICampaignForm,
	ICampaignGenAIPayload,
	ICompanyAccount,
} from 'src/lib/schemas';
import { IOption } from 'src/lib/schemas/misc';

export const transformFormToCampaign = (
	form: ICampaignForm,
): Omit<ICampaign, 'id'> => {
	const {
		group,
		title,
		description,
		goal,
		promotedObject,
		destination,
		audience,
		tone,
		promotion,
		channels,
		budget,
	} = form;
	const selectedChannels = Object.keys(channels)
		.filter((platform) => channels[platform as keyof typeof channels])
		.map((key) => key);

	return {
		title,
		description,
		group: group ?? '',
		goal: goal.map(({ id, target }) => ({
			id: id,
			target: Number(target),
		})),
		promotedObject: {
			type: promotedObject.type,
			ids: promotedObject.value.map(({ value }) => value),
		},
		destination,
		audience,
		tone,
		promotion,
		channels: selectedChannels,
		budget: {
			...budget,
			currency: budget?.currency ?? 'USD',
			total: Number(budget?.total ?? '0'),
		},
		status: 'draft',
	};
};

export const transformCampaignToForm = (
	campaign: ICampaign,
	promotedObjecOptions: IOption[],
): ICampaignForm => {
	const {
		title,
		description,
		goal,
		promotedObject,
		destination,
		audience,
		tone,
		promotion,
		channels,
		budget,
		placements,
	} = campaign;

	const selectedChannels = (channels ?? []).reduce(
		(acc, id) => ({ ...acc, [id]: true }),
		{},
	);

	const promotedObjectValue = promotedObject.ids.map((id) => {
		const option = promotedObjecOptions.find((option) => option.value === id);
		return option ?? { value: '', label: '' };
	});

	return {
		title,
		description,
		goal: goal ?? [],
		promotedObject: { type: promotedObject.type, value: promotedObjectValue },
		destination,
		audience,
		tone,
		promotion,
		channels: selectedChannels,
		budget: { ...budget, total: String(budget?.total ?? '0') },
		placements,
	};
};

export const transformCampaignToGenAIPayload = (
	campaign: Omit<ICampaign, 'id'>,
	config: ICampaignConfig | null,
	metaOptions: IOption[],
	tonesOptions: IOption[],
	audienceOptions: IOption[],
): ICampaignGenAIPayload => {
	if (!config) throw new Error('Campaign config is not defined');

	const {
		title,
		description,
		goal,
		promotedObject,
		audience,
		tone,
		promotion,
		channels,
		budget,
	} = campaign;

	const mappedGoals = goal?.map(({ id }) => {
		const goal = config.goals.find((g) => g.value === id);
		if (!goal) return id;
		return goal.label;
	});

	const mappedTones = tone.map((id) => {
		const elem = tonesOptions.find((t) => t.value === id);
		if (!elem) return id;
		return elem.label;
	});

	const mappedAudience = audience.map((id) => {
		const elem = audienceOptions.find((a) => a.value === id);
		if (!elem) return id;
		return elem.label;
	});

	const mappedProducts = promotedObject.ids.map((id) => {
		const prod = metaOptions.find((p) => p.value === id);
		if (!prod) return id;
		return prod.label;
	});

	return {
		name: title,
		description,
		goals: mappedGoals ?? [],
		audiance: mappedAudience,
		tone: mappedTones,
		products: mappedProducts,
		promo_code: promotion?.[0]?.code ?? '',
		promo_description: promotion?.[0]?.description ?? '',
		channels,
		budget: `USD ${budget?.total ?? 0}`,
	};
};

export const transformCampaignToGenAIAppPayload = (
	campaign: Omit<ICampaign, 'id'>,
	config: ICampaignConfig | null,
	metaOptions: IOption[],
	tonesOptions: IOption[],
	audienceOptions: IOption[],
	account: ICompanyAccount | null,
	content: string,
): any => {
	if (!config) throw new Error('Campaign config is not defined');

	const { brief, goal, promotedObject, audience, tone, promotion } = campaign;

	const mappedGoals = goal?.map(({ id }) => {
		const goal = config.goals.find((g) => g.value === id);
		if (!goal) return id;
		return goal.label;
	});

	const mappedAudience = audience.map((id) => {
		const elem = audienceOptions.find((a) => a.value === id);
		if (!elem) return { id, name: id };
		return { id, name: elem.label };
	});

	const mappedProducts = promotedObject.ids.map((id) => {
		const prod = metaOptions.find((p) => p.value === id);
		if (!prod) return id;
		return { id, name: prod.label };
	});

	const mappedTones = tone.map((id) => {
		const elem = tonesOptions.find((t) => t.value === id);
		if (!elem) return { id, name: id };
		return {
			id,
			name: elem.label,
			account: account?.id ?? '',
			type: 'account',
		};
	});

	const promotions = promotion?.length ? promotion : undefined;

	const payload = {
		id: '',
		callback_url: '',

		inputs: {
			content,
			merchantName: account?.name ?? '',
			description: brief?.description ?? '',
			goals: mappedGoals,
			audiences: mappedAudience,
			tones: mappedTones,
			products: mappedProducts,
			promotion: promotions,
		},
		sync: 'true',
	};

	return payload;
};
