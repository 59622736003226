import { FC, ReactNode, useEffect, useState } from 'react';
import {
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverHeader,
	PopoverContent,
	PopoverTrigger,
	useDisclosure,
	Flex,
	Box,
	Heading,
	Button,
} from '@chakra-ui/react';

interface DownloadPopoverProps {
	children: ReactNode;
	onDownload: (fileType: string) => void;
	onTogglePopover?: (isOpen: boolean) => void;
}

const DownloadPopover: FC<DownloadPopoverProps> = ({
	children,
	onDownload,
	onTogglePopover,
}) => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	const [isDownloadingJPG, setIsDownloadingJPG] = useState(false);
	const [isDownloadingPSD, setIsDownloadingPSD] = useState(false);

	useEffect(() => {
		onTogglePopover && onTogglePopover(isOpen);
	}, [isOpen]);

	const handleDownalodJPG = async () => {
		setIsDownloadingJPG(true);
		await onDownload('JPG');
		setIsDownloadingJPG(false);
		onClose();
	};

	const handleDownalodPSD = async () => {
		setIsDownloadingPSD(true);
		await onDownload('PSD');
		setIsDownloadingPSD(false);
		onClose();
	};

	return (
		<Popover
			isOpen={isOpen}
			onClose={onClose}
			placement="bottom-start"
			preventOverflow={true}
		>
			<PopoverTrigger>
				<Box display="inline-block" onClick={onToggle}>
					{children}
				</Box>
			</PopoverTrigger>
			<PopoverContent
				borderRadius="lg"
				w="150px"
				px={2}
				py={2}
				pb={3}
				rootProps={{
					zIndex: 1600,
				}}
				_focusVisible={{
					outline: 'none',
				}}
			>
				<PopoverHeader fontWeight="semibold">
					<Heading>Download</Heading>
				</PopoverHeader>
				<PopoverArrow />
				<PopoverBody p={0}>
					<Flex direction="column">
						<Box>
							<Button
								bg="transparent"
								w="full"
								p={0}
								px={3}
								fontWeight="medium"
								textAlign="left"
								justifyContent="flex-start"
								onClick={handleDownalodJPG}
								isLoading={isDownloadingJPG}
								loadingText="Downloading..."
							>
								.jpg
							</Button>
						</Box>
						<Box>
							<Button
								bg="transparent"
								w="full"
								p={0}
								px={3}
								fontWeight="medium"
								textAlign="left"
								justifyContent="flex-start"
								onClick={handleDownalodPSD}
								isLoading={isDownloadingPSD}
								loadingText="Downloading..."
							>
								Layered .psd
							</Button>
						</Box>
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default DownloadPopover;
