import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	FormControl,
	FormLabel,
	Input,
	VStack,
} from '@chakra-ui/react';
import { ITag } from 'src/services/tags';

interface TagFormModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (tag: Partial<ITag>) => Promise<void>;
	initialValues?: Partial<ITag>;
	title: string;
}

const TagFormModal: React.FC<TagFormModalProps> = ({
	isOpen,
	onClose,
	onSubmit,
	initialValues,
	title,
}) => {
	const [name, setName] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		if (initialValues) {
			setName(initialValues.name || '');
		} else {
			setName('');
		}
	}, [initialValues, isOpen]);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsSubmitting(true);
		try {
			await onSubmit({ ...initialValues, name });
			onClose();
		} catch (error) {
			console.error('Error submitting tag:', error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay zIndex={1800} />
			<ModalContent
				containerProps={{
					zIndex: 1900,
				}}
			>
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={handleSubmit}>
					<ModalBody>
						<VStack spacing={4}>
							<FormControl isRequired>
								<FormLabel>Tag Name</FormLabel>
								<Input
									value={name}
									onChange={(e) => setName(e.target.value)}
									placeholder="Enter tag name"
								/>
							</FormControl>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button variant="ghost" mr={3} onClick={onClose}>
							Cancel
						</Button>
						<Button
							colorScheme="blue"
							type="submit"
							isLoading={isSubmitting}
							loadingText="Saving..."
						>
							Save
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};

export default TagFormModal;
