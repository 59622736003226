import { Box, Text } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { AssistantChatContext } from 'src/contexts';
import Message from './Message';

interface ISuggestion {
	content: string;
}

const Suggestion: FC<ISuggestion> = ({ content }) => {
	const { applySuggestion, closeChat } = useContext(AssistantChatContext);

	const handleClick = () => {
		applySuggestion(content);
		closeChat();
	};

	return (
		<Box
			onClick={handleClick}
			border="2px solid"
			borderColor="white.suggestion.border"
			borderRadius={14}
			bg="white.suggestion.bg"
			p={2}
			cursor="pointer"
			_hover={{
				transitionDuration: '0.3s',
				bg: 'orange.suggestion.bg',
				borderColor: 'orange.suggestion.border',
			}}
			mb={1}
		>
			<Message content={content} />
			<Text textAlign="end" color="gray.500" alignContent="end" mb={1} mr={2}>
				Click to apply
			</Text>
		</Box>
	);
};

export default Suggestion;
