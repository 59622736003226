import { z } from 'zod';

export interface IGroupForm {
	id?: string;
	name: string;
}

export interface IGroup extends IGroupForm {
	id: string;
	assignedCampaigns?: number;
}

export const GroupFormSchema: Zod.ZodType<IGroupForm> = z.object({
	id: z.string().optional(),
	name: z.string().nonempty('Group name is required'),
});
