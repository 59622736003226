import { Box, Flex, Heading } from '@chakra-ui/react';
import TemplatesContext from 'src/contexts/templates/TemplatesContext';
import { ImageListItem } from './commponents/ImageListItem';
import { useContext } from 'react';

export const ImageListTemplates = () => {
	const { payloadsByCase } = useContext(TemplatesContext);

	return (
		<Flex mt="40px" justifyContent="center" gap="50px">
			<Box>
				<Heading fontWeight="medium">Preview Images</Heading>
				{payloadsByCase.map((payloadCase) => (
					<Box key={payloadCase.idCase}>
						<Heading fontWeight="bold" mt="20px" fontSize="xl">
							Case {payloadCase.idCase}
						</Heading>
						<Flex wrap="wrap" justifyContent="center" gap="30px">
							{payloadCase.payloadList.map((payload) => (
								<ImageListItem
									key={payload.variationId}
									payload={payload}
									caseId={payloadCase.idCase}
								/>
							))}
						</Flex>
					</Box>
				))}
			</Box>
		</Flex>
	);
};
