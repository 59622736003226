import { FC, useState } from 'react';
import { Flex, IconButton, Text } from '@chakra-ui/react';

import TextCreactiveContent from './TextCreactiveContent';
import { LockIcon, SyncIcon, UnlockIcon } from 'src/assets/icons';
import { IChannelCreativeAttributes, ICreative } from 'src/lib/schemas';

interface TextCreactiveProps {
	creative?: ICreative;
	title?: string;
	onRegenerate: (id: string) => void;
	onLockUnlock: (id: string, locked: boolean) => void;
	channelCreativeAttributes?: IChannelCreativeAttributes;
	onRefetchCreatives: () => void;
}

const TextCreactive: FC<TextCreactiveProps> = ({
	creative,
	title,
	onRegenerate,
	onLockUnlock,
	channelCreativeAttributes,
}) => {
	const [isRegenerating, setIsRegenerating] = useState(false);
	const [isLockingUnlocking, setIsLockingUnlocking] = useState(false);

	if (!creative) return null;
	const { variant, locked, mediaType } = creative;

	const channelCreative = channelCreativeAttributes?.creatives.find(
		(c) => c.mediaType === mediaType,
	);

	const isCreativePending = creative.status !== 'GENERATED';

	const handleRegenerateText = async () => {
		setIsRegenerating(true);
		await onRegenerate(creative.id);
		setIsRegenerating(false);
	};

	const handleLockUnlockCreative = async () => {
		setIsLockingUnlocking(true);
		await onLockUnlock(creative.id, !creative.locked);
		setIsLockingUnlocking(false);
	};

	return (
		<Flex direction="column" gap={4}>
			<Flex justify="space-between">
				<Text height={5} fontWeight="semibold">
					{title}
				</Text>
				<Flex gap={3} justifyContent="flex-end">
					{!locked && (
						<IconButton
							aria-label="Regenerate"
							colorScheme="secondary"
							icon={<SyncIcon color="#fff" />}
							onClick={handleRegenerateText}
							isDisabled={isRegenerating || isCreativePending}
							size="sm"
						/>
					)}
					<IconButton
						aria-label="Lock/Unlock"
						colorScheme="secondary"
						icon={
							locked ? <UnlockIcon color="#fff" /> : <LockIcon color="#fff" />
						}
						onClick={handleLockUnlockCreative}
						isDisabled={isLockingUnlocking || isCreativePending}
						size="sm"
					/>
				</Flex>
			</Flex>
			<TextCreactiveContent
				creativeData={creative}
				channelCreative={channelCreative}
				title={`Version ${variant}`}
				isCreativePending={isCreativePending}
				isLocked={creative.locked}
				onRegenerate={handleRegenerateText}
				onLockUnlock={handleLockUnlockCreative}
			/>
		</Flex>
	);
};

export default TextCreactive;
