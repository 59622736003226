import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import _ from 'lodash';

import { renderOutputWidget } from './utils/renderOutputWidget';
import { IAppActionOutput, IAppResponse } from 'src/lib/schemas';

const SUPPORTED_OUTPUT_TYPES: Array<string> = [
	'String',
	'Image',
	'FlatImage',
	'Object[]',
	'Object',
	'Number',
	'String[]',
];

interface AppOutputsProps {
	outputSkeleton: IAppActionOutput[];
	result: IAppResponse;
	isProcessing: boolean;
	view?: string;
}

const AppOutputs: FC<AppOutputsProps> = ({
	outputSkeleton,
	result,
	isProcessing,
	view,
}) => {
	const filteredOutputSkeleton = outputSkeleton.filter(
		(ops) =>
			SUPPORTED_OUTPUT_TYPES.includes(ops.type) && !ops.property.includes('.'),
	);

	const output = result ?? {};
	return (
		<Flex direction="column" gap={4}>
			{filteredOutputSkeleton.map((op) => {
				const OutputWidget = renderOutputWidget(op.type);
				const value = _.get(output, op.property);

				if (OutputWidget) {
					return (
						<OutputWidget
							key={op.property}
							isParent={true}
							output={op}
							outputSkeleton={outputSkeleton}
							rawOutput={output}
							baseProperty={op.property}
							value={value}
							level={1}
							isProcessing={isProcessing}
							view={view}
						/>
					);
				}
				return null;
			})}
		</Flex>
	);
};

export default AppOutputs;
