import http from './http-appsai';

interface ITextResponse {
	body: {
		content: string;
	};
}
const rephraseUrl = '/copy/rephrase';

export const enrichText = async (payload: any): Promise<string> => {
	const { data } = await http.post<ITextResponse>(rephraseUrl, payload);
	return data.body.content;
};
