import { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';
import {
	Box,
	Flex,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { ICalendarMonthItem } from 'src/lib/schemas';
import './CampaignCalendar.css';

interface CampaignCalendarProps {
	data: ICalendarMonthItem[];
	minDate: Date;
	maxDate: Date;
	currentMonth: Date;
	isLoading: boolean;
	onMonthChange: (date: Date) => void;
}

const CampaignCalendar: FC<CampaignCalendarProps> = ({
	data,
	minDate,
	maxDate,
	currentMonth,
	isLoading,
	onMonthChange,
}) => {
	const formattedData = data.map((item) => ({
		id: item.id,
		date: format(new Date(item.schedule.startDate), 'yyyy-MM-dd'),
		title: item.title,
	}));

	const renderDayContents = (day: number, date: Date) => {
		const formattedDate = format(date, 'yyyy-MM-dd');
		const hasCampaigns = Boolean(
			formattedData.find((item) => item.date === formattedDate),
		);

		return (
			<CustomDay
				day={day}
				hasCampaigns={hasCampaigns}
				campaigns={formattedData
					.filter((item) => item.date === formattedDate)
					.map((item) => ({
						id: item.id,
						name: item.title,
					}))}
			/>
		);
	};

	return (
		<Box
			display={isLoading ? 'none' : 'block'}
			className="customReactCampaignCalendar"
		>
			<ReactDatePicker
				selected={currentMonth}
				onChange={() => {}}
				minDate={minDate}
				maxDate={maxDate}
				onMonthChange={onMonthChange}
				renderDayContents={renderDayContents}
				selectsRange
				inline
			/>
		</Box>
	);
};

interface CustomDayProps {
	day: number;
	hasCampaigns: boolean;
	campaigns: Array<{ id: string; name: string }>;
}

const CustomDay: FC<CustomDayProps> = ({ day, hasCampaigns, campaigns }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const calendarDetail = useDisclosure();

	const handleClosePopover = () => {
		setTimeout(() => {
			onClose();
		}, 100);
	};

	const handleOpenPopover = () => {
		if (hasCampaigns) onOpen();
	};

	return (
		<Popover
			isOpen={isOpen || calendarDetail.isOpen}
			placement="top-start"
			preventOverflow={true}
		>
			<PopoverTrigger>
				<Flex
					flexDirection="column"
					alignItems="center"
					onMouseEnter={handleOpenPopover}
					onMouseLeave={handleClosePopover}
				>
					<Box fontWeight={400} color={!hasCampaigns ? 'lightgray' : '#202223'}>
						{day}
					</Box>
					{hasCampaigns && (
						<Box borderRadius="full" bg="red.300" w="6px" h="6px" />
					)}
				</Flex>
			</PopoverTrigger>
			<PopoverContent
				rootProps={{
					zIndex: 1500,
				}}
				_focusVisible={{ outline: 'none' }}
			>
				<PopoverArrow />
				<PopoverHeader textAlign="left">Campaigns</PopoverHeader>
				<PopoverBody
					textAlign="left"
					onMouseEnter={calendarDetail.onOpen}
					onMouseLeave={calendarDetail.onClose}
				>
					<Flex direction="column" gap={2} pb={2}>
						<Flex direction="column" gap={1}>
							{campaigns.map((c) => (
								<Link key={c.id} to={`/projects/campaigns/${c.id}`}>
									<Text color="#2c6ecb" p={1}>
										{c.name}
									</Text>
								</Link>
							))}
						</Flex>
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default CampaignCalendar;
