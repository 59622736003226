import { Box, usePrefersReducedMotion } from '@chakra-ui/react';
import DotAnimation from './DotAnimation';
import {
	keyframe_dot1,
	keyframe_dot2,
	keyframe_dot3,
} from './AnimationKeyFrames';

const ThreeDotsAnimation = () => {
	const prefersReducedMotion = usePrefersReducedMotion();
	const animationParams = 'infinite 1s linear';
	const animation1 = prefersReducedMotion
		? undefined
		: `${keyframe_dot1} ${animationParams}`;
	const animation2 = prefersReducedMotion
		? undefined
		: `${keyframe_dot2} ${animationParams}`;
	const animation3 = prefersReducedMotion
		? undefined
		: `${keyframe_dot3} ${animationParams}`;
	return (
		<Box>
			<DotAnimation animation={animation1} />
			<DotAnimation animation={animation2} />
			<DotAnimation animation={animation3} />
		</Box>
	);
};

export default ThreeDotsAnimation;
