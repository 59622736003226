import { useContext } from 'react';
import { Box, Divider, Flex, Heading, Text } from '@chakra-ui/react';

import UserAvatar from 'src/components/common/UserAvatar';
import Chip from 'src/components/common/Chip';
import { CompanyAccountForm } from 'src/components/account';
import UserContext from 'src/contexts/UserContext';
import { USER_ROLE_OPTIONS } from 'src/lib/constants';
import CustomContainer from 'src/components/common/CustomContainer';

const CompanyAccount = () => {
	const { user } = useContext(UserContext);

	const userRole = USER_ROLE_OPTIONS.find((r) => r.value === user?.role)?.label;

	return (
		<Flex justifyContent="center" w="full">
			<CustomContainer maxW="md" justifyContent="center">
				<Flex direction="column" gap="16px">
					<Heading>Account Info</Heading>
					<Flex gap="24px" alignItems="center">
						<Text>Current User:</Text>
						<UserAvatar name={user?.name ?? ' User'} />
					</Flex>
					<Flex gap="24px" alignItems="center">
						<Text>Role:</Text>
						<Chip
							label={userRole ?? 'Role'}
							tagProps={{
								colorScheme: 'green',
							}}
						/>
					</Flex>
				</Flex>
				<Divider my="32px" />
				<CompanyAccountForm />
			</CustomContainer>
		</Flex>
	);
};

export default CompanyAccount;
