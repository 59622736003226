import { FC, useEffect } from 'react';
import { InputGroup, Input, InputRightAddon } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

import useDebouncedState from 'src/hooks/useDebounce';

interface ISearchInputProps {
	placeholder?: string;
	onChange: (value: string) => void;
}

const SearchInput: FC<ISearchInputProps> = ({ placeholder, onChange }) => {
	const { debouncedState, state, setState } = useDebouncedState('', 500);

	useEffect(() => {
		onChange(debouncedState);
	}, [debouncedState]);

	return (
		<InputGroup>
			<Input
				width="220px"
				fontSize="14px"
				fontWeight={400}
				type="search"
				placeholder={placeholder || 'Search'}
				value={state}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setState(e.target.value)
				}
			/>
			<InputRightAddon bg="transparent">
				<SearchIcon />
			</InputRightAddon>
		</InputGroup>
	);
};

export default SearchInput;
