import {
	IconButton,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	useDisclosure,
} from '@chakra-ui/react';

import { KebabIcon } from 'src/assets/icons';

export type TActions =
	| 'Edit'
	| 'Delete'
	| 'Resend invite'
	| 'Create campaign'
	| 'Create'
	| 'Create subcategory';

export interface DataTableActionsProps<T> {
	data: T;
	actionsAllowed: Array<TActions>;
	isActionDisabled?: (action: TActions, row: T) => void;
	onAction: (action: TActions, payload: T) => void;
}

const DataTableActions = <T,>({
	data,
	actionsAllowed,
	isActionDisabled,
	onAction,
}: DataTableActionsProps<T>) => {
	const { isOpen, onToggle, onClose } = useDisclosure();

	return (
		<Popover
			isOpen={isOpen}
			onClose={onClose}
			placement="bottom-start"
			preventOverflow={true}
		>
			<PopoverTrigger>
				<IconButton
					aria-label="Action options"
					onClick={onToggle}
					bg="transparent"
					_hover={{
						bg: 'transparent',
					}}
				>
					<KebabIcon />
				</IconButton>
			</PopoverTrigger>
			<PopoverContent w={44} px={2} py={2}>
				<PopoverArrow />
				<PopoverBody display="flex" flexDirection="column" gap={2}>
					{actionsAllowed.map((action) => {
						const isDisabled =
							isActionDisabled && isActionDisabled(action, data);
						return (
							<Text
								key={action}
								cursor="pointer"
								textAlign="left"
								display={isDisabled ? 'none' : 'block'}
								onClick={() => {
									onAction(action, data);
									onClose();
								}}
							>
								{action}
							</Text>
						);
					})}
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default DataTableActions;
