import {
	Box,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Checkbox,
	Input,
	Text,
	VStack,
	Select,
	Button,
	Flex,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
	ICase,
	ILayer,
	ITemplateFormValues,
} from 'src/lib/schemas/account/template/TemplateSchema';
import { AspectRatioText } from '../testTemplates/commponents/AspectRatioText';
import RemoveBackgroundRow from '../testTemplates/commponents/RemoveBackgroundRow';

interface TestTableProps {
	caseItem: ICase;
	onDuplicate: (caseItem: ICase) => void;
}

const TestTable = ({ caseItem, onDuplicate }: TestTableProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isDisabled] = useState(false);
	const { setValue, getValues } = useFormContext<ITemplateFormValues>();

	const updateCase = (updatedLayers: ILayer[]) => {
		const updatedCases = [...getValues('cases')];
		const caseIndex = updatedCases.findIndex((c) => c.id === caseItem.id);
		if (caseIndex !== -1) {
			updatedCases[caseIndex] = { ...caseItem, layers: updatedLayers };
			setValue('cases', updatedCases);
		}
	};

	const handleCheckboxChange = (layerIndex: number, checked: boolean) => {
		const updatedLayers = [...caseItem.layers];
		updatedLayers[layerIndex].visible = checked;

		updateCase(updatedLayers);
	};

	const handleTypeChange = (layerIndex: number, newType: string) => {
		const updatedLayers = [...caseItem.layers];
		updatedLayers[layerIndex].type = newType;

		if (newType === 'text') {
			updatedLayers[layerIndex] = {
				...updatedLayers[layerIndex],
				content: 'Creative Style',
			};
		} else {
			const { content, ...rest } = updatedLayers[layerIndex];
			updatedLayers[layerIndex] = { ...rest };
		}

		updateCase(updatedLayers);
	};

	const handleNameChange = (layerIndex: number, newName: string) => {
		const updatedLayers = [...caseItem.layers];
		updatedLayers[layerIndex].name = newName;
		updateCase(updatedLayers);
	};

	const handleValueChange = (layerIndex: number, newValue: string) => {
		const updatedLayers = [...caseItem.layers];

		if (updatedLayers[layerIndex].type === 'image') {
			updatedLayers[layerIndex].imageUrl = newValue;
		} else {
			updatedLayers[layerIndex].content = newValue;
		}

		updateCase(updatedLayers);
	};

	const handleAddLayer = () => {
		const updatedLayers = [
			...(caseItem.layers || []),
			{
				name: '',
				type: 'Text',
				visible: false,
			},
		];
		updateCase(updatedLayers);
	};

	const onToggle = () => {
		if (!isDisabled) {
			setIsOpen(!isOpen);
		}
	};

	return (
		<VStack spacing={0} mb={5}>
			<Accordion
				allowToggle={!isDisabled}
				index={isOpen && !isDisabled ? 0 : -1}
				boxShadow="0px 0px 15px -3px rgba(0, 0, 0, 0.25)"
				borderRadius="8px"
				borderWidth="1px"
				borderColor={isDisabled ? 'gray.100' : 'gray.100'}
				w="full"
				onChange={onToggle}
			>
				<AccordionItem border="none">
					{({ isExpanded }) => (
						<>
							<Flex alignItems="center">
								<AccordionButton
									borderRadius="10px"
									_hover={{ bg: 'inherit' }}
									opacity={isDisabled ? 0.6 : 1}
									cursor={isDisabled ? 'default' : 'pointer'}
									flex="1"
									onClick={onToggle}
								>
									<Box
										py={5}
										px={2}
										textAlign="left"
										fontWeight="bold"
										fontSize="lg"
										color={isExpanded ? 'black' : 'gray.500'}
									>
										Case {caseItem.id}
									</Box>

									<AccordionIcon boxSize={6} />
								</AccordionButton>
								<Button
									ml={2}
									colorScheme="blue"
									size="sm"
									onClick={() => onDuplicate(caseItem)}
									mr="10px"
								>
									Duplicate
								</Button>
							</Flex>
							<AccordionPanel pb={4} px={0}>
								<Box px={4}>
									<Box mt="50px">
										<Table variant="simple">
											<Thead>
												<Tr>
													<Th>Visible</Th>
													<Th>Type</Th>
													<Th>Name</Th>
													<Th>Value</Th>
												</Tr>
											</Thead>
											<Tbody>
												{caseItem.layers && Array.isArray(caseItem.layers) ? (
													caseItem.layers.map((layer, index) => (
														<Tr key={index}>
															<Td>
																<Checkbox
																	isChecked={layer.visible}
																	onChange={(e) =>
																		handleCheckboxChange(
																			index,
																			e.target.checked,
																		)
																	}
																/>
															</Td>
															<Td>
																<Select
																	value={layer.type}
																	onChange={(e) =>
																		handleTypeChange(index, e.target.value)
																	}
																>
																	<option value="text">Text</option>
																	<option value="image">Image</option>
																</Select>
															</Td>
															<Td>
																<Input
																	value={layer.name}
																	onChange={(e) =>
																		handleNameChange(index, e.target.value)
																	}
																	placeholder="Enter name"
																	disabled={!layer.visible}
																/>
															</Td>
															<Td>
																<Box display="flex" alignItems="center">
																	<Input
																		value={
																			layer.type === 'text'
																				? layer.content
																				: layer.imageUrl
																		}
																		onChange={(e) =>
																			handleValueChange(index, e.target.value)
																		}
																		placeholder="Enter value"
																		disabled={!layer.visible}
																	/>
																	{layer.type === 'image' &&
																		layer.name === '$Product' && (
																			<Box ml={2}>
																				<AspectRatioText
																					imageUrl={layer.imageUrl}
																				/>
																			</Box>
																		)}
																</Box>
															</Td>
														</Tr>
													))
												) : (
													<Tr>
														<Td colSpan={4}>
															<Text>No layers available</Text>
														</Td>
													</Tr>
												)}
											</Tbody>
											<Button
												onClick={handleAddLayer}
												colorScheme="blue"
												mt={4}
											>
												Add Layer
											</Button>
										</Table>
									</Box>
								</Box>
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
			</Accordion>
		</VStack>
	);
};

export default TestTable;
