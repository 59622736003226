import {
	FieldValues,
	FieldPath,
	useController,
	useFormContext,
	get,
} from 'react-hook-form';
import SelectSearchInput, { SelectSearchInputProps } from './SelectSearchInput';
import { IOption } from 'src/lib/schemas/misc';

interface SelectSearchInputHookProps<T extends FieldValues>
	extends SelectSearchInputProps {
	name: FieldPath<T>;
	requirementsLabel?: string;
	defaultValue?: string;
}

function SelectSearchInputHook<T extends FieldValues = FieldValues>({
	name,
	requirementsLabel,
	onValueChangeCallback,
	...props
}: SelectSearchInputHookProps<T>) {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { field } = useController({ control, name });
	const { onChange, value } = field;
	const error = get(errors, name);

	const handleChange = (val: string | IOption) => {
		onChange(val);
		onValueChangeCallback?.(val);
	};

	return (
		<SelectSearchInput
			name={name}
			value={value}
			requirementsLabel={requirementsLabel}
			onChange={handleChange}
			error={error?.message}
			{...props}
			ref={register(name).ref}
		/>
	);
}

export default SelectSearchInputHook;
