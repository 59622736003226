import React, { useEffect, useState } from 'react';
import {
	Button,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { SelectInputHook, StringInputHook } from 'src/components/common/form';
import { IUser, IUserForm, UserFromSchema } from 'src/lib/schemas';
import { FILTERED_USER_ROLE_OPTIONS } from 'src/lib/constants';
import AlertMessage from '../common/AlertMessage';

const INITIAL_STATE = {
	name: '',
	email: '',
	role: '',
};
interface UserFormModalProps {
	isOpen: boolean;
	onClose: () => void;
	initialValues?: IUser;
	onSubmit: (payload: IUserForm) => void;
	title: string;
}

const UserFormModal: React.FC<UserFormModalProps> = ({
	isOpen,
	onClose,
	onSubmit,
	initialValues,
	title,
}) => {
	const [error, setError] = useState<string | null>(null);

	const formMethods = useForm<IUserForm>({
		resolver: zodResolver(UserFromSchema),
	});

	const { formState, reset } = formMethods;

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		} else {
			reset(INITIAL_STATE);
		}
	}, [initialValues]);

	const watchedEmail = formMethods.watch('email');

	useEffect(() => {
		if (watchedEmail && watchedEmail !== watchedEmail.trim()) {
			formMethods.setValue('email', watchedEmail.trim());
		}
	}, [watchedEmail]);

	const handleClose = () => {
		if (formState.isSubmitting) return;
		setError(null);
		reset(INITIAL_STATE);
		onClose();
	};

	const handleSubmit = formMethods.handleSubmit(async (payload) => {
		try {
			setError(null);
			await onSubmit(payload);
			onClose();
			reset(INITIAL_STATE);
		} catch (error: any) {
			const { message } = error.response.data;
			setError(message);
		}
	});

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<ModalOverlay zIndex={1800} />
			<ModalContent
				containerProps={{
					zIndex: 1900,
				}}
			>
				<ModalHeader pb={2}>
					<Heading>{title}</Heading>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<FormProvider {...formMethods}>
						<form onSubmit={handleSubmit}>
							<Flex direction="column" gap={6}>
								{error && <AlertMessage status="error">{error}</AlertMessage>}
								<StringInputHook
									name="name"
									label="Name"
									placeholder="Enter name"
									required
								/>
								<StringInputHook
									name="email"
									label="Email"
									placeholder="Enter email"
									required
									formControlProps={{ isDisabled: !!initialValues }}
								/>
								<SelectInputHook
									name="role"
									label="Role"
									placeholder="Select role"
									required
									options={FILTERED_USER_ROLE_OPTIONS}
								/>
								<Flex justifyContent="right" gap={6}>
									<Button onClick={handleClose}>Cancel</Button>
									<Button
										type="submit"
										colorScheme="secondary"
										isLoading={formState.isSubmitting}
										loadingText="Submitting..."
									>
										Submit
									</Button>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default UserFormModal;
