import { z } from 'zod';

export interface ILoginForm {
	email: string;
	password: string;
}

export const LoginSchema: z.ZodType<ILoginForm> = z.object({
	email: z.string().nonempty('Email is required').email('Email is not valid'),
	password: z.string().nonempty('Password is required'),
});
