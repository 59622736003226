import { FC, useState, useEffect, useRef } from 'react';
import { Box, Progress, Text } from '@chakra-ui/react';

interface ContentPendingProgressProps {
	time: number; // seconds
	isPendingGeneration?: boolean;
	onGenerateStatusChange: (val: boolean) => void;
	progressText?: string;
	isGray?: boolean;
}

const ContentPendingProgress: FC<ContentPendingProgressProps> = ({
	time,
	isPendingGeneration,
	onGenerateStatusChange,
	progressText = 'Generating',
	isGray = false,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const regularIntervalRef = useRef<any>();
	const duration = time * 1000;
	const intervalTime = 100;
	const totalSteps = duration / intervalTime;

	useEffect(() => {
		if (isPendingGeneration && !isLoading) {
			setIsLoading(true);
			onGenerateStatusChange(true);
		}
	}, [isPendingGeneration]);

	useEffect(() => {
		if (!isPendingGeneration) return;

		let currentStep = 0;
		regularIntervalRef.current = setInterval(() => {
			const currentProgress = (currentStep / totalSteps) * 100;
			setProgress(currentProgress);

			if (currentProgress < 25) currentStep += Math.floor(Math.random() * 6);
			else if (currentProgress < 50)
				currentStep += Math.floor(Math.random() * 3);
			else if (currentProgress < 75)
				currentStep += Math.floor(Math.random() * 2);
			else currentStep += 1;

			if (currentProgress > 90 && regularIntervalRef.current)
				clearInterval(regularIntervalRef.current);
		}, intervalTime);

		return () => {
			if (regularIntervalRef.current) clearInterval(regularIntervalRef.current);
		};
	}, [isLoading, isPendingGeneration]);

	useEffect(() => {
		if (isPendingGeneration) return;

		if (!isPendingGeneration && regularIntervalRef.current)
			clearInterval(regularIntervalRef.current);

		let currentProgress = progress;
		const completionInterval = setInterval(() => {
			currentProgress += 5;
			setProgress(currentProgress);

			if (currentProgress >= 100 && completionInterval) {
				clearInterval(completionInterval);
				setIsLoading(false);
			}
		}, intervalTime);

		if (!isLoading) {
			if (completionInterval) clearInterval(completionInterval);
			onGenerateStatusChange(false);
			setProgress(0);
		}

		return () => {
			if (completionInterval) clearInterval(completionInterval);
		};
	}, [isPendingGeneration, isLoading, progress]);

	return (
		<Box
			flex={1}
			justifyContent="center"
			px={4}
			py={6}
			minH="81px"
			display={isLoading ? 'block' : 'none'}
		>
			<Progress size="sm" borderRadius="full" bg="#EEEEEE" value={progress} />
			<Text textAlign="center" mt={2} color={isGray ? 'white' : 'black'}>
				{progressText}
			</Text>
		</Box>
	);
};

export default ContentPendingProgress;
