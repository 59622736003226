import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
interface SyncIconProps extends IconProps {
	size?: string;
	weight?: string;
}

const SyncIcon: FC<SyncIconProps> = ({
	color,
	width = '20px',
	height = '20px',
}) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.33203 10.5586C5.02734 11.4219 4.0625 11.8789 3.25 11.5742C2.38672 11.2695 1.92969 10.3555 2.23438 9.49219C2.79297 7.91797 3.70703 6.44531 4.92578 5.22656C9.39453 0.808594 16.5547 0.808594 21.0234 5.22656L21.8867 6.14062V3.5C21.8867 2.63672 22.6484 1.875 23.5117 1.875C24.4258 1.875 25.1367 2.63672 25.1367 3.5V10C25.1367 10.9141 24.4258 11.625 23.5117 11.625H17.0625C16.1484 11.625 15.4375 10.9141 15.4375 10C15.4375 9.13672 16.1484 8.375 17.0625 8.375H19.6016L18.7383 7.51172C15.5391 4.36328 10.4102 4.36328 7.21094 7.51172C6.34766 8.42578 5.6875 9.44141 5.33203 10.5586ZM1.98047 14.9766C2.03125 14.9258 2.13281 14.9258 2.18359 14.9258C2.28516 14.9258 2.33594 14.875 2.4375 14.875H8.9375C9.80078 14.875 10.5625 15.6367 10.5625 16.5C10.5625 17.4141 9.80078 18.125 8.9375 18.125H6.34766L7.21094 19.0391C10.4102 22.1875 15.5391 22.1875 18.7383 19.0391C19.6016 18.125 20.2617 17.1094 20.6172 15.9922C20.9219 15.1289 21.8867 14.6719 22.6992 14.9766C23.5625 15.2812 24.0195 16.1953 23.7148 17.0586C23.1562 18.6328 22.2422 20.0547 21.0234 21.3242C16.5547 25.7422 9.39453 25.7422 4.92578 21.3242L4.0625 20.4102V23C4.0625 23.9141 3.30078 24.625 2.4375 24.625C1.52344 24.625 0.8125 23.9141 0.8125 23V16.5508C0.8125 16.4492 0.8125 16.3477 0.8125 16.2969C0.8125 16.1953 0.8125 16.1445 0.863281 16.0938C0.914062 15.8398 1.06641 15.5859 1.26953 15.3828C1.47266 15.1797 1.72656 15.0273 1.98047 14.9766Z"
				fill={color ? (color as string) : '#3182CE'}
			/>
		</Icon>
	);
};

export default SyncIcon;
