import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import useFetch from './useFetch';
import { IGroup } from 'src/lib/schemas';
import { upsertElem } from 'src/lib/utils';
import { createGroup, getGroups } from 'src/services/config';
import { toastError } from 'src/services/toast';

const useGroups = () => {
	const { data, setData, isLoading, error, refetch } = useFetch(getGroups);

	const createDefaultGroup = async () => {
		try {
			await createGroup(
				upsertElem(data ?? [], { id: uuid(), name: 'Default' } as IGroup),
			);
			refetch();
		} catch (error: any) {
			toastError(error);
		}
	};

	useEffect(() => {
		if (error && !data) {
			createDefaultGroup();
		}
	}, [data, error]);

	return { isLoading: isLoading || !data, data, setData, error, refetch };
};

export default useGroups;
