import React, { useContext } from 'react';
import {
	Drawer,
	DrawerBody,
	DrawerContent,
	List,
	Skeleton,
	useDisclosure,
} from '@chakra-ui/react';
import NavListItem from './NavListItem';
import NestedNavList from './NestedNavList';
import UserContext from 'src/contexts/UserContext';
import { IMenuItem } from 'src/services/menu';

interface ISideBarProps {
	setDrawerOpen?: (open: boolean) => void;
}

function DrawerExample({ setDrawerOpen }: ISideBarProps) {
	const { onClose } = useDisclosure();
	const { user, navList, isLoading } = useContext(UserContext);

	return (
		<Drawer
			isOpen={true}
			placement="left"
			onClose={onClose}
			variant="clickThrough"
			trapFocus={false}
			blockScrollOnMount={false}
		>
			<DrawerContent
				bg="white"
				w="250px"
				maxW="250px"
				marginTop="72px"
				pt="10px"
				boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
			>
				<DrawerBody p={1} w="100%">
					<List>
						{isLoading ? (
							<>
								{[...Array(5)].map((_, index) => (
									<Skeleton key={index} height="20px" my="4" />
								))}
							</>
						) : navList && navList.length > 0 ? (
							navList.map((item: IMenuItem) =>
								item.children && item.children.length > 0 ? (
									<NestedNavList
										key={item._id}
										{...item}
										setDrawerOpen={setDrawerOpen}
									/>
								) : (
									<NavListItem key={item._id} {...item} />
								),
							)
						) : (
							<div></div>
						)}
					</List>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
}

export default DrawerExample;
