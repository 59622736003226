import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import 'src/styles/loader.css';

interface LoadingItemProps {
	isLoading: boolean;
	text: string;
	step: number;
	currentStep: number;
}

const LoadingItem: FC<LoadingItemProps> = ({
	text,
	isLoading,
	step,
	currentStep,
}) => {
	const loadingCircle = () => {
		return (
			<Box className="load-circle-loading">
				<svg width="23" height="23" viewBox="0 0 100 100">
					<circle className="circle-bg-loading" cx="50" cy="50" r="45"></circle>
					<circle
						className={
							step - 1 === currentStep
								? `circle-loading-${step}`
								: 'circle-bg-loading'
						}
						cx="50"
						cy="50"
						r="45"
					></circle>
				</svg>
			</Box>
		);
	};
	const checkMark = () => {
		return (
			<Box className="checkmark">
				<span className="checkmark">
					<div className="checkmark_stem"></div>
					<div className="checkmark_kick"></div>
				</span>
			</Box>
		);
	};

	return (
		<Flex
			alignItems="center"
			justifyContent="flex-start"
			color="white"
			borderRadius="5px"
			gap={{ base: 3, md: 5 }}
			p={{ base: 2, lg: 5 }}
		>
			<Box>{isLoading ? loadingCircle() : checkMark()}</Box>
			<Text color="white" fontSize={{ base: '12px', md: '16px', lg: '20px' }}>
				{text}
			</Text>
		</Flex>
	);
};

export default LoadingItem;
