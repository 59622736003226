import { z } from 'zod';

export interface IForgotPasswordFrom {
	email: string;
}

export const ForgotPasswordSchema: z.ZodType<IForgotPasswordFrom> = z.object({
	email: z.string().nonempty('Email is required').email('Email is not valid'),
});

export interface IPasswordForm {
	password: string;
	confirmPassword: string;
}

export const PasswordSchema: z.ZodType<IPasswordForm> = z
	.object({
		password: z
			.string()
			.nonempty('Password is required')
			.refine(
				(value: string) => /^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value),
				'Password must contain at least 1 letter and 1 number',
			),
		confirmPassword: z.string().nonempty('Confirm Password is required'),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords don't match",
		path: ['confirmPassword'],
	});
