import React, { ReactNode } from 'react';
import { Box, BoxProps, VStack } from '@chakra-ui/react';

interface ContainerProps extends BoxProps {
	children: ReactNode;
	withGradientBorder?: boolean;
	isBanner?: boolean;
}

const Container: React.FC<ContainerProps> = ({
	children,
	withGradientBorder,
	isBanner,
	...boxProps
}) => {
	return (
		<Box
			overflowY="auto"
			position="relative"
			borderRadius="lg"
			h="max-content"
			w="full"
			maxW="-webkit-fill-available"
			boxShadow=" 0px 0px 15px -3px rgba(0, 0, 0, 0.25)"
			p={isBanner ? 0 : '16px'}
			pl={isBanner ? 0 : '24px'}
			alignItems="flex-start"
			justifyContent="center"
			{...boxProps}
			_before={
				withGradientBorder
					? {
							content: '""',
							position: 'absolute',
							top: 0,
							left: 0,
							bottom: 0,
							width: '8px',
							background: 'linear-gradient(180deg, #F7480B 0%, #FF8A62 100%)',
							borderTopLeftRadius: 'lg',
							borderBottomLeftRadius: 'lg',
					  }
					: undefined
			}
		>
			<VStack align="start" spacing={1} maxW="-webkit-fill-available">
				{children}
			</VStack>
		</Box>
	);
};

export default Container;
