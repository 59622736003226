import { FC, useContext, useEffect, useRef, useState } from 'react';
import { BoxProps, Flex, Image, Text } from '@chakra-ui/react';

import ContentPendingProgress from 'src/components/common/ContentPendingProgress';
import Overlay from 'src/components/common/Overlay';
import { LockIcon } from 'src/assets/icons';
import {
	IAssistantCreativeData,
	IAttribute,
	IChannelMediaAttributes,
} from 'src/lib/schemas';
import { updateCampaignCreative } from 'src/services/campaign';
import { toastError } from 'src/services/toast';
import { CampaignContext } from 'src/contexts';
import { TextCreativeForm } from './CreativeForm';
import { getValidationRules } from './CreativeForm/utils/inputValidation';
import CreativeImageActions from './CreativeImageActions';

interface CreativeImageProps {
	creativeId: string;
	name?: string;
	width: number;
	height: number;
	source?: string;
	isPendingGeneration?: boolean;
	isLocked?: boolean;
	imageId?: string;
	hideDimensions?: boolean;
	attributes?: IAttribute[];
	mediaContent: IChannelMediaAttributes;
	creativeData?: IAssistantCreativeData;
	onEditCreative: () => void;
}

const CreativeImage: FC<CreativeImageProps> = ({
	creativeId,
	width,
	height,
	source,
	isPendingGeneration,
	isLocked,
	imageId,
	hideDimensions,
	attributes,
	mediaContent,
	creativeData,
	onEditCreative,
}) => {
	const [imageWrapperHeight, setImageWrapperHeight] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isActions, setIsActions] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const [imageSource, setImageSource] = useState(source);
	const resizeIntervalRef = useRef<any>();
	const intervalCountRef = useRef(0);
	const { id: campaignId } = useContext(CampaignContext);

	useEffect(() => {
		if (isLoading && !isPendingGeneration) {
			const random = (Math.random() + 1).toString(36).substring(7);
			setImageSource(`${source}?${random}`);
		}
	}, [isPendingGeneration, isLoading]);

	const handleResize = () => {
		const imageRatio = width / height;
		const containerWidth = containerRef.current?.offsetWidth;
		const wrapperHeight = Number(
			(containerWidth ? containerWidth / imageRatio : 200).toFixed(0),
		);
		setImageWrapperHeight(wrapperHeight);
		intervalCountRef.current = intervalCountRef.current + 1;
		if (intervalCountRef.current > 10 && resizeIntervalRef.current) {
			clearInterval(resizeIntervalRef.current);
		}
	};

	useEffect(() => {
		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [width, height]);

	useEffect(() => {
		resizeIntervalRef.current = setInterval(() => {
			handleResize();
		}, 100);
		return () => {
			if (resizeIntervalRef.current) clearInterval(resizeIntervalRef.current);
		};
	}, []);

	const calculateTotalHeight = (inputs: IAttribute[]) =>
		inputs &&
		inputs.reduce((totalHeight, input) => {
			const validationRules = getValidationRules(input);
			const isStringType = input.type === 'String';
			const isSingleline = validationRules?.multiline?.value === false;

			const isTextAreaType =
				isStringType &&
				!isSingleline &&
				(!validationRules.maxLength || validationRules.maxLength.value >= 90);
			return (
				totalHeight +
				((isTextAreaType ? 127 : isStringType ? 84 : 62) || 0) +
				16
			);
		}, 0);

	const formHeight = attributes ? calculateTotalHeight(attributes) : 0;

	const renderHeight = Math.min(imageWrapperHeight, height);

	const handleInputSubmit = async (formData: any) => {
		const payload = {
			attributes: formData,
		};
		if (!campaignId || !creativeId) return;
		try {
			await updateCampaignCreative(campaignId, creativeId, payload);
		} catch (error: any) {
			toastError(error);
		}
	};

	return (
		<Flex
			flex={1}
			direction="column"
			position="relative"
			height="full"
			w="full"
			ref={containerRef}
			gap={1}
		>
			{!isLoading && !isPendingGeneration && attributes && (
				<TextCreativeForm
					inputs={attributes}
					onSubmit={handleInputSubmit}
					initialValues={mediaContent}
					stringTypeCasting
					creativeData={creativeData}
				/>
			)}

			<Flex
				h={renderHeight + (isLoading ? formHeight : 0)}
				w="full"
				justify="center"
				alignItems="center"
				id={imageId}
				onMouseEnter={() => setIsActions(true)}
				onMouseLeave={() => setIsActions(false)}
			>
				{isLocked && <LockedImageOverlay height={renderHeight + formHeight} />}
				<ContentPendingProgress
					time={120}
					isPendingGeneration={isPendingGeneration}
					onGenerateStatusChange={(val) => setIsLoading(val)}
				/>
				<Flex position="relative">
					<Image
						display={isLoading || isPendingGeneration ? 'none' : 'block'}
						src={imageSource}
						fallbackSrc="/fusion-loader.gif"
						h={renderHeight}
						w="auto"
					/>
					{isActions && !isLoading && !isPendingGeneration && !isLocked && (
						<CreativeImageActions onEdit={onEditCreative} />
					)}
				</Flex>
			</Flex>
			{!hideDimensions && (
				<Text textAlign="center">
					{width}x{height}
				</Text>
			)}
		</Flex>
	);
};

const LockedImageOverlay: FC<{ height: BoxProps['height'] }> = ({ height }) => {
	return (
		<Overlay
			height={height}
			children={
				<Flex w="full" h="full" justify="center" align="center">
					<LockIcon color="white" w={6} h={6} />
				</Flex>
			}
		/>
	);
};

export default CreativeImage;
