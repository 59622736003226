import { LegacyRef, ReactNode, forwardRef } from 'react';
import {
	Flex,
	Text,
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	Input,
	InputProps,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

export interface CurrencyInputProps {
	label?: string;
	subLabel?: string;
	requirementsLabel?: string;
	id?: string;
	placeholder?: string;
	type?: 'text' | 'email' | 'password';
	error?: ReactNode;
	tooltip?: string;
	required?: boolean;

	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	inputProps?: InputProps;
}

const CurrencyInput = (
	{
		error,
		label,
		subLabel,
		requirementsLabel,
		placeholder,
		required,
		formControlProps,
		formLabelProps,
		inputProps,
	}: CurrencyInputProps,
	ref: LegacyRef<HTMLInputElement>,
) => {
	const hasError = !!error;

	const renderLabelIndicator = () => {
		if (!required) return null;

		if (error) return <InfoIcon color="#e53e3e" />;

		return <Text color="#e53e3e">*</Text>;
	};

	const errorFocusProps = error
		? {
				boxShadow: '0 0 0 1px #E53E3E',
				borderColor: '#E53E3E',
		  }
		: {
				boxShadow: '0 0 0 1px #3182ce',
				borderColor: '#3182ce',
		  };

	return (
		<FormControl isInvalid={hasError} {...formControlProps}>
			{label && (
				<Flex justify="space-between" alignItems="start" direction="column">
					<FormLabel mb="2" {...formLabelProps}>
						<Flex gap={1} alignItems="left">
							{label}
							{subLabel && (
								<Text color="#718096" fontSize="smaller">
									{subLabel}
								</Text>
							)}
							{renderLabelIndicator()}
						</Flex>
						{requirementsLabel && (
							<Text fontSize="12px" color="#959595">
								{requirementsLabel}
							</Text>
						)}
					</FormLabel>
				</Flex>
			)}
			<Input
				size="md"
				maxW="150px"
				marginTop="9px"
				fontSize="14px"
				_placeholder={{ color: '#A0AEC0' }}
				placeholder={placeholder}
				{...inputProps}
				ref={ref}
				_focusVisible={errorFocusProps}
			/>
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default forwardRef(CurrencyInput);
