import { FC } from 'react';
import { Flex, Heading, Image } from '@chakra-ui/react';

interface AppHeaderProps {
	logo: string;
	name: string;
}

const AppHeader: FC<AppHeaderProps> = ({ logo, name }) => {
	return (
		<Flex gap={4} alignItems="center">
			<Image src={logo} w={10} minW={10} h={10} />
			<Heading fontWeight="semibold">{name}</Heading>
		</Flex>
	);
};

export default AppHeader;
