import { ITokens, IUser } from 'src/lib/schemas';
import http from './http';
import {
	IExecutionOutput,
	IScrappingDataResponse,
	IScrappingFullData,
	IScrappingRegisterResponse,
} from 'src/lib/schemas/scrapping';

const apiVersion = 'v1';
const authUrl = `/${apiVersion}/auth`;
const scrapeUrl = `/${apiVersion}/scrape`;
const usersUrl = `/${apiVersion}/users`;
const tokensKey = 'tokens';
const userKey = 'user';

const setTokens = (tokens: ITokens) => {
	localStorage.setItem(tokensKey, JSON.stringify(tokens));
};

const setCurrentUser = (user: IUser) => {
	localStorage.setItem(userKey, JSON.stringify(user));
};

export const registerByDomain = async (domainUrl: string) => {
	const { data } = await http.post<IScrappingRegisterResponse>(
		`${authUrl}/register-by-domain`,
		{ domainUrl },
	);
	setTokens(data.tokens);
	setCurrentUser(data.user);
	return data;
};

export const scrapeData = async (url: string) => {
	const payload: any = {
		inputs: {
			url,
			importCatalogs: 'false',
			sync: 'false',
			skipFields: [],
		},
	};
	const { data } = await http.post<any>(
		'v2/apps/fusion_ai.web_scraper/execute/scrape_url',
		payload,
	);
	return data;
};

export const scrapeCatalogs = async (url: string) => {
	const { data } = await http.post<any>(
		'v2/apps/fusion_ai.web_scraper/execute/scrape_and_save_catalogs',
		{
			inputs: {
				url,
			},
		},
	);
	return data;
};

export const getData = async (url: string) => {
	const { data } = await http.get<IScrappingDataResponse>(
		`${scrapeUrl}/domain?url=${url}`,
	);
	return data;
};
export const completeRegistration = async (payload: any, id: string) => {
	const { data } = await http.post<any>(
		`${usersUrl}/${id}/register-demo`,
		payload,
	);
	setTokens(data.tokens);
	setCurrentUser(data.user);
	return data;
};
export const geFullUrlData = async (url: string) => {
	const { data } = await http.post<IExecutionOutput<IScrappingFullData>>(
		`${scrapeUrl}/url`,
		{ 
			inputs: 
			{
				url:url,
				sync:"true"
			} 
		},
	);
	return data.output;
};