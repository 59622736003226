import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { EditIcon, RemoveIcon, DownloadIcon } from 'src/assets/icons';

interface CarouselActionsProps {
	onEdit: () => void;
	onRemove: () => void;
	isRemovingDD: boolean;
	isLoading: boolean;
	onDownload: () => void;
}

const CarouselActions: FC<CarouselActionsProps> = ({
	onEdit,
	onRemove,
	isRemovingDD,
	isLoading,
	onDownload,
}) => {
	return (
		<Flex
			position="absolute"
			bottom="0"
			justify="center"
			alignItems="center"
			zIndex={1}
			w="full"
			height="40%"
			transition="ease-in"
			px={8}
			gap={4}
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.65) 40.09%, rgba(0, 0, 0, 0) 96.11%)"
			borderRadius="0 0 15px 15px"
		>
			{!isLoading && (
				<>
					<Flex
						direction="column"
						color="white"
						alignItems="center"
						gap={1}
						cursor="pointer"
						onClick={(e) => {
							e.stopPropagation();
							onDownload();
						}}
					>
						<DownloadIcon color="white" />
						<Text color="white" align="center">
							Download
						</Text>
					</Flex>
					<Flex
						direction="column"
						color="white"
						alignItems="center"
						gap={1}
						cursor="pointer"
						onClick={() => onEdit()}
					>
						<EditIcon color="white" />
						<Text color="white" align="center">
							Edit design
						</Text>
					</Flex>
				</>
			)}
			<Flex
				direction="column"
				color="white"
				alignItems="center"
				gap={1}
				cursor="pointer"
				onClick={
					!isRemovingDD
						? (e) => {
								e.stopPropagation();
								onRemove();
						  }
						: () => {}
				}
			>
				<RemoveIcon color="white" />
				<Text color="white" align="center">
					Remove design
				</Text>
			</Flex>
		</Flex>
	);
};

export default CarouselActions;
