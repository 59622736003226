import { Box, Flex, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
	ICase,
	ITemplateFormValues,
} from 'src/lib/schemas/account/template/TemplateSchema';
import TestTable from '../../parts/TestTable';
import TemplateContext from 'src/contexts/templates/TemplatesContext';

export const TableHook = () => {
	const { layers, loadingLayers, selectedTemplate } =
		useContext(TemplateContext);
	const { setValue, watch, getValues } = useFormContext<ITemplateFormValues>();
	const cases = watch('cases');

	const handleDuplicateCase = (caseItem: ICase) => {
		const currentCases = getValues('cases');
		const newCase = {
			...caseItem,
			id: currentCases.length + 1,
			isDuplicate: true,
		};
		setValue('cases', [...currentCases, newCase]);
	};

	useEffect(() => {
		if (layers.length > 0) {
			setValue('cases', [
				{
					id: 1,
					layers: layers,
				},
			]);
		}
	}, [layers, setValue]);

	if (loadingLayers) {
		return (
			<Flex mt="10px" justifyContent="center">
				<Spinner />
			</Flex>
		);
	}

	if (selectedTemplate && selectedTemplate.variations!.length >= 1) {
		return (
			<>
				{cases.map((caseItem) => (
					<Box key={caseItem.id} mt="50px">
						<TestTable onDuplicate={handleDuplicateCase} caseItem={caseItem} />
					</Box>
				))}
			</>
		);
	}
};
