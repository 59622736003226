import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { EditIcon } from 'src/assets/icons';

interface CreativeImageActionsProps {
	onEdit: () => void;
}

const CreativeImageActions: FC<CreativeImageActionsProps> = ({ onEdit }) => {
	return (
		<Flex
			position="absolute"
			bottom="0"
			justify="center"
			alignItems="flex-end"
			pb="50px"
			zIndex={1}
			w="full"
			height="40%"
			transition="ease-in"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.65) 40.09%, rgba(0, 0, 0, 0) 96.11%)"
		>
			<Flex
				direction="column"
				color="white"
				alignItems="center"
				gap={1}
				cursor="pointer"
				onClick={onEdit}
			>
				<EditIcon color="white" />
				<Text align="center">Edit image layer </Text>
			</Flex>
		</Flex>
	);
};

export default CreativeImageActions;
