import { useContext, useState } from 'react';
import {
	Flex,
	Text,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from '@chakra-ui/react';
import TemplateContext from 'src/contexts/templates/TemplatesContext';

interface LayerDropDownProps {
	onSelect: (layerName: string) => void;
	selectedLayer: string;
	borderColor:string;
}

export const LayerDropDown = ({
	onSelect,
	selectedLayer,
	borderColor
}: LayerDropDownProps) => {
	const { layers } = useContext(TemplateContext);

	return (
		<>
			<Flex direction="column">
				<Text pl={5}>Layer</Text>
				<Menu>
					<MenuButton
						border="1px solid"
						borderColor={borderColor}
						w="250px"
						h="40px"
						borderRadius="md"
						textAlign="left"
						px={3}
						_hover={{ borderColor: 'gray.500', bg: 'gray.400' }}
					>
						{selectedLayer || ''}
					</MenuButton>
					<MenuList maxH="300px" overflowY="auto">
						{layers.length > 0 ? (
							layers.map((layer, index) => (
								<MenuItem
									key={index}
									value={layer.name}
									onClick={() => onSelect(layer.name)}
								>
									{layer.name}
								</MenuItem>
							))
						) : (
							<MenuItem disabled>No layers available</MenuItem>
						)}
					</MenuList>
				</Menu>
			</Flex>
		</>
	);
};
