import { Box, Text } from '@chakra-ui/react';
import { WarningIcon } from 'src/assets/icons';

const NotAllowedMessage = () => {
	return (
		<Box
			display={'inline-flex'}
			bg="#ffedee"
			py={1}
			px={2}
			borderRadius="sm"
			gap={1}
			width="auto"
		>
			<Box>
				<WarningIcon color="#E53E3E" boxSize="16px" />
			</Box>
			<Text display={'inline'} lineHeight={'25px'} color="#E53E3E">
				You don’t have permission to this section, please request access to an
				admin account.
			</Text>
		</Box>
	);
};

export default NotAllowedMessage;
