import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const RemoveIcon: FC<IconProps> = ({
	color,
	width = '20px',
	height = '20px',
	...props
}) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M17.7016 0.964444C27.3649 0.964444 35.1943 8.79387 35.1943 18.4572C35.1943 28.1206 27.3649 35.95 17.7016 35.95C8.03824 35.95 0.208814 28.1206 0.208814 18.4572C0.208814 8.79387 8.03824 0.964444 17.7016 0.964444ZM26.2364 23.1125L21.6516 18.4572L26.2364 13.8724C26.589 13.5903 26.589 13.026 26.2364 12.6733L23.4855 9.92243C23.1328 9.56976 22.5685 9.56976 22.2864 9.92243L17.7016 14.5072L13.1168 9.92243C12.7641 9.56976 12.1998 9.56976 11.9177 9.92243L9.09627 12.6733C8.74359 13.026 8.74359 13.5903 9.09627 13.8724L13.7516 18.4572L9.09627 23.042C8.74359 23.3947 8.74359 23.959 9.09627 24.2411L11.8471 27.0625C12.1998 27.4152 12.7641 27.4152 13.0462 27.0625L17.7016 22.4072L22.2864 27.0625C22.5685 27.4152 23.1328 27.4152 23.4855 27.0625L26.2364 24.3116C26.589 23.959 26.589 23.3947 26.2364 23.1125Z"
				fill={color ? (color as string) : '#3182CE'}
			/>
		</Icon>
	);
};

export default RemoveIcon;
