import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { SelectSearchInputHook } from 'src/components/common/form';
import { IAttribute } from 'src/lib/schemas';

interface SelectInputWidgetProps {
	input: IAttribute;
	fieldChanges?: () => void;
	validationRules: Record<string, any>;
}

const SelectInputWidget: FC<SelectInputWidgetProps> = ({
	input,
	fieldChanges,
	validationRules,
}) => {
	const { control } = useFormContext();
	const options =
		input.options?.map((item) => {
			return { label: item.value, value: item.id };
		}) || [];

	const isRequred = validationRules?.required?.value;
	if (!isRequred) validationRules.required = false;
	else validationRules.required = validationRules.required.message;

	return (
		<Controller
			name={input.property}
			control={control}
			defaultValue={input.default}
			rules={validationRules}
			render={() => (
				<SelectSearchInputHook
					name={input.property}
					label={input.name}
					placeholder="Select/search"
					options={options}
					required={isRequred}
					onValueChangeCallback={() => {
						fieldChanges && fieldChanges();
					}}
				/>
			)}
		/>
	);
};

export default SelectInputWidget;
