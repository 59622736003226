import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const CommentIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M1.31329 7.5C1.31329 4.185 4.00129 1.5 7.31704 1.5H10.5915C13.959 1.5 16.6883 4.23 16.6883 7.5975C16.6883 9.8175 15.483 11.8575 13.5413 12.93L7.50079 16.275V13.5075H7.45054C4.08304 13.5825 1.31329 10.875 1.31329 7.5ZM7.31704 3C4.82929 3 2.81329 5.0175 2.81329 7.5C2.81329 10.0275 4.89079 12.06 7.41679 12.0075L7.68004 12H9.0008V13.725L12.816 11.6175C14.2793 10.8075 15.1883 9.27 15.1883 7.5975C15.1883 5.055 13.1303 3 10.5915 3H7.31704Z"
				fill={color ? (color as string) : '#6D6D6D'}
			/>
		</Icon>
	);
};

export default CommentIcon;
