import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const FusionBannerIcon: FC<IconProps> = ({ height, width }) => {
	return (
		<Icon
			height={height}
			width={width}
			viewBox="0 0 451 347"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="277.5"
				cy="173.5"
				r="173.5"
				fill="#F7A183"
				fillOpacity="0.2"
			/>
			<circle
				cx="132"
				cy="136.048"
				r="131.952"
				fill="#E75320"
				fillOpacity="0.8"
			/>
			<g clipPath="url(#clip0_4033_5778)">
				<path
					d="M149.723 121.204C141.001 117.513 135.705 116.091 134.287 115.756C134.051 115.697 133.913 115.499 133.913 115.243V109.992C138.264 106.341 141.1 100.261 141.1 93.3724C141.1 82.2201 133.638 73.1602 124.443 73.1602C115.249 73.1602 107.787 82.2004 107.787 93.3724C107.787 100.261 110.642 106.341 114.973 109.992V115.243C114.973 115.479 114.816 115.697 114.599 115.756C113.181 116.111 107.885 117.532 99.1634 121.204C88.8663 125.546 89.2995 135.001 89.2995 135.001H159.587C159.587 135.001 160.02 125.546 149.723 121.204Z"
					fill="white"
					fillOpacity="0.8"
				/>
				<path
					d="M197.251 170.708L146.987 154.799C146.987 154.799 146.79 154.76 146.711 154.76C146.14 154.76 145.667 155.312 145.845 155.924L160.906 209.021C161.024 209.435 161.398 209.692 161.792 209.692C162.029 209.692 162.265 209.593 162.442 209.416L171.636 199.704C171.814 199.507 172.05 199.428 172.286 199.428C172.522 199.428 172.759 199.527 172.936 199.704L187.269 214.745C187.446 214.942 187.682 215.021 187.919 215.021C188.155 215.021 188.391 214.923 188.568 214.745L202.724 199.783C203.059 199.428 203.059 198.875 202.724 198.52L188.273 183.361C187.938 183.006 187.938 182.453 188.273 182.098L197.625 172.228C198.078 171.735 197.881 170.926 197.231 170.728L197.251 170.708Z"
					fill="white"
					fillOpacity="0.8"
				/>
				<path
					d="M136.65 149.586H85.4406C83.8064 149.586 82.4873 148.263 82.4873 146.625C82.4873 144.987 83.8064 143.664 85.4406 143.664H136.65C138.284 143.664 139.603 144.987 139.603 146.625C139.603 148.263 138.284 149.586 136.65 149.586Z"
					fill="white"
					fillOpacity="0.8"
				/>
				<path
					d="M124.975 162.771H85.4406C83.8064 162.771 82.4873 161.449 82.4873 159.81C82.4873 158.172 83.8064 156.85 85.4406 156.85H124.975C126.609 156.85 127.928 158.172 127.928 159.81C127.928 161.449 126.609 162.771 124.975 162.771Z"
					fill="white"
					fillOpacity="0.8"
				/>
				<path
					d="M124.975 175.937H85.4406C83.8064 175.937 82.4873 174.615 82.4873 172.976C82.4873 171.338 83.8064 170.016 85.4406 170.016H124.975C126.609 170.016 127.928 171.338 127.928 172.976C127.928 174.615 126.609 175.937 124.975 175.937Z"
					fill="white"
					fillOpacity="0.8"
				/>
				<path
					d="M136.65 189.123H85.4406C83.8064 189.123 82.4873 187.8 82.4873 186.162C82.4873 184.524 83.8064 183.201 85.4406 183.201H136.65C138.284 183.201 139.603 184.524 139.603 186.162C139.603 187.8 138.284 189.123 136.65 189.123Z"
					fill="white"
					fillOpacity="0.8"
				/>
				<path
					d="M152.657 201.578H80.8139C74.8089 201.578 69.9065 196.683 69.9065 190.643V74.8567C69.9065 68.8364 74.7892 63.9215 80.8139 63.9215H168.092C174.097 63.9215 179 68.8167 179 74.8567V158.726L184.906 160.601V74.8567C184.906 65.5598 177.366 58 168.092 58H80.8139C71.5407 58 64 65.5598 64 74.8567V190.662C64 199.959 71.5407 207.519 80.8139 207.519H154.35L152.676 201.598L152.657 201.578Z"
					fill="white"
					fillOpacity="0.8"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4033_5778">
					<rect
						width="139"
						height="157"
						fill="white"
						transform="translate(64 58)"
					/>
				</clipPath>
			</defs>
		</Icon>
	);
};

export default FusionBannerIcon;
