import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const LikeIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M12.5227 4.12462C11.6062 4.07962 10.5135 4.50712 9.60524 5.74462L9.00149 6.56212L8.39699 5.74462C7.48799 4.50712 6.39449 4.07962 5.47799 4.12462C4.54574 4.17712 3.71624 4.70962 3.29549 5.55712C2.88149 6.39712 2.82074 7.64212 3.65474 9.17212C4.46024 10.6496 6.09749 12.3746 9.00149 14.1296C11.904 12.3746 13.5405 10.6496 14.346 9.17212C15.1792 7.64212 15.1185 6.39712 14.7037 5.55712C14.283 4.70962 13.4542 4.17712 12.5227 4.12462ZM15.663 9.89212C14.6497 11.7521 12.6622 13.7321 9.37874 15.6446L9.00149 15.8696L8.62349 15.6446C5.33924 13.7321 3.35174 11.7521 2.33699 9.89212C1.31699 8.01712 1.27949 6.24712 1.95149 4.88962C2.61674 3.54712 3.93674 2.70712 5.40224 2.63212C6.64049 2.56462 7.92824 3.05212 9.00074 4.13962C10.0725 3.05212 11.3602 2.56462 12.5977 2.63212C14.0632 2.70712 15.3832 3.54712 16.0485 4.88962C16.7205 6.24712 16.683 8.01712 15.663 9.89212Z"
				fill={color ? (color as string) : '#6D6D6D'}
			/>
		</Icon>
	);
};

export default LikeIcon;
