import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const usePagination = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const initialPage = parseInt(searchParams.get('page') || '1', 10);

	const [page, setPage] = useState(initialPage);
	const [pages, setPages] = useState(1);

	useEffect(() => {
		const pageParam = parseInt(searchParams.get('page') || '1', 10);
		setPage(pageParam);
	}, [location.search]);

	const nextPage = () => {
		const nextPageNumber = Math.min(page + 1, pages);
		setPage(nextPageNumber);
		updateUrl(nextPageNumber);
	};

	const prevPage = () => {
		const prevPageNumber = Math.max(page - 1, 1);
		setPage(prevPageNumber);
		updateUrl(prevPageNumber);
	};

	const goToPage = (pageNumber: number) => {
		const currentPage = Math.max(1, Math.min(pageNumber, pages));
		setPage(currentPage);
		updateUrl(currentPage);
	};

	const handleSetPages = (totalPages: number) => {
		setPages(totalPages);
	};

	const updateUrl = (pageNumber: number) => {
		navigate(`?page=${pageNumber}`);
	};

	return {
		page,
		pages,
		setPages: handleSetPages,
		nextPage,
		prevPage,
		goToPage,
	};
};

export default usePagination;
