import { FC, useState } from 'react';

import AppInputConfigContext, {
	IAppInputConfig,
	IAppInputLoading,
} from './AppInputConfigContext';

interface AppInputConfigContextProps {
	children: React.ReactNode;
}

const AppInputConfigProvider: FC<AppInputConfigContextProps> = ({
	children,
}) => {
	const [loadingInputs, setLoadingInputs] = useState<IAppInputLoading>({});
	const [loadedInputs, setLoadedInputs] = useState<IAppInputLoading>({});
	const [inputConfig, setInputConfig] = useState<IAppInputConfig>({});

	const handleLoadingInputs = (inputs: IAppInputLoading) => {
		setLoadingInputs((prevState) => ({ ...prevState, ...inputs }));
	};

	const handleLoadedInputs = (inputs: IAppInputLoading) => {
		setLoadedInputs((prevState) => ({ ...prevState, ...inputs }));
	};

	const handleSetInputConfig = (config: IAppInputConfig) => {
		setInputConfig((prevState) => ({ ...prevState, ...config }));
	};

	return (
		<AppInputConfigContext.Provider
			value={{
				loadingInputs,
				loadedInputs,
				inputConfig,
				setInputConfig: handleSetInputConfig,
				setLoadingInputConfig: handleLoadingInputs,
				setLoadedInputConfig: handleLoadedInputs,
			}}
		>
			{children}
		</AppInputConfigContext.Provider>
	);
};

export default AppInputConfigProvider;
