import { FormEvent } from 'react';
import {
	FieldPath,
	FieldValues,
	get,
	useController,
	useFormContext,
} from 'react-hook-form';

import CurrencyInput, { CurrencyInputProps } from './CurrencyInput';
import { formatCurrency, parseCurrency } from 'src/lib/utils';

export interface CurrencyInputHookProps<T extends FieldValues>
	extends CurrencyInputProps {
	name: FieldPath<T>;
	requirementsLabel?: string;
}

function CurrencyInputHook<T extends FieldValues = FieldValues>({
	name,
	requirementsLabel,
	...props
}: CurrencyInputHookProps<T>) {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { field } = useController({ control, name });
	const { onChange, value } = field;

	const handleChange = (event: FormEvent<HTMLInputElement>) => {
		onChange(parseCurrency(event.currentTarget.value));
	};

	const error = get(errors, name);

	return (
		<CurrencyInput
			id={name}
			error={error?.message}
			requirementsLabel={requirementsLabel}
			{...props}
			inputProps={{
				...props.inputProps,
				onChange: (e: any) => {
					handleChange(e);
					props.inputProps?.onChange?.(e);
				},
				value: value ? formatCurrency(value) : '',
			}}
			ref={register(name).ref}
		/>
	);
}

export default CurrencyInputHook;
