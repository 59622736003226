import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const DragHandleIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			width="16px"
			height="16px"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
				fill={color ? (color as string) : '#E2E8F0'}
			/>
		</Icon>
	);
};

export default DragHandleIcon;
