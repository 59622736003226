import { FC, useState } from 'react';
import {
	Box,
	Flex,
	Text,
	UnorderedList,
	ListItem,
	useDisclosure,
} from '@chakra-ui/react';

import GoalEditModal from './GoalEditModal';
import { EditIcon } from 'src/assets/icons';
import { ICampaignBriefGoal } from 'src/lib/schemas';
import { IOption } from 'src/lib/schemas/misc';

interface BriefGoalsProps {
	campaignGoals: Array<ICampaignBriefGoal>;
	onGoalsChange: (val: Array<ICampaignBriefGoal>) => void;
}

const BriefGoals: FC<BriefGoalsProps> = ({ campaignGoals, onGoalsChange }) => {
	const [selectedGoal, setSelectedGoal] = useState<IOption | null>(null);
	const { isOpen, onClose, onOpen } = useDisclosure();

	const handleGoalEdit = (goal: IOption) => {
		setSelectedGoal(goal);
		onOpen();
	};

	const handleGoalsChange = async (val: string) => {
		if (selectedGoal) {
			const newGoals = campaignGoals.map((goal) => {
				if (goal.title === selectedGoal.value) {
					return {
						...goal,
						title: val,
					};
				}
				return goal;
			});
			await onGoalsChange(newGoals);
		}
	};

	return (
		<>
			<GoalEditModal
				isOpen={isOpen}
				onClose={onClose}
				value={selectedGoal?.label || ''}
				onApply={handleGoalsChange}
			/>
			<Flex
				flex={1}
				direction="column"
				gap={4}
				px={6}
				py={4}
				bg="white"
				height="auto"
				borderRadius="lg"
				boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.15)"
			>
				<Text fontWeight="semibold">Campaign Goals</Text>
				{campaignGoals
					.map((goal) => ({
						value: goal.title,
						label: goal.title,
						description: goal.description,
					}))
					.map((goal) => (
						<Flex key={goal.value} justifyContent="space-between" gap={3}>
							<UnorderedList>
								<ListItem fontSize="sm">{goal.label}</ListItem>
							</UnorderedList>
							<Flex gap={4}>
								<Box
									cursor="pointer"
									onClick={() => {
										handleGoalEdit(goal);
									}}
								>
									<EditIcon />
								</Box>
							</Flex>
						</Flex>
					))}
			</Flex>
		</>
	);
};

export default BriefGoals;
