import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const AnalyticsIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11V16C9 16.5523 8.55228 17 8 17C7.44772 17 7 16.5523 7 16V11Z"
				fill={color ? (color as string) : '#5C5F62'}
			/>
			<path
				d="M11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V6Z"
				fill={color ? (color as string) : '#5C5F62'}
			/>
			<path
				d="M15 4C15 3.44772 15.4477 3 16 3C16.5523 3 17 3.44772 17 4V16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16V4Z"
				fill={color ? (color as string) : '#5C5F62'}
			/>
			<path
				d="M3 8C3 7.44772 3.44772 7 4 7C4.55228 7 5 7.44772 5 8V16C5 16.5523 4.55228 17 4 17C3.44772 17 3 16.5523 3 16V8Z"
				fill={color ? (color as string) : '#5C5F62'}
			/>
		</Icon>
	);
};

export default AnalyticsIcon;
