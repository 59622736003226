import { FC } from 'react';
import { Flex, Box, Text, BoxProps, NumberInputProps } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { IOption } from 'src/lib/schemas/misc';
import { CloseIcon } from '@chakra-ui/icons';
import { CurrencyInputHook, NumberInputHook } from 'src/components/common/form';
import { ICampaignGoal } from 'src/lib/schemas';
import { InputProps } from 'react-select';

interface CampaignGoalsListProps {
	name: string;
	options: IOption[];
	onRemove: (val: IOption) => void;
	boxProps?: BoxProps;
	inputProps?: NumberInputProps | InputProps;
	title?: string;
}

const CampaignGoalsList: FC<CampaignGoalsListProps> = ({
	name,
	options,
	onRemove,
	boxProps,
	inputProps,
	title,
}) => {
	const { control } = useFormContext();
	const { field } = useController({ control, name });
	const { value } = field;

	if (!value?.length) return null;

	const goals = value as ICampaignGoal[];

	return (
		<Box mt={6} {...boxProps}>
			<Flex gap={6} flexWrap="wrap">
				{goals.map((item, index: number) => {
					const goal = options.find((v: IOption) => v.value === item.id);
					if (!goal) return null;
					return (
						<Flex
							key={item.id}
							flex={1}
							minW="300px"
							maxW="300px"
							direction="column"
							p={4}
							gap={2}
							borderRadius="lg"
							bg="white"
							justify="space-between"
							boxShadow="0px 1px 6px 0px rgba(0, 0, 0, 0.15)"
						>
							<Flex gap={1}>
								<Text flex={1}>{goal.label}</Text>
								<CloseIcon
									boxSize={3}
									color="#737981"
									cursor="pointer"
									onClick={() => onRemove(goal)}
								/>
							</Flex>
							<Text color="#737981">{goal.description}</Text>
							{goal.supportType === 'amount' ? (
								<CurrencyInputHook
									name={`${name}.${index}.target`}
									label={goal.title}
									placeholder="$0"
									inputProps={{ ...(inputProps as any), minWidth: '270px' }}
								/>
							) : (
								<NumberInputHook
									name={`${name}.${index}.target`}
									label={goal.title}
									placeholder="0"
									inputProps={inputProps as NumberInputProps}
								/>
							)}
						</Flex>
					);
				})}
			</Flex>
		</Box>
	);
};

export default CampaignGoalsList;
