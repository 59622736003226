import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Link, Collapse, Icon } from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import GoogleDriveIcon from 'src/assets/icons/GoogleDriveIcon';

import { Variation } from '../../parts/VariationsList';
import { ConfigureLayerHook } from './configureLayers/ConfigureLayerHook';

interface VariationItemProps {
	variation: Variation;
}

export const VariationItem: React.FC<VariationItemProps> = ({ variation }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => setIsOpen(!isOpen);

	const getViewUrl = (layeredFile: string) => {
		if (!layeredFile) return '';
		const fileId = layeredFile.split('id=')[1]?.split('&')[0];
		return fileId ? `https://drive.google.com/file/d/${fileId}/view` : '';
	};

	useEffect(() => {
		if (variation.id === 'design') {
			setIsOpen(true);
		}
	}, [variation.id]);

	return (
		<Box borderBottom="1px" borderColor="gray.200" mb={2} p={4}>
			<Box onClick={handleToggle} cursor="pointer">
				<Flex alignItems="center" justifyContent="space-between">
					<Flex alignItems="center" flex="1">
						<Text fontSize="16px" mr={4} minWidth="130px">
							{variation.title || variation.id}
						</Text>
						<Flex alignItems="center">
							<Icon as={GoogleDriveIcon} color="gray.500" w={5} h={5} mr={1} />
							{variation.layeredFile && variation.layeredFile.trim() !== '' ? (
								<Link
									href={getViewUrl(variation.layeredFile)}
									color="blue.500"
									fontSize="14px"
									display="flex"
									alignItems="center"
									isExternal
								>
									Open in GDrive{' '}
									<Icon
										as={FaExternalLinkAlt}
										color="blue.500"
										w={3}
										h={3}
										ml={1}
									/>
								</Link>
							) : (
								<Text color="gray.500" fontSize="14px">
									Not generated yet
								</Text>
							)}
						</Flex>
					</Flex>
					<Text
						cursor="pointer"
						color="black"
						display="flex"
						alignItems="center"
					>
						Configure layers
						<Icon as={isOpen ? ChevronUpIcon : ChevronDownIcon} ml={1} />
					</Text>
				</Flex>
			</Box>
			{variation.layeredFile && variation.layeredFile.trim() !== '' ? (
				<Collapse in={isOpen}>
					<Box mt={30}>
						<ConfigureLayerHook variation={variation} />
					</Box>
				</Collapse>
			) : (
				<Box></Box>
			)}
		</Box>
	);
};
