import { FC, ReactNode } from 'react';
import { Flex, Heading } from '@chakra-ui/react';

import { InfoCircleIcon, LightBulbIcon } from 'src/assets/icons';

interface InformationBannerProps {
	title: string;
	children: ReactNode;
	type?: 'info' | 'warning';
}

const InformationBanner: FC<InformationBannerProps> = ({
	title,
	children,
	type = 'warning',
}) => {
	return (
		<Flex
			direction="column"
			borderRadius="md"
			py={4}
			pl={4}
			pr={16}
			boxShadow="0px 0.913px 5.478px 0px rgba(0, 0, 0, 0.15)"
			borderLeft="3px solid #f08602"
			gap={2}
		>
			<Flex gap={1}>
				{type === 'info' ? (
					<InfoCircleIcon color="#f08602" />
				) : (
					<LightBulbIcon color="#f08602" />
				)}
				<Heading fontWeight={500} color="#f08602">
					{title}
				</Heading>
			</Flex>
			<Flex direction="column">{children}</Flex>
		</Flex>
	);
};

export default InformationBanner;
