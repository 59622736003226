import { v4 as uuid } from 'uuid';
import http from './http';

const apiVersion = 'v1';
const storageUrl = `/${apiVersion}/storage`;

export const uploadFileToStorage = async (file: File, path: string) => {
	const id = uuid();
	const fileExtension = file.name.split('.').pop();
	const fileName = `${path}/${id}.${fileExtension}`;

	const signedUrl = await getSignedUrl(fileName, file.type);

	const method = 'PUT';

	await fetch(`${signedUrl}`, {
		body: file,
		method,
		headers: {
			'Content-Type': file.type,
			'Access-Control-Allow-Origin': '*',
		},
	});

	return `https://storage.googleapis.com/fusion-cloud-ai-ugc/${fileName}`;
};

const getSignedUrl = async (
	fileName: string,
	fileType: string,
): Promise<string> => {
	const { data } = await http.get<string>(
		`${storageUrl}/get-signed-url?fileName=${fileName}&fileType=${fileType}`,
	);

	return data;
};
