import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import _ from 'lodash';

import { IAppActionOutput } from 'src/lib/schemas';
import { renderOutputWidget } from '../../utils/renderOutputWidget';

interface ObjectOutputWidgetProps {
	isParent?: boolean;
	output: IAppActionOutput;
	outputSkeleton: IAppActionOutput[];
	rawOutput: any;
	baseProperty: string;
	level: number;
	isProcessing: boolean;
	view?: string;
}

const ObjectOutputWidget: FC<ObjectOutputWidgetProps> = ({
	isParent,
	output,
	outputSkeleton,
	rawOutput,
	baseProperty,
	level,
	isProcessing,
	view,
}) => {
	const currentLevel = level + 1;
	const currentOutput = outputSkeleton.filter(
		({ property }) =>
			property.startsWith(`${output.property}`) &&
			property.split('.').length === currentLevel,
	);

	return (
		<Flex direction="column" gap={1}>
			<Text fontWeight={isParent ? 'semibold' : 500}>{output.name}</Text>
			<Flex direction="column" gap={4}>
				{currentOutput.map((op) => {
					const prop = `${baseProperty}.${op.property.split('.').pop()}`;
					const value = _.get(rawOutput, prop);
					const OutputWidget = renderOutputWidget(op.type);

					if (OutputWidget) {
						return (
							<OutputWidget
								key={op.property}
								output={op}
								outputSkeleton={outputSkeleton}
								rawOutput={rawOutput}
								baseProperty={prop}
								value={value}
								level={currentLevel}
								isProcessing={isProcessing}
								view={view}
							/>
						);
					}
					return null;
				})}
			</Flex>
		</Flex>
	);
};

export default ObjectOutputWidget;
