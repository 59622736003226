import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

interface CircleArrowLeftIconProps extends IconProps {
	filled?: boolean;
}

const CircleArrowLeftIcon: FC<CircleArrowLeftIconProps> = ({
	color,
	width = '40px',
	height = '42px',
	...props
}) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 40 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M39.82 21.0675C39.82 28.2227 36.0091 34.7556 29.865 38.3332C23.6431 41.9108 16.0991 41.9108 9.955 38.3332C3.73312 34.7556 0 28.2227 0 21.0675C0 13.9901 3.73312 7.45715 9.955 3.87957C16.0991 0.301992 23.6431 0.301992 29.865 3.87957C36.0091 7.45715 39.82 13.9901 39.82 21.0675ZM17.9657 11.0347L9.25504 19.8231C8.4773 20.5231 8.4773 21.6897 9.25504 22.3896L17.9657 31.1003C18.6656 31.878 19.8322 31.878 20.5322 31.1003C21.3099 30.4003 21.3099 29.2337 20.5322 28.456L15.0103 22.9341H29.2428C30.2539 22.9341 31.1094 22.1563 31.1094 21.0675C31.1094 20.0564 30.2539 19.2009 29.2428 19.2009H15.0103L20.5322 13.679C21.3099 12.9791 21.3099 11.8125 20.5322 11.0347C19.8322 10.3348 18.6656 10.3348 17.9657 11.0347Z"
				fill={color ? (color as string) : '#3182CE'}
			/>
		</Icon>
	);
};

export default CircleArrowLeftIcon;
