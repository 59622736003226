import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

import EditCellPopover from './EditCellPopover';
import { formatCurrency, formatNumber } from 'src/lib/utils';
import ErrorMessage from 'src/components/common/ErrorMessage';
import InformationBanner from 'src/components/common/InformationBanner';
import WarningMessage from 'src/components/common/WarningMessage';

export interface IBudgetBreakdownItem {
	id: string;
	name: string;
	budget: number;
	groupId: string;
	group: string;
	percentage: number;
	isExpanded?: boolean;
	reach?: number;
	impressions?: number;
	new?: number;
	repeat?: number;
	cac?: number;
	cpo?: number;
}

const columnHelper = createColumnHelper<IBudgetBreakdownItem>();

interface IGetBudgetBreakdownColumns {
	onToggleSubtable: (row: IBudgetBreakdownItem) => void;
	currentBudget: number;
	onValueChange: (
		id: string,
		value: number,
		level: 'group' | 'channel' | 'overall',
	) => void;
	budget: number;
}

const renderIcon = (isOpen?: boolean) => (isOpen ? MinusIcon : AddIcon);

export const getBudgetBreakdownColumns = ({
	onToggleSubtable,
	onValueChange,
	budget,
	currentBudget,
}: IGetBudgetBreakdownColumns) => {
	const isUnderBudget = currentBudget < budget;
	const budgetdDiff = budget - currentBudget;
	const underBudgetMessage = `Pending to allocate ${formatCurrency(
		budgetdDiff,
	)}`;
	const noBudgetMessage = `This channel does not have budget`;

	return [
		columnHelper.accessor('name', {
			header: '',
			meta: {
				ThProps: {
					borderBottom: '1px solid #CDCDCD',
					bg: 'white',
					width: '160px',
				},
				TdProps: {
					fontWeight: 'bold',
					verticalAlign: 'top',
					p: 0,
				},
			},
			cell: (info) => {
				const value = info.getValue();
				const isParent = !info.row.original.groupId;
				return (
					<Box
						display="flex"
						gap={2}
						alignItems="center"
						w="full"
						p={4}
						cursor={isParent ? 'pointer' : 'default'}
						onClick={() => {
							isParent && onToggleSubtable(info.row.original);
						}}
					>
						{value !== 'Overall' && isParent && (
							<Icon
								as={renderIcon(info.row.original.isExpanded)}
								color="#3182CE"
							/>
						)}
						<Text fontSize="sm" fontWeight={isParent ? 'bold' : 'medium'}>
							{value}
						</Text>
					</Box>
				);
			},
		}),
		columnHelper.accessor('budget', {
			header: 'AMOUNT',
			cell: (info) => {
				const value = info.getValue();
				const isParent = !info.row.original.groupId;
				const isOverall = info.row.original.name === 'Overall';
				const isZeroBudget = value === 0;

				return (
					<Box
						p={3}
						cursor={isParent ? 'pointer' : 'default'}
						onClick={(e) => {
							e.currentTarget === e.target &&
								isParent &&
								onToggleSubtable(info.row.original);
						}}
					>
						<Flex gap={2} direction="column">
							<EditCellPopover
								id={info.row.original.id}
								value={value}
								budget={budget}
								currentBudget={currentBudget}
								level={isOverall ? 'overall' : isParent ? 'group' : 'channel'}
								onSubmit={onValueChange}
							/>
							{isOverall && isUnderBudget && (
								<ErrorMessage message={underBudgetMessage} />
							)}
							{!isOverall && !isParent && isZeroBudget && (
								<WarningMessage message={noBudgetMessage} />
							)}
						</Flex>
					</Box>
				);
			},
			meta: {
				ThProps: {
					borderLeft: '1px solid #CDCDCD',
					borderBottom: '1px solid #CDCDCD',
					bg: 'white',
					width: '150px',
				},
				TdProps: {
					borderLeft: '1px solid #CDCDCD',
					fontWeight: 'medium',
					verticalAlign: 'top',
					p: 0,
				},
			},
		}),
		columnHelper.accessor('percentage', {
			header: '%',
			cell: (info) => {
				const value = info.getValue();
				const isParent = !info.row.original.groupId;

				return (
					<Box
						p={4}
						cursor={isParent ? 'pointer' : 'default'}
						onClick={() => {
							isParent && onToggleSubtable(info.row.original);
						}}
					>
						<Text>{Number.isNaN(value) ? 0 : formatNumber(value)}%</Text>
					</Box>
				);
			},
			meta: {
				ThProps: {
					borderBottom: '1px solid #CDCDCD',
					bg: 'white',
					width: '80px',
					borderRight: '1px solid #CDCDCD',
				},
				TdProps: {
					fontWeight: 'medium',
					p: 0,
					verticalAlign: 'top',
					borderRight: '1px solid #CDCDCD',
				},
			},
		}),
		columnHelper.accessor('reach', {
			header: 'REACH',
			meta: {
				ThProps: {
					borderBottom: '1px solid #CDCDCD',
					bg: 'white',
					width: '100px',
				},
			},
		}),
		columnHelper.accessor('impressions', {
			header: 'IMPRESSIONS',
			meta: {
				ThProps: {
					borderBottom: '1px solid #CDCDCD',
					bg: 'white',
					width: '150px',
				},
			},
		}),
		columnHelper.accessor('new', {
			header: 'NEW',
			meta: {
				ThProps: {
					borderBottom: '1px solid #CDCDCD',
					bg: 'white',
					width: '80px',
				},
			},
		}),
		columnHelper.accessor('repeat', {
			header: 'REPEAT',
			meta: {
				ThProps: {
					borderBottom: '1px solid #CDCDCD',
					bg: 'white',
					width: '110px',
				},
			},
		}),
		columnHelper.accessor('cac', {
			header: 'CAC',
			meta: {
				ThProps: {
					borderBottom: '1px solid #CDCDCD',
					bg: 'white',
					width: '80px',
				},
			},
		}),
		columnHelper.accessor('cpo', {
			header: 'CPO',
			meta: {
				ThProps: {
					borderBottom: '1px solid #CDCDCD',
					bg: 'white',
					width: '80px',
				},
			},
		}),
	];
};
