import { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { VscRunAll } from 'react-icons/vsc';

interface AppExecuteProps {
	onExecute: () => void;
	isLoading?: boolean;
	isDisabled?: boolean;
}

const AppExecute: FC<AppExecuteProps> = ({ onExecute, ...props }) => {
	return (
		<Button
			borderRadius="38px"
			bgColor="orange.base"
			color="white"
			fontSize="14px"
			_hover={{ bg: 'orange.base' }}
			onClick={onExecute}
			maxW={100}
			alignSelf="end"
			p={5}
			leftIcon={<VscRunAll size={20} />}
			boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
		>
			Run
		</Button>
	);
};

export default AppExecute;
