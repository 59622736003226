import http from './http';

const apiVersion = 'v2';
const menuUrl = `/${apiVersion}/menu`;

export interface IMenuItem {
	_id: string;
	label: string;
	url: string | null;
	position: number;
	icon: string | null;
	children: IMenuItem[];
	access?: string[];
	shouldRefresh?: boolean;
}

const getMenu = async (): Promise<IMenuItem[]> => {
	const { data } = await http.get<IMenuItem[]>(menuUrl);
	return data;
};

export { getMenu };
