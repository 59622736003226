import { FC, useState } from 'react';
import {
	Container,
	Box,
	Button,
	Heading,
	Text,
	Flex,
	BoxProps,
} from '@chakra-ui/react';
import * as amplitude from '@amplitude/analytics-browser';

import CheckCircleIcon from 'src/assets/icons/CheckCircleIcon';

interface LockedContentProps {
	boxProps?: BoxProps;
}

const LockedContent: FC<LockedContentProps> = ({ boxProps }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isSubscribed, setIsSubscribed] = useState(false);

	const handleSubscribeInsights = async () => {
		setIsLoading(true);
		amplitude.track('FusionInsights Subscription');
		await new Promise((resolve) => setTimeout(resolve, 1000));
		setIsSubscribed(true);
		setIsLoading(false);
	};

	return (
		<Box
			bg="rgba(200, 200, 200, 0.1)"
			position="absolute"
			width="100%"
			height="100%"
			borderRadius="2xl"
			boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
			{...boxProps}
		>
			{!isSubscribed && (
				<Container
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					gap={6}
					maxW="lg"
					height="100%"
				>
					<Text fontWeight={600}>
						This feature is only available for customers with FusionInsights
					</Text>
					<Button
						bg="#f74809"
						color="white"
						isLoading={isLoading}
						loadingText="Applying..."
						onClick={handleSubscribeInsights}
						_hover={{ bg: '#ce3908' }}
					>
						Get FusionInsights
					</Button>
				</Container>
			)}
			{isSubscribed && (
				<Container
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					gap={4}
					maxW="lg"
					height="100%"
				>
					<CheckCircleIcon height="40px" width="40px" color="#f74809" />
					<Flex direction="column" gap={2} alignItems="center">
						<Heading fontSize="20px" fontWeight="semibold">
							Awesome
						</Heading>
						<Text fontWeight={600}>
							Keep an eye on your inbox for early access.
						</Text>
					</Flex>
				</Container>
			)}
		</Box>
	);
};

export default LockedContent;
