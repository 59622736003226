import * as jsonDiff from 'jsondiffpatch';
import _ from 'lodash';

import { ICampaign, ICampaignSchedule } from 'src/lib/schemas';

type TCampaignKeys = keyof ICampaign;
type TScheduleKeys = keyof ICampaignSchedule;

const INITIAL_PAYLOAD = {
	title: '',
	description: '',
	promotedObject: {
		type: 'catalogs',
		ids: [],
	},
	destination: '',
	channels: ['facebook', 'instagram', 'twitter', 'google', 'sms', 'pns'],
	budget: {
		total: 0,
		currency: 'USD',
		breakdown: [],
	},
};

export const getCampaignDelta = (
	campaign: Partial<ICampaign>,
	newCampaign: Partial<ICampaign>,
) => {
	const difference = jsonDiff.diff(campaign, newCampaign);
	const diffenceKeys = Object.keys(difference ?? {}) as TCampaignKeys[];
	const delta: Partial<ICampaign> = {};
	diffenceKeys.forEach((key) => {
		// @ts-ignore
		delta[key] = newCampaign[key];
	});

	return delta;
};

export const validateInitialPayload = (campaign: Partial<ICampaign>) => {
	const difference = jsonDiff.diff(INITIAL_PAYLOAD, campaign);
	const delta = _.omit(difference, ['id', 'status']);
	return !Object.keys(delta).length;
};

export const getScheduleDelta = (
	schedule: Partial<ICampaignSchedule>,
	newSchedule: Partial<ICampaignSchedule>,
) => {
	const difference = jsonDiff.diff(schedule, newSchedule);
	const diffenceKeys = Object.keys(difference ?? {}) as TScheduleKeys[];
	const delta: Partial<ICampaignSchedule> = {};
	diffenceKeys.forEach((key) => {
		// @ts-ignore
		delta[key] = newSchedule[key];
	});

	return delta;
};
