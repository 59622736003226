import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

const Overlay: FC<BoxProps> = (props) => {
	return (
		<Box
			pos="absolute"
			w="full"
			h="full"
			top={0}
			left={0}
			bg="rgba(0, 0, 0, 0.5)"
			{...props}
		/>
	);
};

export default Overlay;
