import { Container, Flex } from '@chakra-ui/react';

import { AuthHeader, ForgotPasswordForm } from 'src/components/auth';

const ForgotPassword = () => {
	return (
		<Container maxW="md">
			<Flex direction="column" gap={12}>
				<AuthHeader title="Forgot Password" />
				<ForgotPasswordForm />
			</Flex>
		</Container>
	);
};

export default ForgotPassword;
