import { useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	Container,
	Flex,
	Heading,
	Text,
	useMediaQuery,
} from '@chakra-ui/react';

import { FusionBannerIcon } from 'src/assets/icons';

const InvalidAccountBanner = () => {
	const navigate = useNavigate();
	const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');

	return (
		<Container maxW="5xl" m={0}>
			<Flex mt={4} flexWrap="wrap" gap={isLargerThan1200 ? 0 : 8}>
				<Flex
					direction="column"
					flex={1}
					pl={isLargerThan1200 ? 12 : 0}
					justifyContent="center"
				>
					<Flex direction="column" maxW="380px" gap={8}>
						<Flex direction="column" gap={6}>
							<Heading fontSize="18px" fontWeight="bold">
								Welcome to FusionAds.ai
							</Heading>
							<Text fontWeight="medium">
								Before you start using FusionAds.ai, please tell us a bit more
								about your company so we can tailor your experience and
								campaigns.
							</Text>
						</Flex>
						<Box>
							<Button
								colorScheme="secondary"
								height={8}
								onClick={() => navigate('/account/company')}
							>
								Go to my Account
							</Button>
						</Box>
					</Flex>
				</Flex>
				<Flex flex={1}>
					<FusionBannerIcon
						height={isLargerThan1200 ? '374px' : '280px'}
						width={isLargerThan1200 ? '451px' : '380px'}
					/>
				</Flex>
			</Flex>
		</Container>
	);
};

export default InvalidAccountBanner;
