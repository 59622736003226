import { FC } from 'react';
import FusionLoading from '../../FusionLoading';

interface DataTableLoadingProps {
	isLoading: boolean;
}

const DataTableLoading: FC<DataTableLoadingProps> = ({ isLoading }) => {
	return (
		<FusionLoading
			isLoading={isLoading}
			boxProps={{
				as: 'td',
				position: 'absolute',
				w: 'full',
				h: 'full',
				top: 0,
				pt: 20,
				bg: 'rgba(255,255,255,0.6)',
			}}
		/>
	);
};

export default DataTableLoading;
