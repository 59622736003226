import { FC, memo, useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { IAssistantCreativeData, IAttribute } from 'src/lib/schemas';
import { renderInputWidget } from './utils/renderInputWidget';
import { getValidationRules } from './utils/inputValidation';

interface TextCreativeFormProps {
	inputs: IAttribute[];
	initialValues?: any;
	stringTypeCasting?: boolean;
	onSubmit: (data: any) => void;
	creativeData?: IAssistantCreativeData;
}

const TextCreativeForm: FC<TextCreativeFormProps> = ({
	inputs,
	initialValues,
	stringTypeCasting = false,
	creativeData,
	onSubmit,
}) => {
	const formMethods = useForm({ defaultValues: initialValues, mode: 'all' });
	const timeoutId = useRef<any>(null);

	useEffect(() => {
		formMethods.reset(initialValues);
	}, []);

	const waitForSaving = () => {
		clearTimeout(timeoutId.current);
		timeoutId.current = setTimeout(onFieldSubmit, 1500);
	};

	const onFieldSubmit = async () => {
		const formValues = formMethods.getValues();
		await onSubmit(formValues);
	};

	const setFieldValue = (field: any, value: string) => {
		formMethods.setValue(field, value);
		onFieldSubmit();
	};

	useEffect(() => {
		const elements = document.querySelectorAll(
			'[data-rbd-droppable-id="droppable"]',
		);
		elements.forEach((element) => {
			(element as HTMLElement).style.height = 'auto';
		});
	}, []);

	return (
		<FormProvider {...formMethods}>
			<form>
				<Flex direction="column" gap={4} pb={4}>
					{inputs?.map((input, index) => {
						const validationRules = getValidationRules(input);
						const isStringType = input.type === 'String';
						const isStringArrayType = input.type === 'String[]';
						const isSingleline = validationRules?.multiline?.value === false;

						const isTextAreaType =
							isStringType &&
							!isSingleline &&
							(!validationRules.maxLength ||
								validationRules.maxLength.value >= 90);

						const isTextAreaArrayType =
							isStringArrayType &&
							!isSingleline &&
							(!validationRules.maxLength ||
								validationRules.maxLength.value >= 90);

						const InputWidget = renderInputWidget(
							stringTypeCasting && isTextAreaType
								? 'TextArea'
								: stringTypeCasting && isTextAreaArrayType
								? 'TextArea[]'
								: input.type,
						);

						const maxCharCount = validationRules?.maxLength?.value
							? validationRules?.maxLength?.value
							: undefined;

						if (InputWidget) {
							return (
								<InputWidget
									key={input.name + index}
									input={input}
									withAssistant
									setFieldValue={setFieldValue}
									fieldChanges={waitForSaving}
									validationRules={validationRules}
									copyToClipboard={formMethods.getValues(input.property)}
									maxLength={maxCharCount}
									creativeData={creativeData}
								/>
							);
						}
						return null;
					})}
				</Flex>
			</form>
		</FormProvider>
	);
};

export default memo(TextCreativeForm);
