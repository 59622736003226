import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { NumberInputHook } from 'src/components/common/form';
import { IAppActionInput } from 'src/lib/schemas';

interface NumberInputWidgetProps {
	input: IAppActionInput;
	validationRules: Record<string, any>;
	propertyPrefix?: string;
}

const NumberInputWidget: FC<NumberInputWidgetProps> = ({
	input,
	validationRules,
	propertyPrefix = '',
}) => {
	const { control } = useFormContext();

	let placeholder = `Enter ${input.name.toLowerCase()}`;
	if (input.placeholder) {
		placeholder = input.placeholder;
	}

	return (
		<Controller
			name={input.property}
			control={control}
			rules={validationRules}
			render={() => (
				<NumberInputHook
					name={propertyPrefix + input.property}
					label={input.name}
					requirementsLabel={input.explanation}
					placeholder={placeholder}
					required={validationRules.required}
				/>
			)}
		/>
	);
};

export default NumberInputWidget;
