import { FC, useState } from 'react';
import {
	Button,
	Text,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { IUser } from 'src/lib/schemas';

export interface UserDeleteModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: (data: IUser) => void;
	data?: IUser;
}

const UserDeleteModal: FC<UserDeleteModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	data,
}) => {
	const [isDeleting, setIsDeleting] = useState(false);

	if (!data) return null;

	const handleClose = () => {
		if (isDeleting) return;
		onClose();
	};

	const handleDelete = async () => {
		setIsDeleting(true);
		await onConfirm(data);
		setIsDeleting(false);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Heading>Delete User</Heading>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text mb={4}>
						Are you sure you want to delete &ldquo;{data.name}
						&rdquo;?
					</Text>
				</ModalBody>
				<ModalFooter>
					<Button variant="outline" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						colorScheme="red"
						ml={4}
						onClick={handleDelete}
						isLoading={isDeleting}
						loadingText="Deleting..."
					>
						Delete
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default UserDeleteModal;
