import { Image } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

import { IBrand } from 'src/lib/schemas';
import {
	DataTableActions,
	DataTableActionsProps,
} from 'src/components/common/DataTable';

export interface IBrandsListingColumn extends IBrand {
	action?: unknown;
}

const columnHelper = createColumnHelper<IBrandsListingColumn>();

export const getBrandsListingColumns = (
	onAction: DataTableActionsProps<IBrand>['onAction'],
) => {
	return [
		columnHelper.accessor('image', {
			header: 'Image',
			cell: (info) => {
				return (
					<Image
						w="48px"
						h="48px"
						borderRadius="md"
						objectFit="contain"
						src={info.row.original.image}
					/>
				);
			},
			meta: {
				ThProps: {
					width: '300px',
				},
			},
		}),
		columnHelper.accessor('name', {
			header: 'Name',
		}),
		// columnHelper.accessor('noOfCampaigns', {
		// 	header: 'No. of Campaigns',
		// }),
		columnHelper.accessor('action', {
			header: 'Action',
			cell: (info) => {
				return (
					<DataTableActions
						data={info.row.original}
						onAction={onAction}
						actionsAllowed={['Edit', 'Delete', 'Create campaign']}
					/>
				);
			},
			meta: {
				ThProps: {
					width: '100px',
				},
				TdProps: {
					textAlign: 'center',
				},
			},
		}),
	];
};
