import { useEffect } from 'react';
import {
	FieldPath,
	FieldValues,
	get,
	useController,
	useFormContext,
} from 'react-hook-form';

import RadioGroupInput, { RadioGroupInputProps } from './RadioGroupInput';
import { IOption } from 'src/lib/schemas/misc';

interface RadioGroupInputHookProps<T extends FieldValues>
	extends RadioGroupInputProps {
	name: FieldPath<T>;
	options: Array<IOption>;
	defaultValue?: string | number;
	onChangeCustom?: (value: string | null) => void;
}

function RadioGroupInputHook<T extends FieldValues = FieldValues>({
	name,
	defaultValue,
	onChangeCustom,
	...props
}: RadioGroupInputHookProps<T>) {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	const { field } = useController({ control, name });
	const { onChange, value } = field;

	const error = get(errors, name);

	useEffect(() => {
		if (!value) onChange(defaultValue);
	}, []);

	const handleChange = (val: string | null) => {
		onChange(val);
		if (onChangeCustom) {
			onChangeCustom(val);
		}
	};

	return (
		<RadioGroupInput
			{...props}
			id={name}
			value={value}
			error={error?.message}
			radioGroupProps={{
				...props.radioGroupProps,
				onChange: handleChange,
				children: null,
			}}
		/>
	);
}

export default RadioGroupInputHook;
