declare global {
	interface Window {
		gapi: any;
		google: any;
	}
}

const SCOPES =
	'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.photos.readonly https://www.googleapis.com/auth/drive.install https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';

function loadGapiClient() {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = 'https://apis.google.com/js/api.js';
		script.onload = resolve;
		script.onerror = reject;
		document.head.appendChild(script);
	});
}

export async function initGapiClient(accessToken: string, clientId: string) {
	await loadGapiClient();
	await new Promise((resolve) => window.gapi.load('client:auth2', resolve));

	const authInstance = gapi.auth2.getAuthInstance();
	if (!authInstance) {
		await window.gapi.client.init({
			apiKey: 'AIzaSyBKLGtxn2Tfzire1oIsYOXKwuIQvfEEz-M',
			clientId: clientId,
			discoveryDocs: [
				'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
			],
			scope:
				'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
		});
	}
	window.gapi.client.setToken({ access_token: accessToken });
}

export async function createFolder(
	name: string,
	parentId: string = '1XCi4BQ9IIgJazHfrT4e6ayBAX7J6KBOg',
) {
	const body = {
		name: name,
		mimeType: 'application/vnd.google-apps.folder',
		parents: [parentId],
	};

	const response = await window.gapi.client.drive.files.create({
		resource: body,
		fields: 'id',
	});
	return response.result;
}

export async function copyFileTo(fileId: string, destinationFolderId: string) {
	const response = await window.gapi.client.drive.files.copy({
		fileId: fileId,
		resource: {
			parents: [destinationFolderId],
		},
		fields: 'id, name',
	});
	return response.result;
}

async function findFolder(name: string) {
	const response = await window.gapi.client.drive.files.list({
		q: `mimeType='application/vnd.google-apps.folder' and name='${name}'`,
		fields: 'files(id, name)',
		spaces: 'drive',
	});

	if (response.result.files.length > 0) {
		return response.result.files[0];
	}
	return null;
}

async function setFilePermissionsToReader(fileId: string) {
	const permission = {
		type: 'anyone',
		role: 'reader',
	};

	try {
		await window.gapi.client.drive.permissions.create({
			fileId: fileId,
			resource: permission,
		});
	} catch (error) {
		console.error(
			`Error al actualizar los permisos del archivo ${fileId}:`,
			error,
		);
	}
}

export async function main(
	accessToken: string,
	clientId: string,
	templateName: string,
	accountId: string,
	accountName: string,
) {
	try {
		await initGapiClient(accessToken, clientId);

		const userFolderName = `${accountName}-${accountId}`;
		let userFolder = await findFolder(userFolderName);
		if (!userFolder) {
			userFolder = await createFolder(userFolderName);
		}

		const templateFolder = await createFolder(templateName, userFolder.id);

		const fileIds = [
			'1GXYRQGrU8Xc7RqybPmYg8IBL9Z-a-XSA',
			'1bv8Tlbm3v2ciBtf8KW_FA5jMEp9EJTZk',
			'1r2qsYNhMeChIUP-YWdAxGHnVC06Hork3',
			'16yp6jVidlHhu3aR9iBNnKk9pDc2Ec3G0',
		];

		const copiedFiles = await Promise.all(
			fileIds.map((fileId) => copyFileTo(fileId, templateFolder.id)),
		);

		await Promise.all(
			copiedFiles.map((file) => setFilePermissionsToReader(file.id)),
		);

		const variations = [
			{
				id: 'design',
				title: 'Design Direction',
				layeredFile: `https://drive.usercontent.google.com/download?id=${copiedFiles[0].id}&nocache`,
				layerSpecs: [
					{
						name: '$Product',
						actions: [
							{
								id: 'SET_PROPERTY',
								arguments: {
									property: 'imageUrl',
									value: '$variable.product',
								},
							},
						],
					},
				],
			},
			{
				id: 'facebook_story',
				title: 'FB/IG Story',
				layeredFile: `https://drive.usercontent.google.com/download?id=${copiedFiles[1].id}&nocache`,
			},
			{
				id: 'facebook_feed',
				title: 'Facebook Feed',
				layeredFile: `https://drive.usercontent.google.com/download?id=${copiedFiles[2].id}&nocache`,
			},
			{
				id: 'instagram_feed',
				title: 'Instagram Feed',
				layeredFile: `https://drive.usercontent.google.com/download?id=${copiedFiles[3].id}&nocache`,
			},
			{
				id: 'instagram_story',
				title: 'FB/IG Story',
				layeredFile: `https://drive.usercontent.google.com/download?id=${copiedFiles[1].id}&nocache`,
			},
		];

		return variations;
	} catch (error) {
		console.error('Error in Google API:', error);
		throw error;
	}
}
