const useRoute = () => {
	// Given an URL, it returns it with the format: https://www.{url}
	const formatUrl = (url: string): string => {
		url = url.trim();

		// Add "https://" if not present
		if (!/^https?:\/\//i.test(url)) {
			url = `https://${url}`;
		}

		// Add "www." if not present after "https://"
		if (!/^https?:\/\/www\./i.test(url)) {
			url = url.replace(/^(https?:\/\/)/i, '$1www.');
		}

		// Remove trailing slash if present
		url = url.replace(/\/$/, '');

		return url;
	};

	//Given an URL, it return it without the prefix: 'https://www.'
	const removeUrlPrefix = (url: string): string => {
		url = url.trim();
		url = url.replace(/^(https?:\/\/)?(www\.)?/i, '');
		return url;
	};

	return { formatUrl, removeUrlPrefix };
};

export default useRoute;
