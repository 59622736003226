import { IOption } from 'src/lib/schemas/misc';
import {
	CampaignFormSchema,
	CampaignScheduleSchema,
} from 'src/lib/schemas/campaign/form';
import { transformCampaignToForm } from 'src/lib/utils';
import { ICampaign, ICampaignSchedule } from 'src/lib/schemas';

export const validateCampaignConfig = (
	campaign: ICampaign,
	promotedObjecOptions: IOption[],
): boolean => {
	if (!campaign?.promotedObject) return false;
	const campaignForm = transformCampaignToForm(campaign, promotedObjecOptions);
	campaignForm.goalsList = campaignForm.goal?.map((goal) => goal.id);

	try {
		CampaignFormSchema.parse(campaignForm);
		return true;
	} catch (error) {
		return false;
	}
};

export const validateCampaignSchedule = (
	schedule: Partial<ICampaignSchedule>,
): boolean => {
	const scheduleForm = {
		...schedule,
		...{
			startDate: schedule.startDate ? new Date(schedule.startDate) : undefined,
			endDate: schedule.endDate ? new Date(schedule.endDate) : undefined,
		},
	};
	try {
		CampaignScheduleSchema.parse(scheduleForm);
		return true;
	} catch (error) {
		return false;
	}
};
