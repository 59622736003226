export const PRODUCT_FEATURES = [
	{ id: 1, text: 'Unlimited number of campaigns' },
	{ id: 2, text: '3 seats' },
	{ id: 3, text: '6 Connectors' },
	{ id: 4, text: '5000 MTUs' },
];

export const USER_ROLE_OPTIONS = [
	{ value: 'verifiedAccountAdmin', label: 'Account owner' },
	{ value: 'accountAdmin', label: 'Account owner' },
	{ value: 'verifiedUser', label: 'Account user' },
	{ value: 'user', label: 'Account user' },
];

export const FILTERED_USER_ROLE_OPTIONS = USER_ROLE_OPTIONS.filter(
	(role) => role.value !== 'accountAdmin' && role.value !== 'user',
);
