import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { ArrowForwardIcon, CloseIcon } from '@chakra-ui/icons';

import { IOption } from 'src/lib/schemas/misc';

interface OptionCardProps {
	option: IOption;
	action?: 'select' | 'remove';
	onAction: (goal: IOption) => void;
}

const OptionCard: FC<OptionCardProps> = ({
	option,
	action = 'remove',
	onAction,
}) => {
	const { label, description } = option;

	const handleOptionSelect = () => {
		if (action === 'select') onAction(option);
	};

	const handleOptionRemove = () => {
		onAction(option);
	};

	const boxProps = action === 'select' ? { cursor: 'pointer' } : {};

	return (
		<Flex
			flex={1}
			maxW="200px"
			direction="column"
			p={4}
			gap={2}
			borderRadius="lg"
			bg="white"
			boxShadow="0px 1px 6px 0px rgba(0, 0, 0, 0.15)"
			onClick={handleOptionSelect}
			{...boxProps}
		>
			<Flex gap={1}>
				<Text flex={1}>{label}</Text>
				{action === 'remove' ? (
					<CloseIcon
						boxSize={3}
						color="#737981"
						cursor="pointer"
						onClick={handleOptionRemove}
					/>
				) : (
					<ArrowForwardIcon boxSize={5} color="#2C6ECB" />
				)}
			</Flex>
			<Text color="#737981">{description}</Text>
		</Flex>
	);
};

export default OptionCard;
