import {
	Heading,
	List,
	ListItem,
	Flex,
	Checkbox,
	Avatar,
	Box,
	Text,
	FormErrorMessage,
	FormControl,
} from '@chakra-ui/react';
import FaebookTextIcon from 'src/assets/icons/FacebookTextIcon';
import InstagramTextIcon from 'src/assets/icons/InstagramTextIcon';
import { IPage } from 'src/lib/schemas';

interface PagesListProps {
	title?: string;
	facebookIcon?: boolean;
	pages: IPage[];
	instagramPages?: IPage[];
	selectedPages: IPage[];
	onSelect: (page: IPage) => void;
	error?: string;
}

const PagesList = ({
	title,
	facebookIcon,
	pages,
	instagramPages,
	selectedPages,
	onSelect,
	error,
}: PagesListProps) => {
	const data =
		instagramPages && instagramPages.length > 0 ? instagramPages : pages;

	const isChecked = (page: IPage) =>
		selectedPages.some((p) => p.id === page.id);

	return (
		<Box>
			{facebookIcon ? <FaebookTextIcon /> : <InstagramTextIcon />}
			<Heading fontSize="16px" fontWeight="400" lineHeight="20px" mb={8}>
				{title}
				<Box as="span" color="#e53e3e" ml={1}>
					*
				</Box>
			</Heading>

			<FormControl mt={-4} mb={4} isInvalid={Boolean(error)}>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
			<List spacing={3} mb={5}>
				{data.map((page) => (
					<ListItem
						key={page.id}
						p={3}
						boxShadow="md"
						borderRadius="md"
						bg="gray.50"
						onClick={() => onSelect(page)}
						cursor="pointer"
					>
						<Flex align="center">
							<Checkbox
								isChecked={isChecked(page)}
								onChange={() => onSelect(page)}
								mr={3}
							/>
							<Flex align="center" gap={2}>
								<Avatar src={page.image} h={7} w={7} />
								<Text>{page.name}</Text>
							</Flex>
						</Flex>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default PagesList;
