import { FC } from 'react';
import {
	Button,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';

interface AccountSettingsModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

const AccountSettingsModal: FC<AccountSettingsModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="md" autoFocus={false}>
			<ModalOverlay />
			<ModalContent p={6} bg="#EEE" borderRadius="lg">
				<ModalHeader p={0}>
					<Heading as="h3" fontWeight={500}>
						Your account is ready
					</Heading>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody py={4} px={0}>
					<Text fontWeight={300}>
						Now, it is time to create your first campaign.
					</Text>
				</ModalBody>
				<ModalFooter p={0} mt={4}>
					<Flex w="full" justify="flex-end">
						<Button colorScheme="secondary" size="sm" onClick={onConfirm}>
							Create my first campaign
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default AccountSettingsModal;
