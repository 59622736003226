import React, { createContext, useContext, useState, ReactNode } from 'react';
import SelectTemplateModal from 'src/components/campaigns/design-directions/SelectTemplateModal';

interface PopupContextType {
	isPopupVisible: boolean;
	setPopupVisible: (visible: boolean) => void;
	isAdTemplateModalOpen: boolean;
	setAdTemplateModalOpen: (visible: boolean) => void;
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const PopupProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [isPopupVisible, setPopupVisible] = useState(true);
	const [isAdTemplateModalOpen, setAdTemplateModalOpen] = useState(false);

	return (
		<PopupContext.Provider
			value={{
				isPopupVisible,
				setPopupVisible,
				isAdTemplateModalOpen,
				setAdTemplateModalOpen,
			}}
		>
			{children}
		</PopupContext.Provider>
	);
};

export const usePopup = (): PopupContextType => {
	const context = useContext(PopupContext);
	if (!context) {
		throw new Error('usePopup must be used within a PopupProvider');
	}
	return context;
};
