import { useState, useMemo, useEffect } from 'react';

interface PaginationOptions {
	page: number;
	pageSize: number;
	pages: number;
}

interface UseClientPaginationProps<T> {
	data: T[];
	pageSize?: number;
	searchFunction?: (item: T, query: string) => boolean;
	sortFunction?: (a: T, b: T) => number;
}

function useClientPagination<T>({
	data,
	pageSize,
	searchFunction,
	sortFunction,
}: UseClientPaginationProps<T>) {
	const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>(
		{
			page: 1,
			pageSize: pageSize || 10,
			pages: 1,
		},
	);
	const [searchQuery, setSearchQuery] = useState<string>('');

	const filteredResults = useMemo(() => {
		const sortedResults = [...data].sort(sortFunction);

		return sortedResults.filter((item) =>
			searchFunction ? searchFunction(item, searchQuery) : true,
		);
	}, [data, paginationOptions.page, searchQuery]);

	const paginatedResults = useMemo(() => {
		const startIndex =
			(paginationOptions.page - 1) * paginationOptions.pageSize;
		const endIndex = startIndex + paginationOptions.pageSize;

		return filteredResults.slice(startIndex, endIndex);
	}, [filteredResults]);

	useEffect(() => {
		if (data.length) {
			setPaginationOptions((prevOptions) => ({
				...prevOptions,
				pages: Math.ceil(filteredResults.length / prevOptions.pageSize),
			}));
		}
	}, [filteredResults]);

	const goToPage = (newPage: number) => {
		setPaginationOptions((prevOptions) => ({
			...prevOptions,
			page: newPage,
		}));
	};

	const nextPage = () => {
		setPaginationOptions((prevOptions) => ({
			...prevOptions,
			page: Math.min(prevOptions.page + 1, prevOptions.pages),
		}));
	};

	const prevPage = () => {
		setPaginationOptions((prevOptions) => ({
			...prevOptions,
			page: Math.max(prevOptions.page - 1, 1),
		}));
	};

	const handleSearchQuery = (query: string) => {
		setSearchQuery(query);
		setPaginationOptions((prevOptions) => ({
			...prevOptions,
			page: 1,
		}));
	};

	return {
		data: paginatedResults,
		page: paginationOptions.page,
		pages: paginationOptions.pages,
		goToPage,
		nextPage,
		prevPage,
		setSearchQuery: handleSearchQuery,
	};
}

export default useClientPagination;
