import React, { useState, useContext, useRef, useEffect } from 'react';
import {
	Input,
	FormControl,
	FormErrorMessage,
	Text,
	Flex,
	FormLabel,
	InputGroup,
	InputRightElement,
} from '@chakra-ui/react';
import { AssistantChatContext, CampaignContext } from 'src/contexts';
import AssistantIcon from 'src/components/common/AssistantIcon';
import { IField, IGetThreadParams, ThreadTypesEnum } from 'src/lib/schemas';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';

interface ValidatedInputProps {
	label?: string;
	value: string;
	onChange: (value: string) => void;
	maxLength?: number;
	isTextarea?: boolean;
	withAssistant?: boolean;
	name: string;
	creativeData?: ICreative;
	displayName?: string;
	setFieldValue?: (field: string, value: string) => void;
}

const InputField: React.FC<ValidatedInputProps> = ({
	label,
	value,
	onChange,
	maxLength,
	isTextarea = false,
	withAssistant = false,
	name,
	creativeData,
	displayName,
}) => {
	const [error, setError] = useState<string | null>(null);
	const { openAssistantChat } = useContext(AssistantChatContext);
	const { id: campaignId } = useContext(CampaignContext);
	const inputRef = useRef<HTMLDivElement | null>(null);
	const [isFocused, setIsFocused] = useState(false);

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const newValue = e.target.value;

		if (maxLength && newValue.length > maxLength) {
			setError(`Maximum length is ${maxLength} characters`);
		} else {
			setError(null);
		}

		onChange(newValue);

		if (isTextarea) {
			handleAutoResize(e.target as HTMLTextAreaElement);
		}
	};

	const handleAutoResize = (textareaElement: HTMLTextAreaElement) => {
		textareaElement.style.height = 'auto';
		textareaElement.style.height = `${textareaElement.scrollHeight}px`;
	};

	useEffect(() => {
		if (isTextarea && inputRef.current) {
			const textarea = inputRef.current.querySelector('textarea');
			if (textarea) handleAutoResize(textarea as HTMLTextAreaElement);
		}
	}, [isTextarea, inputRef, value]);

	const handleOpenAssistantChat = async () => {
		const threadParams: IGetThreadParams = {
			type: ThreadTypesEnum.Campaign,
			reference: campaignId!,
			sub_reference: creativeData?.id ?? name,
			property: name,
		};
		const fieldParams: IField = {
			displayName: label ?? displayName ?? '',
			name,
			variant: creativeData?.variant,
			channel: creativeData?.channel,
			content: value,
			id: name,
			setValue: (field: string, newValue: string) => onChange(newValue),
		};
		const ref = inputRef.current;
		openAssistantChat({ threadParams, fieldParams, ref });
	};

	return (
		<FormControl isInvalid={!!error}>
			{label && <FormLabel>{label}</FormLabel>}
			<InputGroup ref={inputRef}>
				<Input
					value={value}
					onChange={handleInputChange}
					maxLength={maxLength}
					bg="white"
					as={isTextarea ? 'textarea' : 'input'}
					minHeight={isTextarea ? '80px' : ''}
					onClick={(e) => e.stopPropagation()}
					fontSize="sm"
					pr={withAssistant ? 10 : 0}
					pt={isTextarea ? 1 : 0}
					pb={isTextarea ? 1 : 0}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					style={{ resize: 'none', overflow: 'hidden' }}
				/>
				{withAssistant && (
					<InputRightElement
						width="10px"
						height="100%"
						display="flex"
						right={isTextarea ? '17px' : '15px'}
						alignItems="flex-end"
						justifyContent="center"
						pb={isTextarea ? 0 : 1}
						pr={isTextarea ? 0 : 3}
					>
						<AssistantIcon onOpen={handleOpenAssistantChat} />
					</InputRightElement>
				)}
			</InputGroup>
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
			{maxLength && (
				<Flex justifyContent="flex-end">
					<Text fontSize="sm" color="gray.500">
						{value.length}/{maxLength}
					</Text>
				</Flex>
			)}
		</FormControl>
	);
};

export default InputField;
