import { FC, useContext, useEffect, useState } from 'react';
import { Flex, Heading, Button, Link } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { FacebookIcon, MetaIcon } from 'src/assets/icons';
import Chip from 'src/components/common/Chip';
import {
	ChangeAccountLink,
	FacebookAuthButton,
} from 'src/components/integration';
import UserContext from 'src/contexts/UserContext';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';
import SocialPages from './SocialPages';
import { IPage } from 'src/lib/schemas';
import { disconnectFacebook } from 'src/services/integration';
import { getFacebookOauthUrl } from 'src/lib/integrations';
import AlertMessage from 'src/components/common/AlertMessage';
interface IntegrationCardProps {
	isConnetedToFb: boolean;
	pages?: IPage[];
	adAccounts?: any[];
	adAccountsLoading: boolean;
	redirectedFromFB: boolean;
	isCampaignContext?: boolean;
	error: string | null;
	instagramPages?: IPage[];
	noPagesAvailable: boolean;
}
const IntegrationCard: FC<IntegrationCardProps> = ({
	pages,
	adAccounts,
	isConnetedToFb,
	adAccountsLoading,
	redirectedFromFB,
	instagramPages,
	isCampaignContext = false,
	error,
	noPagesAvailable,
}) => {
	const { user } = useContext(UserContext);
	const { setIntegrations } = useMyIntegrations();
	const { pathname } = useLocation();
	const [connected, setConnected] = useState(isConnetedToFb);

	useEffect(() => {
		if (error) {
			setConnected(false);
		}
	}, [error]);
	if (!user) return null;
	

	useEffect(() => {
		setConnected(isConnetedToFb);
	}, [isConnetedToFb]);

	const handleDisconnect = async () => {
		try {
			await disconnectFacebook(user.account);
			setConnected(false);
			setIntegrations(null);
		} catch (error) {
			console.error('Error disconnecting Facebook integration:', error);
		}
	};

	return (
		<Flex
			boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
			p={5}
			borderRadius="8px"
			w="full"
			maxW="600px"
		>
			<Flex gap={8} direction="column" w="full">
				{error && <AlertMessage status="error">{error}</AlertMessage>}

				<Flex alignItems="center" justifyContent="space-between">
					<Heading fontSize="16px">Meta</Heading>
				</Flex>

				{connected ? (
					<Flex direction="column" gap={10}>
						<Flex
							gap={2}
							alignItems="center"
							justifyContent="space-between"
							w="full"
						>
							<Flex gap={2} alignItems="center">
								<MetaIcon />
								<Chip
									label="Connected"
									tagProps={{
										colorScheme: 'green',
									}}
								/>
							</Flex>
							<Flex gap={2} alignItems="center">
								<ChangeAccountLink
									link={getFacebookOauthUrl({
										account: user.account,
										redirectTo: `${pathname}?fromFB`,
									})}
								/>
								<Button
									onClick={handleDisconnect}
									bg="#E5E7EB"
									color="black"
									size="sm"
									_hover={{ bg: '#D1D5DB' }}
								>
									Disconnect
								</Button>
							</Flex>
						</Flex>
						{pages && (
							<SocialPages
								pages={pages}
								instagramPages={instagramPages}
								adAccounts={adAccounts}
								adAccountsLoading={adAccountsLoading}
								redirectedFromFB={redirectedFromFB}
								noPagesAvailable={noPagesAvailable}
							/>
						)}
					</Flex>
				) : (
					<Flex
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						w="full"
					>
						<Flex direction="row" alignItems="center" gap={4}>
							<FacebookIcon width="24px" height="24px" />
							<Chip
								label="Disconnected"
								tagProps={{
									colorScheme: 'red',
								}}
							/>
						</Flex>
						<FacebookAuthButton account={user.account} />
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

export default IntegrationCard;
