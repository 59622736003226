import React, { createContext, useContext, FC, ReactNode } from 'react';

interface ProductFormModalContextProps {
	isProductFormModal: boolean;
}

const ProductFormModalContext = createContext<
	ProductFormModalContextProps | undefined
>(undefined);

export const ProductFormModalProvider: FC<{
	isProductFormModal: boolean;
	children: ReactNode;
}> = ({ isProductFormModal, children }) => {
	return (
		<ProductFormModalContext.Provider value={{ isProductFormModal }}>
			{children}
		</ProductFormModalContext.Provider>
	);
};

export const useProductFormModalContext = () => {
	return useContext(ProductFormModalContext);
};
