import { useContext, useEffect, useState } from 'react';
import {
	useLocation,
	useNavigate,
	Link,
	useSearchParams,
} from 'react-router-dom';
import { Button, Flex, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { StringInputHook } from 'src/components/common/form';
import UserContext from 'src/contexts/UserContext';
import AlertMessage from '../common/AlertMessage';
import authService from 'src/services/auth';
import { LoginSchema, ILoginForm } from 'src/lib/schemas';
import { isMobile } from '../campaigns/utils/constants';
const LoginForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const formMethods = useForm<ILoginForm>({
		resolver: zodResolver(LoginSchema),
	});
	const { setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const location = useLocation();
	const [search] = useSearchParams();
	const redirectUrl = search.get('redirect_url');
	const watchedEmail = formMethods.watch('email');
	const fontSize = isMobile() ? '16px' : '14px';

	useEffect(() => {
		if (location.state?.error) {
			setError(location.state.error);
		}
	}, [location]);

	useEffect(() => {
		if (watchedEmail && watchedEmail !== watchedEmail.trim()) {
			formMethods.setValue('email', watchedEmail.trim());
		}
	}, [watchedEmail]);
	const onSubmit = formMethods.handleSubmit(async (formData) => {
		setError(null);
		setIsLoading(true);
		try {
			const user = await authService.login(formData);
			setUser(user);
			navigate(redirectUrl || '/home');
		} catch (error: any) {
			setIsLoading(false);
			if (error?.data?.errorCode === 'email_not_verified') {
				navigate('/pending-verification');
			} else {
				setError((error as Error).message);
			}
		}
	});
	const getLinkWithRedirect = (link: string) => {
		return redirectUrl ? `${link}?redirect_url=${redirectUrl}` : link;
	};
	return (
		<Flex flex={1} direction="column" gap={3}>
			<FormProvider {...formMethods}>
				{error && <AlertMessage status="error">{error}</AlertMessage>}
				<form onSubmit={onSubmit} style={{ width: '100%' }}>
					<Flex direction="column" gap={5}>
						<StringInputHook
							label="Email"
							name="email"
							type="text"
							placeholder="you@company.com"
							required
							inputProps={{
								size: 'md',
								fontSize: fontSize,
								autoComplete: 'username',
							}}
						/>
						<Flex direction="column" gap="3">
							<StringInputHook
								label="Password"
								name="password"
								type={isPasswordVisible ? 'text' : 'password'}
								placeholder="Strong password"
								required
								inputProps={{ size: 'md', fontSize: fontSize }}
							/>
							<Flex alignItems="center">
								<input
									type="checkbox"
									name="showPassword"
									id="showPassword"
									onChange={() => setIsPasswordVisible(!isPasswordVisible)}
								/>
								<label htmlFor="showPassword">
									<Text fontSize="13px">&nbsp; Show password</Text>
								</label>
							</Flex>
						</Flex>
						<Flex justifyContent="space-between" alignItems="center" w="full">
							<Link to={getLinkWithRedirect('/forgot-password')}>
								<Text
									flex={3}
									textDecoration="underline"
									fontSize="sm"
									color="#2C6ECB"
								>
									Reset your password
								</Text>
							</Link>
							<Button
								variant="orangeSolid"
								type="submit"
								loadingText="Authenticating..."
								isLoading={isLoading}
								minW={28}
							>
								Sign in
							</Button>
						</Flex>
						<Flex alignItems="center" justifyContent="center" gap={1}>
							<Text>Dont have an account?</Text>
							<Link to={getLinkWithRedirect('/signup')}>
								<Text textDecoration="underline" color="#2C6ECB">
									Sign up for free
								</Text>
							</Link>
						</Flex>
					</Flex>
				</form>
			</FormProvider>
		</Flex>
	);
};
export default LoginForm;
