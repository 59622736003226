import { IAppActionInput } from 'src/lib/schemas';

export const getValidationRules = (input: IAppActionInput) => {
	const { validations, name } = input;
	if (!validations?.length) return {};

	const validationRules: Record<string, any> = {};

	for (const validation of validations) {
		switch (validation.id) {
			case 'String.maxLength': {
				validationRules['maxLength'] = {
					value: validation.value,
					message: `Maximum length is ${validation.value}`,
				};
				break;
			}
			case 'multiline': {
				validationRules['message'] = validation.message;
				validationRules['multiline'] = {
					value: validation.value,
					message: `${name} is required`,
				};
				break;
			}
			case 'required': {
				validationRules['message'] = validation.message;
				validationRules['required'] = `${name} is required`;
				break;
			}
			case 'minWidth': {
				validationRules['message'] = validation.message;
				validationRules['minWidth'] = validation.value;
				break;
			}
			case 'minHeight': {
				validationRules['message'] = validation.message;
				validationRules['minHeight'] = validation.value;
				break;
			}
			case 'minLongestSide': {
				validationRules['message'] = validation.message;
				validationRules['minLongestSide'] = validation.value;
				break;
			}
			case 'minShortestSide': {
				validationRules['message'] = validation.message;
				validationRules['minShortestSide'] = validation.value;
				break;
			}
		}
	}

	return validationRules;
};
