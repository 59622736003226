import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
const ChartLineIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			width="15px"
			height="15px"
			viewBox="0 0 15 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.5717 0.144531C13.8217 0.144531 14.0717 0.394531 14.0717 0.644531V3.14453C14.0717 3.42578 13.8217 3.64453 13.5717 3.64453H13.0717C12.7904 3.64453 12.5717 3.42578 12.5717 3.14453V2.14453H8.07166V12.6445H9.57166C9.82166 12.6445 10.0717 12.8945 10.0717 13.1445V13.6445C10.0717 13.9258 9.82166 14.1445 9.57166 14.1445H4.57166C4.29041 14.1445 4.07166 13.9258 4.07166 13.6445V13.1445C4.07166 12.8945 4.29041 12.6445 4.57166 12.6445H6.07166V2.14453H1.57166V3.14453C1.57166 3.42578 1.32166 3.64453 1.07166 3.64453H0.571655C0.290405 3.64453 0.0716553 3.42578 0.0716553 3.14453V0.644531C0.0716553 0.394531 0.290405 0.144531 0.571655 0.144531H13.5717Z"
				fill={color ? (color as string) : '#969B9D'}
			/>
		</Icon>
	);
};
export default ChartLineIcon;
