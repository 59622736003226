import { FC } from 'react';
import { HStack } from '@chakra-ui/react';
import { IDesignDirection } from 'src/lib/schemas';
import CarouselImage from './CarouselImage';

interface CarouselProps {
	designDirections: IDesignDirection[];
	currentDDId: string | undefined;
	onEdit: (designDirection: IDesignDirection) => void;
	onRemove: (ddId: string) => void;
	isRemovingDD: boolean;
	onRetry: (designDirection: IDesignDirection) => void;
}

const Carousel: FC<CarouselProps> = ({
	designDirections,
	currentDDId,
	onEdit,
	onRemove,
	isRemovingDD,
	onRetry,
}) => {
	return (
		<HStack spacing={8}>
			{designDirections.map((dd) => {
				return (
					<CarouselImage
						key={dd.id}
						designDirection={dd}
						currentDDId={currentDDId}
						onEdit={onEdit}
						onRemove={onRemove}
						isRemovingDD={isRemovingDD}
						onRetry={onRetry}
					/>
				);
			})}
		</HStack>
	);
};

export default Carousel;
