import { FC } from 'react';
import { Alert, AlertIcon, AlertProps } from '@chakra-ui/alert';

const AlertMessage: FC<AlertProps> = ({ children, ...props }) => {
	return (
		<Alert borderRadius="md" fontSize="sm" {...props}>
			<AlertIcon />
			{children}
		</Alert>
	);
};

export default AlertMessage;
