import { FC, useState } from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';

import { ClipboardIcon } from 'src/assets/icons';

interface CopyToClipboardProps {
	value: string;
	size?: string;
	children?: React.ReactNode;
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({
	value,
	size = 'sm',
	children,
}) => {
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	const handleClipboard = (content: string) => {
		setIsCopied(true);
		setIsTooltipOpen(true);
		navigator.clipboard.writeText(content);
	};

	return (
		<Tooltip
			placement="top"
			isOpen={isTooltipOpen}
			color={isCopied ? 'green.800' : '#202223'}
			label={isCopied ? 'Copied!' : 'Copy'}
			bg={isCopied ? 'green.100' : 'gray.100'}
			closeDelay={800}
			onClose={() => {
				setIsTooltipOpen(false);
				setIsCopied(false);
			}}
			hasArrow
		>
			<IconButton
				aria-label="Copy value"
				size={size}
				bg="transparent"
				_hover={{ bg: 'transparent' }}
				onMouseEnter={() => setIsTooltipOpen(true)}
				onMouseLeave={() => setIsTooltipOpen(false)}
				onClick={() => handleClipboard(value)}
			>
				{children ? children : <ClipboardIcon />}
			</IconButton>
		</Tooltip>
	);
};

export default CopyToClipboard;
