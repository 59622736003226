import { FC, useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
	Button,
	Flex,
	Container,
	Collapse,
	Heading,
	Skeleton,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { SparklesIcon } from 'src/assets/icons';

import { TextareaInputHook } from 'src/components/common/form';
import { CampaignContext } from 'src/contexts';
import { createOrUpdateCampaign } from 'src/services/campaign';
import { toastError } from 'src/services/toast';

interface BackgroundPromptProps {
	onGeneratePrompt: (isEmpty?: boolean) => void;
	isGeneratingPropmt: boolean;
}

const BackgroundPrompt: FC<BackgroundPromptProps> = ({
	onGeneratePrompt,
	isGeneratingPropmt,
}) => {
	const { campaign, isFetching, setCampaign } = useContext(CampaignContext);
	const [isSaving, setIsSaving] = useState(false);
	const [isCollapsed, setIsCollapsed] = useState(true);
	const timeoutId = useRef<any>(null);
	const formMethods = useForm<{ backgroundPrompt: string }>();
	const { watch } = formMethods;
	const backgroundPrompt = watch('backgroundPrompt');

	useEffect(() => {
		if (!isFetching && campaign?.brief?.prompt) {
			formMethods.reset({ backgroundPrompt: campaign.brief.prompt });
		}
	}, [campaign?.brief?.prompt]);

	const handleFieldChanges = () => {
		if (!campaign?.brief) return;
		setCampaign({
			...campaign,
			brief: { ...campaign.brief, prompt: backgroundPrompt },
		});
	};

	useEffect(() => {
		backgroundPrompt && waitForSaving();
	}, [backgroundPrompt]);

	const waitForSaving = () => {
		clearTimeout(timeoutId.current);
		timeoutId.current = setTimeout(handleAutosavePrompt, 1000);
	};

	const handleAutosavePrompt = async () => {
		if (!campaign?.brief) return;
		if (isSaving) {
			waitForSaving();
			return;
		}
		setIsSaving(true);
		try {
			await createOrUpdateCampaign(
				{ brief: { ...campaign.brief, prompt: backgroundPrompt } },
				campaign.id,
			);
		} catch (error) {
			toastError(error);
		}
		setIsSaving(false);
	};

	const setFieldValue = (field: any, value: string) => {
		formMethods.setValue(field, value);
		handleFieldChanges();
	};

	if (isGeneratingPropmt)
		return <Skeleton h="58px" w="full" borderRadius="10px" />;

	return (
		<Container
			maxW="7xl"
			px={0}
			py={5}
			m={0}
			bgColor="white"
			borderColor="grey"
			border="1px solid #EDEDED"
			borderRadius="10px"
			padding="10px 20px 15px 20px"
		>
			<Flex
				alignItems="center"
				justifyContent="space-between"
				gap={1}
				onClick={() => setIsCollapsed(!isCollapsed)}
				cursor="pointer"
			>
				<Heading fontWeight="regular" my={2} fontSize="14px">
					Background Description (Optional)
				</Heading>
				{isCollapsed ? (
					<ChevronDownIcon boxSize={8} color="grey" />
				) : (
					<ChevronUpIcon boxSize={8} color="grey" />
				)}
			</Flex>
			<Collapse in={!isCollapsed}>
				<Flex direction="column" gap={1}>
					<FormProvider {...formMethods}>
						<form>
							<TextareaInputHook
								withAssistant
								placeholder="Do you already have an idea of the background for your creatives? You can describe it here or we will autogenerate one"
								name="backgroundPrompt"
								label=""
								setFieldValue={setFieldValue}
								textareaProps={{
									minH: '170px',
									isDisabled: isGeneratingPropmt,
								}}
								formLabelProps={{
									fontSize: '12px',
									color: '#A0AEC0',
								}}
							/>
						</form>
						<Flex justifyContent="right">
							<Button
								colorScheme="secondary"
								height={7}
								width="300px"
								rightIcon={<SparklesIcon color="white" />}
								onClick={() => onGeneratePrompt()}
								isLoading={isGeneratingPropmt}
								size="sm"
								mt="10px"
							>
								Generate background description
							</Button>
						</Flex>
					</FormProvider>
				</Flex>
			</Collapse>
		</Container>
	);
};

export default BackgroundPrompt;
