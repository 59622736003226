import { FC, useContext, useEffect, useState } from 'react';
import { Box, Container, Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import Overlay from 'src/components/common/Overlay';
import ContentPendingProgress from 'src/components/common/ContentPendingProgress';
import { LockIcon } from 'src/assets/icons';
import {
	IAttribute,
	IChannelCreative,
	IChannelEmailContent,
	IChannelTextContent,
	ICreative,
} from 'src/lib/schemas';
import { TextCreativeForm } from './CreativeForm';
import { toastError } from 'src/services/toast';
import { updateCampaignCreative } from 'src/services/campaign';
import { CampaignContext } from 'src/contexts';
import { getValidationRules } from './CreativeForm/utils/inputValidation';

interface TextCreactiveContentProps {
	creativeData?: ICreative;
	channelCreative?: IChannelCreative;
	title: string;
	content?: string;
	isLocked?: boolean;
	isCreativePending: boolean;
	onRegenerate: () => void;
	onLockUnlock: () => void;
}

interface FormData {
	content: string;
}

const TextCreactiveContent: FC<TextCreactiveContentProps> = ({
	creativeData,
	channelCreative,
	content = '',
	isCreativePending,
	isLocked,
}) => {
	const { id: campaignId } = useContext(CampaignContext);
	if (!creativeData) return null;
	const inputs = channelCreative?.attributes as IAttribute[];
	const creativeId = creativeData.id;
	const attributesData = creativeData.attributes as
		| IChannelTextContent
		| IChannelEmailContent;
	const [isLoading, setIsLoading] = useState(false);
	const formMethods = useForm<FormData>();

	const handleSubmit = async (formData: any) => {
		const payload = {
			attributes: formData,
		};
		if (!campaignId || !creativeId) return;
		try {
			await updateCampaignCreative(campaignId, creativeId, payload);
		} catch (error: any) {
			toastError(error);
		}
	};

	useEffect(() => {
		formMethods.reset({
			content,
		});
	}, [content]);

	const calculateTotalHeight = (inputs: IAttribute[]) =>
		inputs &&
		inputs.reduce((totalHeight, input) => {
			const validationRules = getValidationRules(input);
			const isStringType = input.type === 'String';
			const isSingleline = validationRules?.multiline?.value === false;

			const isTextAreaType =
				isStringType &&
				!isSingleline &&
				(!validationRules.maxLength || validationRules.maxLength.value >= 90);

			return (
				totalHeight +
				((isTextAreaType || input.type === 'HTML'
					? 127
					: isStringType
					? 84
					: 62) || 0) +
				20
			);
		}, 0);

	const wrapperHeight = calculateTotalHeight(inputs);

	return (
		<Flex
			flex={1}
			borderRadius="lg"
			background="#F8F8F8"
			px={2}
			py={6}
			boxShadow="0px 1px 6px 0px rgba(0, 0, 0, 0.15)"
		>
			<Container maxW="7xl" minH={wrapperHeight}>
				<Flex gap={4} w="full" h="full" justify="center" alignItems="center">
					<Box
						display={isLoading || isCreativePending ? 'none' : 'block'}
						w="full"
						position="relative"
						pb={1}
					>
						{isLocked && <LockedImageOverlay />}
						{!isLoading && !isCreativePending && (
							<TextCreativeForm
								inputs={inputs}
								initialValues={attributesData}
								onSubmit={handleSubmit}
								stringTypeCasting
								creativeData={creativeData}
							/>
						)}
					</Box>
					<ContentPendingProgress
						time={60}
						isPendingGeneration={isCreativePending}
						onGenerateStatusChange={(val) => setIsLoading(val)}
					/>
				</Flex>
			</Container>
		</Flex>
	);
};

const LockedImageOverlay = () => {
	return (
		<Overlay
			zIndex={50}
			h="full"
			borderRadius="md"
			children={
				<Flex w="full" h="full" justify="center" align="center">
					<LockIcon color="white" w={6} h={6} />
				</Flex>
			}
		/>
	);
};
export default TextCreactiveContent;
