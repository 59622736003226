import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RefreshPage = () => {
	const { state } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (state) {
			navigate(state.to, { replace: true });
		} else {
			navigate('/home', { replace: true });
		}
	}, [state]);

	return null;
};

export default RefreshPage;
