import { FC, useContext, useEffect, useRef } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { IMessage, ThreadRolesEnum } from 'src/lib/schemas';
import Suggestion from './Suggestion';
import { AssistantChatContext } from 'src/contexts';
import ThreeDotsAnimation from './animation/ThreeDotsAnimation';
import FusionLoading from '../common/FusionLoading';
import UserLogo from '../common/UserLogo';
import FusionLogo from '../common/FusionLogo';
import Message from './Message';
import _ from 'lodash';

interface IMessages {
	conversation: Array<IMessage>;
}

const Messages: FC<IMessages> = ({ conversation }) => {
	const LOGO_SIZES = {
		h: 9,
		w: 9,
	};
	const { isChatLoading, isMyAssistantWritting } =
		useContext(AssistantChatContext);
	const lastItemRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		lastItemRef?.current?.scrollIntoView();
	}, [conversation]);

	const renderAssistantWritting = () => (
		<Flex gap={5} alignItems="center">
			<FusionLogo size={LOGO_SIZES} />
			<ThreeDotsAnimation />
			<Text
				width="350px"
				fontSize="11px"
				lineHeight="12px"
				color=" gray"
				ml="-10px"
			>
				I may take me up to 30 seconds. You can continue working with other
				fields and I will blink when I am done.
			</Text>
		</Flex>
	);

	const renderAvatar = (isUser: boolean) =>
		isUser ? <UserLogo size={LOGO_SIZES} /> : <FusionLogo size={LOGO_SIZES} />;

	const renderContent = (message: IMessage) => (
		<Flex direction="column" justifyContent="center" gap={2} px={2}>
			{message.splitted_content?.map((content, i) =>
				content.suggestion ? (
					<Suggestion key={i} content={content.value} />
				) : (
					<Message key={i} content={content.value} />
				),
			)}
		</Flex>
	);

	const renderedMessages = conversation.map((message, i) => {
		const isLastItem = conversation.length - 1 === i;
		const isUser = message.role === ThreadRolesEnum.User;

		return (
			<Flex
				ref={isLastItem ? lastItemRef : null}
				key={i}
				mb={3}
				direction={isUser ? 'row-reverse' : 'row'}
			>
				{renderAvatar(isUser)}
				<Box
					bg={'gray.200'}
					borderRadius={20}
					borderTopLeftRadius={isUser ? 20 : 2}
					borderTopRightRadius={isUser ? 2 : 20}
					p={3}
					mt={3}
					ml={isUser ? 0 : 2}
					mr={isUser ? 2 : 0}
				>
					<Flex direction="column" justifyContent="center" gap={3}>
						{renderContent(message)}
					</Flex>
				</Box>
			</Flex>
		);
	});

	return (
		<Flex flex={1} direction="column" p={4} overflowX="scroll">
			{isChatLoading ? (
				<FusionLoading isLoading={isChatLoading} />
			) : (
				<>
					{renderedMessages}
					{isMyAssistantWritting && renderAssistantWritting()}
				</>
			)}
		</Flex>
	);
};

export default Messages;
