import { FC } from 'react';
import { Icon, IconProps, Tooltip } from '@chakra-ui/react';

interface DownloadIconProps extends IconProps {
	tooltipLabel?: string;
}

const DownloadIcon: FC<DownloadIconProps> = ({
	color,
	width = '20px',
	height = '20px',
	tooltipLabel = 'Download',
	...props
}) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9.28015 0.959406V9.72999L12.3547 6.65543C12.646 6.33179 13.1314 6.33179 13.4551 6.65543C13.7463 6.9467 13.7463 7.43216 13.4551 7.72343L9.02124 12.1249C8.72996 12.4485 8.24451 12.4485 7.95323 12.1249L3.55176 7.72343C3.22812 7.43216 3.22812 6.9467 3.55176 6.65543C3.84304 6.33179 4.32849 6.33179 4.65213 6.65543L7.72669 9.72999V0.959406C7.72669 0.538677 8.05033 0.182676 8.50342 0.182676C8.92415 0.182676 9.28015 0.538677 9.28015 0.959406ZM4.36086 10.0213L5.91432 11.5747H2.28958C1.9983 11.5747 1.77176 11.8336 1.77176 12.0925V14.6816C1.77176 14.9729 1.9983 15.1995 2.28958 15.1995H14.7173C14.9762 15.1995 15.2351 14.9729 15.2351 14.6816V12.0925C15.2351 11.8336 14.9762 11.5747 14.7173 11.5747H11.0602L12.6136 10.0213H14.7173C15.85 10.0213 16.7885 10.9598 16.7885 12.0925V14.6816C16.7885 15.8467 15.85 16.7529 14.7173 16.7529H2.28958C1.12448 16.7529 0.218295 15.8467 0.218295 14.6816V12.0925C0.218295 10.9598 1.12448 10.0213 2.28958 10.0213H4.36086ZM14.1994 13.3871C14.1994 13.8402 13.8434 14.1638 13.4227 14.1638C12.9696 14.1638 12.646 13.8402 12.646 13.3871C12.646 12.9664 12.9696 12.6104 13.4227 12.6104C13.8434 12.6104 14.1994 12.9664 14.1994 13.3871Z"
				fill={color ? (color as string) : '#3182CE'}
			/>{' '}
		</Icon>
	);
};

export default DownloadIcon;
