import { Box, Flex, Heading, Skeleton } from '@chakra-ui/react';

interface IImageSkeleton {
	payloadLength: number;
}
export const ImageSkeleton = (props: IImageSkeleton) => {
	const { payloadLength } = props;

	return (
		<Box>
			{Array.from({ length: payloadLength }).map((_, index) => (
				<Skeleton
					key={`h-skeleton-${index}`}
					height="200px"
					mt="10px"
					width="200px"
					borderRadius="10px"
				/>
			))}
		</Box>
	);
};
