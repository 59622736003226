export const upsertElem = <T extends { id: string }>(
	arr: T[],
	item: T,
): T[] => {
	const index = arr.findIndex((i) => i.id === item.id);
	if (index === -1) {
		arr.push(item);
	} else {
		arr[index] = item;
	}
	return arr;
};

export const deleteElem = <T extends { id: string }>(
	arr: T[],
	id: string,
): T[] => {
	const index = arr.findIndex((i) => i.id === id);
	if (index !== -1) {
		arr.splice(index, 1);
	}
	return arr;
};
