import { FC } from 'react';
import {
	Tag,
	TagLabel,
	TagRightIcon,
	Tooltip,
	Text,
	Flex,
} from '@chakra-ui/react';
import { CloseIcon, InfoOutlineIcon } from '@chakra-ui/icons';

import { IOption } from 'src/lib/schemas/misc';

interface ValueChipProps {
	option: IOption;
	info?: boolean;
	onRemove: (val: IOption) => void;
}

const ValueChip: FC<ValueChipProps> = ({ option, info, onRemove }) => {
	return (
		<Tag
			px={3}
			py={1.5}
			bg="white"
			borderRadius="full"
			boxShadow="0px 1px 6px 0px rgba(0, 0, 0, 0.15)"
		>
			<TagLabel fontSize="sm" fontWeight={400} lineHeight="20px">
				{option.label}
			</TagLabel>
			{info && (
				<Tooltip
					label={<CustomToolTip label={option.description ?? ''} />}
					placement="bottom-start"
					bg="white"
					color="#202223"
					border="lg"
					boxShadow="0px 1px 6px 0px rgba(0, 0, 0, 0.15)"
					hasArrow
				>
					<span>
						<TagRightIcon
							boxSize="16px"
							cursor="pointer"
							color="#3182CE"
							as={InfoOutlineIcon}
						/>
					</span>
				</Tooltip>
			)}
			<TagRightIcon
				boxSize="11px"
				cursor="pointer"
				color="#737981"
				as={CloseIcon}
				onClick={() => onRemove(option)}
			/>
		</Tag>
	);
};

interface CustomToolTipProps {
	label: string;
}

const CustomToolTip: FC<CustomToolTipProps> = ({ label }) => {
	return (
		<Flex gap={1} p={3}>
			<Text fontWeight={600}>Description:</Text>
			<Text>{label}</Text>
		</Flex>
	);
};

export default ValueChip;
