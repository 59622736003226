import {
	Flex,
	Text,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Box,
} from '@chakra-ui/react';
import { ILayerAction } from 'src/contexts/templates/TemplatesContext';

interface ActionDropdownProps {
	actionId?: string;
	onSelect: (actionId: ILayerAction) => void;
	borderColor: string;
}

export const ActionDropdown = ({
	onSelect,
	actionId,
	borderColor,
}: ActionDropdownProps) => {
	const actions = [
		{ id: 'REMOVE_BACKGROUND' },
		{
			id: 'SET_PROPERTY',
			arguments: {
				property: 'imageUrl',
				value: '$variable.product',
			},
		},
	];

	function getLabelByActionId(actionId?: string): string {
		switch (actionId) {
			case 'REMOVE_BACKGROUND':
				return 'Remove Background';
			case 'SET_PROPERTY':
				return 'Set Property';
			default:
				return '';
		}
	}

	return (
		<Box>
			<Text pl={5}>Action</Text>
			<Menu>
				<MenuButton
					border="1px solid"
					borderColor={borderColor}
					w="250px"
					h="40px"
					borderRadius="md"
					textAlign="left"
					px={3}
					_hover={{ borderColor: 'gray.500', bg: 'gray.400' }}
					boxShadow="s"
				>
					{getLabelByActionId(actionId)}
				</MenuButton>
				<MenuList maxH="300px" overflowY="auto">
					{actions.map((action) => (
						<MenuItem
							key={action.id}
							value={action.id}
							onClick={() => onSelect(action)}
						>
							{getLabelByActionId(action.id)}
						</MenuItem>
					))}
				</MenuList>
			</Menu>
		</Box>
	);
};
