import { FC, useContext, useEffect, useState } from 'react';
import AssistantChatContext from './AssistanChatContext';
import {
	ChatMessageEnum,
	IField,
	IMessage,
	IOpenAssistantChat,
	IThread,
	IThreadPayload,
	ThreadRolesEnum,
} from 'src/lib/schemas';
import {
	getThreadByReference,
	sendMessage as sendMessageService,
} from 'src/services/assistant';
import { toastError } from 'src/services/toast';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import UserContext from '../UserContext';

enum ResponseStatus {
	Seen,
	Pending,
	Unread,
}

interface AssistantChatContextProps {
	children: React.ReactNode;
}
declare global {
	interface Window {
		Intercom: any;
	}
}

export const threadInitialValue: IThread = {
	conversation: [
		{
			role: ThreadRolesEnum.Assistant,
			splitted_content: [
				{
					value: 'How can I help you?',
					type: ChatMessageEnum.String,
					suggestion: false,
				},
			],
		},
	],
};

const DEFAULT_ASSISTANT = 'asst_gpeBq87agLX8yZ94Y3oZPz2B';

const AssistantChatProvider: FC<AssistantChatContextProps> = ({ children }) => {
	const [isChatOpen, setIsChatOpen] = useState(false);
	const [isChatCollapsed, setIsChatCollapsed] = useState(false);
	const [isChatLoading, setIsChatLoading] = useState(false);
	const [isIntercomVisible, setIsIntercomVisible] = useState(false);
	const [field, setField] = useState<IField | null>(null);
	const [thread, setThread] = useState<IThread | null>(threadInitialValue);
	const [fieldRef, setFieldRef] = useState<HTMLDivElement | null>(null);
	const [responseMessage, setResponseMessage] = useState<IMessage | null>(null);
	const [requestMessages, setRequestMessages] = useState<Array<IThreadPayload>>(
		[],
	);
	const [responseStatus, setResponseStatus] = useState(ResponseStatus.Seen);
	const location = useLocation();

	useEffect(() => {
		closeChat();
		window.Intercom &&
			window.Intercom('onShow', () => {
				setIsIntercomVisible(true);
			});
	}, [location]);

	useEffect(() => {
		if (!responseMessage) return;
		if (isCurrentChat) {
			addMessage(responseMessage);
		}
	}, [responseMessage]);

	const isCurrentChat =
		thread?.threadId === responseMessage?.threadId &&
		thread?.property === responseMessage?.property;

	const hasUnseenMessages = responseStatus === ResponseStatus.Unread;

	const shouldSubmitBeAble =
		!requestMessages.length ||
		thread?.threadId !== _.last(requestMessages)?.thread_id;

	const isMyAssistantWritting =
		requestMessages.length > 0 &&
		_.last(requestMessages)?.thread_id === thread?.threadId &&
		_.last(requestMessages)?.scope.property === thread?.property;

	const openChat = () => {
		closeChat();
		setIsChatCollapsed(false);
		setIsChatOpen(true);
		resetThread();
		setResponseStatus(ResponseStatus.Seen);
		hideIntercomMessenger();
	};

	const hideIntercomMessenger = () => {
		if (!window.Intercom) return;

		window.Intercom('update', {
			hide_default_launcher: true,
		});
	};
	const showIntercomMessenger = () => {
		if (!isIntercomVisible || !window.Intercom) return;

		window.Intercom('update', {
			hide_default_launcher: false,
		});
	};
	const closeChat = () => {
		resetThread();
		setField(null);
		setIsChatOpen(false);
		showIntercomMessenger();
	};

	const resetThread = () => {
		setThread(threadInitialValue);
	};

	const addMessage = (message: IMessage) => {
		setThread((prevState) => ({
			...prevState,
			id: message.threadId,
			conversation: [...prevState!.conversation, message],
		}));
	};

	const openAssistantChat = async ({
		fieldParams,
		threadParams,
		ref,
	}: IOpenAssistantChat) => {
		openChat();
		setIsChatLoading(true);
		setFieldRef(ref ?? null);
		const { type, reference, sub_reference } = threadParams!;
		const { id: property } = fieldParams!;
		let updatedThread = _.cloneDeep(thread);
		try {
			const { scope, conversation } = await getThreadByReference({
				type,
				reference,
				sub_reference,
				property,
			});
			const { thread_id: threadId, assistant_id: assistantId } = scope;
			const updatedConversation =
				conversation.length > 0
					? conversation
					: threadInitialValue.conversation;
			updatedThread = {
				threadId,
				assistantId,
				property,
				conversation: updatedConversation,
				reference,
				sub_reference,
			};
		} catch (error) {
			updatedThread = {
				...threadInitialValue,
				reference,
				sub_reference,
				property,
			};
		}
		setIsChatLoading(false);
		setField(fieldParams);
		setThread(updatedThread);
	};

	const markMessageAsSeen = () => setResponseStatus(ResponseStatus.Seen);

	const sendMessage = async (payload: IThreadPayload) => {
		setRequestMessages((prevState) => [...prevState, payload]);
		setResponseStatus(ResponseStatus.Pending);
		try {
			const { response } = await sendMessageService({
				assistantId: thread?.assistantId || DEFAULT_ASSISTANT,
				payload,
				threadId: payload.thread_id,
			});
			const message = {
				role: response.role,
				threadId: response.thread_id,
				property: response.property,
				splitted_content: response.splitted_content,
			};
			setResponseStatus(ResponseStatus.Unread);
			setResponseMessage(message);

			if (!thread?.threadId) {
				setThread((prevState) => ({
					...prevState,
					threadId: response.thread_id,
					conversation: [...prevState!.conversation],
				}));
			}
		} catch (error) {
			toastError(
				'The message could not be sent. Please refresh the page and try again.',
			);
		}
		setRequestMessages((prevState) =>
			prevState.filter((message) => message !== payload),
		);
	};

	const applySuggestion = (value: string) => {
		field?.setValue?.(field.name, value);
		field?.setValueV2?.(value);
		const input = fieldRef?.lastChild as HTMLElement;
		input?.focus();
		input?.scrollIntoView({
			block: 'center',
			behavior: 'instant',
		});
	};

	return (
		<AssistantChatContext.Provider
			value={{
				isChatOpen,
				isChatCollapsed,
				field,
				thread,
				isMyAssistantWritting,
				isChatLoading,
				shouldSubmitBeAble,
				requestMessages,
				sendMessage,
				setField,
				openChat,
				closeChat,
				setIsChatCollapsed,
				resetThread,
				addMessage,
				openAssistantChat,
				applySuggestion,
				isCurrentChat,
				hasUnseenMessages,
				markMessageAsSeen,
			}}
		>
			{children}
		</AssistantChatContext.Provider>
	);
};

export default AssistantChatProvider;
