import { SimpleGrid } from '@chakra-ui/react';
import { ICardData } from '../utils/marketinData';
import ShortcutCard from './ShortcutCard';

const routes = {
	campaign: '/projects/campaigns/new',
	imageGenerator: '/tasks/fusion_ai.image_generator',
};
const shortcuts: ICardData[] = [
	{
		icon: 'images/home/multi-channels.png',
		id: 'all',
		size: 'sm',

		title: 'Promote my business in all the channels',
		navigate: routes.campaign,
	},
	{
		icon: 'images/home/facebook.png',
		id: 'facebook',
		title: 'Promote my business in Facebook',
		size: 'sm',
		navigate: routes.campaign,
	},
	{
		icon: 'images/home/instagram.png',
		id: 'instagram',
		title: 'Promote my business in Instagram',
		size: 'sm',
		navigate: routes.campaign,
	},
	{
		icon: 'images/home/google.png',
		id: 'google',
		size: 'sm',
		title: 'Promote my business in Google',
		navigate: routes.campaign,
	},
	{
		icon: 'images/home/x-icon.png',
		id: 'twitter',
		title: 'Promote my business in X',
		size: 'sm',
		navigate: routes.campaign,
	},
	// {
	// 	icon: 'images/home/url.svg',
	// 	id: 'twitter',
	// 	title: 'Generate ads from a URL',
	// 	size: 'sm',
	// 	navigate: routes.campaign,
	// },
	// {
	// 	icon: 'images/home/ads.svg',
	// 	id: 'twitter',
	// 	title: 'Generate ads from a product detail page',
	// 	size: 'sm',
	// 	navigate: routes.campaign,
	// },
	{
		icon: 'images/home/copy.svg',
		id: 'twitter',
		title: 'Generate ad copy ',
		size: 'sm',
		navigate: '/tasks?category=65d5e6e691a129f951e102c4',
	},
	{
		icon: 'images/home/fusionLogo.svg',
		id: 'generate',
		title: 'Generate an image with AI',
		size: 'sm',

		navigate: '/tasks/fusion_ai.image_generator',
	},
	{
		icon: 'https://storage.googleapis.com/fusion-cloud-ai-static/static-assets/icons/photoroom_fusion.png',

		id: 'background',
		size: 'sm',

		title: 'Change image background',
		navigate: '/tasks/fusion_ai.change_split_background_with_ref',
	},
];

const ShortcutGrid = () => {
	return (
		<SimpleGrid w="full" columns={{ base: 3, lg: 4 }} spacing={7}>
			{shortcuts.map((shortcut, index) => (
				<ShortcutCard key={index} card={shortcut} />
			))}
		</SimpleGrid>
	);
};

export default ShortcutGrid;
