import { FC } from 'react';
import {
	Box,
	Flex,
	Text,
	Switch,
	FormLabel,
	FormControl,
	Img,
} from '@chakra-ui/react';

export interface MarketingChannelInputProps {
	icon?: string;
	value?: boolean;
	label?: string;
	name: string;
	onChange?: (val: boolean) => void;
}

const MarketingChannelInput: FC<MarketingChannelInputProps> = ({
	value,
	name,
	label,
	icon,
	onChange,
}) => {
	const handleSwitchChange = () => {
		onChange && onChange(!value);
	};

	const containerBoxProps = value
		? { borderColor: '#3182CE' }
		: { borderColor: 'white' };
	const labelProps = value ? { color: '#3182CE' } : {};

	return (
		<Box
			width="180px"
			p={1}
			border="2px solid"
			borderRadius="lg"
			gap={6}
			cursor="pointer"
			{...containerBoxProps}
		>
			<Flex
				w="full"
				direction="column"
				gap={2}
				py={4}
				px={3}
				boxShadow="0px 1px 6px 0px rgba(0, 0, 0, 0.15)"
				borderRadius="lg"
				onClick={handleSwitchChange}
			>
				<Flex justify="space-between" alignItems="center">
					<Text>{label}</Text>
					<Img width="20px" height="20px" src={icon} />
				</Flex>
				<FormControl
					display="flex"
					gap={2}
					mt="15px"
					alignItems="center"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Switch name={name} isChecked={value} onChange={handleSwitchChange} />
					<FormLabel cursor="pointer" {...labelProps} mb="0px">
						Enabled
					</FormLabel>
				</FormControl>
			</Flex>
		</Box>
	);
};

export default MarketingChannelInput;
