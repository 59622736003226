import { FC, useEffect, useState } from 'react';
import {
	Button,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Textarea,
} from '@chakra-ui/react';

interface GoalEditModalProps {
	isOpen: boolean;
	onClose: () => void;
	value: string;
	onApply: (val: string) => void;
}

const GoalEditModal: FC<GoalEditModalProps> = ({
	isOpen,
	onClose,
	value,
	onApply,
}) => {
	const [inputValue, setInputValue] = useState<string>('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const handleInputChange = (e: any) => {
		const inputValue = e.target.value;
		setInputValue(inputValue);
	};

	const handleSubmit = async () => {
		setIsSubmitting(true);
		await onApply(inputValue);
		setIsSubmitting(false);
		onClose();
	};

	const handleClose = () => {
		if (isSubmitting) return;
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={handleClose} size="lg">
			<ModalOverlay zIndex={1800} />
			<ModalContent
				margin="auto"
				containerProps={{
					zIndex: 1900,
				}}
			>
				<ModalHeader>
					<Heading fontWeight="semibold">Edit Goal</Heading>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Textarea
						variant="outline"
						placeholder="Enter goal"
						fontSize="sm"
						value={inputValue}
						onChange={handleInputChange}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						size="sm"
						colorScheme="secondary"
						isLoading={isSubmitting}
						loadingText="Submitting..."
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default GoalEditModal;
