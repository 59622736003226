import { Flex, Divider } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { ClipboardIcon, LockIcon } from 'src/assets/icons';
import CopyToClipboard from 'src/components/common/CopyToClipboard';
import Overlay from 'src/components/common/Overlay';
import { TextareaInputHook } from 'src/components/common/form';
import { ICreative, IDesignDirection, TextLayer } from 'src/lib/schemas';

interface DDTextFieldProps {
	layer: TextLayer;
	isLocked?: boolean;
	isChangingLayer: boolean;
	creativeData?: ICreative | IDesignDirection;
	fieldChanges?: () => void;
	setFieldValue: (field: string, value: string) => void;
}
const DDTextField: FC<DDTextFieldProps> = ({
	creativeData,
	layer,
	isLocked,
	fieldChanges,
	isChangingLayer,
	setFieldValue,
}) => {
	const { name, displayName } = layer;
	const { field } = useController({ name });
	const { value: updatedValue } = field;

	useEffect(() => {
		if (updatedValue !== undefined) fieldChanges && fieldChanges();
	}, [updatedValue]);

	return (
		<Flex direction="column" gap={1}>
			<Flex
				gap={4}
				justify="space-between"
				alignItems="center"
				wrap="wrap-reverse"
			>
				<Flex fontSize="14px">{displayName}</Flex>

				<Flex gap={2} alignItems="center" wrap="wrap">
					<CopyToClipboard value={updatedValue ?? ''}>
						<ClipboardIcon filled={false} color="#3182CE" />
					</CopyToClipboard>
				</Flex>
			</Flex>

			<Flex position="relative">
				{isLocked && <LockedImageOverlay />}
				<TextareaInputHook
					displayName={displayName}
					withAssistant
					creativeData={creativeData}
					setFieldValue={setFieldValue}
					layer={layer}
					name={name}
					textareaProps={{
						rows: 2,
						isDisabled: isChangingLayer,
					}}
				/>
			</Flex>
			<Divider color="#CACACA" my={6} />
		</Flex>
	);
};

const LockedImageOverlay = () => {
	return (
		<Overlay
			zIndex={100}
			children={
				<Flex w="full" h="full" justify="center" align="center">
					<LockIcon color="white" w={6} h={6} />
				</Flex>
			}
		/>
	);
};

export default DDTextField;
