import { FC } from 'react';
import { Box, Text, Image, VStack, Icon } from '@chakra-ui/react';
import { IChannel, IPlacement } from 'src/lib/schemas';
import styles from './MarketingChannelInput.module.css';
import { ChannelType } from 'src/components/campaigns/channels/ChannelType';
import { CHANNELS_ICON_MAPPING } from 'src/lib/constants';
import { CheckCircleIcon } from '@chakra-ui/icons';

export interface MarketingChannelInputProps {
	id: string;
	placement?: IPlacement;
	type: ChannelType;
	isSelected?: boolean;
	name?: string;
	onChange: (channelId: string, channelType: ChannelType) => void;
	description?: string;
	icon: string;
	fontWeight?: string;
	fontColor?: string;
}

const MarketingChannelInput: FC<MarketingChannelInputProps> = ({
	type,
	name,
	id,
	isSelected,
	onChange,
	description,
	icon,
	fontWeight,
	fontColor,
}) => {


	const handleSwitchChange = () => {
		onChange && onChange(id, type);
	};

	const borderWidth = isSelected ? '2px' : '1px';
	const padding = isSelected ? '0px' : '2px';

	return (
		<Box
			className={isSelected ? '' : styles.container}
			borderWidth={borderWidth}
			borderRadius="lg"
			w="full"
			maxW="200px"
			mx="auto"
			textAlign="center"
			onClick={handleSwitchChange}
			borderColor={isSelected ? 'orange.base' : 'gray.300'}
			cursor="pointer"
			transition="border-color 0.2s ease-in-out"
			position="relative"
			p={padding}
			_hover={{
				borderColor: isSelected ? 'orange.base' : 'gray.400',
			}}
		>
			{isSelected && (
				<Icon
					as={CheckCircleIcon}
					color="#F77A20"
					position="absolute"
					top="8px"
					right="8px"
					boxSize="24px"
				/>
			)}
			<Box
				bg="gray.50"
				width="100%"
				borderTopRadius="lg"
				transition="background-color 0.3s ease-in-out"
			>
				<VStack spacing={4} py={4} height="100%" justifyContent="center">
					<Image src={icon} alt={name} h="3em" />
				</VStack>
			</Box>
			<Box bg="white" p={2} className={styles.text} borderBottomRadius="lg">
				<Text color={fontColor} fontWeight={fontWeight}>{name}</Text>
				{description && (
					<Text color="gray.500" fontSize="12px">
						{description}
					</Text>
				)}
			</Box>
		</Box>
	);
};

export default MarketingChannelInput;
