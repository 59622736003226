import { IIndustry } from 'src/services/industries';
import { z } from 'zod';

export interface IGetDomainDataSchema {
	websiteLink: string;
	name?: string;
	logo?: string;
	font?: string;
	industry?: IIndustry;
}

export const GetDomainDataSchema: Zod.ZodType<IGetDomainDataSchema> = z.object({
	websiteLink: z.string(),
	name: z.string().optional(),
	logo: z.string().optional(),
	font: z.string().optional(),
});
