import {
	ICampaignGenAIPayload,
	// IChannelPlainContent,
	// IFacebookContent,
} from 'src/lib/schemas';

import http from './http-genai';

const genAIUrl = '/gen-ai';

interface IGoals {
	goals: Array<string>;
}

export const generateCampaignDescription = async (
	payload: ICampaignGenAIPayload,
): Promise<string> => {
	const { data } = await http.post<string>(
		`${genAIUrl}/campaign/description`,
		payload,
	);
	return data;
};

export const generateCampaignGoals = async (payload: ICampaignGenAIPayload) => {
	const { data } = await http.post<IGoals>(
		`${genAIUrl}/campaign/goals`,
		payload,
	);

	return data.goals.map((goal) => ({
		id: goal,
		title: goal,
		description: goal,
	}));
};

export const generateCampaignBrief = async (payload: ICampaignGenAIPayload) => {
	const [description, goals] = await Promise.all([
		generateCampaignDescription(payload),
		generateCampaignGoals(payload),
	]);

	return {
		title: payload.name,
		description,
		goals,
	};
};

// const generateFacebookContent = async (
// 	payload: ICampaignGenAIPayload,
// ): Promise<IFacebookContent> => {
// 	const { data } = await http.post<IFacebookContent>(
// 		`${genAIUrl}/campaign/facebook-content`,
// 		payload,
// 	);
// 	return data;
// };

// const generatePushContent = async (
// 	payload: ICampaignGenAIPayload,
// ): Promise<IChannelPlainContent> => {
// 	const { data } = await http.post<IChannelPlainContent>(
// 		`${genAIUrl}/campaign/push-content`,
// 		payload,
// 	);
// 	return data;
// };

// const generateSMSContent = async (
// 	payload: ICampaignGenAIPayload,
// ): Promise<IChannelPlainContent> => {
// 	const { data } = await http.post<IChannelPlainContent>(
// 		`${genAIUrl}/campaign/sms-content`,
// 		payload,
// 	);
// 	return data;
// };

// export const generateCampaignContent = async (
// 	payload: ICampaignGenAIPayload,
// ) => {
// 	const channels = payload.channels.filter((c) =>
// 		['sms', 'pns', 'facebook'].includes(c),
// 	);
// 	const contentPromises = [];

// 	if (channels.includes('facebook'))
// 		contentPromises.push(generateFacebookContent(payload));

// 	if (channels.includes('sms'))
// 		contentPromises.push(generateSMSContent(payload));

// 	if (channels.includes('pns'))
// 		contentPromises.push(generatePushContent(payload));

// 	const contentResponse = await Promise.all(contentPromises);

// 	const content = channels.map((channel, index) => {
// 		return {
// 			id: channel,
// 			channel: channel,
// 			value: contentResponse[index],
// 		};
// 	});

// 	return content;
// };
