import { FC, Fragment, useEffect, useState } from 'react';
import { Box, Flex, Text, IconButton } from '@chakra-ui/react';

import GeneratingIcon from 'src/components/common/GeneratingIcon';
import { EditIcon, UnlockIcon, LockIcon } from 'src/assets/icons';
import CustomTooltip from 'src/components/common/Tooltip';
import ErrorMessage from 'src/components/common/ErrorMessage';
import {
	ITextSplit,
	expandTextToCompleteWords,
	isEmptyString,
	lockTextInSplits,
	splitText,
	unlockTextInSplits,
} from 'src/lib/utils';

interface BreifEditWidgetProps {
	text: string;
	isValidCampaignConfig: boolean;
	onEdit: () => void;
	onRegenerate: () => void;
	onEnrichDescription: (text: string) => void;
	onTextSplitChange: (textSplits: Array<ITextSplit>) => void;
}

const BreifEditWidget: FC<BreifEditWidgetProps> = ({
	text,
	onEdit,
	isValidCampaignConfig,
	onRegenerate,
	onEnrichDescription,
	onTextSplitChange,
}) => {
	const [textSplits, setTextSplits] = useState<Array<ITextSplit>>([]);
	const [selectedText, setSelectedText] = useState('');
	const [isReGeneratingWholeText, setIsReGeneratingWholeText] = useState(false);
	const [isReGeneratingSelectedText, setIsReGeneratingSelectedText] =
		useState(false);

	const handleInitialTextSplit = () => {
		let rawTextSplit: ITextSplit[] = [
			{
				value: text,
				status: 'raw',
			},
		];

		const lockedSplits = textSplits.filter((ts) => ts.status === 'locked');
		lockedSplits.forEach((ls) => {
			rawTextSplit = lockTextInSplits(rawTextSplit, ls.value);
		});

		setTextSplits(rawTextSplit);
	};

	useEffect(() => {
		handleInitialTextSplit();
	}, [text]);

	useEffect(() => {
		onTextSplitChange(textSplits);
	}, [textSplits]);

	const handleTextSelection = () => {
		const selectedText = document?.getSelection()?.toString();
		if (!selectedText?.trim()) {
			setSelectedText('');
			return;
		}

		const index = textSplits.findIndex(
			(item) => item.status !== 'locked' && item.value.includes(selectedText),
		);
		if (index === -1 || !selectedText) return;

		const completeSelection = expandTextToCompleteWords(
			textSplits[index].value,
			selectedText,
		);

		setSelectedText(completeSelection);
	};

	const handleWholeTextLock = () => {
		const newDistributionText = textSplits.map((item) => item.value).join(' ');

		setTextSplits([{ value: newDistributionText, status: 'locked' }]);
	};

	const handleWholeTextUnLock = () => {
		const newDistributionText = textSplits.map((item) => item.value).join(' ');

		setTextSplits([{ value: newDistributionText, status: 'raw' }]);
	};

	const handleLockText = () => {
		const newTextSplits = lockTextInSplits(textSplits, selectedText);
		setTextSplits(newTextSplits);
		setSelectedText('');
	};

	const handleUnlockText = (text: string) => {
		const newTextSplits = unlockTextInSplits(textSplits, text);
		setTextSplits(newTextSplits);
	};

	const handleRenegerateWholeText = async () => {
		if (isReGeneratingWholeText) return;

		setIsReGeneratingWholeText(true);
		await onRegenerate();
		setIsReGeneratingWholeText(false);
		setSelectedText('');
	};

	const handleRegenerateSelectedText = async () => {
		if (isReGeneratingSelectedText) return;

		setIsReGeneratingSelectedText(true);
		const text = textSplits
			.map((item) => {
				if (item.value.includes(selectedText)) {
					const [first, search, last] = splitText(item.value, selectedText);

					return `${
						!first ? '' : `<lock>${first}</lock>`
					}<regenerate>${search}</regenerate>${
						!last ? '' : `<lock>${last}</lock>`
					}`;
				}
				return `<lock>${item.value}</lock>`;
			})
			.join(' ');

		await onEnrichDescription(text);
		setIsReGeneratingSelectedText(false);
		setSelectedText('');
	};

	const isWholeTextLocked = textSplits.every(
		(item) => item.status === 'locked',
	);

	return (
		<Flex
			direction="column"
			px={6}
			py={4}
			gap={4}
			bg="white"
			borderRadius="lg"
			boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.15)"
		>
			<Flex gap={2} alignItems="center">
				<Text fontWeight="semibold" mr={2}>
					Description
				</Text>
				<CustomTooltip
					label={
						<ErrorMessage
							message="There are validation errors inside configuration section, please fix them to regenerate the brief."
							boxProps={{ bg: 'white' }}
						/>
					}
					placement="top-start"
					isDisabled={isValidCampaignConfig}
					hasArrow
				>
					<IconButton
						aria-label="ReGenerate"
						size="xs"
						bg="transparent"
						onClick={handleRenegerateWholeText}
						isDisabled={!isValidCampaignConfig || isWholeTextLocked}
					>
						<GeneratingIcon isRotating={isReGeneratingWholeText} />
					</IconButton>
				</CustomTooltip>

				<IconButton
					aria-label="Edit"
					size="xs"
					bg="transparent"
					onClick={onEdit}
				>
					<EditIcon />
				</IconButton>
				<IconButton
					aria-label="Lock"
					size="xs"
					bg="transparent"
					onClick={
						isWholeTextLocked ? handleWholeTextUnLock : handleWholeTextLock
					}
				>
					{isWholeTextLocked ? <UnlockIcon /> : <LockIcon w={5} h={5} />}
				</IconButton>
			</Flex>
			<Box onMouseUp={handleTextSelection}>
				{textSplits.map((textSplit) => (
					<SplittedText
						key={textSplit.value}
						textSplit={textSplit}
						searchText={selectedText}
						isReGeneratingSelectedText={isReGeneratingSelectedText}
						isValidCampaignConfig={isValidCampaignConfig}
						onLockText={handleLockText}
						isWholeTextLocked={isWholeTextLocked}
						onRegenerateText={handleRegenerateSelectedText}
						onUnlockText={handleUnlockText}
					/>
				))}
			</Box>
		</Flex>
	);
};

interface SplittedTextProps {
	textSplit: ITextSplit;
	searchText: string;
	isWholeTextLocked?: boolean;
	isReGeneratingSelectedText?: boolean;
	isValidCampaignConfig: boolean;
	onLockText: () => void;
	onRegenerateText: () => void;
	onUnlockText: (value: string) => void;
}

const SplittedText: FC<SplittedTextProps> = ({
	textSplit,
	searchText,
	isWholeTextLocked,
	isReGeneratingSelectedText,
	isValidCampaignConfig,
	onLockText,
	onRegenerateText,
	onUnlockText,
}) => {
	const [first, selected, last] = splitText(textSplit.value, searchText);

	if (textSplit.status === 'locked')
		return (
			<Fragment>
				<span
					style={{
						backgroundColor: 'lightgreen',
						userSelect: 'none',
						msUserSelect: 'none',
						WebkitUserSelect: 'none',
						fontSize: '14px',
					}}
				>
					{textSplit.value}
				</span>
				{!isWholeTextLocked && (
					<IconButton
						aria-label="Regenrate"
						size="xs"
						bg="transparent"
						onClick={() => {
							onUnlockText(textSplit.value);
						}}
					>
						<UnlockIcon />
					</IconButton>
				)}
			</Fragment>
		);

	if (!searchText)
		return <span style={{ fontSize: '14px' }}>{textSplit.value}</span>;

	return (
		<span style={{ fontSize: '14px' }}>
			{isEmptyString(first) ? null : first}
			{isEmptyString(selected) ? null : (
				<span style={{ backgroundColor: 'lightblue', fontSize: '14px' }}>
					{selected}
				</span>
			)}
			{isEmptyString(selected) ? null : (
				<Flex
					display="inline-block"
					flex={1}
					justify="center"
					alignItems="center"
					align="center"
				>
					<CustomTooltip
						label={
							<ErrorMessage
								message="There are validation errors inside configuration section, please fix them to regenerate the brief."
								boxProps={{ bg: 'white' }}
							/>
						}
						placement="top-start"
						isDisabled={isValidCampaignConfig}
						hasArrow
					>
						<IconButton
							aria-label="Regenrate"
							size="xs"
							bg="transparent"
							onClick={onRegenerateText}
							onMouseUp={(e) => {
								e.stopPropagation();
							}}
							isDisabled={!isValidCampaignConfig}
						>
							<GeneratingIcon isRotating={isReGeneratingSelectedText} />
						</IconButton>
					</CustomTooltip>
					<IconButton
						aria-label="Lock"
						size="xs"
						bg="transparent"
						onClick={onLockText}
						onMouseUp={(e) => {
							e.stopPropagation();
						}}
					>
						<LockIcon w={5} h={5} />
					</IconButton>
				</Flex>
			)}
			{isEmptyString(last) ? null : last}
		</span>
	);
};

export default BreifEditWidget;
