import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const StatsIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M6.5625 15.75V2.25H8.0625V15.75H6.5625ZM13.5 15.75V6.375H15V15.75H13.5ZM3 15.75L3.003 8.25H4.503L4.5 15.75H3ZM9.936 15.75V10.5H11.436V15.75H9.936Z"
				fill={color ? (color as string) : '#6D6D6D'}
			/>
		</Icon>
	);
};

export default StatsIcon;
