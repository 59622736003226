import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFacebookToken } from 'src/services/integration';

const FacebookCallback = () => {
	const { search } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchToken = async (code: string) => {
			try {
				const data = await getFacebookToken(code);
				const state = { facebookTokens: data };
				navigate('/integrations', { state });
			} catch (error) {
				console.error('Error fetching Facebook token:', error);
			}
		};

		const urlParams = new URLSearchParams(search);
		const code = urlParams.get('code');

		if (code) {
			fetchToken(code);
		} else {
			navigate('/integrations');
		}
	}, [search, navigate]);

	return <></>;
};

export default FacebookCallback;
