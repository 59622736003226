import config from 'src/config';

export const isProdEnvironment = () => {
	return config.env === 'production';
};

export function getDisplayName(
	WrappedComponent: React.ComponentType<any>,
): string {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const parseCampaignName = (name: string) => {
	return name.substring(0, 20).replace(/[<>:"'\\/|?* ]/g, '_');
};
