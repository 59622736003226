import { useContext, useEffect } from 'react';

import { CampaignConfigContext } from 'src/contexts';
import { getCampaignConfig } from 'src/services/config';
import { toastError } from 'src/services/toast';

const useCampaignConfig = () => {
	const { config, setConfig, isLoading, setIsLoading, retries } = useContext(
		CampaignConfigContext,
	);

	const fetchConfig = async () => {
		setIsLoading(true);
		try {
			const { audience, goals, tones } = await getCampaignConfig();
			setConfig({
				audience: audience.map((item) => ({
					value: item.id,
					label: item.name,
					description: item.description,
				})),
				goals: goals.map((item) => ({
					value: item.id,
					label: item.name,
					supportType: item.supportType,
					description: item.description,
					title: item.title,
				})),
				tones: tones.map((item) => ({
					value: item.id,
					label: item.name,
					description: item.description,
				})),
			});
		} catch (error: any) {
			toastError(error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (isLoading) return;

		if (!config && retries < 2) {
			fetchConfig();
		}
	}, [config, isLoading]);

	return { config, isLoading };
};

export default useCampaignConfig;
