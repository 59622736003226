import { useEffect, useState } from 'react';

import { IUser } from 'src/lib/schemas';
import authService from 'src/services/auth';
import { toastError } from 'src/services/toast';
import { getMyProfile } from 'src/services/users';

const useMyProfile = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState<IUser | null>();

	const fetchProfile = async () => {
		setIsLoading(true);
		try {
			if (authService.isAuthenticated()) {
				const data = await getMyProfile();
				setUser(data);
			}
		} catch (error: any) {
			toastError(error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchProfile();
	}, []);

	return { user, isLoading };
};

export default useMyProfile;
