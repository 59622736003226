export const getFormatedDate = () => {
	const now = new Date();
	const day = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(
		now,
	);
	const date = new Intl.DateTimeFormat('en-US', {
		month: 'short',
		day: 'numeric',
	}).format(now);
	const time = new Intl.DateTimeFormat('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	}).format(now);

	return `${day}, ${date}, ${time}`;
};
