import {
	FieldValues,
	FieldPath,
	useController,
	useFormContext,
	get,
} from 'react-hook-form';
import MultiSelectCreateableInput, {
	MultiSelectCreateableInputProps,
} from './MultiSelectCreateableInput';
import { IOption } from 'src/lib/schemas/misc';
import { RefObject } from 'react';
import { ECampaignPromotedType } from 'src/lib/schemas';

interface MultiSelectCreateableProductInputHookProps<T extends FieldValues>
	extends MultiSelectCreateableInputProps {
	name: FieldPath<T>;
	requirementsLabel?: string;
	disableCreateOption?: boolean;
	onOpenMenu?: (ref: RefObject<HTMLDivElement>) => void;
	direction?: 'row' | 'column';
	promotedObjectType: ECampaignPromotedType;
}

function MultiSelectCreatProducteableInputHook<
	T extends FieldValues = FieldValues,
>({
	name,
	requirementsLabel,
	onValueChangeCallback,
	disableCreateOption,
	onOpenMenu,
	direction,
	promotedObjectType,
	...props
}: MultiSelectCreateableProductInputHookProps<T>) {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { field } = useController({ control, name });
	const { onChange, value } = field;
	const error = get(errors, name);

	const handleChange = (
		val: IOption['value'][] | IOption[],
		generateByAI?: boolean,
	) => {
		val = generateByAI ? [] : val;
		onChange(val);
		onValueChangeCallback?.(val, generateByAI);
	};

	return (
		<MultiSelectCreateableInput
			name={name}
			value={value ?? []}
			requirementsLabel={requirementsLabel}
			onChange={handleChange}
			error={error?.message}
			disableCreateOption={disableCreateOption}
			{...props}
			ref={register(name).ref}
			onOpenMenu={onOpenMenu}
			direction={direction}
			isProductInput={true}
			promotedObjectType={promotedObjectType}
		/>
	);
}

export default MultiSelectCreatProducteableInputHook;
