import { FC } from 'react';
import { Flex, GridItem, SimpleGrid } from '@chakra-ui/react';

import MediaCreative from './MediaCreative';
import {
	IChannelCreative,
	IChannelCreativeAttributes,
	IChannelMediaAttributes,
	ICreative,
} from 'src/lib/schemas';

interface MediaCreativesProps {
	creativesIndex: string;
	creatives: ICreative[];
	onRegenerate: (id: string) => void;
	onLockUnlock: (id: string, locked: boolean) => void;
	channelCreativeAttributes?: IChannelCreativeAttributes;
	onRefetchCreatives: () => void;
}

const MediaCreatives: FC<MediaCreativesProps> = ({
	creativesIndex,
	creatives,
	onRegenerate,
	onLockUnlock,
	channelCreativeAttributes,
	onRefetchCreatives,
}) => {
	creatives.sort((a, b) => {
		const contenta = a.attributes as unknown as IChannelMediaAttributes;
		const contentb = b.attributes as unknown as IChannelMediaAttributes;
		return contenta.image.dimensions.width > contentb.image.dimensions.width
			? -1
			: 1;
	});
	return (
		<Flex direction="column" bg="white">
			<SimpleGrid templateColumns="repeat(1, 1fr)" gap={8}>
				{creatives.map((creative, index) => {
					const availableChannelsAttributes = channelCreativeAttributes
						? channelCreativeAttributes.creatives.find(
								(c: any) => c.mediaType === creative.mediaType,
						  )
						: [];

					return (
						<GridItem
							key={index}
							colSpan={1}
							justifyContent="center"
							alignItems="center"
							py={5}
							px={4}
							bg="#F8F8F8"
						>
							<MediaCreative
								key={index}
								creativeIndex={`${creativesIndex}-${index + 1}`}
								creative={creative}
								channelCreativeAttributes={
									availableChannelsAttributes as IChannelCreative
								}
								onRegenerate={onRegenerate}
								onLockUnlock={onLockUnlock}
								onRefetchCreatives={onRefetchCreatives}
							/>
						</GridItem>
					);
				})}
			</SimpleGrid>
		</Flex>
	);
};

export default MediaCreatives;
