import { FC, useState } from 'react';

import CampaignConfigContext from './CampaignConfigContext';
import { ICampaignConfig } from 'src/lib/schemas';

interface CampaignConfigContextProps {
	children: React.ReactNode;
}

const CampaignConfigProvider: FC<CampaignConfigContextProps> = ({
	children,
}) => {
	const [retries, setRetries] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [config, setConfig] = useState<ICampaignConfig | null>(null);

	const handleLoading = (loading: boolean) => {
		if (loading) {
			setRetries(retries + 1);
		}
		setIsLoading(loading);
	};

	return (
		<CampaignConfigContext.Provider
			value={{
				isLoading,
				setIsLoading: handleLoading,
				config,
				setConfig,
				retries,
			}}
		>
			{children}
		</CampaignConfigContext.Provider>
	);
};

export default CampaignConfigProvider;
