import { useState, useContext, useEffect } from 'react';
import {
	Text,
	Button,
	useDisclosure,
	Box,
	Flex,
	IconButton,
	Icon,
	Tooltip,
	Skeleton,
} from '@chakra-ui/react';
import { CampaignContext } from 'src/contexts';
import { useForm, FormProvider } from 'react-hook-form';
import CampaignDetails from './CampaignDetails';
import { FaEdit, FaCheck, FaTimes } from 'react-icons/fa';
import TextareaInputEditableHook from './TextareaInputEditableHook';
import { createOrUpdateCampaign } from 'src/services/campaign';
import GeneratingIcon from 'src/components/common/GeneratingIcon';
import { AppInputConfigContext } from 'src/contexts';
import { generateCampaignDescription } from 'src/services/genai';

const SummaryExtraInformation = () => {
	const { isOpen, onToggle } = useDisclosure();
	const { campaign, setCampaign } = useContext(CampaignContext);
	const [isRegeneratingText, setIsRegeneratingText] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const { inputConfig } = useContext(AppInputConfigContext);

	const [isLoading, setIsLoading] = useState(!campaign?.brief);

	const methods = useForm({
		defaultValues: {
			brief: {
				description: campaign?.brief?.description || '',
			},
		},
	});

	useEffect(() => {
		if (campaign?.brief) {
			setIsLoading(false);
		}
	}, [campaign?.brief]);

	const handleRegenerateText = async () => {
		if (isRegeneratingText) return;

		setIsRegeneratingText(true);

		try {
			if (!campaign || !campaign.id) {
				console.error('Campaign or campaign ID is not defined');
				setIsRegeneratingText(false);
				return;
			}

			const mappedAudience = campaign.audience.map(
				(id) =>
					inputConfig['Audience'].find((option) => option.value === id)
						?.label || id,
			);

			const mappedTone = campaign.tone.map(
				(id) =>
					inputConfig['Tone'].find((option) => option.value === id)?.label ||
					id,
			);

			const genAIPayload = {
				name: campaign.brief?.title || '',
				description: campaign.brief?.description || '',
				goals: campaign.brief?.goals?.map((goal) => goal.title) || [],
				audiance: mappedAudience,
				tone: mappedTone,
				products: [],
				promo_code: '',
				promo_description: '',
				budget: null,
				channels: campaign.channels || [],
			};

			const newDescription = await generateCampaignDescription(genAIPayload);

			const updatedCampaign = await createOrUpdateCampaign(
				{
					brief: {
						...campaign.brief,
						description: newDescription,
						goals: campaign.brief?.goals || [],
					},
				},
				campaign.id,
			);

			setCampaign(updatedCampaign);
		} catch (error) {
			console.error('Error saving description:', error);
		} finally {
			setIsRegeneratingText(false);
		}
	};

	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleSave = async (newDescription: string) => {
		if (!campaign || !newDescription) return;

		try {
			if (!newDescription || newDescription.trim() === '') {
				console.error('Description is required');
				return;
			}

			const updatedBrief = {
				...campaign.brief,
				description: newDescription.trim(),
				goals: campaign.brief?.goals || [],
				title: campaign.brief?.title || '',
				prompt: campaign.brief?.prompt || '',
			};

			const updatedCampaign = await createOrUpdateCampaign(
				{
					brief: updatedBrief,
				},
				campaign.id,
			);

			setCampaign(updatedCampaign);
			setIsEditing(false);
		} catch (error) {
			console.error('Error saving description:', error);
		}
	};

	const handleCancel = () => {
		setIsEditing(false);
	};

	return (
		<FormProvider {...methods}>
			<Box>
				<Box px={5} pt={4} borderBottom="1px solid" borderColor="gray.200">
					<Text size="md" mb={1}>
						Campaign Summary
					</Text>
					<Text fontSize="sm" color="gray.500" mb={4}>
						This is the generated summary of information used by AI to generate
						the designs to promote your business
					</Text>
				</Box>
				<Box px={5} pt={4} bg="gray.50" borderBottomRadius="lg">
					{isOpen ? (
						<>
							<Flex justify="space-between" align="center">
								<Skeleton isLoaded={!isLoading}>
									<Text size="md" mb={1}>
										Campaign Brief
									</Text>
								</Skeleton>
								<Flex>
									{!isEditing ? (
										<>
											<Skeleton isLoaded={!isLoading}>
												<Tooltip label="Regenerate" borderRadius={100} px={3}>
													<IconButton
														aria-label="Regenerate"
														size="xl"
														px={2}
														bg="transparent"
														onClick={handleRegenerateText}
														icon={
															<GeneratingIcon isRotating={isRegeneratingText} />
														}
													/>
												</Tooltip>
											</Skeleton>
											<Skeleton isLoaded={!isLoading}>
												<Tooltip label="Edit" borderRadius={100} px={3}>
													<IconButton
														aria-label="Edit"
														size="xl"
														px={3}
														bg="transparent"
														icon={<Icon as={FaEdit} color="gray.600" />}
														onClick={handleEditClick}
														isDisabled={isRegeneratingText}
													/>
												</Tooltip>
											</Skeleton>
										</>
									) : (
										<Flex>
											<Skeleton isLoaded={!isLoading}>
												<Tooltip label="Cancel" borderRadius={100} px={3}>
													<IconButton
														aria-label="Cancel"
														size="xl"
														px={3}
														bg="transparent"
														icon={<Icon as={FaTimes} color="red.600" />}
														onClick={handleCancel}
													/>
												</Tooltip>
											</Skeleton>
											<Skeleton isLoaded={!isLoading}>
												<Tooltip label="Done" borderRadius={100} px={3}>
													<IconButton
														aria-label="Done"
														size="xl"
														px={3}
														bg="transparent"
														icon={<Icon as={FaCheck} color="green.600" />}
														onClick={() =>
															handleSave(methods.getValues('brief.description'))
														}
													/>
												</Tooltip>
											</Skeleton>
										</Flex>
									)}
								</Flex>
							</Flex>
							<Skeleton isLoaded={!isLoading}>
								<TextareaInputEditableHook
									name="brief.description"
									isEditing={isEditing}
									onSave={handleSave}
									defaultValue={campaign?.brief?.description}
								/>
							</Skeleton>
							<Skeleton isLoaded={!isLoading}>
								<CampaignDetails
									enabledChannels={campaign?.channels}
									targetAudience={campaign?.audience}
									campaignTone={campaign?.tone}
								/>
							</Skeleton>
						</>
					) : (
						<Skeleton isLoaded={!isLoading} height="50px">
							<Text noOfLines={3} overflow="hidden" textOverflow="ellipsis">
								<Text as="span" fontWeight="bold">
									Overview:
								</Text>{' '}
								{campaign?.brief?.description}
							</Text>
						</Skeleton>
					)}
					<Button
						onClick={onToggle}
						fontWeight="medium"
						my={2}
						colorScheme="blue"
						variant="link"
						isDisabled={isLoading}
						color={isLoading ? 'gray.400' : 'blue.500'}
					>
						{isOpen ? 'See less...' : 'See more...'}
					</Button>
				</Box>
			</Box>
		</FormProvider>
	);
};

export default SummaryExtraInformation;
