import { FC, useContext, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import {
	MultiSelectCreateableInputHook,
	MultiSelectValuesHook,
} from 'src/components/common/form';
import { ProductFormModal } from 'src/components/commerce/products';
import { AppInputConfigContext } from 'src/contexts';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import useCatalogKinds from 'src/hooks/useCatalogKinds';

import {
	ECampaignPromotedType,
	IAppActionInput,
	ICatalog,
	ICatalogForm,
} from 'src/lib/schemas';
import {
	createAccountConfigOptions,
	getAccountConfigOptions,
} from 'src/services/account';
import { toastError } from 'src/services/toast';

interface TargetInputWidgetProps {
	input: IAppActionInput;
	validationRules: Record<string, any>;
	initialValues?: Partial<ICatalogForm>;
}

const TargetInputWidget: FC<TargetInputWidgetProps> = ({
	input,
	validationRules,
	initialValues = {},
}) => {
	const formProductToggle = useToggleWithPayload<Partial<ICatalog>>();
	const {
		inputConfig,
		loadingInputs,
		loadedInputs,
		setInputConfig,
		setLoadingInputConfig,
		setLoadedInputConfig,
	} = useContext(AppInputConfigContext);
	const { control } = useFormContext();
	const { field: promotedObjectValueField } = useController({
		control,
		name: input.property,
	});

	const promotedObjectType = ECampaignPromotedType.catalogs;

	const parsedType = input.type.replace('[]', '');
	const isArrayInput = input.type.includes('[]');

	const { catalogKinds } = useCatalogKinds();

	const handleFetchTargetOptions = async () => {
		setLoadingInputConfig({ [parsedType]: true });
		const targetOptionsPromise = [
			getAccountConfigOptions<ICatalog>('catalogs'),
		];
		const response = await Promise.all(targetOptionsPromise);
		const [products] = response.map((val) =>
			val.map((item) => ({ label: item.name, value: item.id })),
		);
		setInputConfig({
			[ECampaignPromotedType.catalogs]: products,
		});

		setLoadingInputConfig({ [parsedType]: false });
		setLoadedInputConfig({ [parsedType]: true });
	};

	useEffect(() => {
		if (!loadingInputs[parsedType] || !loadedInputs[parsedType])
			handleFetchTargetOptions();
	}, []);

	useEffect(() => {
		promotedObjectValueField.onChange(undefined);
	}, [promotedObjectType]);

	const options = inputConfig[promotedObjectType] ?? [];

	const handleCreatePromotedObject = (val: string) => {
		const defaultInitialValues = {
			kind: 'service',
			name: val,
			url: '',
			details: {},
			category: undefined,
			tags: [],
		};

		switch (promotedObjectType) {
			case ECampaignPromotedType.catalogs:
				formProductToggle.onOpen({ ...defaultInitialValues, ...initialValues });
				break;
			default:
				break;
		}
	};

	const handleCreateProduct = async (payload: ICatalogForm) => {
		try {
			const { id: _id, ...restPayload } = payload;
			const { id } = await createAccountConfigOptions('catalogs', restPayload);
			if (isArrayInput) {
				const currentValue = promotedObjectValueField.value ?? [];
				promotedObjectValueField.onChange([...currentValue, id]);
			} else {
				promotedObjectValueField.onChange(id);
			}
			await handleFetchTargetOptions();
		} catch (error: any) {
			toastError(error);
		}
	};

	return (
		<Flex direction="column" gap={2}>
			<Controller
				name={input.property}
				control={control}
				rules={validationRules}
				render={() => (
					<MultiSelectCreateableInputHook
						name={input.property}
						label={input.name}
						isLoading={loadingInputs[parsedType]}
						placeholder="Select/search"
						options={options}
						onCreateOption={handleCreatePromotedObject}
						isMulti={isArrayInput}
						required={validationRules.required}
					/>
				)}
			/>
			{isArrayInput && (
				<MultiSelectValuesHook
					name={input.property}
					title={promotedObjectType}
					options={options}
				/>
			)}
			<ProductFormModal
				isOpen={formProductToggle.isOpen}
				catalogKinds={catalogKinds}
				onClose={formProductToggle.onClose}
				onSubmit={handleCreateProduct}
				title="What are you promoting?"
				initialValues={formProductToggle.payload}
			/>
		</Flex>
	);
};

export default TargetInputWidget;
