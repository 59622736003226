import {
	ArrayOutputWidget,
	MediaOutputWidget,
	ObjectOutputWidget,
	TextOutputWidget,
	StringArrayOutputWidget,
} from '../widgets/output';

export const renderOutputWidget = (type: string) => {
	switch (type) {
		case 'String':
			return TextOutputWidget;
		case 'Number':
			return TextOutputWidget;
		case 'Image':
			return MediaOutputWidget;
		case 'FlatImage':
			return MediaOutputWidget;
		case 'Object':
			return ObjectOutputWidget;
		case 'Object[]':
			return ArrayOutputWidget;
		case 'String[]':
			return StringArrayOutputWidget;
		default:
			return null;
	}
};
