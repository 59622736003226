// remove properties with empty array
export const cleanInputObject = (obj: any) => {
	const newInputObj = { ...obj };
	Object.keys(newInputObj).forEach((key) => {
		if (Array.isArray(newInputObj[key]) && newInputObj[key].length === 0) {
			delete newInputObj[key];
		} else if (key === 'seed' && newInputObj[key] === '') {
			delete newInputObj[key];
		}
	});
	return newInputObj;
};

export const mapCallToAction = (callToActionId: string): string => {
	const options = [
		{ id: 'APPLY_NOW', value: 'Apply Now' },
		{ id: 'BOOK_NOW', value: 'Book now' },
		{ id: 'CONTACT_US', value: 'Contact Us' },
		{ id: 'DOWNLOAD', value: 'Download' },
		{ id: 'LEARN_MORE', value: 'Learn More' },
		{ id: 'GET_QUOTE', value: 'Get Quote' },
		{ id: 'MESSAGE_PAGE', value: 'Send Message' },
		{ id: 'ORDER_NOW', value: 'Order Now' },
		{ id: 'SHOP_NOW', value: 'Shop Now' },
		{ id: 'CALL_NOW', value: 'Call Now' },
		{ id: 'GET_DIRECTIONS', value: 'Get Directions' },
		{ id: 'SIGN_UP', value: 'Sign Up' },
		{ id: 'WATCH_MORE', value: 'Watch More' },
		{ id: 'FIND_YOUR_GROUPS', value: 'Find your groups' },
		{ id: 'LISTEN_NOW', value: 'Listen Now' },
		{ id: 'SUBSCRIBE', value: 'Subscribe' },
		{ id: 'GET_SHOWTIMES', value: 'Get Showtimes' },
		{ id: 'WHATSAPP_MESSAGE', value: 'Send Whatsapp Message' },
	];

	const option = options.find((option) => option.id === callToActionId);
	return option ? option.value : callToActionId;
};
