import {
	FieldValues,
	FieldPath,
	useController,
	useFormContext,
	get,
} from 'react-hook-form';
import SelectSearchCreateableInput, {
	SelectSearchCreateableInputProps,
} from './SelectSearchCreateableInput';
import { IOption } from 'src/lib/schemas/misc';

interface SelectSearchCreateableInputHookProps<T extends FieldValues>
	extends SelectSearchCreateableInputProps {
	name: FieldPath<T>;
}

function SelectSearchCreateableInputHook<T extends FieldValues = FieldValues>({
	name,
	onValueChangeCallback,
	...props
}: SelectSearchCreateableInputHookProps<T>) {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { field } = useController({ control, name });
	const { onChange, value } = field;
	const error = get(errors, name);

	const handleChange = (val: IOption['value'] | IOption) => {
		onChange(val);
		onValueChangeCallback?.(val);
	};

	return (
		<SelectSearchCreateableInput
			name={name}
			value={value}
			onChange={handleChange}
			error={error?.message}
			{...props}
			ref={register(name).ref}
		/>
	);
}

export default SelectSearchCreateableInputHook;
