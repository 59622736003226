import { FC, ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';
import { ChartBarIcon, ChartLineIcon } from 'src/assets/icons';

interface AppOutputHeaderProps {
	toggleView: string;
	onToggleView: (value: string) => void;
}

const AppOutputHeader: FC<AppOutputHeaderProps> = ({
	toggleView,
	onToggleView,
}) => {
	return (
		<Flex gap={4} alignItems="center" pb={4}>
			<Flex bg="#DFE0E0" p={1} rounded={2}>
				<ViewButton
					toggleView={toggleView}
					view="preview"
					onToggleView={onToggleView}
				>
					<ChartBarIcon color={toggleView === 'preview' ? '#0B44F7' : ''} />
				</ViewButton>
				<ViewButton
					toggleView={toggleView}
					view="text"
					onToggleView={onToggleView}
				>
					<ChartLineIcon color={toggleView === 'text' ? '#0B44F7' : ''} />
				</ViewButton>
			</Flex>
		</Flex>
	);
};

interface ViewButtonProps {
	toggleView: string;
	view: string;
	children: ReactNode;
	onToggleView: (value: string) => void;
}

const ViewButton: FC<ViewButtonProps> = ({
	toggleView,
	view,
	children,
	onToggleView,
}) => (
	<Flex
		borderRadius="sm"
		py={2}
		bg={toggleView === view ? 'white' : ''}
		justify="center"
		width={8}
		onClick={() => onToggleView(view)}
		cursor="pointer"
	>
		{children}
	</Flex>
);

export default AppOutputHeader;
