export type SplitStatus = 'raw' | 'locked';

export interface ITextSplit {
	value: string;
	status: SplitStatus;
}

export const splitText = (text: string, search: string) => {
	const index = text.indexOf(search);
	if (index === -1) return [text, '', ''];
	const first = text.slice(0, index);
	const selected = text.slice(index, index + search.length);
	const last = text.slice(index + search.length);
	return [first, selected, last];
};

export const isEmptyString = (text: string) => {
	return text.trim().length === 0;
};

const combineSubsequentSplits = (textSplits: Array<ITextSplit>) => {
	const newTextSplits = [];
	let textSplit = textSplits[0];

	for (let i = 1; i < textSplits.length; i++) {
		const currentTextSplit = textSplits[i];
		if (currentTextSplit.status === textSplit.status) {
			const combinedText = [
				textSplit.value.trim(),
				currentTextSplit.value.trim(),
			].join(' ');
			textSplit.value = combinedText;
		} else {
			newTextSplits.push(textSplit);
			textSplit = currentTextSplit;
		}
		if (i === textSplits.length - 1) {
			newTextSplits.push(textSplit);
		}
	}
	return newTextSplits;
};

export const lockTextInSplits = (
	textSplits: ITextSplit[],
	search: string,
): ITextSplit[] => {
	const index = textSplits.findIndex(
		(item) => item.status !== 'locked' && item.value.includes(search),
	);
	if (index === -1 || !search) return textSplits;

	const textSplit = textSplits[index];
	const splittedTexts = splitText(textSplit.value, search);

	const newSplits = splittedTexts
		.filter((text) => Boolean(text.trim()))
		.map((text) => ({
			value: text,
			status: (text === search ? 'locked' : 'raw') as SplitStatus,
		}));

	const mergedSplits = [
		...textSplits.slice(0, index),
		...newSplits,
		...textSplits.slice(index + 1),
	];

	const combinedTextSplits = combineSubsequentSplits(mergedSplits);

	return combinedTextSplits;
};

export const unlockTextInSplits = (textSplits: ITextSplit[], text: string) => {
	const newSplits = textSplits.map((item) => {
		if (item.value === text) {
			return {
				value: item.value,
				status: 'raw' as SplitStatus,
			};
		}
		return item;
	});
	const combinedTextSplits = combineSubsequentSplits(newSplits);

	return combinedTextSplits;
};

export const expandTextToCompleteWords = (text: string, search: string) => {
	const searchIndex = text.indexOf(search);
	let lastIndex = searchIndex + search.length;
	while (lastIndex < text.length && text[lastIndex] !== ' ') {
		lastIndex++;
	}

	let startIndex = searchIndex;
	while (startIndex >= 0 && text[startIndex] !== ' ') {
		startIndex--;
	}

	return text.slice(startIndex + 1, lastIndex);
};
