import { z } from 'zod';

export interface IIntegrationsForm {
	//TODO: type this
	adAccount?: any;
}

export const IntegrationsSchema: Zod.ZodType<IIntegrationsForm> = z.object({
	//TODO: type this
	adAccount: z.any().optional(),
});

export interface IPage {
	id: string;
	name: string;
	image?: string;
	access_token?: string;
}

export interface IIntegrationItem {
	enabled: boolean;
	attributes: Record<string, any>;
}

export interface IIntegration {
	user: string;
	social: {
		fb: IIntegrationItem;
		twitter: IIntegrationItem;
	};
	cdp: {
		google: IIntegrationItem;
	};
}
