import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextareaInputHook } from 'src/components/common/form';
import { IAppActionInput } from 'src/lib/schemas';

interface TextAreaInputWidgetProps {
	input: IAppActionInput;
	validationRules: Record<string, any>;
	maxLength?: number;
	propertyPrefix?: string;
}

const TextAreaInputWidget: FC<TextAreaInputWidgetProps> = ({
	input,
	validationRules,
	maxLength,
	propertyPrefix = '',
}) => {
	const { control } = useFormContext();

	let placeholder = `Enter ${input.name.toLowerCase()}`;
	if (input.placeholder) {
		placeholder = input.placeholder;
	}

	return (
		<Controller
			name={input.property}
			control={control}
			rules={validationRules}
			render={() => (
				<TextareaInputHook
					name={propertyPrefix + input.property}
					label={input.name}
					requirementsLabel={input.explanation}
					placeholder={placeholder}
					maxLength={maxLength}
					required={validationRules?.required}
					errorMessage={validationRules?.message}
				/>
			)}
		/>
	);
};

export default TextAreaInputWidget;
