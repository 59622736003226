import { FC } from 'react';
import { Tooltip, TooltipProps } from '@chakra-ui/react';

const CustomTooltip: FC<TooltipProps> = ({ children, ...props }) => {
	return (
		<Tooltip
			bg="white"
			color="#202223"
			py={2}
			boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.05)"
			{...props}
		>
			{children}
		</Tooltip>
	);
};

export default CustomTooltip;
