export const removeDuplicates = <T>(
	items: T[],
	keyExtractor: (item: T) => string | number,
): T[] => {
	const seenKeys = new Set<string | number>();
	return items.filter((item) => {
		const key = keyExtractor(item);
		if (seenKeys.has(key)) {
			return false;
		}
		seenKeys.add(key);
		return true;
	});
};
