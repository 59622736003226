import { useState } from 'react';

function useToggleWithPayload<P>(value?: P) {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [payload, setPayload] = useState<P | undefined>(value);

	const onClose = async () => {
		setIsOpen(false);

		setPayload(undefined);
	};

	const onOpen = (value?: P) => {
		setPayload(value);
		setIsOpen(true);
	};

	return {
		isOpen,
		payload,
		onClose,
		onOpen,
	};
}

export default useToggleWithPayload;
