import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const CircledAddIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			width="20px"
			height="19px"
			viewBox="0 0 20 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 2.4375C7.36328 2.4375 4.97266 3.84375 3.63672 6.09375C2.33594 8.37891 2.33594 11.1562 3.63672 13.4062C4.97266 15.6914 7.36328 17.0625 10 17.0625C12.6016 17.0625 14.9922 15.6914 16.3281 13.4062C17.6289 11.1562 17.6289 8.37891 16.3281 6.09375C14.9922 3.84375 12.6016 2.4375 10 2.4375ZM10 18.75C6.76562 18.75 3.8125 17.0625 2.19531 14.25C0.578125 11.4727 0.578125 8.0625 2.19531 5.25C3.8125 2.47266 6.76562 0.75 10 0.75C13.1992 0.75 16.1523 2.47266 17.7695 5.25C19.3867 8.0625 19.3867 11.4727 17.7695 14.25C16.1523 17.0625 13.1992 18.75 10 18.75ZM9.15625 12.8438V10.5938H6.90625C6.41406 10.5938 6.0625 10.2422 6.0625 9.75C6.0625 9.29297 6.41406 8.90625 6.90625 8.90625H9.15625V6.65625C9.15625 6.19922 9.50781 5.8125 10 5.8125C10.457 5.8125 10.8438 6.19922 10.8438 6.65625V8.90625H13.0938C13.5508 8.90625 13.9375 9.29297 13.9375 9.75C13.9375 10.2422 13.5508 10.5938 13.0938 10.5938H10.8438V12.8438C10.8438 13.3359 10.457 13.6875 10 13.6875C9.50781 13.6875 9.15625 13.3359 9.15625 12.8438Z"
				fill={color ? (color as string) : 'white'}
			/>
		</Icon>
	);
};

export default CircledAddIcon;
