import React, {
	useContext,
	useMemo,
	useState,
	useEffect,
	useRef,
	useCallback,
} from 'react';
import {
	Box,
	Button,
	Flex,
	HStack,
	Skeleton,
	Text,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import {
	IChannelCreativeAttributes,
	IChannelGroup,
	ICreative,
	IDesignDirection,
} from 'src/lib/schemas';
import DDCard from './DDCard';
import GenerateOrSelectCard from './GenerateOrSelectCard';
import TemplateContext from 'src/contexts/templates/TemplatesContext';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import { removeDuplicateLayers } from '../utils/removeDuplicateLayers';
import EditDDModal from './EditDDModal';
import { AssistantChatContext, CampaignContext } from 'src/contexts';
import {
	getCampaign,
	genrateNewDesignDirection,
	removeDesignDirection,
	genrateCampaignCreatives,
	regenerateCampaignDesignDirection,
} from 'src/services/campaign';
import { toastError, toastSuccess } from 'src/services/toast';
import Creatives from '../creatives/Creatives';
import EmptyState from './EmptyState';

interface DesignDirectionsProps {
	creativesConfig: IChannelCreativeAttributes[];
	onSubmit: () => void;
	isLoading?: boolean;
	designDirections: IDesignDirection[];
	setDesignDirections: React.Dispatch<
		React.SetStateAction<IDesignDirection[] | IDesignDirection[]>
	>;
	creatives: ICreative[];
	setCreatives: React.Dispatch<React.SetStateAction<ICreative[] | ICreative[]>>;
	availableChannelGroups: IChannelGroup[];
}

const DesignDirections = ({
	creativesConfig,
	isLoading,
	designDirections,
	setDesignDirections,
	creatives,
	setCreatives,
	availableChannelGroups,
	...props
}: DesignDirectionsProps) => {
	const { completeStep } = useContext(CampaignContext);
	const { id: campaignId, setCampaign, campaign } = useContext(CampaignContext);
	const { closeChat } = useContext(AssistantChatContext);
	const [selectedDesignDirectionId, setSelectedDesignDirectionId] = useState<
		string | null
	>(null);

	const { selectedTemplateIds } = useContext(TemplateContext);

	const [selectedDesignDirection, setSelectedDesignDirection] =
		useState<IDesignDirection>();
	const [channels, setChannels] = useState<string[]>(campaign?.channels ?? []);
	const [loadingCreatives, setLoadingCreatives] = useState(false);
	const [isGeneratingDD, setIsGeneratingDD] = useState(false);
	const [isAutoAssign, setIsAutoAssign] = useState(false);
	const prevDesignDirectionsLength = useRef(designDirections.length);
	const tagModalToggle = useToggleWithPayload<IDesignDirection>();
	const skeletonsRef = useRef<HTMLDivElement | null>(null);
	const ddRefs = useRef<Array<HTMLElement | null>>([]);

	const timeoutId = useRef<any>(null);
	const creativesRef = useRef<any>(null);
	const hasAnyGenerated = designDirections?.some(
		(c) => c.status === 'GENERATED',
	);
	const [selectedTemplatesCount, setSelectedTemplatesCount] = useState<number>(
		selectedTemplateIds.length,
	);
	const isDesignDirectionPending = designDirections?.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);

	const isShowingSkeletons = isLoading && designDirections.length === 0;

	useEffect(() => {
		if (isGeneratingDD) {
			if (isAutoAssign) {
				setSelectedTemplatesCount(1);
			} else {
				setSelectedTemplatesCount(selectedTemplateIds.length);
			}
		}
	}, [isGeneratingDD, selectedTemplateIds.length, isAutoAssign]);

	useEffect(() => {
		if (!isGeneratingDD) {
			setIsAutoAssign(false);
		}
	}, [isGeneratingDD]);

	useEffect(() => {
		if (isGeneratingDD) {
			setSelectedTemplatesCount(isAutoAssign ? 1 : selectedTemplateIds.length);
		}
	}, [isGeneratingDD, selectedTemplateIds.length, isAutoAssign]);
	useEffect(() => {
		setSelectedTemplatesCount(selectedTemplateIds.length);
	}, [selectedTemplateIds]);

	useEffect(() => {
		if (
			!selectedDesignDirectionId &&
			designDirections?.length &&
			designDirections[0].status === 'GENERATED'
		) {
			handleSelectDesignDirection(designDirections[0], false);
		}
	}, [designDirections]);

	useEffect(() => {
		if (isLoading || (isDesignDirectionPending && !timeoutId.current)) {
			handleRefetchCampaignDesignDirection();
		}
	}, [isDesignDirectionPending, timeoutId.current, isLoading]);

	useEffect(() => {
		campaign?.designDirections &&
			setDesignDirections(removeDuplicateLayers(campaign.designDirections));
		campaign?.creatives && setCreatives(campaign?.creatives);
		campaign?.channels && setChannels(campaign?.channels);
			}, [campaign]);

	useEffect(() => {
		if (isLoading || !designDirections.length) {
			setSelectedDesignDirectionId(null);
		} else if (
			!selectedDesignDirectionId || 
			(selectedDesignDirection?.id === designDirections[0].id && 
			 designDirections[0].status === 'GENERATED' &&
			 selectedDesignDirection?.status !== 'GENERATED') ||
			(designDirections.every(d => d.status === 'pending'))
		) {
			handleSelectDesignDirection(designDirections[0]);
		}
	}, [isLoading, designDirections]);

	useEffect(() => {
		if (designDirections.length > prevDesignDirectionsLength.current) {
			setIsGeneratingDD(false);
		}
		prevDesignDirectionsLength.current = designDirections.length;
	}, [designDirections]);

	useEffect(() => {
		if (isGeneratingDD && skeletonsRef.current) {
			skeletonsRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [isGeneratingDD]);

	useEffect(() => {
		if (designDirections.length > prevDesignDirectionsLength.current) {
			const lastDDIndex = designDirections.length - 1;
			const lastDDRef = ddRefs.current[lastDDIndex];
			if (lastDDRef) {
				lastDDRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
		prevDesignDirectionsLength.current = designDirections.length;
	}, [designDirections.length]);

	useEffect(() => {
		if (designDirections.length > prevDesignDirectionsLength.current) {
			const lastDDIndex = designDirections.length - 1;
			const lastDDRef = ddRefs.current[lastDDIndex];
			if (lastDDRef) {
				lastDDRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
		prevDesignDirectionsLength.current = designDirections.length;
	}, [designDirections.length]);

	useEffect(() => {
		prevDesignDirectionsLength.current = designDirections.length;
	}, [designDirections]);

	const handleRefetchCampaignDesignDirection = async () => {
		try {
			if (!campaignId || campaignId === 'new') return;
			const response = await getCampaign(campaignId);
			setDesignDirections(response.designDirections ?? []);
			const isPending = response.designDirections?.some(
				(c) => c.status === 'pending' || c.status === 'generating',
			);
			if (isPending) {
				timeoutId.current = setTimeout(
					handleRefetchCampaignDesignDirection,
					1000,
				);
			} else {
				setIsGeneratingDD(false);
				clearTimeout(timeoutId.current);
				timeoutId.current = undefined;
			}
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleRefreshDesignDirectionsWithCount = async (count: number) => {
		setSelectedTemplatesCount(count);
		await handleRefreshDesignDirections();
	};

	const handleRetryGenerateDD = async (designDirection: IDesignDirection) => {
		if (!campaignId) return;
		try {
			setIsGeneratingDD(true);
			await regenerateCampaignDesignDirection(
				campaignId,
				designDirection.id,
				{},
			);
			toastSuccess('Design direction regenerated');
			await handleRefreshDesignDirections();
		} catch (error) {
			toastError(error);
		} finally {
			setIsGeneratingDD(false);
		}
	};

	const handleRefreshDesignDirections = async () => {
		if (!campaignId) return;
		const response = await getCampaign(campaignId);
		setDesignDirections(response.designDirections ?? []);
		setCampaign(response);
	};

	const handleGenerateNewDD = async () => {
		try {
			setIsAutoAssign(true);
			if (!campaignId) return;
			setIsGeneratingDD(true);
			await genrateNewDesignDirection(campaignId);
			await handleRefreshDesignDirectionsWithCount(1);
		} catch (error: any) {
			toastError(error);
			setIsGeneratingDD(false);
		}
	};

	const handleRemoveDesignDirection = async (id: string) => {
		try {
			if (!campaignId || !id) return;
			await removeDesignDirection(campaignId, id);
			toastSuccess('Design direction deleted');
			if (selectedDesignDirectionId === id) {
				setSelectedDesignDirectionId(null);
			}
			handleRefreshDesignDirections();
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleGenerateCreatives = async (
		designDirectionId?: string,
		onlyUnprocessed = true,
	) => {
		if (!campaignId) return;
		try {
			await genrateCampaignCreatives(
				campaignId,
				designDirectionId,
				onlyUnprocessed,
			);
			const data = await getCampaign(campaignId);
			setChannels(data.channels);
			data.creatives && setCreatives(data.creatives);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleSelectDesignDirection = (
		designDirection: IDesignDirection,
		scroll = true,
	) => {
		if (selectedDesignDirectionId !== designDirection.id) {
			setSelectedDesignDirectionId(designDirection.id);
			setSelectedDesignDirection(designDirection);
			scroll && creativesRef.current.scrollIntoView();
		}
		if (designDirection.status !== 'GENERATED') return;

		const creativesFromSelectedDD = creatives?.filter(
			(creative) => creative.designDirectionId === designDirection.id,
		);
		const allChannelsCovered = campaign?.channels?.every(
			(channel) =>
				creativesFromSelectedDD?.some(
					(creative) => creative.channel === channel,
				),
		);
		const hasPendingCreatives =
			!creativesFromSelectedDD.length || !allChannelsCovered;

		if (hasPendingCreatives && designDirection.status === 'GENERATED') {
			setLoadingCreatives(true);
			handleGenerateCreatives(designDirection.id, hasPendingCreatives);
		}
	};

	const isAnySelected = selectedDesignDirectionId !== null;
	const sortedDesignDirections = [...designDirections].sort((a, b) => {
		if (a.locked && !b.locked) return -1;
		if (!a.locked && b.locked) return 1;
		return a.variant.localeCompare(b.variant);
	});
	const designDirectionsToShow = isLoading
		? sortedDesignDirections.filter((dd) => dd.locked)
		: sortedDesignDirections;

	return (
		<Flex p={4} direction="column">
			<HStack
				justifyContent="space-between"
				w="full"
				alignItems="center"
				mb={10}
				mt="-20px"
			>
				<Text
					size="md"
					visibility={
						designDirections.length > 0 || isShowingSkeletons
							? 'visible'
							: 'hidden'
					}
				>
					Designs to promote your business
				</Text>

				<GenerateOrSelectCard
					onGenerate={handleGenerateNewDD}
					campaignId={campaignId!}
					setIsGeneratingDD={setIsGeneratingDD}
					isDisabled={isShowingSkeletons}
					source="designDirections"
					handleRefreshDesignDirections={handleRefreshDesignDirections}
				/>
			</HStack>
			<Wrap spacing={4}>
				{designDirections.length > 0 &&
					designDirections.map((dd: IDesignDirection, index) => (
						<WrapItem
							key={dd.id}
							ref={(el: HTMLLIElement | null) => (ddRefs.current[index] = el)}
						>
							<Box>
								<DDCard
									designDirection={dd}
									openEditModal={() => tagModalToggle.onOpen(dd)}
									onRetry={handleRetryGenerateDD}
									onRemove={handleRemoveDesignDirection}
									onSelect={() => handleSelectDesignDirection(dd)}
									isSelected={selectedDesignDirectionId === dd.id}
									isAnySelected={isAnySelected}
								/>
							</Box>
						</WrapItem>
					))}

				{(isLoading || isGeneratingDD) &&
					Array.from({ length: selectedTemplatesCount || 1 }).map(
						(_, index) => (
							<WrapItem key={`skeleton-${index}`}>
								<Box ref={index === 0 ? skeletonsRef : undefined}>
									<Skeleton w="323px" h="323px" borderRadius="16px" />
								</Box>
							</WrapItem>
						),
					)}
				{designDirections.length === 0 && !isLoading && !isGeneratingDD && (
					<EmptyState />
				)}
			</Wrap>

			<Box ref={creativesRef} />
			{designDirections.length > 0 &&
				selectedDesignDirection &&
				channels.length > 0 && (
					<Creatives
						creatives={creatives}
						setCreatives={setCreatives}
						loadingCreatives={loadingCreatives}
						setLoadingCreatives={setLoadingCreatives}
						channels={channels}
						designDirection={selectedDesignDirection}
						creativesConfig={creativesConfig}
						availableChannelGroups={availableChannelGroups}
					/>
				)}
			{designDirections.length > 0 && (
				<Box alignSelf="flex-end" mt={5}>
					<Button
						isDisabled={!hasAnyGenerated || isDesignDirectionPending}
						variant="orangeSolid"
						_hover={{
							opacity: 0.9,
						}}
						_disabled={{
							opacity: 0.7,
							cursor: 'not-allowed',
						}}
						onClick={() => {
							props.onSubmit();
							completeStep(1);
						}}
					>
						Schedule & Publish
					</Button>
				</Box>
			)}
			{tagModalToggle.payload && (
				<EditDDModal
					isOpen={tagModalToggle.isOpen}
					onClose={() => {
						tagModalToggle.onClose();
						closeChat();
					}}
					initialValues={tagModalToggle.payload}
					handleRefreshDesignDirections={handleRefreshDesignDirections}
					designDirections={designDirections}
					setLoadingCreatives={setLoadingCreatives}
				/>
			)}
		</Flex>
	);
};
export default DesignDirections;
