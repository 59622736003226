import { FC } from 'react';
import { Td, Tr, Text } from '@chakra-ui/react';

interface EmptyTableProps {
	columns: number;
}

const EmptyTable: FC<EmptyTableProps> = ({ columns }) => (
	<Tr>
		<Td colSpan={columns} h={20} border={0}>
			<Text textAlign="center">No entries to show</Text>
		</Td>
	</Tr>
);

export default EmptyTable;
