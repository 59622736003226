import {
	StringInputWidget,
	SelectInputWidget,
	TextAreaInputWidget,
	StringArrayInputWidget,
	TextAreaArrayInputWidget,
} from '../widgets';

export const renderInputWidget = (type: string) => {
	switch (type) {
		case 'String':
			return StringInputWidget;
		case 'TextArea':
			return TextAreaInputWidget;
		case 'HTML':
			return TextAreaInputWidget;
		case 'enum':
			return SelectInputWidget;
		case 'String[]':
			return StringArrayInputWidget;
		case 'TextArea[]':
			return TextAreaArrayInputWidget;

		default:
			return null;
	}
};
