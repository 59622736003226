import { Box, Text } from '@chakra-ui/react';
import { ICreative } from 'src/lib/schemas';
import CreativeCard from './CreativeCard';
import { getFormatedDate } from './utils/formatedDate';

interface MessageCreativeProps {
	creative: ICreative;
	withActions?: boolean;
	size?: string;
}

const MessageCreative = ({
	creative,
	withActions = true,
	size = 'md',
}: MessageCreativeProps) => {
	return (
		<CreativeCard
			creative={creative}
			withActions={withActions}
			size={size}
			title="SMS"
		>
			<Box maxW="sm" bg="white" p={4} borderRadius="md" boxShadow="md">
				<Text fontSize="xs" color="gray.500" mb={2} textAlign="center">
					{getFormatedDate()}
				</Text>

				<Box bg="gray.100" p={3} borderRadius="lg" borderBottomLeftRadius="sm">
					<Text data-testid="sms-content" fontSize="sm" color="black">
						{(creative as any).attributes.content}{' '}
					</Text>
				</Box>
			</Box>
		</CreativeCard>
	);
};

export default MessageCreative;
