import { z } from 'zod';

export interface IBrandForm {
	id?: string;
	image: string;
	name: string;
	url: string;
	noOfCampaigns?: number;
}

export interface IBrand extends IBrandForm {
	id: string;
}

export const BrandFormSchema: Zod.ZodType<IBrandForm> = z.object({
	id: z.string().optional(),
	url: z
		.string()
		.nonempty('Landing page URL is required')
		.url('Landing page URL is not valid'),
	image: z.string().nonempty('Image is required').url('Image URL is not valid'),
	name: z.string().nonempty('Name is required'),
});
