import { FC, useState } from 'react';
import { IconButton, Tooltip, Image } from '@chakra-ui/react';
import SparklesIcon from 'src/assets/svgs/sparklesIcon.svg';

interface AssistantIconProps {
	onOpen: () => void;
	size?: string | number;
	children?: React.ReactNode;
}

const AssistantIcon: FC<AssistantIconProps> = ({
	onOpen,
	children,
	size = '24px',
}) => {
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	return (
		<Tooltip
			placement="top"
			isOpen={isTooltipOpen}
			borderRadius={100}
			label="Let Fusion Copilot help you"
			onClose={() => {
				setIsTooltipOpen(false);
			}}
			hasArrow
		>
			<IconButton
				aria-label="Assistant chat"
				bg="transparent"
				_hover={{ bg: 'transparent' }}
				_focus={{ boxShadow: 'none' }}
				height="auto"
				justifyContent="flex-end"
				mb={1.5}
				minH={0}
				onMouseEnter={() => setIsTooltipOpen(true)}
				onMouseLeave={() => setIsTooltipOpen(false)}
				onMouseDown={(e) => e.preventDefault()}
				onClick={onOpen}
			>
				{children ? children : <Image src={SparklesIcon} pr={1} w={size} />}
			</IconButton>
		</Tooltip>
	);
};

export default AssistantIcon;
