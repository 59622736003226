import { Dispatch, SetStateAction, createContext } from 'react';
import { ICampaign } from 'src/lib/schemas';

interface Step {
	label: string;
	isCompleted: boolean;
	isActive: boolean;
}

interface ICampaignContext {
	isFetching: boolean;
	id?: string;
	campaign: ICampaign | null;
	isLaunching: boolean;
	steps: Step[];
	completeStep: (stepIndex: number) => void;
	setIsLaunching: (isLaunching: boolean) => void;
	setCampaign: (campaign: ICampaign) => void;
	enabledSections: boolean[];
	openSections: boolean[];
	setOpenSections: Dispatch<SetStateAction<boolean[]>>;
	oldCampaignCopy: ICampaign | null;
	setOldCampaignCopy: (campaign: ICampaign) => void;
}

const initialSteps: Step[] = [
	{ label: 'Gather Information', isCompleted: false, isActive: true },
	{ label: 'Generate Designs', isCompleted: false, isActive: false },
	{ label: 'Preview & Finalize', isCompleted: false, isActive: false },
	{ label: 'Schedule & Publish', isCompleted: false, isActive: false },
];

const CampaignContext = createContext<ICampaignContext>({
	isFetching: false,
	isLaunching: false,
	campaign: null,
	steps: initialSteps,
	completeStep: () => {},
	setIsLaunching: () => {},
	setCampaign: () => {},
	enabledSections: [true, false, false],
	openSections: [true, false, false],
	setOpenSections: () => {},
	oldCampaignCopy: null,
	setOldCampaignCopy: () => {},
});

export default CampaignContext;
