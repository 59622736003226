import { FC } from 'react';
import { Link } from '@chakra-ui/react';

interface ChangeAccountLinkProps {
	link: string;
}

const ChangeAccountLink: FC<ChangeAccountLinkProps> = ({ link }) => {
	return (
		<Link href={link} color="#2c6ecb" fontSize="sm">
			Change account
		</Link>
	);
};

export default ChangeAccountLink;
