import { Box, Divider, Heading, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import UserContext from 'src/contexts/UserContext';
import { ICreative } from 'src/lib/schemas';
import CreativeCard from './CreativeCard';

interface EmailCreativeProps {
	creative: ICreative;
	withActions?: boolean;
	size?: string;
}

const EmailCreative = ({
	creative,
	withActions = true,
	size = 'md',
}: EmailCreativeProps) => {
	const { user } = useContext(UserContext);
	const subject = (creative as any).attributes.emailSubject;
	const body = (creative as any).attributes.plainEmailBody;

	return (
		<CreativeCard
			creative={creative}
			withActions={withActions}
			size={size}
			title="Email"
		>
			<Box
				borderWidth="1px"
				borderRadius="lg"
				overflow="hidden"
				boxShadow="md"
				maxW="600px"
				bg="white"
				p={6}
			>
				<Box mb={4}>
					<Heading mb={2}>{subject}</Heading>
					<Text color="gray.500" fontSize="sm">
						From: {user?.email}
					</Text>
					<Text color="gray.500" fontSize="sm">
						To: john.doe@example.com
					</Text>
				</Box>
				<Divider />
				<Box data-testid="email-content"
					p={5}
					dangerouslySetInnerHTML={{
						__html: body,
					}}
				/>
			</Box>
		</CreativeCard>
	);
};

export default EmailCreative;
