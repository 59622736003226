import { ECampaignPromotedType, ECampaignScheduleType } from 'src/lib/schemas';

export const getScheduleOptions = () => {
	const keys = Object.keys(ECampaignScheduleType) as Array<
		keyof typeof ECampaignScheduleType
	>;
	const options = keys.map((key) => ({
		label: ECampaignScheduleType[key],
		value: ECampaignScheduleType[key],
	}));
	return options;
};

export const getPromotedObjectOptions = () => {
	const keys = Object.keys(ECampaignPromotedType) as Array<
		keyof typeof ECampaignPromotedType
	>;
	const options = keys.map((key) => ({
		label: ECampaignPromotedType[key],
		value: ECampaignPromotedType[key],
	}));
	return options;
};
