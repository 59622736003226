import { useState, useEffect, useCallback } from 'react';
import { ICatalogKind } from 'src/lib/schemas/commerce/catalogs';
import { getCatalogKinds } from 'src/services/catalogKindsService';

const useCatalogKinds = () => {
	const [catalogKinds, setCatalogKinds] = useState<ICatalogKind[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<Error | null>(null);

	const fetchCatalogKinds = useCallback(async () => {
		setIsLoading(true);
		setError(null);
		try {
			const data = await getCatalogKinds();
			setCatalogKinds(data);
		} catch (err: unknown) {
			if (err instanceof Error) {
				setError(err);
			} else {
				setError(
					new Error('An unknown error occurred while fetching catalog kinds'),
				);
			}
			console.error('Error fetching catalog kinds:', err);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchCatalogKinds();
	}, [fetchCatalogKinds]);

	return { catalogKinds, isLoading, error, refetch: fetchCatalogKinds };
};

export default useCatalogKinds;
