import { useContext, useEffect } from 'react';

import { AccountConfigContext } from 'src/contexts';
import { getAccountConfig } from 'src/services/config';
import { toastError } from 'src/services/toast';

const useAccountConfig = () => {
	const { isLoading, setIsLoading, config, setConfig, retries } =
		useContext(AccountConfigContext);

	const fetchConfig = async () => {
		setIsLoading(true);
		try {
			const { industries, languages, timezones, fonts } =
				await getAccountConfig();
			setConfig({
				industries: industries.map((item) => ({
					value: item.id,
					label: item.name,
				})),
				languages: languages.map((item) => ({
					value: item.id,
					label: item.name,
				})),
				timezones: timezones.map((item) => ({
					value: item.id,
					label: item.name,
				})),
				fonts: fonts.map((item) => ({
					value: item.id,
					label: item.name,
				})),
			});
		} catch (error: any) {
			toastError(error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (isLoading) return;

		if (!config && retries < 2) {
			fetchConfig();
		}
	}, [config, isLoading]);

	return { config, isLoading };
};

export default useAccountConfig;
