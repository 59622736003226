import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { StringInputHook } from 'src/components/common/form';
import { IAssistantCreativeData, IAttribute } from 'src/lib/schemas';

interface StringInputWidgetProps {
	input: IAttribute;
	fieldChanges?: () => void;
	validationRules: Record<string, any>;
	maxLength?: number;
	copyToClipboard?: string;
	creativeData?: IAssistantCreativeData;
}

const StringInputWidget: FC<StringInputWidgetProps> = ({
	input,
	fieldChanges,
	validationRules,
	maxLength,
	copyToClipboard,
	creativeData,
}) => {
	const { control, setValue } = useFormContext();

	const isRequred = validationRules?.required?.value;
	if (!isRequred) validationRules.required = false;
	else validationRules.required = validationRules.required.message;

	const setFieldValue = (field: any, value: string) => {
		setValue(field, value);
		fieldChanges && fieldChanges();
	};

	return (
		<Controller
			name={input.property}
			control={control}
			rules={validationRules}
			render={() => (
				<StringInputHook
					name={input.property}
					label={input.name}
					{...(copyToClipboard && { copyToClipboard })}
					placeholder={`Enter ${input.name.toLowerCase()}`}
					maxLength={maxLength}
					formControlProps={{
						minH: '83px',
					}}
					inputProps={{
						bg: 'white',
						onChange: () => {
							fieldChanges && fieldChanges();
						},
					}}
					withAssistant
					setFieldValue={setFieldValue}
					creativeData={creativeData}
					required={isRequred}
				/>
			)}
		/>
	);
};

export default StringInputWidget;
