import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isAfter, isValid } from 'date-fns';
import { Box, Button, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import {
	DatePickerInputHook,
	RadioGroupInputHook,
} from 'src/components/common/form';
import FusionLoading from '../common/FusionLoading';
import { CampaignContext } from 'src/contexts';
import useCompanyAccount from 'src/hooks/useCompanyAccount';
import {
	ICampaignSchedule,
	ECampaignScheduleType,
	CampaignScheduleSchema,
} from 'src/lib/schemas';
import { convertTimzoneToGMT } from 'src/lib/utils';
import { getScheduleOptions } from './utils/options';
import { getScheduleDelta } from './utils/delta';
import { createOrUpdateCampaign } from 'src/services/campaign';
import { toastError } from 'src/services/toast';

interface CampaignScheduleProps {
	schedule?: Partial<ICampaignSchedule>;
	onScheduleSubmit: (data: ICampaignSchedule) => void;
}

const CampaignSchedule: FC<CampaignScheduleProps> = ({
	schedule,
	onScheduleSubmit,
}) => {
	const { companyAccount } = useCompanyAccount();
	const { campaign, setCampaign } = useContext(CampaignContext);
	const [isSaving, setIsSaving] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const timeoutId = useRef<any>(null);

	const formMethods = useForm<ICampaignSchedule>({
		resolver: zodResolver(CampaignScheduleSchema),
	});

	const { formState, watch, reset, setValue, handleSubmit } = formMethods;
	const { isSubmitting } = formState;

	const watchScheduleType = watch('type');
	const watchedStartDate = watch('startDate');
	const watchedEndDate = watch('endDate');

	useEffect(() => {
		if (watchScheduleType === ECampaignScheduleType.Evergreen) {
			setValue('endDate', undefined);
		}
	}, [watchScheduleType]);

	useEffect(() => {
		if (watchedEndDate) {
			formMethods.trigger('endDate');
		}
		if (isValid(new Date(watchedStartDate))) {
			formMethods.trigger('startDate');
		}
	}, [watchScheduleType, watchedStartDate, watchedEndDate]);

	useEffect(() => {
		if (schedule) {
			reset({
				type: schedule.type,
				startDate: schedule.startDate
					? new Date(schedule.startDate)
					: undefined,
				endDate: schedule.endDate ? new Date(schedule.endDate) : undefined,
			});
		}
	}, [schedule]);

	const waitForSaving = () => {
		clearTimeout(timeoutId.current);
		timeoutId.current = setTimeout(handleAutoSaveSchedule, 1500);
	};

	useEffect(() => {
		timeoutId.current = setTimeout(handleAutoSaveSchedule, 1500);
		return () => clearTimeout(timeoutId.current);
	}, [watchScheduleType, watchedStartDate, watchedEndDate]);

	const handleAutoSaveSchedule = async () => {
		if (!campaign) return;
		if (isSaving) {
			waitForSaving();
			return;
		}
		setIsSaving(true);
		const newSchedule = {
			type: watchScheduleType,
			startDate: watchedStartDate ? new Date(watchedStartDate) : undefined,
			endDate: watchedEndDate ? new Date(watchedEndDate) : undefined,
		};
		const delta = getScheduleDelta(
			{
				type: schedule?.type ?? ECampaignScheduleType.Evergreen,
				startDate: schedule?.startDate
					? new Date(schedule.startDate)
					: undefined,
				endDate: schedule?.endDate ? new Date(schedule.endDate) : undefined,
			},
			newSchedule,
		);
		const isEmptyPayload = _.isEmpty(delta);
		const isInvalidEndDate =
			watchedEndDate &&
			watchedStartDate &&
			isAfter(watchedStartDate, watchedEndDate);
		if (isEmptyPayload || isInvalidEndDate) {
			setIsSaving(false);
			return;
		}

		await handleSaveSchedule(newSchedule);

		setIsSaving(false);
	};

	const handleSaveSchedule = async (
		newSchedule: Partial<ICampaignSchedule>,
	) => {
		if (!campaign) return;
		try {
			const response = await createOrUpdateCampaign(
				{ schedule: newSchedule },
				campaign.id,
			);
			setCampaign(response);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleScheduleSubmit = handleSubmit(async (data) => {
		clearTimeout(timeoutId.current);
		await onScheduleSubmit(data);
		setIsSubmitted(true);
	});

	const handleDateChange = () => {
		if (
			isSubmitted ||
			!watchedStartDate ||
			(watchScheduleType !== ECampaignScheduleType.Evergreen && !watchedEndDate)
		)
			return;
		const scheduleFormSubmitElement = document.getElementById(
			'campaign-schedule-form-submit',
		);
		if (scheduleFormSubmitElement) {
			scheduleFormSubmitElement.click();
		}
		setIsSubmitted(true);
	};

	useEffect(() => {
		handleDateChange();
	}, [watchedStartDate, watchedEndDate]);

	const scheduleOptions = useMemo(() => getScheduleOptions(), []);
	const hideProceedToDeploy =
		isSubmitting || !!campaign?.budget.breakdown?.length;

	return (
		<Container maxW="7xl" px={0} pt={5} m={0}>
			<Flex direction="column" gap={6}>
				<Heading fontWeight="bold" display="flex" alignItems="center">
					Schedule and Deploy
					<Text color="#e53e3e" marginLeft="2px">
						*
					</Text>
				</Heading>
				<FormProvider {...formMethods}>
					<form onSubmit={handleScheduleSubmit}>
						<Flex direction="column" gap={1} mb={6}>
							<RadioGroupInputHook
								name="type"
								defaultValue={ECampaignScheduleType.Evergreen}
								options={scheduleOptions}
							/>
							<Flex gap={4}>
								<Box minW="360px">
									<DatePickerInputHook
										name="startDate"
										value={watchedStartDate}
										label="Start Date"
										placeholder="Select date"
									/>
								</Box>
								{watchScheduleType !== ECampaignScheduleType.Evergreen && (
									<Box minW="360px">
										<DatePickerInputHook
											name="endDate"
											value={watchedEndDate}
											label="End Date"
											placeholder="Select date"
										/>
									</Box>
								)}
								<Text mt="45px">
									{convertTimzoneToGMT(companyAccount?.timezone)}
								</Text>
							</Flex>
						</Flex>
						{/* <FusionLoading isLoading={isSubmitting} /> */}
						<Box display="none" textAlign="center">
							<Button
								id="campaign-schedule-form-submit"
								colorScheme="secondary"
								fontWeight="medium"
								type="submit"
							>
								Proceed to deploy
							</Button>
						</Box>
					</form>
				</FormProvider>
			</Flex>
		</Container>
	);
};

export default CampaignSchedule;
