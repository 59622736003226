import { FC, useContext, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Controller, useController, useFormContext } from 'react-hook-form';

import {
	MultiSelectCreateableInputHook,
	MultiSelectInputHook,
	MultiSelectValuesHook,
	SelectSearchCreateableInputHook,
	SelectSearchInputHook,
} from 'src/components/common/form';
import { AppInputConfigContext } from 'src/contexts';
import ConfigFormModal from 'src/components/config/ConfigFormModal';
import useAccountConfigOptions from 'src/hooks/config/useAccountConfigOptions';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import { getCampaignConfig } from 'src/services/config';
import { toastError } from 'src/services/toast';
import { IAppInputLoading } from 'src/contexts/app/AppInputConfigContext';
import { IAccountConfigOption } from 'src/lib/schemas/misc';
import { IAppActionInput } from 'src/lib/schemas';

const CONFIG_LOADING_MAP: Record<string, string[]> = {
	Audience: ['Audience', 'Goal'],
	Goal: ['Audience', 'Goal'],
};

const CREATABLE_INPUTS = ['Tone', 'Audience'];

interface SelectInputWidgetProps {
	input: IAppActionInput;
	validationRules: Record<string, any>;
}

const SelectInputWidget: FC<SelectInputWidgetProps> = ({
	input,
	validationRules,
}) => {
	const {
		inputConfig,
		loadingInputs,
		loadedInputs,
		setInputConfig,
		setLoadingInputConfig,
		setLoadedInputConfig,
	} = useContext(AppInputConfigContext);
	const { control } = useFormContext();
	const { field } = useController({ control, name: input.property });
	const { onChange, value } = field;
	const formToggle = useToggleWithPayload<IAccountConfigOption>();

	const { fetchConfig: fetchTones, createConfig: createTone } =
		useAccountConfigOptions('Tone');
	const { fetchConfig: fetchAudiences, createConfig: createAudience } =
		useAccountConfigOptions('Audience');

	const parsedType = input.type.replace('[]', '');
	const isArrayInput = input.type.includes('[]');

	const fetchInputConfig = async () => {
		const inputsToLoad = (CONFIG_LOADING_MAP[parsedType] || []).reduce(
			(acc, item) => {
				return { ...acc, [item]: true };
			},
			{} as IAppInputLoading,
		);
		setLoadingInputConfig(inputsToLoad);
		try {
			const { goals } = await getCampaignConfig();
			setInputConfig({
				Goal: goals.map((item) => ({
					value: item.id,
					label: item.name,
				})),
			});
		} catch (error: any) {
			toastError(error);
		}
		const loadedInputs = (CONFIG_LOADING_MAP[parsedType] || []).reduce(
			(acc, item) => {
				return { ...acc, [item]: false };
			},
			{} as IAppInputLoading,
		);
		setLoadedInputConfig(inputsToLoad);
		setLoadingInputConfig(loadedInputs);
	};

	useEffect(() => {
		if (loadingInputs[parsedType] || loadedInputs[parsedType]) return;

		if (parsedType === 'Tone') {
			fetchTones();
		} else if (parsedType === 'Audience') {
			fetchAudiences();
		} else {
			fetchInputConfig();
		}
	}, [input.type]);

	const options = inputConfig[parsedType] || [];

	const isCreatable = CREATABLE_INPUTS.includes(parsedType);

	const handleCreateConfigOption = async (name: string) => {
		if (!name.trim()) {
			formToggle.onOpen();
			return;
		}
		await handleCreateConfig({ name });
	};

	const handleCreateConfig = async (payload: IAccountConfigOption) => {
		try {
			let reponse: any = null;
			if (parsedType === 'Tone') {
				reponse = await createTone(payload);
			} else if (parsedType === 'Audience') {
				reponse = await createAudience(payload);
			} else {
				formToggle.onClose();
				return;
			}
			const id = reponse?.id;
			if (isArrayInput) {
				onChange([...(value || []), id]);
			} else {
				onChange(id);
			}
		} catch (error: any) {
			toastError(error);
		}
	};

	return (
		<Box>
			{isArrayInput ? (
				<Flex direction="column">
					<Controller
						name={input.property}
						control={control}
						rules={validationRules}
						render={() => {
							return isCreatable ? (
								<MultiSelectCreateableInputHook
									name={input.property}
									label={input.name}
									placeholder="Select/search"
									isLoading={loadingInputs[parsedType]}
									options={options}
									onCreateOption={handleCreateConfigOption}
									isMulti={true}
									required
								/>
							) : (
								<MultiSelectInputHook
									name={input.property}
									label={input.name}
									placeholder="Select/search"
									options={options}
									isLoading={loadingInputs[parsedType]}
									required={validationRules.required}
								/>
							);
						}}
					/>
					<MultiSelectValuesHook
						name={input.property}
						title={input.name}
						options={options}
					/>
				</Flex>
			) : (
				<Controller
					name={input.property}
					control={control}
					rules={validationRules}
					render={() => {
						return isCreatable ? (
							<SelectSearchCreateableInputHook
								name={input.property}
								label={input.name}
								placeholder="Select/search"
								options={options}
								isLoading={loadingInputs[parsedType]}
								onCreateOption={handleCreateConfigOption}
								required={validationRules.required}
							/>
						) : (
							<SelectSearchInputHook
								name={input.property}
								label={input.name}
								placeholder="Select/search"
								options={options}
								required={validationRules.required}
							/>
						);
					}}
				/>
			)}

			<ConfigFormModal
				isOpen={formToggle.isOpen}
				onClose={formToggle.onClose}
				onSubmit={handleCreateConfig}
				initialValues={formToggle.payload}
				title={input.name}
			/>
		</Box>
	);
};

export default SelectInputWidget;
