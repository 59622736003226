import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const UploadIcon: FC<IconProps> = ({
	color,
	width = '18px',
	height = '18px',
	...props
}) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 22 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M19.5938 11.0625C20.5586 11.0625 21.375 11.8789 21.375 12.8438V17.5938C21.375 18.5957 20.5586 19.375 19.5938 19.375H1.78125C0.779297 19.375 0 18.5957 0 17.5938V12.8438C0 11.8789 0.779297 11.0625 1.78125 11.0625H7.125V9.875H4.75C3.1543 9.875 2.33789 7.98242 3.48828 6.86914L9.42578 0.931641C10.0938 0.226562 11.2441 0.226562 11.9121 0.931641L17.8496 6.86914C19 7.98242 18.1836 9.875 16.625 9.875H14.25V11.0625H19.5938ZM4.75 8.09375H8.90625V14.0312H12.4688V8.09375H16.625L10.6875 2.15625L4.75 8.09375ZM19.5938 17.5938V12.8438H14.25V14.0312C14.25 15.0332 13.4336 15.8125 12.4688 15.8125H8.90625C7.9043 15.8125 7.125 15.0332 7.125 14.0312V12.8438H1.78125V17.5938H19.5938ZM18.1094 15.2188C18.1094 15.7383 17.7012 16.1094 17.2188 16.1094C16.6992 16.1094 16.3281 15.7383 16.3281 15.2188C16.3281 14.7363 16.6992 14.3281 17.2188 14.3281C17.7012 14.3281 18.1094 14.7363 18.1094 15.2188Z"
				fill={color ? (color as string) : '#3182CE'}
			/>
		</Icon>
	);
};

export default UploadIcon;
