import { Box, BoxProps } from '@chakra-ui/react';
import React, { FC } from 'react';

interface ICustomContainer extends BoxProps {
	children: React.ReactNode;
}

const CustomContainer: FC<ICustomContainer> = ({ children, ...props }) => {
	return (
		<Box
			bg="white"
			maxW="1500px"
			p="25px"
			borderRadius="8px"
			boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
			position="relative"
			zIndex={0}
			minW="75%"
			h="fit-content"
			{...props}
		>
			{children}
		</Box>
	);
};

export default CustomContainer;
