import http from './http';
import { ICatalogKind } from 'src/lib/schemas/commerce/catalogs';

const apiVersion = 'v1';
const accountUrl = `/${apiVersion}/account`;

export const getCatalogKinds = async (): Promise<ICatalogKind[]> => {
	try {
		const { data } = await http.get<ICatalogKind[]>(
			`${accountUrl}/catalog-kinds`,
		);
		return data;
	} catch (error: any) {
		throw new Error('Failed to fetch catalog kinds');
	}
};
