import { Flex, Heading, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface IHeader {
	step?: number;
	title: string;
	subtitle?: string | ReactNode;
}

const Header: FC<IHeader> = ({ step, title, subtitle }) => {
	return (
		<Flex textAlign="center" direction="column" mb={15}>
			{step && (
				<Text color="gray.subText" mb="20px" fontWeight={700} fontSize={20}>
					Step {step} of 2
				</Text>
			)}
			<Heading size="lg" mb="15px" fontSize={26}>
				{title}
			</Heading>

			{subtitle && (
				<Flex alignSelf="center">
					<Text color="gray.subText" fontSize={{ base: 14, md: 18 }}>
						{subtitle}
					</Text>
				</Flex>
			)}
		</Flex>
	);
};

export default Header;
