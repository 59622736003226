import { Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

import logo from 'src/assets/svgs/logo-sm.svg';
import { IUser } from 'src/lib/schemas';
import {
	DataTableActions,
	DataTableActionsProps,
	TActions,
} from 'src/components/common/DataTable';
import { USER_ROLE_OPTIONS } from 'src/lib/constants';

export interface IUsersListingColumn extends IUser {
	status?: string;
	action?: unknown;
}

const columnHelper = createColumnHelper<IUsersListingColumn>();

export const getUsersListingColumns = (
	onAction: DataTableActionsProps<IUser>['onAction'],
	isActionDisabled: (action: TActions, row: IUser) => void,
) => {
	return [
		columnHelper.accessor('name', {
			header: 'Name',
			cell: (info) => {
				return (
					<Flex gap={2}>
						<Image src={logo} />
						<Text>{info.getValue()}</Text>
					</Flex>
				);
			},
		}),
		columnHelper.accessor('email', {
			header: 'Email',
			cell: (info) => {
				return (
					<Tooltip label={info.getValue()} aria-label="email" borderRadius="sm">
						<Text isTruncated>{info.getValue()}</Text>
					</Tooltip>
				);
			},
		}),
		columnHelper.accessor('role', {
			header: 'Role',
			cell: (info) => {
				const role = info.getValue();
				return USER_ROLE_OPTIONS.find((r) => r.value === role)?.label;
			},
		}),
		columnHelper.accessor('status', {
			header: 'Status',
			cell: (info) => {
				const { isEmailVerified, isInvited } = info.row.original;
				if (isEmailVerified) return 'Active';
				if (isInvited) return 'Invite sent';
				return 'Pending';
			},
		}),
		columnHelper.accessor('action', {
			header: 'Action',
			cell: (info) => {
				return (
					<DataTableActions
						data={info.row.original}
						actionsAllowed={['Edit', 'Delete', 'Resend invite']}
						isActionDisabled={isActionDisabled}
						onAction={onAction}
					/>
				);
			},
			meta: {
				ThProps: {
					width: '100px',
				},
				TdProps: {
					textAlign: 'center',
				},
			},
		}),
	];
};
