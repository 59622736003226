const MountainSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="382"
			height="150"
			viewBox="0 0 382 150"
			fill="none"
		>
			<path
				opacity="0.1"
				d="M0.551323 38.106C11.8231 -57.6172 189.186 57.1765 189.186 57.1765C189.186 57.1765 243.256 94.4029 281.051 98.3728C319.29 102.389 357.469 33.6185 377.82 76.2469C389.475 100.662 377.82 150 377.82 150H31.2536C31.2536 150 -4.85293 84.0007 0.551323 38.106Z"
				fill="#FFD3D3"
			/>
		</svg>
	);
};

export default MountainSvg;
