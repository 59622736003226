import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableCellProps,
	TableProps,
	ComponentDefaultProps,
	Box,
} from '@chakra-ui/react';
import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	ColumnDef,
	RowData,
} from '@tanstack/react-table';

import LockedContent from '../LockedContent';
import { DataTableLoading, EmptyTable } from './parts';

declare module '@tanstack/table-core' {
	interface ColumnMeta<TData extends RowData, TValue> {
		TdProps?: TableCellProps;
		ThProps?: TableCellProps;
	}
}

export type DataTableProps<Data extends object> = {
	data: Data[];
	columns: ColumnDef<Data, any>[];
	isLoading: boolean;
	tableProps?: TableProps;
	trProps?: ComponentDefaultProps;
	lockedColumnsIndex?: number;
};

export default function DataTable<Data extends object>({
	data,
	columns,
	isLoading,
	tableProps,
	trProps,
	lockedColumnsIndex,
}: DataTableProps<Data>) {
	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
	});

	const isEmpty = !isLoading && !data.length;

	const totalRows = table.getRowModel().rows.length;
	const totalColumns = columns.length;

	return (
		<Box w="full" pos="relative">
			<Table __css={{ tableLayout: 'fixed', width: 'full' }} {...tableProps}>
				<Thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr
							key={headerGroup.id}
							borderBottom="1px solid #CDCDCD"
							height={12}
							{...trProps}
						>
							{headerGroup.headers.map((header) => {
								const meta: any = header.column.columnDef.meta;
								return (
									<Th
										key={header.id}
										onClick={header.column.getToggleSortingHandler()}
										isNumeric={meta?.isNumeric}
										fontWeight="semibold"
										textColor="#202223"
										fontSize="sm"
										fontFamily="inherit"
										px={4}
										textTransform="capitalize"
										bg="#F5F5F5"
										{...meta?.ThProps}
									>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</Th>
								);
							})}
						</Tr>
					))}
				</Thead>

				<Tbody position="relative">
					{table.getRowModel().rows.map((row, rowIndex) => (
						<Tr key={row.id} h={5} {...trProps}>
							{row.getVisibleCells().map((cell, columnIndex) => {
								const meta: any = cell.column.columnDef.meta;
								const isParent = ['Overall', 'owned', 'paid'].includes(
									// @ts-ignore
									row.original.id,
								);

								if (
									lockedColumnsIndex &&
									rowIndex === 0 &&
									columnIndex === lockedColumnsIndex
								) {
									return (
										<Td
											key={cell.id}
											colSpan={totalColumns - lockedColumnsIndex}
											rowSpan={totalRows}
											height="inherit"
											p={0}
										>
											<Box
												width="100%"
												height="100%"
												position="relative"
												bg="#F4F4F4"
												blur="1px"
											>
												<LockedContent boxProps={{ borderRadius: 'none' }} />
											</Box>
										</Td>
									);
								}

								if (lockedColumnsIndex && columnIndex >= lockedColumnsIndex)
									return null;

								return (
									<Td
										key={cell.id}
										isNumeric={meta?.isNumeric}
										textColor="#202223"
										fontSize="sm"
										p={2}
										bg={isParent ? '#F5F5F5' : 'white'}
										border={0}
										{...meta?.TdProps}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</Td>
								);
							})}
						</Tr>
					))}
					{isLoading && (
						<Tr>
							<DataTableLoading isLoading={isLoading} />
						</Tr>
					)}
					{isEmpty && <EmptyTable columns={columns.length} />}
					{/* TODO:  This is a workaround to cover the Tbody height,
					as the table height is spliting between all Tr */}
					<Tr></Tr>
				</Tbody>
			</Table>
		</Box>
	);
}
