import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const EditIcon: FC<IconProps> = ({
	width = '20px',
	height = '20px',
	color,
}) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 27 27"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.3945 3.24609H22.3438C21.8867 2.78906 21.125 2.78906 20.668 3.24609L19.1445 4.71875L21.5312 7.10547L23.0039 5.58203C23.4609 5.125 23.4609 4.36328 23.0039 3.85547L22.3945 3.24609ZM10.6133 13.3008C10.4609 13.4023 10.3594 13.6055 10.3086 13.8086L9.44531 16.7539L12.4414 15.9414C12.6445 15.8906 12.7969 15.7891 12.9492 15.6367L19.8047 8.78125L17.4688 6.44531L10.6133 13.3008ZM18.9414 1.51953C20.3633 0.0976562 22.6484 0.0976562 24.0703 1.51953L24.7305 2.17969C26.1523 3.60156 26.1523 5.88672 24.7305 7.30859L14.6758 17.3633C14.2695 17.7695 13.7109 18.0742 13.1016 18.2773L8.02344 19.6992C7.61719 19.8516 7.16016 19.75 6.85547 19.3945C6.5 19.0898 6.39844 18.6328 6.5 18.2266L7.97266 13.1484C8.125 12.5391 8.48047 11.9805 8.88672 11.5742L18.9414 1.51953ZM4.46875 3.5H10.1562C10.8164 3.5 11.375 4.05859 11.375 4.71875C11.375 5.42969 10.8164 5.9375 10.1562 5.9375H4.46875C3.30078 5.9375 2.4375 6.85156 2.4375 7.96875V21.7812C2.4375 22.9492 3.30078 23.8125 4.46875 23.8125H18.2812C19.3984 23.8125 20.3125 22.9492 20.3125 21.7812V16.0938C20.3125 15.4336 20.8203 14.875 21.5312 14.875C22.1914 14.875 22.75 15.4336 22.75 16.0938V21.7812C22.75 24.2695 20.7188 26.25 18.2812 26.25H4.46875C1.98047 26.25 0 24.2695 0 21.7812V7.96875C0 5.53125 1.98047 3.5 4.46875 3.5Z"
				fill={color ? (color as string) : '#3182CE'}
			/>
		</Icon>
	);
};

export default EditIcon;
