import { FC, useContext, useEffect, useMemo } from 'react';
import { Container, Flex, Button, useDisclosure } from '@chakra-ui/react';
import LaunchingCampaignModal from './parts/LaunchingCampaignModal';
import CampaignSuccessModal from './parts/CampaignSuccessModal';
import { CampaignContext } from 'src/contexts';
import { customToast } from 'src/services/toast';
import { validateCampaignSchedule } from '../utils/validations';
import IntegrationsModal from './parts/IntegrationsModal';
import { useLocation } from 'react-router-dom';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';
import { ECampaignScheduleType } from 'src/lib/schemas';

interface LaunchCampaignProps {
	isValidCampaignConfig: boolean;
	isSocialEnabled?: boolean;
	onLaunch: () => void;
}

const LaunchCampaign: FC<LaunchCampaignProps> = ({
	isValidCampaignConfig,
	isSocialEnabled,
	onLaunch,
}) => {
	const {
		isOpen: isOpenLaunchingModal,
		onOpen: onOpenLaunchingModal,
		onClose: onCloseLaunchingModal,
	} = useDisclosure();
	const {
		isOpen: isOpenSuccessModal,
		onOpen: onOpenSuccessModal,
		onClose: onCloseSuccessModal,
	} = useDisclosure();
	const {
		isOpen: isOpenIntegrationsModal,
		onOpen: onOpenIntegrationsModal,
		onClose: onCloseIntegrationsModal,
	} = useDisclosure();
	const { campaign, setIsLaunching } = useContext(CampaignContext);
	const { integrations, shouldLaunch, setShouldLaunch } = useMyIntegrations();
	const { search } = useLocation();
	const redirectedFromFB = search.includes('fromFB');

	useEffect(() => {
		if (redirectedFromFB) {
			onOpenIntegrationsModal();
		}
	}, [redirectedFromFB]);

	useEffect(() => {
		if (shouldLaunch) {
			onCloseIntegrationsModal();
			handleLaunch();
			setShouldLaunch(false);
		}
	}, [shouldLaunch]);

	const isValidCampaignSchedule = useMemo(() => {
		if (!campaign?.schedule) return false;
		return validateCampaignSchedule(campaign.schedule);
	}, [campaign]);

	const handleLaunch = async () => {
		if (!campaign) return;
		//TODO: show message error
		// if (!isValidCampaignConfig) {
		//     const configFormSubmitElement = document.getElementById(
		//         'campaign-config-form-submit',
		//     );
		//     if (configFormSubmitElement) {
		//         configFormSubmitElement.click();
		//     }
		//     return;
		// }
		if (!isValidCampaignSchedule) {
			const scheduleFormSubmitElement = document.getElementById(
				'campaign-schedule-form-submit',
			);
			if (scheduleFormSubmitElement) {
				scheduleFormSubmitElement.click();
			}
			return;
		}
		if (
			campaign.schedule?.type === ECampaignScheduleType['One-off'] &&
			!campaign.schedule.endDate
		) {
			customToast('End Date is required for One-off campaigns', 'error');
			return;
		}
		if (
			(!isOpenLaunchingModal && !isSocialEnabled) ||
			!integrations?.social?.fb?.attributes?.pagesToPublishContent 
		) {
			onOpenIntegrationsModal();
			return;
		}
		setIsLaunching(true);
		onOpenLaunchingModal();
		try {
			await onLaunch();
			onOpenSuccessModal();
		} catch (error: any) {
			const message =
				error?.response?.data?.message ??
				"We couldn't publish the campaign, please try again.";
			customToast(message, 'error');
		}
		onCloseLaunchingModal();
		setIsLaunching(false);
	};
	return (
		<>
			<LaunchingCampaignModal isOpen={isOpenLaunchingModal} />
			<CampaignSuccessModal
				isOpen={isOpenSuccessModal}
				onClose={onCloseSuccessModal}
				title={campaign?.title ?? 'No title'}
			/>
			<IntegrationsModal
				isOpen={!isOpenLaunchingModal && isOpenIntegrationsModal}
				onClose={onCloseIntegrationsModal}
				redirectedFromFB={redirectedFromFB}
			/>
			<Container maxW="7xl" py={6} m={0} px={0}>
				<Flex justifyContent="right" alignItems="flex-end">
					<Button
						colorScheme="secondary"
						fontWeight="semibold"
						onClick={handleLaunch}
					>
						Publish to the channels
					</Button>
				</Flex>
			</Container>
		</>
	);
};

export default LaunchCampaign;
