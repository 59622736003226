import { IUser, IUserForm, IUsers } from 'src/lib/schemas';
import http from './http';

const apiVersion = 'v1';
const userUrl = `/${apiVersion}/users`;
const authUrl = `/${apiVersion}/auth`;

export const getUsers = async (query: string) => {
	const { data } = await http.get<IUsers>(`${userUrl}?${query}`);
	return data;
};

export const updateUser = async (payload: Partial<IUserForm>) => {
	if (!payload.id) return;
	const { name, role } = payload;
	const { data } = await http.patch<IUser>(`${userUrl}/${payload.id}`, {
		name,
		role,
	});
	return data;
};

export const inviteUser = async (payload: Partial<IUserForm>) => {
	const { name, email, role } = payload;
	const { data } = await http.post<IUser>(`${authUrl}/send-invitation-email`, {
		name,
		email,
		role,
	});
	return data;
};

export const deleteUser = async (id: string) => {
	await http.delete(`${userUrl}/${id}`);
};

export const getMyProfile = async () => {
	const { data } = await http.get<IUser>(`${userUrl}/me`);
	return data;
};
