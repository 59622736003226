import React, { RefObject } from 'react';
import {
	FieldValues,
	FieldPath,
	useController,
	useFormContext,
	get,
} from 'react-hook-form';
import TagInput, { TagInputProps } from './TagInput';

interface TagInputHookProps<T extends FieldValues> extends TagInputProps {
	name: FieldPath<T>;
}

function TagInputHook<T extends FieldValues = FieldValues>({
	name,
	...props
}: TagInputHookProps<T>) {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { field } = useController({ control, name });
	const { onChange, value } = field;
	const error = get(errors, name);

	const handleChange = (val: string[]) => {
		onChange(val);
	};

	return (
		<TagInput
			name={name}
			value={value ?? []}
			onChange={handleChange}
			error={error?.message}
			{...props}
		/>
	);
}

export default TagInputHook;
