import { Flex, Icon } from '@chakra-ui/react';
import { FC } from 'react';

interface IIconBase {
	icon: any;
	variant?: 'solid' | 'outline';
	size?: number | string;
	fontSize?: number | string;
	alignIcon?: number | string;
}

const CircularIcon: FC<IIconBase> = ({
	icon,
	size,
	variant = 'solid',
	fontSize = '20px',
	alignIcon,
}) => {
	const isSolid = variant === 'solid';
	return (
		<Flex
			bg={isSolid ? 'orange.base' : 'white'}
			id="icons"
			border={variant === 'outline' ? '1.5px solid' : ''}
			borderColor="orange.base"
			borderRadius="50%"
			padding="7px"
			fontSize={fontSize}
			w={size}
			h={size}
			alignItems="center"
		>
			<Icon
				ml={alignIcon}
				color={isSolid ? 'white' : 'orange.base'}
				as={icon}
			/>
		</Flex>
	);
};

export default CircularIcon;
