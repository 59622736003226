import CampaignProvider from './CampaignProvider';
import { getDisplayName } from 'src/lib/utils';

const withCampaignProvider = <P extends object>(
	WrappedComponent: React.ComponentType<P>,
) => {
	const WithCampaignProvider: React.FC<P> = (props: P) => (
		<CampaignProvider>
			<WrappedComponent {...props} />
		</CampaignProvider>
	);

	WithCampaignProvider.displayName = `WithCampaignProvider(${getDisplayName(
		WrappedComponent,
	)})`;

	return WithCampaignProvider;
};

export default withCampaignProvider;
