import { Box, Card } from '@chakra-ui/react';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';
import CardActions from './CardActions';
import EditCreativeModal from './EditCreativeModal';
import { handleSaveCreative } from './utils/saveCreative';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import { useContext } from 'react';
import { CampaignContext } from 'src/contexts';
import { getCampaign, regenerateCampaignCreative } from 'src/services/campaign';
import { toastError } from 'src/services/toast';
import { IChannelCreativeAttributes } from 'src/lib/schemas';

interface CreativeCardProps {
	creative: ICreative;
	withActions?: boolean;
	size?: string;
	children: React.ReactNode;
	title: string;
	creativesConfig?: IChannelCreativeAttributes[];
}

const CreativeCard = ({
	creative,
	withActions = true,
	size = 'md',
	children,
	title,
	creativesConfig,
}: CreativeCardProps) => {
	const creativeModalToggle = useToggleWithPayload<ICreative>(creative);
	const { id: campaignId, setCampaign } = useContext(CampaignContext);
	const config = creativesConfig?.find((c) => c.id === creative.channel);

	const handleRefetchCreatives = async () => {
		if (!campaignId) return;
		try {
			const response = await getCampaign(campaignId);
			setCampaign(response);
		} catch (error: any) {
			toastError(error.message || 'Failed to fetch creatives');
		}
	};

	const hanldeRegenrateCreative = async () => {
		if (!campaignId) return;
		try {
			const payload = {
				creativeId: creative.id,
			};
			await regenerateCampaignCreative(campaignId, payload);
			const response = await getCampaign(campaignId);
			setCampaign(response);
		} catch (error: any) {
			toastError(error);
		}
	};

	return (
		<>
			<Box maxW={size}>
				{withActions && (
					<CardActions
						title={title}
						onEdit={() => creativeModalToggle.onOpen(creative)}
						onRegenerate={hanldeRegenrateCreative}
						mb="10px"
					/>
				)}
				<Card
					border="1px"
					borderRadius="lg"
					borderColor="gray.200"
					bg="white"
					boxShadow="md"
				>
					{children}
				</Card>
			</Box>
			{creativeModalToggle.payload && creativeModalToggle.isOpen && (
				<EditCreativeModal
					isOpen
					onClose={creativeModalToggle.onClose}
					initialValues={creativeModalToggle.payload}
					config={config}
					handleRefetchCreatives={handleRefetchCreatives}
				/>
			)}
		</>
	);
};

export default CreativeCard;
