import { FC, useCallback, useEffect, useState } from 'react';
import {
	Flex,
	Text,
	useDisclosure,
	Container,
	IconButton,
	Heading,
	Collapse,
	Skeleton,
} from '@chakra-ui/react';

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import BreifEditWidget from './parts/BreifEditWidget';
import DescriptionEditModal from './parts/DescriptionEditModal';
import BriefGoals from './parts/BriefGoals';
import GeneratingIcon from 'src/components/common/GeneratingIcon';
import CustomTooltip from 'src/components/common/Tooltip';
import ErrorMessage from 'src/components/common/ErrorMessage';
import { ICampaignBrief, ICampaignBriefGoal } from 'src/lib/schemas';
import { ITextSplit } from 'src/lib/utils';

interface ICampaignFormProps {
	birefDetails: {
		budget: number;
		audience: string[];
		channels: string[];
		tones: string[];
	};
	campaignTitle: string;
	brief?: ICampaignBrief;
	isValidCampaignConfig: boolean;
	onRegenerateBrief: () => void;
	onRegenerateGoals: () => void;
	onRegenerateDescription: () => void;
	onEnrichDescription: (text: string) => void;
	onSaveBrief: (brief: ICampaignBrief) => void;
	isGeneratingBrief: boolean;
}

const CampaignBrief: FC<ICampaignFormProps> = ({
	birefDetails,
	isValidCampaignConfig,
	onRegenerateBrief,
	onRegenerateGoals,
	onRegenerateDescription,
	onEnrichDescription,
	onSaveBrief,
	campaignTitle,
	isGeneratingBrief,
	...props
}) => {
	const [brief, setBrief] = useState(props.brief);
	const { isOpen, onClose, onOpen } = useDisclosure();
	const [isGenerating, setIsGenerating] = useState(false);
	const [textSplits, setTextSplits] = useState<Array<ITextSplit>>([]);
	const [isCollapsed, setIsCollapsed] = useState(true);

	const handelGoalsChange = async (goals: Array<ICampaignBriefGoal>) => {
		await onSaveBrief({ ...brief!, goals: goals });
		setBrief({ ...brief, goals } as ICampaignBrief);
	};

	useEffect(() => {
		setBrief(props.brief);
	}, [props.brief]);

	const handleDescriptionChange = async (val: string) => {
		await onSaveBrief({ ...brief!, description: val });
		setBrief({ ...brief!, description: val });
		onClose();
	};

	const handleReGenerateDescription = async () => {
		const isSomeTextLocked = textSplits.some(
			(item) => item.status === 'locked',
		);

		if (isSomeTextLocked) {
			const text = textSplits
				.map((item) => {
					if (item.status !== 'locked')
						return `<regenerate>${item.value}</regenerate>`;
					return `<lock>${item.value}</lock>`;
				})
				.join(' ');

			await onEnrichDescription(text);
		} else {
			await onRegenerateDescription();
		}
	};

	const handleRegenerateBrief = async () => {
		if (isGenerating) return;
		setIsGenerating(true);
		const isWholeTextLocked = textSplits.every(
			(item) => item.status === 'locked',
		);
		const isSomeTextLocked = textSplits.some(
			(item) => item.status === 'locked',
		);
		if (isWholeTextLocked) {
			await onRegenerateGoals();
		} else if (isSomeTextLocked) {
			await onRegenerateGoals();
			await handleReGenerateDescription();
		} else {
			await onRegenerateBrief();
		}
		setIsGenerating(false);
	};

	const handleTextSplitChange = useCallback((textSplits: Array<ITextSplit>) => {
		setTextSplits(textSplits);
	}, []);

	return (
		<Flex>
			{isGeneratingBrief ? (
				<Skeleton h="58px" w="full" maxW="7xl" borderRadius="10px" mb="10px" />
			) : (
				<Container
					maxW="7xl"
					px={0}
					py={5}
					m={0}
					bgColor="white"
					borderColor="grey"
					border="1px solid #EDEDED"
					borderRadius="10px"
					mb="10px"
					padding="10px 20px 10px 20px"
					visibility={brief ? 'visible' : 'hidden'}
				>
					<Flex
						alignItems="center"
						justifyContent="space-between"
						gap={1}
						onClick={() => setIsCollapsed(!isCollapsed)}
						cursor="pointer"
					>
						<Heading
							fontWeight="regular"
							fontSize="14px"
							fontFamily="'Noto Sans','Open Sans', sans-serif, medium`"
							my={2}
						>
							Campaign Brief (Advanced)
						</Heading>
						{isCollapsed ? (
							<ChevronDownIcon boxSize={8} color="grey" />
						) : (
							<ChevronUpIcon boxSize={8} color="grey" />
						)}
					</Flex>
					<Collapse in={!isCollapsed}>
						<Flex
							direction="column"
							gap={6}
							px={6}
							pb={6}
							pt={4}
							bg="#F8F8F8"
							borderRadius="md"
							mt={4}
						>
							<Flex alignItems="center" my="0px">
								<Text fontWeight="bold" mr="2px">
									{campaignTitle} campaign brief
								</Text>
								<CustomTooltip
									label={
										<ErrorMessage
											message="There are validation errors inside configuration section, please fix them to regenerate the brief."
											boxProps={{ bg: 'white' }}
										/>
									}
									placement="top-start"
									isDisabled={isValidCampaignConfig}
									hasArrow
								>
									<IconButton
										size="sm"
										aria-label="Regenerate"
										bg="transparent"
										onClick={handleRegenerateBrief}
										isDisabled={!isValidCampaignConfig}
									>
										<GeneratingIcon isRotating={isGenerating} />
									</IconButton>
								</CustomTooltip>
							</Flex>
							{brief && (
								<BreifEditWidget
									text={brief.description}
									isValidCampaignConfig={isValidCampaignConfig}
									onEdit={onOpen}
									onRegenerate={handleReGenerateDescription}
									onEnrichDescription={onEnrichDescription}
									onTextSplitChange={handleTextSplitChange}
								/>
							)}
							<Flex gap={4}>
								{brief && (
									<BriefGoals
										campaignGoals={brief.goals}
										onGoalsChange={handelGoalsChange}
									/>
								)}
								<Flex
									flex={1}
									direction="column"
									gap={4}
									px={6}
									py={4}
									bg="white"
									borderRadius="lg"
									height="auto"
									boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.15)"
								>
									<Text fontWeight="semibold">Campaign Details</Text>
									<Flex direction="column" gap={2}>
										<Flex gap={1}>
											<Text fontWeight="bold">Budget:</Text>
											<Text>${birefDetails.budget}</Text>
										</Flex>
										<Flex gap={1}>
											<Text fontWeight="bold">Channels:</Text>
											<Text>{birefDetails.channels.join(', ')}</Text>
										</Flex>
										<Flex gap={1}>
											<Text fontWeight="bold">Target Audience:</Text>
											<Text>{birefDetails.audience.join(', ')}</Text>
										</Flex>
										<Flex gap={1}>
											<Text fontWeight="bold">Campaign Tone:</Text>
											<Text>{birefDetails.tones.join(', ')}</Text>
										</Flex>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					</Collapse>
				</Container>
			)}
			{brief && (
				<DescriptionEditModal
					isOpen={isOpen}
					onClose={onClose}
					value={brief.description}
					onApply={handleDescriptionChange}
				/>
			)}
		</Flex>
	);
};

export default CampaignBrief;
