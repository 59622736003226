import { FC } from 'react';
import { Image } from '@chakra-ui/react';
import Logo from 'src/assets/svgs/logo-sm.svg';

interface FusionLogoProps {
	size: {
		w: number;
		h: number;
	};
}

const FusionLogo: FC<FusionLogoProps> = ({ size }) => (
	<Image src={Logo} alignSelf="baseline" {...size} />
);

export default FusionLogo;
