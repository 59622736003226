import { Container, Flex } from '@chakra-ui/react';

import { AuthHeader, LoginForm } from 'src/components/auth';

const Login = () => (
	<Container maxW="lg">
		<Flex direction="column" gap={12}>
			<AuthHeader title="Sign in" />
			<Flex
				gap={12}
				direction={{ base: 'column', md: 'row' }}
				alignItems="center"
			>
				<LoginForm />
				{/* <ProductFeatures /> */}
			</Flex>
		</Flex>
	</Container>
);

export default Login;
