import { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, Button, Image } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FusionLoading from 'src/components/common/FusionLoading';
import { getApps } from 'src/services/app';
import { DataTablePagination } from 'src/components/common/DataTable';
import usePaginationByOffset from 'src/hooks/usePaginationByOffset';
import { IAppConfig } from 'src/lib/schemas';

const Apps = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const category = searchParams.get('category');
	const page = parseInt(searchParams.get('page') || '1', 10);
	const limit = 10;

	const [total, setTotal] = useState(0);
	const pagination = usePaginationByOffset(total, (page - 1) * limit, limit);

	const [apps, setApps] = useState<IAppConfig[]>([]);
	const [isLoadingApps, setIsLoadingApps] = useState(true);

	useEffect(() => {
		pagination.setPage(page);
	}, [page, pagination]);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoadingApps(true);
			try {
				const response = await getApps({
					category: category,
					offset: pagination.offset,
					limit: pagination.limit,
				});
				setTotal(Number(response.pagination.total));
				pagination.setPages(
					Math.ceil(Number(response.pagination.total) / limit),
				);
				setApps(response.results);
			} catch (error) {
				console.error('Error fetching apps:', error);
			}
			setIsLoadingApps(false);
		};

		fetchData();
	}, [category, pagination.offset, pagination.limit]);

	const handlePageChange = (pageNumber: number) => {
		navigate(
			`/tasks?page=${pageNumber}${category ? `&category=${category}` : ''}`,
		);
	};

	const handleOpenApp = (id: string) => {
		navigate(`/tasks/${id}${category ? `?category=${category}` : ''}`);
	};

	return (
		<Box justifyContent="center" w="full">
			<FusionLoading isLoading={isLoadingApps} />
			<Flex direction="column" gap={8}>
				{!isLoadingApps && <Heading>Tasks</Heading>}
				<Flex
					gap={8}
					flexWrap="wrap"
					display={!isLoadingApps ? 'flex' : 'none'}
				>
					{apps.map((app: any) => (
						<Flex
							key={app.id}
							boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
							p={5}
							borderRadius="8px"
						>
							<Flex gap={4} w="300px">
								<Image src={app.logo} w="56px" minW="56px" h="56px" />
								<Flex
									direction="column"
									justify="space-between"
									gap={1}
									flex={1}
								>
									<Heading fontSize="16px" lineHeight="20px">
										{app.name}
									</Heading>
									<Text fontSize="10px" lineHeight="sm" fontWeight="small">
										{app.categoryDescription}
									</Text>
									<Text fontSize="12px" lineHeight="sm" fontWeight="medium">
										{app.description}
									</Text>
									<Flex justify="right">
										<Button
											size="sm"
											width={16}
											backgroundColor="#F7480B"
											colorScheme="#F7480B"
											onClick={() => handleOpenApp(app.fullName)}
											boxShadow="2px 4px 6px 0px rgba(0, 0, 0, 0.2)"
										>
											Run
										</Button>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					))}
				</Flex>
				<Flex
					direction="column"
					gap={8}
					display={!isLoadingApps ? 'flex' : 'none'}
				>
					<DataTablePagination
						page={pagination.page}
						pages={pagination.pages}
						nextPage={pagination.nextPage}
						prevPage={pagination.prevPage}
						goToPage={handlePageChange}
						containerProps={{ mt: 0 }}
					/>
				</Flex>
			</Flex>
		</Box>
	);
};

export default Apps;
