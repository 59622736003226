import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const RetweetIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				d="M3.37503 2.91016L6.69903 6.01516L5.67603 7.11016L4.12503 5.66266V12.0002C4.12503 12.8252 4.79703 13.5002 5.62503 13.5002H9.75003V15.0002H5.62503C3.96828 15.0002 2.62503 13.6577 2.62503 12.0002V5.66266L1.07403 7.11016L0.0510254 6.01516L3.37503 2.91016ZM12.375 4.50016H8.25003V3.00016H12.375C14.0318 3.00016 15.375 4.34266 15.375 6.00016V12.3377L16.926 10.8902L17.949 11.9852L14.625 15.0902L11.301 11.9852L12.324 10.8902L13.875 12.3377V6.00016C13.875 5.17516 13.203 4.50016 12.375 4.50016Z"
				fill={color ? (color as string) : '#6D6D6D'}
			/>
		</Icon>
	);
};

export default RetweetIcon;
