import { Box } from '@chakra-ui/react';

const DotAnimation = ({ animation }: any) => {
	return (
		<Box
			position="relative"
			width="8px"
			height="8px"
			borderRadius={5}
			bg="#F7480B"
			display=" inline-block"
			margin="0 2px"
			animation={animation}
		/>
	);
};

export default DotAnimation;
