import { useState } from 'react';
import { CallBackProps, STATUS, Step } from 'react-joyride';

interface IUseProductTour {
	steps: Step[];
}
const useProductTour = ({ steps }: IUseProductTour) => {
	const [shouldRun, setShouldRun] = useState(false);
	const [state, setState] = useState({ steps, run: false });

	const updateHeaderOverlay = (status: string, type: string) => {
		const headerElement = document.getElementById('campaign-header-overlay');
		if (headerElement) {
			if (status === 'running' && type === 'tooltip')
				headerElement.style.display = 'block';
			else headerElement.style.display = 'none';
		}
	};

	const startTour = () => {
		if (state.run) return;
		setState({ ...state, run: true });
	};

	const handleCallback = (params: CallBackProps) => {
		const { status, type } = params;
		updateHeaderOverlay(status, type);
		if (status === STATUS.FINISHED) {
			setShouldRun(false);
			setState({ ...state, run: false });
		}
	};

	return { state, start: startTour, callback: handleCallback, setShouldRun };
};

export default useProductTour;
