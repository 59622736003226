import { Image } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

import { ICategory } from 'src/lib/schemas';
import {
	DataTableActions,
	DataTableActionsProps,
} from 'src/components/common/DataTable';

export interface ICategoriesListingColumn extends ICategory {
	action?: unknown;
}

const columnHelper = createColumnHelper<ICategoriesListingColumn>();

export const getCategoriesListingColumns = (
	onAction: DataTableActionsProps<ICategory>['onAction'],
) => {
	return [
		columnHelper.accessor('image', {
			header: 'Image',
			cell: (info) => {
				return (
					<Image
						w="48px"
						h="48px"
						borderRadius="md"
						objectFit="contain"
						src={info.row.original.image}
					/>
				);
			},
		}),
		columnHelper.accessor('name', {
			header: 'Name',
		}),
		columnHelper.accessor('noOfChildrens', {
			header: 'No. of Childrens',
			cell: (info) => {
				const noOfChildrens = info.row.original.childrens?.length;
				if (noOfChildrens) {
					return noOfChildrens;
				}

				return '';
			},
		}),
		// columnHelper.accessor('noOfCampaigns', {
		// 	header: 'No. of Campaigns',
		// }),
		columnHelper.accessor('action', {
			header: 'Action',
			cell: (info) => {
				return (
					<DataTableActions
						data={info.row.original}
						onAction={onAction}
						actionsAllowed={[
							'Edit',
							'Delete',
							'Create subcategory',
							'Create campaign',
						]}
					/>
				);
			},
			meta: {
				ThProps: {
					width: '100px',
				},
				TdProps: {
					textAlign: 'center',
				},
			},
		}),
	];
};
