import {
	ICampaignBudgetBreakdown,
	IChannel,
	IChannelGroup,
	IPlacement,
} from 'src/lib/schemas';
import { IBudgetBreakdownItem } from './columns';
import { formatNumber } from 'src/lib/utils';

export const generateBudgetBreakdown = (
	channels: Array<string>,
	availableChannels: IChannelGroup[],
	budget: number,
) => {
	const flatChannels = availableChannels
		.flatMap((cg) => {
			return cg.channels.map((c) => {
				return {
					...c,
					groupId: cg.group_id,
					group: cg.name,
				};
			});
		})
		.filter((c) => {
			return channels.includes(c.id);
		});

	const totalApportion = flatChannels.reduce((acc, c) => {
		return acc + (c.apportion || 0);
	}, 0);

	let budgetAdjustment = 0;

	const channelsBudget = flatChannels.map((channel) => {
		const channelPercentage = formatNumber(
			(channel.apportion / totalApportion) * 100,
		);
		const channelBudget = formatNumber(
			(channelPercentage * budget) / 100 + budgetAdjustment,
		);
		const channelBudgetAbs = formatNumber(
			(channelPercentage * budget) / 100,
			0,
		);
		budgetAdjustment = formatNumber(channelBudget - channelBudgetAbs);

		return {
			id: channel.id,
			type: channel.groupId,
			budget: channelBudgetAbs,
		};
	});

	if (budgetAdjustment !== 0) {
		const last = channelsBudget[channelsBudget.length - 1];
		last.budget = formatNumber(last.budget + budgetAdjustment, 0);
	}

	return channelsBudget;
};

export const getChannelsByPlacements = (
	placements: string[],
	availableChannels: IChannelGroup[],
): IChannel[] => {
	const matchingChannels: IChannel[] = [];

	availableChannels.forEach((group) => {
		group.channels.forEach((channel) => {
			const hasMatchingPlacement = channel.placements?.some((placement) =>
				placements.includes(placement.id),
			);

			if (hasMatchingPlacement) {
				matchingChannels.push(channel);
			}
		});
	});

	return matchingChannels;
};

export const generateBudgetBreakdownByPlacements = (
	placements: Array<string>,
	availableChannels: IChannelGroup[],
	budget: number,
) => {
	const paidPlacementsId = availableChannels
		.flatMap((group) => group.channels)
		.flatMap((channel) => channel.placements || [])
		.filter(
			(placement: IPlacement | undefined) =>
				placement &&
				placements?.includes(placement.id) &&
				placement.apportion > 0,
		)
		.map((placement: IPlacement) => placement.id);

	const channels = getChannelsByPlacements(paidPlacementsId, availableChannels);

	const totalApportion = channels.reduce((acc, c) => {
		return acc + (c.apportion || 0);
	}, 0);

	let budgetAdjustment = 0;

	const channelsBudget = channels.map((channel) => {
		const channelPercentage = formatNumber(
			(channel!.apportion / totalApportion) * 100,
		);
		const channelBudget = formatNumber(
			(channelPercentage * budget) / 100 + budgetAdjustment,
		);
		const channelBudgetAbs = formatNumber(
			(channelPercentage * budget) / 100,
			0,
		);
		budgetAdjustment = formatNumber(channelBudget - channelBudgetAbs);

		return {
			id: channel!.id,
			type: 'paid',
			budget: channelBudgetAbs,
		};
	});

	if (budgetAdjustment !== 0) {
		const last = channelsBudget[channelsBudget.length - 1];
		last.budget = formatNumber(last.budget + budgetAdjustment, 0);
	}
	return channelsBudget.filter((channel) => channel.id !== 'twitter');
};

export const mapChannelsGroup = (
	totalBudget: number,
	breakdown: ICampaignBudgetBreakdown[],
	availableChannels: IChannelGroup[],
	channelsSeleted: Array<string>,
) => {
	const flatChannels = availableChannels
		.flatMap((cg) => {
			return cg.channels.map((c) => {
				return {
					...c,
					groupId: cg.group_id,
					group: cg.name,
				};
			});
		})
		.filter((c) => channelsSeleted.includes(c.id));
	const currentTotal = breakdown.reduce((acc, b) => {
		return acc + b.budget;
	}, 0);

	let budgetAdjustment = 0;
	const adjustmentFactor = currentTotal === 0 ? 0 : totalBudget / currentTotal;

	const channels = flatChannels.map((channel) => {
		const channelWithBudget = breakdown.find((b) => b.id === channel.id);
		const channelBudget = formatNumber(
			channelWithBudget?.budget ?? 0 * adjustmentFactor + budgetAdjustment,
		);
		const channelBudgetAbs = formatNumber(channelBudget, 0);
		const channelPercentage = channelBudgetAbs
			? formatNumber((channelBudgetAbs / totalBudget) * 100)
			: 0;

		return {
			id: channel.id,
			name: channel.name,
			groupId: channel.groupId,
			group: channel.group,
			budget: channelBudgetAbs,
			percentage: channelPercentage,
		};
	});

	if (budgetAdjustment !== 0) {
		const last = channels[channels.length - 1];
		last.budget = formatNumber(last.budget + budgetAdjustment, 0);
		last.percentage = formatNumber((last.budget / totalBudget) * 100);
		budgetAdjustment = 0;
	}

	return channels;
};

interface ChannelsBudgetSplit {
	id: string;
	name: string;
	groupId: string;
	group: string;
	percentage: number;
	budget: number;
}

export const adjustGroupBudget = (
	id: string,
	value: number,
	totalBudget: number,
	channels: IBudgetBreakdownItem[],
) => {
	const groupBudget = channels
		.filter((c) => c.groupId === id)
		.reduce((acc, c) => {
			return acc + c.budget;
		}, 0);

	const adjustmentFactor = groupBudget === 0 ? 0 : value / groupBudget;
	let budgetAdjustment = groupBudget === 0 ? value : 0;

	const channelsSplit = channels.map((channel) => {
		if (channel.groupId === id) {
			const channelBudget = formatNumber(
				channel.budget * adjustmentFactor + budgetAdjustment,
			);
			const channelBudgetAbs = formatNumber(channelBudget, 0);
			const channelPercentage = formatNumber(
				(channelBudgetAbs / totalBudget) * 100,
			);
			budgetAdjustment = formatNumber(channelBudget - channelBudgetAbs);

			return {
				...channel,
				budget: channelBudgetAbs,
				percentage: channelPercentage,
			};
		}

		return channel;
	});

	if (budgetAdjustment) {
		const groupChannels = channelsSplit.filter((c) => c.groupId === id);
		const last = groupChannels[groupChannels.length - 1];
		last.budget = formatNumber(last.budget + budgetAdjustment, 0);
		last.percentage = formatNumber((last.budget / totalBudget) * 100);
		budgetAdjustment = 0;
	}

	let currentGroupTotal = 0;

	const channelsAdjustedBudget = channelsSplit.map((channel) => {
		if (channel.groupId === id) {
			if (channel.budget < 0 || currentGroupTotal >= value) {
				channel.budget = 0;
				channel.percentage = 0;
			} else if (channel.budget > value - currentGroupTotal) {
				channel.budget = value - currentGroupTotal;
				channel.percentage = formatNumber((channel.budget / totalBudget) * 100);
			}
			currentGroupTotal += channel.budget;
		}

		return channel;
	});
	return channelsAdjustedBudget;
};

export const adjustOverallBudget = (
	value: number,
	channels: IBudgetBreakdownItem[],
) => {
	let budgetAdjustment = 0;
	const channelsSplit = channels.map((channel) => {
		if (value === 0) {
			return {
				...channel,
				percentage: 0,
				budget: 0,
			};
		}
		const channelBudget = formatNumber(
			(channel.percentage * value) / 100 + budgetAdjustment,
		);
		const channelBudgetAbs = formatNumber(channelBudget, 0);

		budgetAdjustment = formatNumber(channelBudget - channelBudgetAbs);

		const channelPercentage =
			channelBudgetAbs === 0
				? 0
				: formatNumber((channelBudgetAbs / value) * 100);

		return {
			...channel,
			percentage: channelPercentage,
			budget: channelBudgetAbs,
		};
	});

	if (budgetAdjustment !== 0) {
		const last = channelsSplit[channelsSplit.length - 1];
		last.budget = formatNumber(last.budget + budgetAdjustment, 0);
		last.percentage = formatNumber((last.budget / value) * 100);
		budgetAdjustment = 0;
	}

	return channelsSplit;
};

export const adjustChannelBudget = (
	id: string,
	value: number,
	totalBudget: number,
	channels: IBudgetBreakdownItem[],
) => {
	const channelsSplit = channels.map((c) =>
		c.id === id
			? {
					...c,
					budget: value,
					percentage: formatNumber((value / totalBudget) * 100),
			  }
			: c,
	);

	return channelsSplit;
};

export const groupChannels = (channels: ChannelsBudgetSplit[]) => {
	const data = channels.reduce((acc, c) => {
		if (acc[c.groupId]) {
			acc[c.groupId].budget += c.budget;
			acc[c.groupId].percentage += c.percentage;
		} else {
			acc[c.groupId] = {
				id: c.groupId,
				name: c.group,
				group: '',
				groupId: '',
				percentage: c.percentage,
				budget: c.budget,
			};
		}
		return acc;
	}, {} as any);

	const groups = [...Object.values(data)] as IBudgetBreakdownItem[];

	return groups.map((g) => ({
		...g,
		percentage: formatNumber(g.percentage),
		budget: formatNumber(g.budget, 0),
	}));
};

export const overallBudget = (
	channels: ChannelsBudgetSplit[],
	budget: number,
) => {
	const channelsBudget = channels.reduce((acc, c) => {
		return acc + c.budget;
	}, 0);

	return [
		{
			id: 'Overall',
			name: 'Overall',
			group: '',
			groupId: '',
			budget: budget,
			percentage: (channelsBudget / budget) * 100,
		},
	];
};
