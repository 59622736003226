import { z } from 'zod';

export interface ISingUpFOrm {
	email: string;
	name: string;
	accountName?: string;
	password: string;
}

export const SingUpSchema: Zod.ZodType<ISingUpFOrm> = z.object({
	email: z.string().nonempty('Email is required').email('Email is not valid'),
	name: z.string().nonempty('Name is required'),
	accountName: z.string().optional(),
	password: z
		.string()
		.nonempty('Password is required')
		.min(8, 'Password must be at least 8 characters')
		.refine(
			(value: string) => /^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value),
			'Password must contain at least 1 letter and 1 number',
		),
});
