import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Container, Flex, Button, useDisclosure } from '@chakra-ui/react';
import LaunchingCampaignModal from './parts/LaunchingCampaignModal';
import CampaignSuccessModal from './parts/CampaignSuccessModal';
import { CampaignContext } from 'src/contexts';
import { customToast } from 'src/services/toast';
import { validateCampaignSchedule } from '../utils/validations';
import IntegrationsModal from './parts/IntegrationsModal';
import { useLocation } from 'react-router-dom';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';
import { ECampaignScheduleType } from 'src/lib/schemas';
import { genrateCampaignCreatives, getCampaign } from 'src/services/campaign';
import { delay } from 'src/lib/utils';
import {
	ICreative,
	IDesignDirection,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import { countPendingCreativesByChannel } from '../utils/countPendingCreativesByChannel';
import http from 'src/services/http';

interface LaunchCampaignProps {
	isValidCampaignConfig: boolean;
	isValidCampaignBudget: boolean;
	isSocialEnabled?: boolean;
	onLaunch: () => Promise<void>;
	setShowCreativesLoader: (val: boolean) => void;
	pendingCreativesCount: number;
	designDirections: IDesignDirection[];
	creatives: ICreative[];
	setPendingCreativesCount: React.Dispatch<React.SetStateAction<number>>;
}

const LaunchCampaign: FC<LaunchCampaignProps> = ({
	isValidCampaignConfig,
	isValidCampaignBudget,
	isSocialEnabled,
	onLaunch,
	setShowCreativesLoader,
	pendingCreativesCount,
	designDirections,
	creatives,
	setPendingCreativesCount,
}) => {
	const {
		isOpen: isOpenLaunchingModal,
		onOpen: onOpenLaunchingModal,
		onClose: onCloseLaunchingModal,
	} = useDisclosure();
	const {
		isOpen: isOpenSuccessModal,
		onOpen: onOpenSuccessModal,
		onClose: onCloseSuccessModal,
	} = useDisclosure();
	const {
		isOpen: isOpenIntegrationsModal,
		onOpen: onOpenIntegrationsModal,
		onClose: onCloseIntegrationsModal,
	} = useDisclosure();
	const {
		campaign,
		setCampaign,
		setIsLaunching,
		setOpenSections,
		id: campaignId,
	} = useContext(CampaignContext);
	const { integrations, shouldLaunch, setShouldLaunch } = useMyIntegrations();
	const { search } = useLocation();
	const redirectedFromFB = search.includes('fromFB');
	const [isLaunchInitiated, setIsLaunchInitiated] = useState(false);
	const publishButtonRef = useRef<HTMLButtonElement | null>(null);
	const isDesignDirectionPending = designDirections?.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);
	const isCreativesPending = creatives?.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);

	useEffect(() => {
		const count = countPendingCreativesByChannel(campaign, creatives);
		setPendingCreativesCount(count);
	}, [campaign]);

	useEffect(() => {
		if (redirectedFromFB) {
			onOpenIntegrationsModal();
		}
	}, [redirectedFromFB, onOpenIntegrationsModal]);

	useEffect(() => {
		if (shouldLaunch) {
			onCloseIntegrationsModal();
			startLaunch();
			setShouldLaunch(false);
		}
	}, [shouldLaunch, onCloseIntegrationsModal, setShouldLaunch]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				isLaunchInitiated &&
				publishButtonRef.current &&
				!publishButtonRef.current.contains(event.target as Node)
			) {
				setIsLaunchInitiated(false);
			}
		};

		if (isLaunchInitiated) {
			document.addEventListener('click', handleClickOutside);
		}

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [isLaunchInitiated]);

	const isValidCampaignSchedule = useMemo(() => {
		if (!campaign?.schedule) return false;
		return validateCampaignSchedule(campaign.schedule);
	}, [campaign]);

	const startLaunch = async () => {
		if (!campaign || !campaignId) return;

		if (!validateCampaign()) return;

		setIsLaunchInitiated(true);

		if (!areAllDesignDirectionsWithCreatives() || isCreativesPending) {
			setShowCreativesLoader(true);
			try {
				await genrateCampaignCreatives(campaignId, '', true);
				const data = await getCampaign(campaignId);
				setCampaign(data);
			} catch (error) {
				console.error('Error generating creatives:', error);
				setShowCreativesLoader(false);
			}
		} else {
			await finishLaunch();
		}
	};

	const validateCampaign = (): boolean => {
		if (!isValidCampaignConfig) {
			handleInvalidCampaignConfig();
			return false;
		}

		if (!isValidCampaignSchedule) {
			handleInvalidCampaignSchedule();
			return false;
		}

		if (!isValidCampaignBudget) {
			return false;
		}

		if (isOneOffScheduleWithoutEndDate()) {
			customToast('End Date is required for One-off campaigns', 'error');
			return false;
		}

		if (shouldOpenIntegrationsModal()) {
			onOpenIntegrationsModal();
			return false;
		}

		return true;
	};

	useEffect(() => {
		if (isLaunchInitiated && pendingCreativesCount > 0) {
			handlePendingCreatives();
		}
	}, [pendingCreativesCount, isLaunchInitiated]);

	const handlePendingCreatives = async () => {
		if (!campaignId) return;

		let attemptCount = 0;
		const maxAttempts = 30;

		let updatedPendingCreativesCount =
			creatives?.filter(
				(creative) =>
					creative.status === 'pending' || creative.status === 'generating',
			).length || 0;

		while (
			updatedPendingCreativesCount > 0 ||
			!areAllDesignDirectionsWithCreatives()
		) {
			await delay(1000);
			const data = await getCampaign(campaignId);
			setCampaign(data);
			if (!data.creatives) return;

			updatedPendingCreativesCount = data.creatives.filter(
				(creative) =>
					creative.status === 'pending' || creative.status === 'generating',
			).length;

			attemptCount++;

			if (attemptCount >= maxAttempts) {
				await http.post('/v2/migrations/process-pending-creatives');
			}
		}

		setShowCreativesLoader(false);
		await finishLaunch();
	};

	const finishLaunch = async () => {
		onOpenLaunchingModal();
		setIsLaunching(true);
		try {
			await onLaunch();
			onOpenSuccessModal();
		} catch (error: any) {
			const message =
				error?.response?.data?.message ??
				'We couldn’t publish the campaign, please try again.';
			customToast(message, 'error');
		} finally {
			onCloseLaunchingModal();
			setIsLaunching(false);
		}
	};

	const handleInvalidCampaignConfig = () => {
		setOpenSections([true, false, false]);
		const configFormSubmitElement = document.getElementById(
			'campaign-config-form-submit',
		);
		configFormSubmitElement?.click();
	};

	const handleInvalidCampaignSchedule = () => {
		const scheduleFormSubmitElement = document.getElementById(
			'campaign-schedule-form-submit',
		);
		scheduleFormSubmitElement?.click();
	};

	const isOneOffScheduleWithoutEndDate = () => {
		return (
			campaign?.schedule?.type === ECampaignScheduleType['One-off'] &&
			!campaign.schedule.endDate
		);
	};

	const shouldOpenIntegrationsModal = () => {
		return (
			(!isOpenLaunchingModal && !isSocialEnabled) ||
			!integrations?.social?.fb?.attributes?.pagesToPublishContent
		);
	};

	const areAllDesignDirectionsWithCreatives = () => {
		if (!campaign || !designDirections || !creatives) {
			return false;
		}

		const designDirectionIds = new Set(
			//@ts-ignore
			designDirections.map((direction: IDesignDirection) => direction.id),
		);

		const creativeDesignDirectionIds = new Set(
			//@ts-ignore

			creatives.map((creative: ICreative) => creative.designDirectionId),
		);

		let allDirectionsWithCreatives = true;

		designDirectionIds.forEach((id) => {
			if (!creativeDesignDirectionIds.has(id)) {
				allDirectionsWithCreatives = false;
			}
		});

		return allDirectionsWithCreatives;
	};

	return (
		<>
			<LaunchingCampaignModal isOpen={isOpenLaunchingModal} />
			<CampaignSuccessModal
				isOpen={isOpenSuccessModal}
				onClose={onCloseSuccessModal}
				title={campaign?.title ?? 'No title'}
			/>
			<IntegrationsModal
				isOpen={!isOpenLaunchingModal && isOpenIntegrationsModal}
				onClose={onCloseIntegrationsModal}
				redirectedFromFB={redirectedFromFB}
			/>
			<Container maxW="full" py={6} m={0} px={0}>
				<Flex justifyContent="right" alignItems="flex-end">
					<Button
						ref={publishButtonRef}
						variant="orangeSolid"
						_hover={{
							opacity: 0.9,
						}}
						_disabled={{
							opacity: 0.7,
							cursor: 'not-allowed',
						}}
						onClick={startLaunch}
						isDisabled={isDesignDirectionPending}
					>
						Publish to the channels
					</Button>
				</Flex>
			</Container>
		</>
	);
};

export default LaunchCampaign;
