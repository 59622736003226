import { useContext, useEffect, useState } from 'react';
import UserContext from 'src/contexts/UserContext';

import { getCompanyAccount } from 'src/services/account';
import { toastError } from 'src/services/toast';

const useCompanyAccount = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { user } = useContext(UserContext);

	const [companyAccount, setCompanyAccount] = useState<any>();

	const fetchCompanyAccount = async () => {
		setIsLoading(true);
		try {
			if (!user) {
				setIsLoading(false);
				return;
			}
			const data = await getCompanyAccount(user.account);
			setCompanyAccount(data);
		} catch (error: any) {
			toastError(error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchCompanyAccount();
	}, []);

	return { companyAccount, isLoading };
};

export default useCompanyAccount;
