import { FC, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import AppExecute from './AppExecute';
import { renderInputWidget } from './utils/renderInputWidget';
import { IAppActionInput } from 'src/lib/schemas';
import { withAppConfigProvider } from 'src/contexts/app';
import { getValidationRules } from './utils/getValidationRules';

interface AppInputsProps {
	inputs: IAppActionInput[];
	onSubmit: (data: any) => void;
	isGeneratingOutput?: boolean;
}

const AppInputs: FC<AppInputsProps> = ({
	inputs,
	onSubmit,
	isGeneratingOutput,
}) => {
	const [isUploadingImage, setIsUploadingImage] = useState(false);

	const formMethods = useForm();

	const handleInputSubmit = formMethods.handleSubmit(async (formData) => {
		onSubmit(formData);
	});

	return (
		<FormProvider {...formMethods}>
			<form onSubmit={handleInputSubmit}>
				<Flex direction="column" gap={6} h="fit-content">
					{inputs.map((input) => {
						const validationRules = getValidationRules(input);
						const isStringType = input.type === 'String';
						const isMultiline = validationRules?.multiline?.value;
						const isTextAreaType =
							(isStringType && isMultiline) ||
							(validationRules.maxLength &&
								validationRules.maxLength.value >= 90);

						const InputWidget = renderInputWidget(
							isTextAreaType ? 'TextArea' : input.type,
						);

						const maxCharCount = validationRules?.maxLength?.value
							? validationRules?.maxLength?.value
							: undefined;

						if (InputWidget) {
							return (
								<InputWidget
									key={input.property}
									input={input}
									validationRules={validationRules}
									maxLength={maxCharCount}
									handleImageUploading={setIsUploadingImage}
								/>
							);
						}
						return null;
					})}
					<AppExecute
						onExecute={handleInputSubmit}
						isLoading={isGeneratingOutput}
						isDisabled={isUploadingImage}
					/>
				</Flex>
			</form>
		</FormProvider>
	);
};

const AppInputsWithProvider = withAppConfigProvider(AppInputs);
export default AppInputsWithProvider;
