import { useContext } from 'react';

import { AppInputConfigContext } from 'src/contexts';
import {
	createAccountConfigOptions,
	getAccountConfigOptions,
} from 'src/services/account';

export const OPTIONS_CONFIG_MAP: Record<string, string> = {
	Tone: 'tones',
	Audience: 'audiences',
	Tag: 'tags',
};

const useAccountConfigOptions = <T extends { id?: string; name?: string }>(
	name: string,
) => {
	const configName = OPTIONS_CONFIG_MAP[name] || name;

	const {
		inputConfig,
		loadingInputs,
		loadedInputs,
		setInputConfig,
		setLoadingInputConfig,
		setLoadedInputConfig,
	} = useContext(AppInputConfigContext);

	const handleFetchConfig = async () => {
		if (loadingInputs[name] || loadedInputs[name]) return;

		try {
			setLoadingInputConfig({ ...loadingInputs, [name]: true });
			const data = await getAccountConfigOptions<T>(configName);
			// @ts-ignore
			setInputConfig({
				[name]: data.map((item) => ({
					...item,
					value: item.id,
					label: item.name,
				})),
			});
		} catch (error) {
			setInputConfig({ ...inputConfig, [name]: [] });
		} finally {
			setLoadingInputConfig({ ...loadingInputs, [name]: false });
			setLoadedInputConfig({ ...loadedInputs, [name]: true });
		}
	};

	const createConfig = async (payload: T) => {
		if (loadingInputs[name]) return;

		setLoadingInputConfig({ ...loadingInputs, [name]: true });
		const currentConfig = inputConfig[name] || [];

		const newOption = await createAccountConfigOptions(configName, payload);

		// @ts-ignore
		setInputConfig({
			...inputConfig,
			[name]: [
				...currentConfig,
				{ value: newOption.id, label: newOption.name },
			],
		});

		setLoadingInputConfig({ ...loadingInputs, [name]: false });
		return newOption;
	};

	return { fetchConfig: handleFetchConfig, createConfig };
};

export default useAccountConfigOptions;
