import { useEffect, useState } from 'react';

interface IFetch<T, P = any> {
	isLoading: boolean;
	data: T | null;
	error: string | null;
	refetch: (params?: P) => void;
	setIsLoading: (isLoading: boolean) => void;
	setData: (data: T | null) => void;
}

const useFetchWithDependencies = <T, P = any>(
	fn: (params?: P) => Promise<T>,
	dependencies: any[] = [],
	preventInitialLoad?: boolean,
	onError?: (error: any) => void,
): IFetch<T, P> => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<T | null>(null);
	const [error, setError] = useState<string | null>(null);

	const fetchData = async (params?: P) => {
		setIsLoading(true);
		setError(null);
		try {
			const response = await fn(params);
			setData(response);
		} catch (error: any) {
			const message =
				error.response?.data?.message || error.message || 'Unknow Error';
			setError(message);
			if (onError) {
				onError(error);
			}
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (!preventInitialLoad) {
			fetchData();
		}
	}, dependencies);

	return {
		isLoading,
		data,
		error,
		refetch: fetchData,
		setIsLoading,
		setData,
	};
};

export default useFetchWithDependencies;
