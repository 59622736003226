import { createStandaloneToast } from '@chakra-ui/react';

const { ToastContainer, toast } = createStandaloneToast();

const toastSuccess = (message: string) => {
	toast({
		description: message,
		status: 'success',
		duration: 5000,
		isClosable: true,
		position: 'top-right',
	});
};

const toastError = (error: any, list?: Array<string>) => {
	const whiteList = [401, 403, 500, ...(list ? list : [])];
	if (!error.response) {
		console.log('Toast error, error response undefined', error);
		return;
	}
	const { code, message } = error.response.data;

	if (!whiteList.includes(code)) {
		toast({
			description: message,
			status: 'error',
			duration: 5000,
			isClosable: true,
			position: 'top-right',
		});
	}
};

const toastErrorGlobal = (error: any) => {
	const { message } = error.data;
	toast({
		description: message,
		status: 'error',
		duration: 5000,
		isClosable: true,
		position: 'top-right',
	});
};

const customToast = (
	message: string,
	status?: 'info' | 'warning' | 'success' | 'error' | 'loading',
) => {
	toast({
		description: message,
		status: status ?? 'info',
		duration: 5000,
		isClosable: true,
		position: 'top-right',
	});
};

export {
	ToastContainer,
	toastError,
	toastSuccess,
	toastErrorGlobal,
	customToast,
};
