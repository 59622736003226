import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const UnlockIcon: FC<IconProps> = ({
	color,
	width = '20px',
	height = '20px',
}) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 23 27"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.3125 6.75V10H19.5C21.2773 10 22.75 11.4727 22.75 13.25V23C22.75 24.8281 21.2773 26.25 19.5 26.25H3.25C1.42188 26.25 0 24.8281 0 23V13.25C0 11.4727 1.42188 10 3.25 10H4.875V6.75C4.875 3.19531 7.76953 0.25 11.375 0.25C14.2695 0.25 16.707 2.17969 17.5195 4.76953C17.7227 5.42969 17.3672 6.08984 16.7578 6.29297C16.0977 6.49609 15.4375 6.14062 15.2344 5.53125C14.6758 3.90625 13.1523 2.6875 11.375 2.6875C9.08984 2.6875 7.3125 4.51562 7.3125 6.75ZM2.4375 13.25V23C2.4375 23.457 2.79297 23.8125 3.25 23.8125H19.5C19.9062 23.8125 20.3125 23.457 20.3125 23V13.25C20.3125 12.8438 19.9062 12.4375 19.5 12.4375H3.25C2.79297 12.4375 2.4375 12.8438 2.4375 13.25Z"
				fill={color ? (color as string) : '#3182CE'}
			/>
		</Icon>
	);
};

export default UnlockIcon;
