import { useEffect, useState } from 'react';

interface IFetch<T, P = any> {
	isLoading: boolean;
	data: T | null;
	error: string | null;
	refetch: (params?: P) => void;
	setIsLoading: (params?: P) => void;
	setData: (data: T | null) => void;
}

const useFetch = <T, P = any>(
	fn: (params?: P) => Promise<T>,
	preventInitialLoad?: boolean,
	onError?: (error: any) => void,
): IFetch<T> => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<T | null>(null);
	const [error, setError] = useState<string | null>(null);

	const fetchData = async (params?: P) => {
		setIsLoading(true);
		setError(null);
		try {
			const response = await fn(params);
			setData(response);
		} catch (error: any) {
			if(error.response){
				const { message } = error.response.data;
				setError(message);
			} else {
				console.error(error)
			}
			onError && onError(error);

		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (!preventInitialLoad) fetchData();
	}, []);

	return {
		isLoading,
		setIsLoading,
		data,
		error,
		refetch: fetchData,
		setData,
	};
};

export default useFetch;
