import { IBrand, ICategory, IGroup, ICatalog } from 'src/lib/schemas';
import http from './http';
import { upsertElem } from 'src/lib/utils';

const apiVersion = 'v1';
const configUrl = `/${apiVersion}/configuration`;

interface IConfigOption {
	id: string;
	name: string;
	supportType?: string;
	description?: string;
	title?: string;
}

interface ICampaignConfig {
	audience: IConfigOption[];
	goals: IConfigOption[];
	tones: IConfigOption[];
}

interface IAccountConfig {
	industries: IConfigOption[];
	languages: IConfigOption[];
	timezones: IConfigOption[];
	fonts: IConfigOption[];
}

export const getCampaignConfig = async (): Promise<ICampaignConfig> => {
	const { data } = await http.get(`${configUrl}/global/campaign`);
	return data.value;
};

export const getAccountConfig = async (): Promise<IAccountConfig> => {
	const { data } = await http.get(`${configUrl}/global/account`);
	return data.value;
};

export const getConfigIfExists = async (configName: string): Promise<any> => {
	try {
		const { data } = await http.get(`${configUrl}/${configName}`);
		return data.value[configName];
	} catch (error) {
		return false;
	}
};

export const createProducts = async (payload: ICatalog[]) => {
	await http.post(`${configUrl}`, {
		name: 'catalogs',
		value: {
			products: payload,
		},
	});
};

export const createBrands = async (payload: IBrand[]) => {
	await http.post(`${configUrl}`, {
		name: 'brands',
		value: {
			brands: payload,
		},
	});
};

export const getBrands = async (): Promise<IBrand[]> => {
	try {
		const { data } = await http.get(`${configUrl}/brands`);
		return data.value.brands;
	} catch (error) {
		return [];
	}
};

export const updateBrands = async (payload: IBrand[]) => {
	await http.patch(`${configUrl}/brands`, {
		name: 'brands',
		value: {
			brands: payload,
		},
	});
};

export const createAndMergeBrands = async (payload: IBrand) => {
	const brands = await getConfigIfExists('brands');
	if (!brands) {
		await createBrands(upsertElem([], payload));
	} else {
		await updateBrands(upsertElem(brands, payload));
	}
};

export const createCategory = async (payload: ICategory[]) => {
	await http.post(`${configUrl}`, {
		name: 'categories',
		value: {
			categories: payload,
		},
	});
};

export const getCategories = async (): Promise<ICategory[]> => {
	try {
		const { data } = await http.get(`${configUrl}/categories`);
		return data.value.categories;
	} catch (error) {
		return [];
	}
};

export const updateCategory = async (payload: ICategory[]) => {
	await http.patch(`${configUrl}/categories`, {
		name: 'categories',
		value: {
			categories: payload,
		},
	});
};

export const createAndMergeCategories = async (payload: ICategory) => {
	const categories = await getConfigIfExists('categories');
	if (!categories) {
		await createCategory(upsertElem([], payload));
	} else {
		await updateCategory(upsertElem(categories, payload));
	}
};

export const createGroup = async (payload: IGroup[]) => {
	await http.post(`${configUrl}`, {
		name: 'groups',
		value: {
			groups: payload,
		},
	});
};

export const getGroups = async (): Promise<IGroup[]> => {
	const { data } = await http.get(`${configUrl}/groups`);
	return data.value.groups;
};

export const updateGroup = async (payload: IGroup[]) => {
	await http.patch(`${configUrl}/groups`, {
		name: 'groups',
		value: {
			groups: payload,
		},
	});
};
