import React, { useState, useEffect, useContext } from 'react';
import { VStack, SimpleGrid, Text, HStack, Image } from '@chakra-ui/react';
import { IChannelGroup, IChannel, IPlacement } from 'src/lib/schemas';
import { ChannelType } from './ChannelType';
import MarketingChannelInput from 'src/components/common/form/MarketingChannelInputV2/MarketingChannelInput';
import { CampaignContext } from 'src/contexts';

interface PlacementsChannelsProps {
	availableChannels: IChannelGroup[];
	handlePlacementsChange: (placements: string[]) => void;
}

const PlacementsChannels: React.FC<PlacementsChannelsProps> = ({
	availableChannels,
	handlePlacementsChange,
}) => {
	const { campaign } = useContext(CampaignContext);
	const [selectedPlacements, setSelectedPlacements] = useState<string[]>([]);

	useEffect(() => {
		if (campaign?.placements) {
			setSelectedPlacements(campaign.placements);
		}
	}, [campaign]);

	const handlePlacementSelection = async (
		placementId: string,
		placementType: ChannelType,
	) => {
		const updatedPlacements = selectedPlacements.includes(placementId)
			? selectedPlacements.filter((id) => id !== placementId)
			: [...selectedPlacements, placementId];

		setSelectedPlacements(updatedPlacements);
		handlePlacementsChange(updatedPlacements);
	};

	return (
		<VStack spacing={4} align="start" w="full">
			{availableChannels.map((channelGroup: IChannelGroup) => (
				<React.Fragment key={channelGroup.group_id}>
					{channelGroup.group_id === 'owned' ? (
						<VStack spacing={4} align="start" w="85%">
							<HStack mt={4}>
								<Text fontSize="md" fontWeight="medium" color="black">
									Direct Marketing
								</Text>
							</HStack>
							<SimpleGrid
								columns={{ base: 2, md: 3, lg: 4, xl: 6, '2xl': 7 }}
								spacing={4}
								w="full"
							>
								{channelGroup.channels.flatMap(
									(channel: IChannel) =>
										channel.placements?.map((placement: IPlacement) => (
											<MarketingChannelInput
												key={placement.id}
												name={placement.name}
												id={placement.id}
												icon={placement.icon}
												type={placement.type as ChannelType}
												isSelected={selectedPlacements.includes(placement.id)}
												onChange={() =>
													handlePlacementSelection(
														placement.id,
														placement.type as ChannelType,
													)
												}
												description={placement.description}
												fontColor="black"
											/>
										)),
								)}
							</SimpleGrid>
						</VStack>
					) : (
						channelGroup.channels.map((channel: IChannel) => (
							<VStack key={channel.id} spacing={4} align="start" w="full">
								<HStack mt={4} w="full">
									<Image
										src={channel.icon}
										alt={`${channel.name} icon`}
										width="30px"
									/>
									<Text fontSize="md" fontWeight="medium" color="black">
										{channel.name}
									</Text>
								</HStack>
								<SimpleGrid
									columns={{ base: 1, md: 3, lg: 4, xl: 6, '2xl': 7 }}
									spacing={4}
									w="full"
								>
									{channel.placements?.map((placement: IPlacement) => (
										<MarketingChannelInput
											key={placement.id}
											name={placement.name}
											id={placement.id}
											icon={placement.icon}
											type={placement.type as ChannelType}
											isSelected={selectedPlacements.includes(placement.id)}
											onChange={() =>
												handlePlacementSelection(
													placement.id,
													placement.type as ChannelType,
												)
											}
											description={placement.description}
											fontColor="black"
										/>
									))}
								</SimpleGrid>
							</VStack>
						))
					)}
				</React.Fragment>
			))}
		</VStack>
	);
};

export default PlacementsChannels;
