import { z } from 'zod';

export interface ICategoryForm {
	id?: string;
	image: string | undefined;
	name: string;
	url: string;
	parents: Array<string>;
	childrens: Array<string>;
	noOfChildrens?: number;
	noOfCampaigns?: number;
}

export interface ICategory extends ICategoryForm {
	id: string;
}

export const CategoryFormSchema: Zod.ZodType<ICategoryForm> = z.object({
	id: z.string().optional(),
	url: z
		.string()
		.nonempty('Landing page URL is required')
		.url('Landing page URL is not valid'),
	image: z.string().nonempty('Image is required').url('Image URL is not valid'),
	name: z.string().nonempty('Name is required'),
	parents: z.array(z.string()),
	childrens: z.array(z.string()),
});
