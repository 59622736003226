import { FC } from 'react';
import {
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';

import FusionLoading from '../../../common/FusionLoading';

interface LaunchingCampaignModalProps {
	isOpen: boolean;
}

const LaunchingCampaignModal: FC<LaunchingCampaignModalProps> = ({
	isOpen,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={() => {}} size="sm">
			<ModalOverlay zIndex={1800} />
			<ModalContent
				my="auto"
				containerProps={{
					zIndex: 1900,
				}}
			>
				<ModalBody
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					h="324px"
					gap={8}
					p={16}
				>
					<FusionLoading isLoading={isOpen} />
					<Text fontWeight="medium" fontSize="md">
						Launching your campaign...
					</Text>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default LaunchingCampaignModal;
