import { getDisplayName } from 'src/lib/utils';
import AppInputConfigProvider from './AppInputConfigProvider';

const withAppInputConfigProvider = <P extends object>(
	WrappedComponent: React.ComponentType<P>,
) => {
	const WithAppInputConfigProvider: React.FC<P> = (props: P) => (
		<AppInputConfigProvider>
			<WrappedComponent {...props} />
		</AppInputConfigProvider>
	);

	WithAppInputConfigProvider.displayName = `WithAppInputConfigProvider(${getDisplayName(
		WrappedComponent,
	)})`;

	return WithAppInputConfigProvider;
};

export default withAppInputConfigProvider;
