import React, { FC, ReactNode, useState, KeyboardEvent } from 'react';
import {
	Flex,
	Text,
	FormControl,
	FormControlProps,
	FormLabel,
	FormLabelProps,
	FormErrorMessage,
	Input,
	Tag,
	TagCloseButton,
	TagLabel,
	Box,
} from '@chakra-ui/react';

export interface TagInputProps {
	name: string;
	label?: string;
	requirementsLabel?: string;
	placeholder?: string;
	error?: ReactNode;
	required?: boolean;
	value?: string[];
	onChange?: (val: string[]) => void;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	placeholderFontSize?: string;
}

const TagInput: FC<TagInputProps> = ({
	label,
	requirementsLabel,
	placeholder,
	value = [],
	onChange,
	required,
	error,
	formControlProps,
	formLabelProps,
	placeholderFontSize = '14px',
}) => {
	const [inputValue, setInputValue] = useState('');
	const hasError = !!error;

	const renderLabelIndicator = () => {
		if (!required) return null;
		if (error)
			return (
				<Text color="#e53e3e" ml="3px">
					*
				</Text>
			);
		return (
			<Text color="#e53e3e" ml="2px">
				*
			</Text>
		);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && inputValue.trim()) {
			e.preventDefault();
			const newTag = inputValue.trim();
			if (!value.includes(newTag)) {
				onChange?.([...value, newTag]);
			}
			setInputValue('');
		}
	};

	return (
		<FormControl isInvalid={hasError} {...formControlProps}>
			{label && (
				<FormLabel mb="2" {...formLabelProps}>
					<Flex gap={1} alignItems="center">
						{label}
						{renderLabelIndicator()}
					</Flex>
					{requirementsLabel && (
						<Text fontSize="12px" color="#959595">
							{requirementsLabel}
						</Text>
					)}
				</FormLabel>
			)}
			<Input
				value={inputValue}
				onChange={handleInputChange}
				onKeyDown={handleInputKeyDown}
				placeholder={placeholder}
				fontSize={placeholderFontSize}
				mb={2}
			/>

			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default TagInput;
