import { FC, useState, useMemo, useContext, useEffect } from 'react';
import { Box, Divider, Flex, Image, Text } from '@chakra-ui/react';
// import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import UserAvatar from 'src/components/common/UserAvatar';
// import Logo from 'src/assets/svgs/logo-sm.svg';
import { SelectSearchCreateableInput } from 'src/components/common/form';
import GroupFormModal from 'src/components/groups/GroupFormModal';
import Overlay from 'src/components/common/Overlay';
import { CampaignContext } from 'src/contexts';
import useGroups from 'src/hooks/useGroups';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import { humanizeTime, upsertElem } from 'src/lib/utils';
import { IGroup, IGroupForm } from 'src/lib/schemas';
import { updateGroup } from 'src/services/config';
import { toastError } from 'src/services/toast';

interface CampaignHeaderProps {
	campaignDetail: {
		title: string;
		group?: string;
		lastEditTime?: Date;
		lastEditBy?: string;
	};
	onGroupChange: (val: string) => void;
}

const CampaignHeader: FC<CampaignHeaderProps> = ({
	campaignDetail,
	onGroupChange,
}) => {
	const [isCreating, setIsCreating] = useState(false);
	const [lastEditTimeMessage, setLastEditTimeMessage] = useState('');
	const { isFetching } = useContext(CampaignContext);
	const formToggle = useToggleWithPayload<IGroupForm>();
	const { title, group, lastEditTime, lastEditBy } = campaignDetail;
	const { data: groups, isLoading, refetch } = useGroups();

	const GROUP_OPTIONS = useMemo(
		() =>
			(groups || []).map((g) => ({
				value: g.id,
				label: g.name,
			})),
		[groups],
	);

	const handleCreateGroup = async (payload: IGroupForm) => {
		setIsCreating(true);
		try {
			if (!payload.id) {
				payload.id = uuid();
			}
			onGroupChange(payload.id);
			await updateGroup(upsertElem(groups || [], payload as IGroup));
			refetch();
		} catch (error: any) {
			toastError(error);
		}
		setIsCreating(false);
	};

	const handleCreateGroupOption = async (name: string) => {
		if (!name.trim()) {
			formToggle.onOpen();
			return;
		}
		await handleCreateGroup({ name });
	};

	useEffect(() => {
		if (!lastEditTime) return;

		const time = `Last edit ${humanizeTime(lastEditTime)} by`;
		setLastEditTimeMessage(time);

		const intervalId = setInterval(() => {
			const time = `Last edit ${humanizeTime(lastEditTime)} by`;
			setLastEditTimeMessage(time);
		}, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, [lastEditTime]);

	const defaultGroup = useMemo(() => {
		const selectedGroup =
			GROUP_OPTIONS?.find((g) => g.value === group) ||
			GROUP_OPTIONS?.find((g) => g.label === 'Default') ||
			GROUP_OPTIONS[0];

		return selectedGroup?.value;
	}, [GROUP_OPTIONS]);

	const showLoading = isCreating || isLoading || isFetching;

	const groupValue = group ?? defaultGroup;

	useEffect(() => {
		if (!showLoading && !group) {
			onGroupChange(defaultGroup);
		}
	}, [showLoading]);

	return (
		<Box
			w="fit-content"
			px={8}
			position="fixed"
			top={19}
			right={0}
			left="260px"
			maxW="69%"
			maxH="40px"
			overflow="hidden"
			bg="white"
			zIndex={1800}
		>
			<Overlay
				display="none"
				h="calc(100% + 1px)"
				zIndex={1600}
				id="campaign-header-overlay"
			/>
			<Flex gap={4} alignItems="center" position="fixed">
				<Text
					fontSize="md"
					fontWeight="medium"
					textDecor="underline"
					minW="60px"
				>
					{title}
				</Text>
				<Divider orientation="vertical" />
				<Box w="250px">
					<SelectSearchCreateableInput
						name="g"
						placeholder="Select"
						options={GROUP_OPTIONS}
						value={showLoading ? undefined : groupValue}
						onChange={(val) => {
							if (typeof val !== 'string') return;
							onGroupChange(val);
						}}
						onCreateOption={handleCreateGroupOption}
						isLoading={isCreating || isLoading || isFetching}
						valuePrefix="Group: "
					/>
				</Box>
				<Flex alignItems="center" gap={1}>
					{lastEditTime && <Text color="#A0AEC0">{lastEditTimeMessage}</Text>}
					{lastEditBy && <UserAvatar name={lastEditBy} />}
				</Flex>
			</Flex>
			<GroupFormModal
				isOpen={formToggle.isOpen}
				onClose={formToggle.onClose}
				onSubmit={handleCreateGroup}
				initialValues={formToggle.payload}
				title={`${formToggle.payload ? 'Edit' : 'Add'} Group`}
			/>
		</Box>
	);
};

export default CampaignHeader;
