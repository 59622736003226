import { ChangeEvent, FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { SelectInput } from '../common/form';
import { IOption } from 'src/lib/schemas/misc';

interface AppActionsProps {
	options: IOption[];
	onActionChange: (actionId: string) => void;
	selectedValue: string
}

const AppActions: FC<AppActionsProps> = ({ options, onActionChange, selectedValue }) => {
	const handleActionChange = (e: ChangeEvent<HTMLSelectElement>) => {
		onActionChange(e.target.value);
	};

	return (
		<Flex direction="column">
			<SelectInput
				label="Action"
				placeholder="Select"
				options={options}
				selectProps={{
					onChange: handleActionChange,
					defaultValue: selectedValue
				}}
			/>
		</Flex>
	);
};

export default AppActions;
