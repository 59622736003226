import { z } from 'zod';

export interface IUserForm {
	id?: string;
	email: string;
	name: string;
	role: string;
}

export const UserFromSchema: Zod.ZodType<IUserForm> = z.object({
	id: z.string().optional(),
	email: z
		.string()
		.nonempty('Email is required')
		.email('Invalid email address'),
	name: z.string().nonempty('Name is required'),
	role: z.string().nonempty('Role is required'),
});

export interface IUser extends IUserForm {
	id: string;
	isEmailVerified: boolean;
	isInvited: boolean;
	account: string;
	permissions?: string[];
}

export interface IUsers {
	limit: number;
	page: number;
	results: Array<IUser>;
	totalPages: number;
	totalResults: number;
}
