import { useContext, useEffect, useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { ConfigureLayers } from './ConfigureLayers';
import TemplateContext, {
	ILayerSpec,
	IVariation,
} from 'src/contexts/templates/TemplatesContext';

interface ConfigureLayersContainerProps {
	variation: IVariation;
}

export const ConfigureLayerHook = ({
	variation,
}: ConfigureLayersContainerProps) => {
	const { setConfiguredLayers, configuredLayers } = useContext(TemplateContext);

	useEffect(() => {
		if (variation.layerSpecs && variation.layerSpecs.length > 0) {
			setConfiguredLayers((prev) => {
				const existingVariation = prev.find(
					(variationObj) => variationObj.variationId === variation.id,
				);

				if (!existingVariation) {
					const separatedLayerSpecs: ILayerSpec[] =
						variation.layerSpecs!.flatMap((layerSpec) => {
							if (layerSpec.actions && layerSpec.actions.length > 1) {
								return layerSpec.actions.map((action) => ({
									...layerSpec,
									actions: [action],
								}));
							}

							return layerSpec;
						});

					const newConfiguredLayer = {
						variationId: variation.id!,
						layerSpec: separatedLayerSpecs,
					};

					return [...prev, newConfiguredLayer];
				}

				return prev;
			});
		} else {
		}
	}, [variation.layerSpecs]);
	const handleAddLayer = (newLayerSpec: ILayerSpec) => {
		setConfiguredLayers((prev) => {
			const existingVariation = prev.find(
				(variationObj) => variationObj.variationId === variation.id,
			);

			if (existingVariation) {
				return prev.map((variationObj) =>
					variationObj.variationId === variation.id
						? {
								variationId: variation.id!,
								layerSpec: [...variationObj.layerSpec, newLayerSpec],
						  }
						: variationObj,
				);
			} else {
				return [
					...prev,
					{ variationId: variation.id!, layerSpec: [newLayerSpec] },
				];
			}
		});
	};

	const handleUpdateLayer = (layerSpec: ILayerSpec, index: number) => {
		setConfiguredLayers((prev) => {
			const existingVariation = prev.find(
				(variationObj) => variationObj.variationId === variation.id,
			);

			if (existingVariation) {
				return prev.map((variationObj) =>
					variationObj.variationId === variation.id
						? {
								...variationObj,
								layerSpec: variationObj.layerSpec.map((layer, layerIndex) =>
									layerIndex === index ? layerSpec : layer,
								),
						  }
						: variationObj,
				);
			} else {
				return prev;
			}
		});
	};

	const handleRemoveLayer = (index: number) => {
		setConfiguredLayers((prev) => {
			const existingVariation = prev.find(
				(variationObj) => variationObj.variationId === variation.id,
			);

			if (existingVariation) {
				const updatedLayerSpec = [...existingVariation.layerSpec];

				const newLayerSpec = updatedLayerSpec.filter(
					(_, layerIndex) => layerIndex !== index,
				);

				return prev.map((variationObj) =>
					variationObj.variationId === variation.id
						? {
								variationId: variation.id!,
								layerSpec: newLayerSpec,
						  }
						: variationObj,
				);
			} else {
				return prev;
			}
		});
	};

	const currentConfiguredLayer = configuredLayers.find(
		(layerObj) => layerObj.variationId === variation.id,
	);

	const mappedLayers = currentConfiguredLayer
		? currentConfiguredLayer.layerSpec
		: [];

	return (
		<Box>
			{mappedLayers.map((layer, layerIndex) => (
				<Box key={`layer-${layerIndex}-${layer.name}`} mb={4}>
					<ConfigureLayers
						action={layer?.actions![0]}
						layer={layer}
						index={layerIndex}
						onUpdateLayer={handleUpdateLayer}
						onRemoveLayer={handleRemoveLayer}
					/>
				</Box>
			))}
			<Flex gap={5} mt={10}>
				<Button
					size="xs"
					fontSize="12px"
					p={2}
					colorScheme="blue"
					onClick={() => handleAddLayer({ name: '', actions: [{ id: '' }] })}
				>
					Add new
				</Button>
			</Flex>
		</Box>
	);
};
