import { FC } from 'react';
import { Tag, TagLabel, TagProps } from '@chakra-ui/react';

interface ChipProps {
	label: string;
	tagProps?: TagProps;
}

const Chip: FC<ChipProps> = ({ label, tagProps }) => {
	return (
		<Tag borderRadius="full" {...tagProps}>
			<TagLabel fontSize="12px" fontWeight={400} lineHeight="20px">
				{label}
			</TagLabel>
		</Tag>
	);
};

export default Chip;
