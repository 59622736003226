import {
	FieldPath,
	FieldValues,
	get,
	useController,
	useFormContext,
} from 'react-hook-form';

import TextareaInput, { TextareaInputProps } from './TextareaInput';
import {
	IAssistantCreativeData,
	ICreative,
	IDesignDirection,
	IField,
	IGetThreadParams,
	TextLayer,
	ThreadTypesEnum,
} from 'src/lib/schemas';
import { useContext } from 'react';
import { AssistantChatContext, CampaignContext } from 'src/contexts';

export interface TextareaInputHookProps<T extends FieldValues>
	extends TextareaInputProps {
	requirementsLabel?: string;
	name: FieldPath<T>;
	layer?: TextLayer;
	setFieldValue?: (field: string, value: string) => void;
	withAssistant?: boolean;
	displayName?: string;
	creativeData?: IAssistantCreativeData;
	errorMessage?: string;
	direction?: 'row' | 'column';
	requirementsLabelStyle?: React.CSSProperties;
}

function TextareaInputHook<T extends FieldValues = FieldValues>({
	name,
	layer,
	requirementsLabel,
	setFieldValue,
	withAssistant,
	displayName,
	creativeData,
	errorMessage,
	direction,
	requirementsLabelStyle,
	...props
}: TextareaInputHookProps<T>) {
	const {
		control,
		register,
		formState: { errors },
	} = useFormContext();
	const { field } = useController({ control, name });
	const { value: content } = field;
	const { id: campaignId } = useContext(CampaignContext);
	const { openAssistantChat } = useContext(AssistantChatContext);
	const error = get(errors, name);

	const registerProps = register(name);

	const handleOpenAssistantChat = async (ref?: HTMLDivElement) => {
		const threadParams: IGetThreadParams = {
			type: ThreadTypesEnum.Campaign,
			reference: campaignId!,
			sub_reference: creativeData?.id ?? name,
			property: name ?? props.id,
		};
		const fieldParams: IField = {
			displayName: props.label ?? displayName ?? '',
			name,
			content,
			id: name ?? props.id,
			variant: creativeData?.variant,
			channel: creativeData?.channel,
			setValue: setFieldValue!,
		};
		openAssistantChat({ threadParams, fieldParams, ref });
	};

	return (
		<TextareaInput
			id={name}
			handleOpenAssistantChat={handleOpenAssistantChat}
			requirementsLabelStyle={requirementsLabelStyle}
			error={errorMessage && error?.message ? errorMessage : error?.message}
			requirementsLabel={requirementsLabel}
			valueLength={content?.length ?? 0}
			withAssistant={withAssistant}
			content={content}
			direction={direction}
			{...props}
			textareaProps={{
				minHeight: '80px',
				...props.textareaProps,
				...registerProps,
				onBlur: (e: any) => {
					registerProps.onBlur(e);
					props.textareaProps?.onBlur?.(e);
				},
				onChange: (e: any) => {
					registerProps.onChange(e);
					props.textareaProps?.onChange?.(e);
				},
			}}
		/>
	);
}

export default TextareaInputHook;
