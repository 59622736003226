import { WarningIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Icon, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface WarningMessageProps {
	message: string;
	boxProps?: BoxProps;
}

const WarningMessage: FC<WarningMessageProps> = ({ message, boxProps }) => {
	return (
		<Box display="flex" gap={1} {...boxProps}>
			<Box>
				<Icon as={WarningIcon} color="#ECA928" />
			</Box>
			<Text flex={1} color="#ECA928">
				{message}
			</Text>
		</Box>
	);
};

export default WarningMessage;
