import {
	FieldValues,
	FieldPath,
	useController,
	useFormContext,
} from 'react-hook-form';
import MarketingChannelInput, {
	MarketingChannelInputProps,
} from './MarketingChannelInput';
import { useEffect } from 'react';

interface MarketingChannelInputHookProps<T extends FieldValues>
	extends MarketingChannelInputProps {
	name: FieldPath<T>;
	isSelected?: boolean;
}

function MarketingChannelInputtHook<T extends FieldValues = FieldValues>({
	name,
	isSelected,
	...props
}: MarketingChannelInputHookProps<T>) {
	const { control } = useFormContext();
	const { field } = useController({ control, name });
	const { onChange, value } = field;

	useEffect(() => {
		isSelected && onChange(true);
	}, [isSelected]);

	const handleChange = (val: boolean) => {
		onChange(val);
	};

	return (
		<MarketingChannelInput
			onChange={handleChange}
			name={name}
			value={value}
			{...props}
		/>
	);
}

export default MarketingChannelInputtHook;
