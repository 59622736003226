import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button, Skeleton, Flex, Box } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IIndustry } from 'src/services/industries';
import TemplateContext from 'src/contexts/templates/TemplatesContext';

interface IndustryOption {
	label: string;
	value: string;
}

interface IndustryLabelSelectorProps {
	selectedScope: string;
	onScopeChange: (scope: string) => void;
	templatesByScope: any[];
	templates: any[];
	industries: IIndustry[];
	industriesLoaded: boolean;
}

const IndustryLabelSelector: React.FC<IndustryLabelSelectorProps> = ({
	selectedScope,
	onScopeChange,
	templatesByScope,
	templates,
	industries,
	industriesLoaded,
}) => {
	const [industryOptions, setIndustryOptions] = useState<
		IndustryOption[] | null
	>(null);
	const [isScrollable, setIsScrollable] = useState<boolean>(false);
	const [isAtStart, setIsAtStart] = useState<boolean>(true);
	const { isOptionsReady } = useContext(TemplateContext);
	const [isAtEnd, setIsAtEnd] = useState<boolean>(false);

	const scrollContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!isOptionsReady) return;

		try {
			const platformTemplates = templatesByScope.filter(
				(template) => template.scope === 'platform',
			);

			const templateIndustryIds = platformTemplates.flatMap(
				(template) => template.attributes?.industries || [],
			);

			const formattedIndustries = [
				{ label: 'All', value: 'all' },
				...(templates.some((template) => template.scope === 'account')
					? [{ label: 'My templates', value: 'my_templates' }]
					: []),
				...industries
					.filter((industry) =>
						templateIndustryIds.includes(industry.industryIds[0]),
					)
					.map((industry) => ({
						label: industry.name,
						value: industry.industryIds[0],
					})),
			];

			setIndustryOptions(formattedIndustries);
		} catch (error) {
			console.error('Error processing industries', error);
		}
	}, [
		industriesLoaded,
		templatesByScope,
		templates,
		industries,
		isOptionsReady,
	]);

	useEffect(() => {
		const scrollContainer = scrollContainerRef.current;
		if (!scrollContainer) return;

		let isDown = false;
		let startX: number;
		let scrollLeft: number;

		const mouseDownHandler = (e: MouseEvent) => {
			isDown = true;
			startX = e.pageX - scrollContainer.offsetLeft;
			scrollLeft = scrollContainer.scrollLeft;
			scrollContainer.classList.add('active');
		};

		const mouseMoveHandler = (e: MouseEvent) => {
			if (!isDown) return;
			e.preventDefault();
			const x = e.pageX - scrollContainer.offsetLeft;
			const walk = (x - startX) * 2;
			scrollContainer.scrollLeft = scrollLeft - walk;
		};

		const mouseUpHandler = () => {
			isDown = false;
			scrollContainer.classList.remove('active');
		};

		const mouseLeaveHandler = () => {
			isDown = false;
			scrollContainer.classList.remove('active');
		};

		scrollContainer.addEventListener('mousedown', mouseDownHandler);
		scrollContainer.addEventListener('mousemove', mouseMoveHandler);
		scrollContainer.addEventListener('mouseup', mouseUpHandler);
		scrollContainer.addEventListener('mouseleave', mouseLeaveHandler);

		return () => {
			scrollContainer.removeEventListener('mousedown', mouseDownHandler);
			scrollContainer.removeEventListener('mousemove', mouseMoveHandler);
			scrollContainer.removeEventListener('mouseup', mouseUpHandler);
			scrollContainer.removeEventListener('mouseleave', mouseLeaveHandler);
		};
	}, []);

	useEffect(() => {
		const scrollContainer = scrollContainerRef.current;
		if (!scrollContainer) return;

		const wheelHandler = (e: WheelEvent) => {
			if (e.deltaY !== 0) {
				e.preventDefault();
				scrollContainer.scrollLeft += e.deltaY;
			}
		};

		scrollContainer.addEventListener('wheel', wheelHandler);

		return () => {
			scrollContainer.removeEventListener('wheel', wheelHandler);
		};
	}, []);

	useEffect(() => {
		const scrollContainer = scrollContainerRef.current;
		if (!scrollContainer) return;

		const handleScroll = () => {
			const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
			setIsAtStart(scrollLeft === 0);
			setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
		};

		scrollContainer.addEventListener('scroll', handleScroll);
		handleScroll();

		return () => {
			scrollContainer.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		const checkScrollable = () => {
			if (scrollContainerRef.current && industryOptions !== null) {
				const { scrollWidth, clientWidth, scrollLeft } =
					scrollContainerRef.current;
				setIsScrollable(scrollWidth > clientWidth);
				setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
			}
		};
		checkScrollable();
		window.addEventListener('resize', checkScrollable);
		return () => {
			window.removeEventListener('resize', checkScrollable);
		};
	}, [industryOptions]);

	const handleScrollLeft = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: -200,
				behavior: 'smooth',
			});
		}
	};

	const handleScrollRight = () => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: 200,
				behavior: 'smooth',
			});
		}
	};

	return (
		<Box position="relative">
			{isScrollable && !isAtStart && (
				<Button
					position="absolute"
					top="50%"
					left="0"
					transform="translateY(-50%)"
					zIndex="1"
					onClick={handleScrollLeft}
					bg="white"
					borderRadius="full"
					boxShadow="md"
					width="30px"
					height="30px"
					minWidth="auto"
					padding="0"
					_hover={{ bg: 'gray.100' }}
				>
					<ChevronLeftIcon w={6} h={6} color="black" />
				</Button>
			)}
			<Flex
				ref={scrollContainerRef}
				gap={2}
				overflowX="auto"
				whiteSpace="nowrap"
				sx={{
					'::-webkit-scrollbar': { display: 'none' },
				}}
			>
				{!isOptionsReady || industryOptions === null
					? [...Array(5)].map((_, index) => (
							<Skeleton
								key={index}
								height="40px"
								width="100px"
								borderRadius="full"
								mx={2}
							/>
					  ))
					: industryOptions.map((industry) => (
							<Button
								key={industry.value}
								colorScheme={
									selectedScope === industry.value ? '#FDDACE' : 'gray'
								}
								onClick={() => onScopeChange(industry.value)}
								minWidth="auto"
								whiteSpace="nowrap"
								px={4}
								py={2}
								fontWeight={400}
								borderRadius="full"
								fontSize="14px"
								bg={
									selectedScope === industry.value
										? 'rgba(255, 231, 217, 1)'
										: 'transparent'
								}
								color={
									selectedScope === industry.value ? 'gray.900' : 'gray.500'
								}
								_hover={{
									bg:
										selectedScope === industry.value
											? 'rgba(255, 231, 217, 1)'
											: 'rgba(230, 230, 230, 0.3)',
								}}
							>
								{industry.label}
							</Button>
					  ))}
			</Flex>
			{isScrollable && !isAtEnd && (
				<Button
					position="absolute"
					top="50%"
					right="0"
					transform="translateY(-50%)"
					zIndex="1"
					onClick={handleScrollRight}
					bg="white"
					borderRadius="full"
					boxShadow="md"
					width="30px"
					height="30px"
					minWidth="auto"
					padding="0"
					_hover={{ bg: 'gray.100' }}
				>
					<ChevronRightIcon w={6} h={6} color="black" />
				</Button>
			)}
		</Box>
	);
};

export default IndustryLabelSelector;
