import { Text, Box } from '@chakra-ui/react';
import { FC } from 'react';

interface IMessage {
	content: string;
}

const Message: FC<IMessage> = ({ content }) => {
	return (
		<Text w="fit-content" maxW={350} alignContent="center">
			{content}
		</Text>
	);
};

export default Message;
