import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { IAppActionOutput } from 'src/lib/schemas';
import FusionLoading from 'src/components/common/FusionLoading';
import CopyToClipboard from 'src/components/common/CopyToClipboard';
import { mapCallToAction } from '../../utils/misc';

interface TextOutputWidgetProps {
	isParent?: boolean;
	output: IAppActionOutput;
	value: string;
	isProcessing: boolean;
	view?: string;
}
const TextOutputWidget: FC<TextOutputWidgetProps> = ({
	isParent,
	output,
	value,
	isProcessing,
	view,
}) => {
	const isLoading = isProcessing && !value;
	const parsedValue =
		output.property === 'callToAction' ? mapCallToAction(value) : value;
	return (
		<Flex direction="column" gap={1}>
			<Text fontWeight={isParent ? 'semibold' : 500}>{output.name}</Text>
			<Flex
				alignItems="center"
				border={view === 'text' ? 'none' : '1px solid #E2E8F0'}
				justifyContent={view === 'text' ? '' : 'space-between'}
				borderRadius="sm"
			>
				<FusionLoading isLoading={isLoading} />
				{!isLoading && (
					<Text pl={2} py={2}>
						{parsedValue}
					</Text>
				)}
				{!isLoading && <CopyToClipboard value={parsedValue} />}
			</Flex>
		</Flex>
	);
};

export default TextOutputWidget;
