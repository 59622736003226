import { Image } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

import { ICatalog } from 'src/lib/schemas';
import {
	DataTableActions,
	DataTableActionsProps,
} from 'src/components/common/DataTable';

export interface ICatalogsListingColumn extends ICatalog {
	image: string;
	action?: unknown;
}

const columnHelper = createColumnHelper<ICatalogsListingColumn>();
const DEFAULT_IMAGE = 'https://img.clevup.in/static/placeholder.jpg';

export const getProductsListingColumns = (
	onAction: DataTableActionsProps<ICatalog>['onAction'],
) => {
	return [
		columnHelper.accessor('image', {
			header: 'Image',
			cell: (info) => {
				return (
					<Image
						w="48px"
						h="48px"
						borderRadius="md"
						objectFit="contain"
						src={info.row.original.image || DEFAULT_IMAGE}
						alt={info.row.original.name || 'Product image'}
					/>
				);
			},
		}),
		columnHelper.accessor('name', {
			header: 'Name',
		}),
		// columnHelper.accessor('kind', {
		// 	header: 'Kind',
		// 	meta: {
		// 		ThProps: {
		// 			bg: '#F5F5F5',
		// 		},
		// 	},
		// }),
		// columnHelper.accessor('category', {
		// 	header: 'Category',
		// }),
		// columnHelper.accessor('brand', {
		// 	header: 'Brand',
		// }),
		columnHelper.accessor('url', {
			header: 'URL',
		}),
		columnHelper.accessor('action', {
			header: 'Action',
			cell: (info) => {
				return (
					<DataTableActions
						data={info.row.original}
						onAction={onAction}
						actionsAllowed={['Edit', 'Delete', 'Create campaign']}
					/>
				);
			},
			meta: {
				ThProps: {
					width: '100px',
				},
				TdProps: {
					textAlign: 'center',
				},
			},
		}),
	];
};
