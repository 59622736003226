import { useState, useEffect } from 'react';

const usePaginationByOffset = (
	total: number,
	offset: number,
	limit: number,
) => {
	const [page, setPage] = useState(1);
	const [pages, setPages] = useState(1);

	useEffect(() => {
		const totalPages = Math.ceil(total / limit);
		setPages(totalPages);
	}, [total, limit]);

	const nextPage = () => {
		setPage((currentPage) => Math.min(currentPage + 1, pages));
	};

	const prevPage = () => {
		setPage((currentPage) => Math.max(currentPage - 1, 1));
	};

	const goToPage = (pageNumber: number) => {
		setPage(pageNumber);
	};

	const handleSetPages = (totalPages: number) => {
		setPages(totalPages);
	};

	const handleSetPage = (page: number) => {
		setPage(page);
	};

	return {
		page,
		pages,
		setPage: handleSetPage,
		setPages: handleSetPages,
		nextPage,
		prevPage,
		goToPage,
		offset: (page - 1) * limit,
		limit,
	};
};

export default usePaginationByOffset;
