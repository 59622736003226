import { FC, ReactNode, forwardRef } from 'react';
import {
	Flex,
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	Text,
	VStack,
} from '@chakra-ui/react';
import Select, {
	ControlProps,
	DropdownIndicatorProps,
	GroupBase,
	OptionProps,
	StylesConfig,
	components,
} from 'react-select';
import {
	ArrowForwardIcon,
	ChevronDownIcon,
	InfoIcon,
	SearchIcon,
} from '@chakra-ui/icons';

import { IOption } from 'src/lib/schemas/misc';

export interface MultiSelectInputProps {
	name: string;
	label?: string;
	id?: string;
	placeholder?: string;
	error?: ReactNode;
	required?: boolean;
	isLoading?: boolean;
	options: IOption[];
	value?: IOption['value'][] | IOption[];
	onChange?: (value: IOption['value'][] | IOption[]) => void;
	valueAsObject?: boolean;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	requirementsLabel?: string;
	direction?: 'row' | 'column';
}

const Control: FC<ControlProps> = ({ children, ...props }) => (
	<components.Control {...props}>
		<SearchIcon mx={2} color="#B8C2D0" /> {children}
	</components.Control>
);

const Option: FC<OptionProps> = ({ children, ...props }) => (
	<components.Option {...props}>
		<Flex justify="space-between">
			{children}
			<ArrowForwardIcon boxSize={5} color="#2C6ECB" alignSelf="right" />
		</Flex>
	</components.Option>
);

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<ChevronDownIcon w={5} h={5} />
		</components.DropdownIndicator>
	);
	return (
		<components.DropdownIndicator {...props}>
			<ChevronDownIcon w={5} h={5} />
		</components.DropdownIndicator>
	);
};

const MultiSelectInput = (
	{
		error,
		label,
		placeholder,
		options,
		value,
		required,
		isLoading,
		onChange,
		formControlProps,
		formLabelProps,
		valueAsObject,
		requirementsLabel,
		direction,
	}: MultiSelectInputProps,
	ref: any,
) => {
	const hasError = !!error;

	const elements = document.querySelectorAll('[class*="container"]');

	elements.forEach((element) => {
		(element as HTMLElement).style.width = '100%';
	});

	const renderLabelIndicator = () => {
		if (!required) return null;
		if (error) return <InfoIcon color="#e53e3e" />;
		return <Text color="#e53e3e">*</Text>;
	};

	const selectedValue = valueAsObject
		? value
		: (value?.map((v) =>
				options.find((o) => o.value === v.toString()),
		  ) as IOption[]);

	return (
		<FormControl isInvalid={hasError} {...formControlProps}>
			<Flex direction={direction} w="full">
				<Flex flex={1}>
					{label && (
						<FormLabel
							fontSize="sm"
							fontWeight="medium"
							mb={2}
							{...formLabelProps}
						>
							<Flex gap={1} alignItems="center">
								{label}
								{renderLabelIndicator()}
							</Flex>
							{requirementsLabel && (
								<Text fontSize="12px" color="#959595">
									{requirementsLabel}
								</Text>
							)}
						</FormLabel>
					)}
				</Flex>
				<VStack flex={1} w="full" alignItems="flex-start">
					<Select
						placeholder={placeholder}
						options={options}
						value={selectedValue}
						onChange={(val) => {
							if (!onChange) return;
							const valueSelected = val as IOption[];
							if (valueAsObject) {
								onChange(valueSelected);
								return;
							} else {
								onChange(valueSelected.map((v) => v.value));
							}
						}}
						components={{
							DropdownIndicator,
							Control,
							Option,
						}}
						styles={{
							...styles,
							...(hasError ? stylesWithErros : { control: styles.control }),
						}}
						controlShouldRenderValue={false}
						ref={ref}
						noOptionsMessage={() => `No element found`}
						isLoading={isLoading}
						isMulti
					/>
					{error && <FormErrorMessage mt={1}>{error}</FormErrorMessage>}
				</VStack>
			</Flex>
		</FormControl>
	);
};

const styles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
	control: (baseStyles, state) => ({
		...baseStyles,
		padding: 0,
		minWidth: '100%',
		minHeight: '40px',
		marginTop: '9px',
		height: '40px',
		borderColor: 'inherit',
		borderRadius: '5px',
		boxShadow: state.isFocused ? '0 0 0 1px #2C6ECB' : 'none',
		border: state.isFocused ? '1px solid #2C6ECB' : '1px solid #E2E8F0',
	}),
	valueContainer: (baseStyles) => ({
		...baseStyles,
		minHeight: '40px',
		height: '40px',
		padding: 0,
		paddingRight: '10px',
	}),
	input: (baseStyles) => ({
		...baseStyles,
		margin: 0,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#202223',
		padding: 0,
	}),
	placeholder: (baseStyles) => ({
		...baseStyles,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#A0AEC0',
	}),
	indicatorsContainer: (baseStyles) => ({
		...baseStyles,
		padding: '0 10px',
	}),
	dropdownIndicator: () => ({
		color: '#202223',
	}),
	clearIndicator: () => ({
		display: 'none',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	option: (baseStyles) => ({
		...baseStyles,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#202223',
		padding: '8px 16px 8px 16px',
		borderBottom: '1px solid #E2E8F0',
	}),
};

const stylesWithErros: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
	control: (baseStyles) => ({
		...baseStyles,
		padding: 0,
		minHeight: '40px',
		height: '40px',
		borderRadius: '5px',
		boxShadow: '0 0 0 1px #E53E3E',
		borderColor: '#E53E3E',
		'&:hover': {
			boxShadow: '0 0 0 1px #E53E3E',
			borderColor: '#E53E3E',
		},
	}),
};

export default forwardRef(MultiSelectInput);
