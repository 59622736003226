import { FC, useState } from 'react';
import {
	Text,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverHeader,
	PopoverContent,
	PopoverTrigger,
	useDisclosure,
	Button,
	Box,
	Flex,
	NumberInput,
	NumberInputField,
	PopoverCloseButton,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from '@chakra-ui/react';

import { WarningIcon } from 'src/assets/icons';
import { formatCurrency, formatNumber } from 'src/lib/utils';

interface EditCellPopoverProps {
	id: string;
	value: number;
	level: 'group' | 'channel' | 'overall';
	budget: number;
	currentBudget: number;
	onSubmit: (
		id: string,
		value: number,
		level: 'group' | 'channel' | 'overall',
	) => void;
}

const EditCellPopover: FC<EditCellPopoverProps> = ({
	id,
	value,
	level,
	budget,
	currentBudget,
	onSubmit,
}) => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	const [input, setInput] = useState(formatNumber(value, 0));

	const isOverall = level === 'overall';

	const prefix = '$';

	const fomrattedValue = formatCurrency(formatNumber(value, 0));

	const isUnderBudget = currentBudget < budget;
	const budgetdDiff = budget - currentBudget;

	const maxValue = formatNumber(budgetdDiff + value, 0);

	const valueAdjustment = formatCurrency(formatNumber(budgetdDiff + value));

	const handleClose = () => {
		setInput(value);
		onClose();
	};

	return (
		<Popover
			isOpen={isOpen}
			onClose={handleClose}
			placement="bottom-start"
			preventOverflow={true}
		>
			<PopoverTrigger>
				<Text
					cursor="pointer"
					display="inline-block"
					color="#3182CE"
					onClick={onToggle}
				>
					{fomrattedValue}
				</Text>
			</PopoverTrigger>
			<PopoverContent
				borderRadius="lg"
				w="420px"
				px={2}
				py={2}
				pb={3}
				rootProps={{
					zIndex: 1600,
				}}
				_focusVisible={{
					outline: 'none',
				}}
			>
				<PopoverHeader fontWeight="semibold" border={0}>
					{isOverall
						? 'Enter new overall budget'
						: 'Enter new channel split amount'}
				</PopoverHeader>
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverBody>
					<Flex gap={2} justifyItems="center">
						<Box>
							<WarningIcon boxSize="16px" />
						</Box>
						<Text flex={1} color="#ECA928">
							{isOverall
								? 'Editing this value will cause the channel split amounts to regenerate.'
								: 'Editing this value will cause the other channel split amount to regenerate.'}
						</Text>
					</Flex>
					<Flex mt={4} alignItems="center" gap={2}>
						<Box width="120px">
							<NumberInput
								step={1}
								size="sm"
								placeholder="Enter value"
								value={input}
								onChange={(_val, numVal) => {
									if (Number.isNaN(numVal)) {
										setInput(0);
										return;
									} else if (!isOverall && numVal > maxValue) {
										setInput(value);
										return;
									}
									setInput(numVal);
								}}
								min={0}
								max={isOverall ? undefined : maxValue}
							>
								<NumberInputField />
								<NumberInputStepper>
									<NumberIncrementStepper />
									<NumberDecrementStepper />
								</NumberInputStepper>
							</NumberInput>
						</Box>
						<Text>{prefix}</Text>
						<Button
							width="100px"
							ml={4}
							size="sm"
							colorScheme="secondary"
							onClick={() => {
								onSubmit(id, Number(input), level);
								onClose();
							}}
						>
							Apply
						</Button>
					</Flex>
					{!isOverall && isUnderBudget && (
						<Text size="xs" mt={1} fontWeight={300} color="gray">
							Maximum available: {valueAdjustment}
						</Text>
					)}
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default EditCellPopover;
