import React, { FC, useState, useRef, useMemo, useEffect } from 'react';
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Input,
	InputGroup,
	InputLeftElement,
	Text,
	Popover,
	PopoverTrigger,
	PopoverContent,
	VStack,
	HStack,
} from '@chakra-ui/react';

interface FilterDropdownProps {
	label: string;
	options: Array<{ label: string; value: string }>;
	selectedOptions: string[];
	onChange: (selected: string[]) => void;
}

const FilterDropdown: FC<FilterDropdownProps> = ({
	label,
	options,
	selectedOptions,
	onChange,
}) => {
	const [searchQuery, setSearchQuery] = useState('');
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const optionsContainerRef = useRef<HTMLDivElement | null>(null);

	const [sortedOptions, setSortedOptions] = useState(options);

	const originalOrderRef =
		useRef<Array<{ label: string; value: string }>>(options);

	useEffect(() => {
		originalOrderRef.current = options;
		if (!isPopoverOpen) {
			setSortedOptions(options);
		}
	}, [options, isPopoverOpen]);

	useEffect(() => {
		if (isPopoverOpen) {
			const sorted = [
				...originalOrderRef.current.filter((option) =>
					selectedOptions.includes(option.value),
				),
				...originalOrderRef.current.filter(
					(option) => !selectedOptions.includes(option.value),
				),
			];
			setSortedOptions(sorted);
			if (optionsContainerRef.current) {
				optionsContainerRef.current.scrollTop = 0;
			}
		} else {
			setSortedOptions(originalOrderRef.current);
			setSearchQuery('');
			if (optionsContainerRef.current) {
				optionsContainerRef.current.scrollTop = 0;
			}
		}
	}, [isPopoverOpen]);

	const filteredOptions = useMemo(() => {
		return sortedOptions.filter((option) =>
			option.label.toLowerCase().includes(searchQuery.toLowerCase()),
		);
	}, [sortedOptions, searchQuery]);

	const handleOptionClick = (optionValue: string) => {
		const isSelected = selectedOptions.includes(optionValue);
		let newSelectedOptions: string[];

		if (isSelected) {
			newSelectedOptions = selectedOptions.filter(
				(option) => option !== optionValue,
			);

			const optionIndexInSorted = sortedOptions.findIndex(
				(option) => option.value === optionValue,
			);
			if (
				optionIndexInSorted !== -1 &&
				optionIndexInSorted < selectedOptions.length
			) {
				const originalOption = originalOrderRef.current.find(
					(option) => option.value === optionValue,
				);
				if (originalOption) {
					const originalIndex = originalOrderRef.current.findIndex(
						(option) => option.value === optionValue,
					);

					const unselectedOptions = originalOrderRef.current.filter(
						(option) =>
							!selectedOptions.includes(option.value) ||
							option.value === optionValue,
					);

					const insertionIndex =
						unselectedOptions
							.slice(0, originalIndex)
							.filter((option) => !newSelectedOptions.includes(option.value))
							.length + selectedOptions.length;

					const updatedSortedOptions = sortedOptions.filter(
						(option) => option.value !== optionValue,
					);

					updatedSortedOptions.splice(insertionIndex, 0, originalOption);

					setSortedOptions(updatedSortedOptions);
				}
			}
		} else {
			newSelectedOptions = [...selectedOptions, optionValue];
			onChange(newSelectedOptions);
			return;
		}

		onChange(newSelectedOptions);
	};

	const handlePopoverClose = () => {
		setIsPopoverOpen(false);
	};

	return (
		<Popover
			isOpen={isPopoverOpen}
			onOpen={() => setIsPopoverOpen(true)}
			onClose={handlePopoverClose}
			placement="bottom-start"
			offset={[0, 7]}
		>
			<PopoverTrigger>
				<Button
					variant="outline"
					bg={
						isPopoverOpen || selectedOptions.length > 0 ? '#FFE8E0' : 'gray.50'
					}
					borderRadius="5px"
					borderColor={
						isPopoverOpen || selectedOptions.length > 0 ? '#ffccbc' : 'gray.200'
					}
					color={
						isPopoverOpen || selectedOptions.length > 0 ? '#F7480B' : 'inherit'
					}
					_hover={
						isPopoverOpen || selectedOptions.length > 0
							? {}
							: { bg: 'gray.200', borderColor: 'gray.300' }
					}
					_active={selectedOptions.length > 0 ? { bg: '#FFE8E0' } : {}}
					rightIcon={<ChevronDownIcon />}
					fontFamily="Noto Sans"
					_focusVisible={{ outline: 'none' }}
					fontWeight="medium"
					fontSize="14px"
					height="10px"
					px={3}
					py={0}
				>
					{label}
					{selectedOptions.length > 0 && (
						<Box
							as="span"
							ml={1}
							bg="#F7480B"
							color="white"
							borderRadius="100px"
							width="20px"
							height="20px"
							display="flex"
							alignItems="center"
							justifyContent="center"
							fontSize="12px"
							fontWeight="bold"
						>
							{selectedOptions.length}
						</Box>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent
				width="280px"
				pt="1.5"
				px="1.5"
				pb="2"
				bg="#FFE8E0"
				border="none"
				borderRadius="5px"
			>
				<VStack spacing={1} align="start">
					<Box width="full" mt={0}>
						<InputGroup>
							<InputLeftElement pointerEvents="none">
								<SearchIcon color="gray.500" />
							</InputLeftElement>
							<Input
								placeholder="Search"
								paddingLeft="8"
								borderRadius="5px"
								borderColor="#FCB69D"
								focusBorderColor="#FCB69D"
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
								bg="#FFF7F4"
								_hover={{ borderColor: '#FCB69D' }}
								fontFamily="Noto Sans"
								fontSize="14px"
							/>
						</InputGroup>
					</Box>
					<VStack
						ref={optionsContainerRef}
						align="start"
						spacing={0}
						width="full"
						maxHeight="210px"
						overflowY="auto"
					>
						{filteredOptions.length > 0 ? (
							filteredOptions.map((option) => {
								const isSelected = selectedOptions.includes(option.value);
								return (
									<HStack
										key={option.value}
										width="full"
										paddingY="2"
										paddingX="2"
										marginY="0"
										borderRadius="3px"
										_hover={{ bg: '#FED0BF' }}
										cursor="pointer"
										onClick={() => handleOptionClick(option.value)}
									>
										<Box
											as="span"
											display="inline-block"
											width="18px"
											height="18px"
											minWidth="18px"
											borderRadius="3px"
											border="2px solid"
											borderColor={isSelected ? '#F7480B' : 'gray.300'}
											bg={isSelected ? '#F7480B' : 'white'}
											position="relative"
											_after={
												isSelected
													? {
															content: '""',
															position: 'absolute',
															top: '0px',
															left: '4px',
															width: '5px',
															height: '9px',
															border: 'solid white',
															borderWidth: '0 2px 2px 0',
															transform: 'rotate(45deg)',
													  }
													: {}
											}
											flexShrink={0}
										/>
										<Text
											userSelect="none"
											fontFamily="Noto Sans"
											fontSize="14px"
											fontWeight="normal"
											noOfLines={2}
										>
											{option.label}
										</Text>
									</HStack>
								);
							})
						) : options.length > 0 ? (
							<Box width="full" padding="2" textAlign="center">
								<Text fontFamily="Noto Sans" fontSize="14px" color="gray.500">
									No results for &quot;{searchQuery}&quot;
								</Text>
							</Box>
						) : null}
					</VStack>
				</VStack>
			</PopoverContent>
		</Popover>
	);
};

export default FilterDropdown;
