export const downloadFile = async (url: string, fileName: string) => {
	const result = await fetch(url);
	const blob = await result.blob();
	const fileExtension = url.split('.').pop();
	const link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.setAttribute('download', `${fileName}.${fileExtension}`);
	document.body.appendChild(link);
	link.click();
	link?.parentNode?.removeChild(link);
};
