import { useEffect, useState } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { StringInputHook } from '../common/form';
import AlertMessage from '../common/AlertMessage';
import { ForgotPasswordSchema, IForgotPasswordFrom } from 'src/lib/schemas';
import authService from 'src/services/auth';

const ForgotPasswordForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const formMethods = useForm<IForgotPasswordFrom>({
		resolver: zodResolver(ForgotPasswordSchema),
	});

	const { isSubmitted } = formMethods.formState;

	const watchedEmail = formMethods.watch('email');

	useEffect(() => {
		if (watchedEmail && watchedEmail !== watchedEmail.trim()) {
			formMethods.setValue('email', watchedEmail.trim());
		}
	}, [watchedEmail]);

	const onSubmit = formMethods.handleSubmit(async (formData) => {
		setError(null);
		setIsLoading(true);
		try {
			await authService.forgotPassword(formData);
		} catch (error: any) {
			const { message } = error.response.data;
			setError(message);
		}
		formMethods.reset({ email: '' });
		setIsLoading(false);
	});

	const showSuccessMessage = isSubmitted && !error && !isLoading;

	return (
		<FormProvider {...formMethods}>
			{error && <AlertMessage status="error">{error}</AlertMessage>}
			{showSuccessMessage && (
				<AlertMessage status="success">
					Reset password instructions has been sent to your email.
				</AlertMessage>
			)}
			<form onSubmit={onSubmit}>
				<Flex direction="column" gap="28px">
					<StringInputHook
						label="Email"
						name="email"
						placeholder="you@company.com"
						required
						inputProps={{ size: 'md', fontSize: '14px' }}
					/>
					<Button
						colorScheme="secondary"
						type="submit"
						loadingText="Submitting..."
						isLoading={isLoading}
					>
						Submit
					</Button>
				</Flex>
			</form>
		</FormProvider>
	);
};
export default ForgotPasswordForm;
