import { Box, Flex, useOutsideClick } from '@chakra-ui/react';
import { useContext, useEffect, useRef } from 'react';
import { AssistantChatContext } from 'src/contexts';
import Header from './header';
import Messages from './messages';
import Actions from './actions';
import { CHANNELS_NAME_MAPPING } from 'src/lib/constants/campaign';

const HEADER_HEIGHT = '40px';

const AssistantChat = () => {
	const {
		thread,
		field,
		isChatCollapsed,
		setIsChatCollapsed,
		markMessageAsSeen,
	} = useContext(AssistantChatContext);
	const chatBoxRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		!isChatCollapsed && markMessageAsSeen();
	}, [isChatCollapsed, thread]);

	useOutsideClick({
		ref: chatBoxRef,
		handler: () => setIsChatCollapsed(true),
	});

	const getTitle = field?.channel
		? `Option ${field?.variant} (${
				CHANNELS_NAME_MAPPING[field?.channel]
		  } / ${field?.displayName})`
		: field?.displayName;

	return (
		<Box
			ref={chatBoxRef}
			position="fixed"
			bottom={3}
			right={3}
			h={isChatCollapsed ? HEADER_HEIGHT : 500}
			w={isChatCollapsed ? 350 : 500}
			zIndex={10000}
			borderRadius={7}
			borderWidth={1}
			borderColor="#CDCDCD"
			bg="#F7FAFF"
		>
			<Flex
				cursor={isChatCollapsed ? 'pointer' : 'default'}
				direction="column"
				h="full"
			>
				<Header
					height={HEADER_HEIGHT}
					onClick={() => setIsChatCollapsed(!isChatCollapsed)}
					fieldName={getTitle}
					currentContent={field?.content}
				/>
				{!isChatCollapsed && thread && (
					<Messages conversation={thread.conversation} />
				)}
				{!isChatCollapsed && <Actions />}
			</Flex>
		</Box>
	);
};

export default AssistantChat;
