import { FC, useEffect } from 'react';
import { CloseIcon, InfoIcon } from '@chakra-ui/icons';
import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	IconButton,
	Text,
} from '@chakra-ui/react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
	DragDropContext,
	Draggable,
	DropResult,
	Droppable,
} from 'react-beautiful-dnd';

import { IAppActionInput } from 'src/lib/schemas';
import { CircledAddIcon, DragHandleIcon } from 'src/assets/icons';
import { StringInputHook } from 'src/components/common/form';

interface StringArrayInputWidgetProps {
	input: IAppActionInput;
	validationRules: Record<string, any>;
}

const StringArrayInputWidget: FC<StringArrayInputWidgetProps> = ({
	input,
	validationRules,
}) => {
	const { control, watch, setValue } = useFormContext();
	const items: string[] = watch(input.property) ?? [];

	const { fields, append, remove } = useFieldArray({
		control,
		name: input.property,
	});

	useEffect(() => {
		if (fields.length === 0) {
			append('');
		}
	}, [fields, append]);

	const handleAdd = () => {
		const newItems: string[] = [...items, ''];
		setValue(input.property, newItems);
	};

	const reorder = (list: any[], startIndex: number, endIndex: number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) return;
		setValue(
			input.property,
			reorder(items, result.source.index, result.destination.index),
		);
	};

	const required = validationRules.required;
	const error = null;
	// TODO: Errors are not being handled properly yet
	const hasError = !!error;
	const renderLabelIndicator = () => {
		if (!required) return null;

		if (error) return <InfoIcon color="#e53e3e" />;

		return <Text color="#e53e3e">*</Text>;
	};
	return (
		<Controller
			name={input.property}
			control={control}
			rules={validationRules}
			render={() => (
				<Flex direction="column" gap={3}>
					<FormControl isInvalid={hasError}>
						{input.name && (
							<FormLabel
								fontSize="sm"
								fontWeight="medium"
								display="inline-block"
							>
								<Flex gap={1} alignItems="center">
									{input.name}
									{renderLabelIndicator()}
								</Flex>
							</FormLabel>
						)}

						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="droppable">
								{(provided: any) => (
									<Flex
										direction="column"
										gap={2}
										pl={2}
										borderLeft="1px solid #E1E1E1"
										h={`${items.length * 48 - 8}px`}
										ref={provided?.innerRef}
										{...provided?.droppableProps}
									>
										{fields.map((item, index) => {
											return (
												<Draggable
													key={`${input.property}.${index}`}
													draggableId={`${input.property}.${index}`}
													index={index}
												>
													{(provided: any) => (
														<Flex
															key={index}
															alignItems="center"
															justifyContent="space-between"
															ref={provided.innerRef}
															{...provided.dragHandleProps}
															{...provided.draggableProps}
														>
															<Flex alignItems="center" gap={1} flex={1}>
																<DragHandleIcon />
																<StringInputHook
																	key={item.id}
																	name={`${input.property}[${index}]`}
																	placeholder={`Enter ${input.name.toLowerCase()}`}
																/>
																<IconButton
																	aria-label="remove"
																	bg="transparent"
																	onClick={() => remove(index)}
																	_hover={{ bg: 'transparent' }}
																	isDisabled={fields.length <= 1}
																>
																	<CloseIcon fontSize="xs" />
																</IconButton>
															</Flex>
														</Flex>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</Flex>
								)}
							</Droppable>
						</DragDropContext>
						<Flex alignItems="center" justifyContent={'flex-start'}>
							{error && <FormErrorMessage>{error}</FormErrorMessage>}
						</Flex>
						<Button
							size="sm"
							p={0}
							colorScheme="#F7480B"
							color="#F7480B"
							onClick={handleAdd}
							fontWeight={700}
							leftIcon={<CircledAddIcon color="#F7480B" />}
						>
							Add {input.name}
						</Button>
					</FormControl>
				</Flex>
			)}
		/>
	);
};

export default StringArrayInputWidget;
