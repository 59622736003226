import { Container, Flex } from '@chakra-ui/react';

import { AuthHeader, SignUpForm } from 'src/components/auth';

const SignUp = () => (
	<Container maxW="lg">
		<Flex direction="column" gap={12}>
			<AuthHeader
				title="Sign up for free"
				description="Get your own AI marketing team today"
			/>
			<Flex
				gap={12}
				direction={{ base: 'column', md: 'row' }}
				alignItems="center"
			>
				<SignUpForm />
				{/* <ProductFeatures /> */}
			</Flex>
		</Flex>
	</Container>
);

export default SignUp;
