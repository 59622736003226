import { Flex, Image, Text, Box } from '@chakra-ui/react';

const NothingToSee = () => (
	<Flex
		justifyContent="center"
		alignItems="center"
		gap={5}
		direction="column"
		h="full"
		textAlign="center"
	>
		<Image
			w={{ base: '90%', '2xl': '75%' }}
			src="images/nothing-to-see.png"
			alt="There are no campaigns"
		/>
		<Text>Nothing to see here</Text>
	</Flex>
);

export default NothingToSee;
