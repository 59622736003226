import { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';

import { CampaignContext } from 'src/contexts';
import { transformPartialFormToCampaign } from 'src/components/campaign/utils/transform';
import {
	getCampaignDelta,
	validateInitialPayload,
} from 'src/components/campaign/utils/delta';
import { ICampaign, ICampaignForm } from 'src/lib/schemas';
import { createOrUpdateCampaign } from 'src/services/campaign';
import { toastError } from 'src/services/toast';

const CAMPAIGN_FIELDS_OMIT = [
	'account',
	'status',
	'brief',
	'creatives',
	'schedule',
	'lastUpdatedBy',
	'updatedAt',
	'createdAt',
];

const useAutoSaveCampaign = () => {
	const {
		campaign,
		id: campaignId,
		setCampaign,
		isLaunching,
	} = useContext(CampaignContext);
	const [changedFields, setChangedFields] = useState<Partial<ICampaign>>({});
	const [isSaving, setIsSaving] = useState(false);
	const timeoutId = useRef<any>(null);
	const navigate = useNavigate();

	const cleanedCampaign = useMemo(() => {
		return _.omit(campaign, CAMPAIGN_FIELDS_OMIT);
	}, [campaign]);

	useEffect(() => {
		timeoutId.current = setTimeout(handleUpdateCampaign, 400);
		return () => clearTimeout(timeoutId.current);
	}, [changedFields]);

	const waitForSaving = () => {
		clearTimeout(timeoutId.current);
		timeoutId.current = setTimeout(handleUpdateCampaign, 400);
	};

	const isAllFieldsUndefined = (obj: any) => {
		return Object.values(obj).every((value) => value === undefined);
	};

	const handleUpdateCampaign = async () => {
		if (!campaignId || Object.keys(changedFields).length === 0) {
			setIsSaving(false);
			return;
		}
		if (isSaving) {
			waitForSaving();
			return;
		}
		setIsSaving(true);

		try {
			const delta = getCampaignDelta(cleanedCampaign, changedFields);
			const payload = _.omit(delta, ['id', 'status']);
			const { tone, goal, audience, promotion } = payload;
			// clean undefined fields
			if (goal === undefined) delete payload.goal;
			if (tone === undefined) delete payload.tone;
			if (audience === undefined) delete payload.audience;
			if (promotion === undefined) delete payload.promotion;

			const isEmptyPayload =
				_.isEmpty(payload) || isAllFieldsUndefined(payload);
			const isInvalidPayload = validateInitialPayload({
				...changedFields,
				group: undefined,
			});
			if (isEmptyPayload || isInvalidPayload) {
				setIsSaving(false);
				return;
			}

			if (campaign) setCampaign({ ...campaign, ...payload });
			const response = await createOrUpdateCampaign(payload, campaignId);
			setCampaign(response);
			if (campaignId === 'new') {
				// navigate(`/projects/campaigns/${response.id}?status=draft`, {
				// 	replace: true,
				// });
				window.history.replaceState(
					{},
					'',
					`/projects/campaigns/${response.id}?status=draft`,
				);
			}
		} catch (error: any) {
			const isTarget = error.response.data.message.includes('target');
			!isTarget && toastError(error);
		}
		setIsSaving(false);
	};

	const handleFieldsChange = (formData: Partial<ICampaignForm>) => {
		if (isLaunching) return;
		const transformedFormValues = transformPartialFormToCampaign(
			formData,
			campaign,
		);

		setChangedFields(transformedFormValues);
	};

	return { onFieldsChange: handleFieldsChange };
};

export default useAutoSaveCampaign;
