import { Flex } from '@chakra-ui/react';
import SignUpForm from './SignupForm';
import { FC } from 'react';
import Header from './Header';

interface IAdGeneration {
	urlWithoutPrefix: string;
	pendingCampaginGeneration: boolean;
	brandData: any;
}

const AdGeneration: FC<IAdGeneration> = ({
	urlWithoutPrefix,
	pendingCampaginGeneration,
	brandData,
}) => {
	return (
		<Flex direction="column">
			<Header
				step={2}
				title="Congratulations, you just created your first FREE ad!"
			/>
			<Flex flex={1} alignItems="center" order={{ base: 1, md: 2 }}>
				<SignUpForm
					urlWithoutPrefix={urlWithoutPrefix}
					pendingCampaginGeneration={pendingCampaginGeneration}
					brandData={brandData}
				/>
			</Flex>
		</Flex>
	);
};

export default AdGeneration;
