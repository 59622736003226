import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import Header from './Header';

const PublicLayout = () => {
	return (
		<Box w="100%">
			<Header />
			<Box
				minH="calc(100vh - 71px)"
				pt={{ base: '20px', md: '52px' }}
				pb={{ base: '50px', md: '100px' }}
				px={10}
			>
				<Outlet />
			</Box>
		</Box>
	);
};

export default PublicLayout;
