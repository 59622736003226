import { createContext } from 'react';
import { IAccountConfig } from 'src/lib/schemas';

interface IAccountConfigContext {
	isLoading: boolean;
	config: IAccountConfig | null;
	retries: number;
	setIsLoading: (isLoading: boolean) => void;
	setConfig: (config: IAccountConfig) => void;
}

const AccountConfigContext = createContext<IAccountConfigContext>({
	isLoading: false,
	config: null,
	retries: 0,
	setIsLoading: (isLoading: boolean) => {},
	setConfig: (config: IAccountConfig) => {},
});

export default AccountConfigContext;
