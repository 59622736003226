import { Box, Button, Flex, Image, Skeleton, Text } from '@chakra-ui/react';
import { IUpdatePayload } from 'src/contexts/templates/TemplatesContext';
import { useImageGeneration } from '../utils/useImageGeneration';
import { useEffect, useState } from 'react';

interface IImageItemProps {
	payload: IUpdatePayload;
	caseId: number;
}
export const ImageListItem = ({ payload, caseId }: IImageItemProps) => {
	const { regenerateImage } = useImageGeneration();
	const [loading, setLoading] = useState(false);

	const width = Number(payload.attributes.image.dimensions.width);
	const height = Number(payload.attributes.image.dimensions.height);
	const aspectRatio = width / height;
	const fixedWidth = '400px';

	const variationIdMap: { [key: string]: string } = {
		facebook_story: 'FB/IG story',
		facebook_feed: 'FB feed',
		design: 'Design direction',
		instagram_feed: 'IG feed',
	};

	const variationText =
		variationIdMap[payload.variationId] || payload.variationId;

	const handleRegenerate = async () => {
		setLoading(true);
		await regenerateImage(caseId, payload.variationId);
	};

	useEffect(() => {
		if (loading) {
			setLoading(false);
		}
	}, [payload]);

	return (
		<Skeleton isLoaded={!loading} borderRadius="10px">
			<Flex
				key={payload.variationId}
				display="flex-column"
				justifyContent="center"
			>
				<Flex justifyContent="space-between">
					<Text mb="2px" mt="20px" fontWeight="medium">
						{variationText}
					</Text>
					<Button
						variant="outline"
						borderRadius="md"
						size="sm"
						colorScheme="gray"
						border="1px"
						onClick={handleRegenerate}
						isDisabled={loading}
					>
						Regenerate
					</Button>
				</Flex>
				<Box border="1px solid #ddd">
					<Image
						src={payload.attributes.image.flatFile}
						width="400px"
						height={`calc(${fixedWidth} / ${aspectRatio})`}
						objectFit="contain"
						alt={`Image for variation ${payload.variationId}`}
					/>
				</Box>
			</Flex>
		</Skeleton>
	);
};
