import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { CircleArrowLeftIcon, CircleArrowRightIcon } from 'src/assets/icons';

interface CarouselArrowButtonsProps {
	onPrev: () => void;
	onNext: () => void;
	showLeftArrowButton: boolean;
	showRightArrowButton: boolean;
}

const CarouselArrowButtons: FC<CarouselArrowButtonsProps> = ({
	onPrev,
	onNext,
	showLeftArrowButton,
	showRightArrowButton,
}) => {
	return (
		<>
			{showLeftArrowButton && (
				<Flex
					position="absolute"
					left="0"
					top="50%"
					transform="translateY(-50%)"
					onClick={onPrev}
					cursor="pointer"
					ml={10}
					zIndex={100}
				>
					<CircleArrowLeftIcon />
				</Flex>
			)}

			{showRightArrowButton && (
				<Flex
					position="absolute"
					right="0"
					top="50%"
					transform="translateY(-50%)"
					onClick={onNext}
					cursor="pointer"
					alignItems="center"
					bg="linear-gradient(to left, rgba(248, 248, 248, 1), transparent)"
					h="100%"
					pr={3}
					zIndex={100}
				>
					<CircleArrowRightIcon />
				</Flex>
			)}
		</>
	);
};

export default CarouselArrowButtons;
