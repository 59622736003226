import {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { isArray } from 'lodash';
import ContentPendingProgress from 'src/components/common/ContentPendingProgress';
import FusionLoading from 'src/components/common/FusionLoading';
import Overlay from 'src/components/common/Overlay';

export interface IIframeStyle {
	marginTop: string;
	marginLeft: string;
	width: string;
	height: string;
}

interface PhotoEditorPreviewProps {
	input: {
		files: string[];
		resources?: string[];
	};
	isEditorLoading: boolean;
	onLoadingFinished: () => void;
	onDocumentCount: (count: number) => void;
	showOverlay?: boolean;
	textLayersContent?: any;
	mediaLayersContent?: any;
	font: string | undefined;
	onDownload: (file: ArrayBuffer) => void;
	onSave: (file: ArrayBuffer) => void;
	isSubmitting: boolean;
	showPendingProgress: boolean;
	onPendingProgressFinished: (val: boolean) => void;
	containerWidth?: string;
	containerHeight?: string;
	iframeWidth?: string;
	iframeHeight?: string;
	// handleClearErrors: () => void;
}

const PhotoEditorPreview: FC<PhotoEditorPreviewProps> = ({
	input,
	isEditorLoading,
	onLoadingFinished,
	onDocumentCount,
	showOverlay,
	textLayersContent,
	mediaLayersContent,
	font,
	onDownload,
	onSave,
	isSubmitting,
	showPendingProgress,
	onPendingProgressFinished,
	containerWidth,
	containerHeight,
	// handleClearErrors,
}) => {
	const resources = [];
	if (font) resources.push(font);
	const DEFAULT_EDITOR_SETTINGS = {
		resources,
		environment: {
			vmode: 2,
			theme: 2,
			topt: { t0: [0, 0, 0], t16: [0, 0, 0] },
		},
	};

	const [eventCounts, setEventCounts] = useState(0);
	const [eventName, setEventName] = useState('');
	const ppRef = useRef<HTMLIFrameElement>(null);
	const eventNameRef = useRef(eventName);
	const [pngUrl, setPngUrl] = useState<string | null>(null);

	const combinedInput = {
		...DEFAULT_EDITOR_SETTINGS,
		...input,
	};

	const parsedInput = encodeURIComponent(JSON.stringify(combinedInput));
	const photopeaUrl = `https://www.photopea.com#${parsedInput}`;

	useEffect(() => {
		if (isEditorLoading && eventCounts >= 2) {
			onLoadingFinished();
		}
	}, [eventCounts, isEditorLoading]);

	useEffect(() => {
		window.addEventListener('message', handleEvents);
		return () => window.removeEventListener('message', handleEvents);
	}, []);

	useEffect(() => {
		eventNameRef.current = eventName;
	}, [eventName]);

	const handleEvents = useCallback(
		(e: MessageEvent) => {
			if (e.data === 'done') setEventCounts((prev) => prev + 1);
			const isSaveAction = e.data instanceof ArrayBuffer;
			if (isSaveAction) {
				if (eventNameRef.current === 'download') {
					onDownload(e.data);
					setEventName('');
					return;
				}
				const blob = new Blob([e.data]);
				const url = URL.createObjectURL(blob);
				setPngUrl(url);
				eventNameRef.current === 'save' && onSave(e.data);
				setEventName('');
			} else if (
				typeof e?.data === 'string' &&
				(e.data as string)?.indexOf('documentCount') === 0
			) {
				const documentCount = Number(e.data.split('=').pop());
				onDocumentCount(documentCount);
			}
		},
		[onSave, onDownload, onDocumentCount],
	);

	const handleLoadFile = async (event: ChangeEvent<HTMLInputElement>) => {
		const pp = ppRef.current?.contentWindow;
		if (!pp) return;

		pp.postMessage(
			'app.activeDocument.close(SaveOptions.DONOTSAVECHANGES)',
			'*',
		);

		const file = event.target.files?.[0];
		if (!file) return;

		const buffer = await file.arrayBuffer();
		pp.postMessage(buffer, '*');
		handleTriggerSave('save');
	};

	const saveToPNG = async () => {
		const pp = ppRef.current?.contentWindow;
		if (!pp) return;
		pp.postMessage('app.activeDocument.saveToOE("png");', '*');
	};

	useEffect(() => {
		if (isEditorLoading) return;
		handleChangeHeadline();
		saveToPNG();
	}, [textLayersContent, isEditorLoading]);

	const handleChangeHeadline = async () => {
		const pp = ppRef.current?.contentWindow;
		if (!pp || !textLayersContent) return;
		if (isArray(textLayersContent)) {
			textLayersContent.forEach((layer) => {
				let groups = '';
				let previousGroup = 'app.activeDocument';
				layer.parents.forEach((parent: string, index: number) => {
					groups += `
							var group${index} = ${previousGroup}.layerSets.getByName('${parent}');
						`;
					previousGroup = `group${index}`;
				});

				const visibilityCommand = layer.visible
					? 'visible = true'
					: 'visible = false';

				const script = `
					${groups}
					var layerObj = ${previousGroup}.layers.getByName('${layer.name}');
					layerObj.${visibilityCommand};
					if (layerObj.textItem) {
						layerObj.textItem.contents = '${layer.content
							.replace(/'/g, "\\'")
							.replace(/\n/g, '\\r')
							.replace(/\r/g, '\\r')}';
					}
				`;

				pp.postMessage(script, '*');
			});
		}
	};

	useEffect(() => {
		if (isEditorLoading) return;
		handleChangeMediaContent();
		saveToPNG();
	}, [mediaLayersContent, isEditorLoading]);

	const handleChangeMediaContent = async () => {
		const pp = ppRef.current?.contentWindow;
		if (!pp || !mediaLayersContent) return;
		if (isArray(mediaLayersContent)) {
			mediaLayersContent.forEach((layer) => {
				let groups = '';
				let previousGroup = 'app.activeDocument';
				layer.parents.forEach((parent: string, index: number) => {
					groups += `
						var group${index} = ${previousGroup}.layerSets.getByName('${parent}');
					`;
					previousGroup = `group${index}`;
				});

				const visibilityCommand = layer.visible ? 'true' : 'false';

				pp.postMessage(
					`${groups}
					var layerObj = ${previousGroup}.layers.getByName('${layer.name}');
					layerObj.visible = ${visibilityCommand};`,
					'*',
				);
			});
		}
	};

	const handleTriggerSave = (action: string) => {
		setEventName(action);
		const pp = ppRef.current?.contentWindow;
		if (!pp) return;

		pp.postMessage('app.activeDocument.saveToOE("psd");', '*');
	};

	return (
		<>
			<Box pos="relative" borderRadius="lg" width={containerWidth}>
				<input
					type="file"
					accept=".psd"
					onChange={handleLoadFile}
					style={{ display: 'none' }}
					id="file-input"
				/>
				{showOverlay && (
					<Overlay zIndex={50} h="full" bg="rgba(255, 255, 255, 0.5)">
						<Flex w="full" h="full" justify="center" align="center">
							<FusionLoading isLoading={showOverlay} />
						</Flex>
					</Overlay>
				)}
				<Overlay zIndex={50} h="full" bg="rgba(255, 255, 255, 0)">
					<Flex w="full" h="full" justify="center" align="center"></Flex>
				</Overlay>

				<Box
					borderRadius="lg"
					width={containerWidth ?? 'full'}
					minH={containerHeight}
					minW="382px"
					p={0}
					m={0}
				>
					{showPendingProgress && (
						<Overlay zIndex={50} w="full" h="92%" bg="rgba(105, 105, 105, 0.5)">
							<Flex w="full" h="full" justify="center" align="center">
								<Flex w="75%" h="full" justify="center" align="center">
									<ContentPendingProgress
										time={30}
										isPendingGeneration={isSubmitting}
										onGenerateStatusChange={(val) => {
											onPendingProgressFinished(val);
										}}
										progressText="Uploading..."
										isGray
									/>
								</Flex>
							</Flex>
						</Overlay>
					)}
					<Box borderRadius="lg" width={containerWidth} p={0} m={0}>
						{pngUrl ? (
							<Image src={pngUrl} borderRadius="lg" objectFit="cover" />
						) : (
							<Flex
								w="full"
								h={containerHeight}
								justify="center"
								align="center"
							>
								<FusionLoading isLoading={!pngUrl} />
							</Flex>
						)}
						<iframe src={photopeaUrl} ref={ppRef} hidden />
					</Box>
				</Box>
				<Button
					onClick={() => handleChangeHeadline()}
					display="none"
					id="change-text-layer-button-preview"
				/>
				<Button
					onClick={() => handleTriggerSave('download')}
					display="none"
					id="psd-preview-download-button"
				>
					download
				</Button>
			</Box>
		</>
	);
};

export default PhotoEditorPreview;
