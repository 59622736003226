import { FC } from 'react';
import { Box, BoxProps, Icon, Text } from '@chakra-ui/react';
import { WarningIcon } from 'src/assets/icons';

interface ErrorMessageProps {
	message: string;
	boxProps?: BoxProps;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message, boxProps }) => {
	return (
		<Box
			display="flex"
			bg="#ffedee"
			py={1}
			px={2}
			borderRadius="sm"
			gap={1}
			{...boxProps}
		>
			<Box>
				<WarningIcon color="#E53E3E" boxSize="16px" />
			</Box>
			<Text flex={1} color="#E53E3E">
				{message}
			</Text>
		</Box>
	);
};

export default ErrorMessage;
