import { Box, Text, Icon, Flex, HStack, Avatar } from '@chakra-ui/react';
import { RiMore2Line } from 'react-icons/ri';
import { IChannelCreativeAttributes, ICreative } from 'src/lib/schemas';
import { CampaignContext } from 'src/contexts/campaign';
import { useContext } from 'react';
import UserContext from 'src/contexts/UserContext';
import CreativeCard from './CreativeCard';

interface GoogleCreativeProps {
	creative: ICreative;
	withActions?: boolean;
	fieldIndex?: number;
	creativesConfig?: IChannelCreativeAttributes[];
}

const GoogleCreative = ({
	creative,
	withActions = true,
	fieldIndex = 0,
	creativesConfig,
}: GoogleCreativeProps) => {
	const { campaign } = useContext(CampaignContext);
	const { account } = useContext(UserContext);
	const destination =
		campaign?.destination ?? 'https://www.website.com/feed/html';
	const headline = (creative as any).attributes.headlines[fieldIndex];
	const description = (creative as any).attributes.descriptions[fieldIndex];

	return (
		<CreativeCard
			creative={creative}
			withActions={withActions}
			creativesConfig={creativesConfig}
			size="lg"
			title="Google"
		>
			<Box
				border="1px"
				borderRadius="md"
				borderColor="gray.200"
				bg="white"
				maxW="lg"
				p={4}
				boxShadow="md"
			>
				<Text fontSize="sm" fontWeight="bold" mb={2}>
					Sponsored
				</Text>

				<Flex justifyContent="space-between" alignItems="center">
					<HStack spacing={3} maxW="lg">
						<Avatar
							src={account?.logo}
							sx={{
								img: {
									objectFit: 'contain',
								},
							}}
						/>
						<Box maxW="sm">
							<Text fontWeight={500}>{account?.name}</Text>
							<Text
								color="gray.500"
								whiteSpace="nowrap"
								overflow="hidden"
								textOverflow="ellipsis"
								fontSize="sm"
							>
								{destination}
							</Text>
						</Box>
					</HStack>
					<Icon as={RiMore2Line} boxSize={5} color="gray.500" />
				</Flex>
				<Text data-testid="google-content" mt={3} fontSize="lg" color="blue.600">
					{headline}
				</Text>
				<Text mt={2} fontSize="sm" color="gray.700">
					{description}
				</Text>
			</Box>
		</CreativeCard>
	);
};

export default GoogleCreative;
