import { IDesignDirection, IChannelMediaAttributes } from 'src/lib/schemas';

export const removeDuplicateLayers = (data?: IDesignDirection[]) => {
	if (!data) return [];
	return data.map((item: any) => {
		const attributes = item.attributes as IChannelMediaAttributes;
		const layers = attributes.image.layers;
		const uniqueLayers: any[] = [];
		const layerNames = new Set();

		if (!layers) return item;
		layers.forEach((layer) => {
			if (!layerNames.has(layer.name)) {
				uniqueLayers.push(layer);
				layerNames.add(layer.name);
			}
		});

		return {
			...item,
			attributes: {
				...item.attributes,
				image: {
					...item.attributes.image,
					layers: uniqueLayers,
				},
			},
		};
	});
};
