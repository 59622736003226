import {
	FC,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Flex } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import {
	IChannelMediaAttributes,
	IDesignDirection,
	ImageLayer,
	TextLayer,
	ThreadTypesEnum,
} from 'src/lib/schemas';
import CustomInput from 'src/components/common/CustomInput/CustomInput';
import { useAssistantField } from 'src/components/assistantChat/hooks/useAssistantField';
import { CampaignContext } from 'src/contexts';
import MediaInputHook from 'src/components/common/CustomInput/hooks/MediaInputHook';

interface IPerview {
	flatFile: string;
	layeredFile: string;
	layers: (ImageLayer | TextLayer)[];
}
interface DDFieldsProps {
	designDirection?: IDesignDirection;
	onLayersTextChange: (content: any, layers: any) => void;
	onChangePreviewPSD?: (
		flatFile: string,
		layeredFile: string,
		layers: (ImageLayer | TextLayer)[],
	) => void;
	previewPSD: IPerview;
	isChangingLayer: boolean;
	onChangingLayer: (status: boolean) => void;
	setTextLayersContent: React.Dispatch<any>;
	setMediaLayersContent: React.Dispatch<any>;
	textLayersContent: any[];
	mediaLayersContent: any[];
}

const DDFields: FC<DDFieldsProps> = ({
	designDirection,
	onLayersTextChange,
	onChangePreviewPSD,
	previewPSD,
	isChangingLayer,
	onChangingLayer,
	setTextLayersContent,
	setMediaLayersContent,
	textLayersContent,
	mediaLayersContent,
}) => {
	if (!designDirection) return;
	const { id: campaignId } = useContext(CampaignContext);
	const { attributes, variant } = designDirection;
	const [prevDesignDirection, setPrevDesignDirection] =
		useState<IDesignDirection | null>(null);
	const firstInputRef = useRef<HTMLInputElement | null>(null);

	if (!attributes) return;

	const mediaContent = attributes as unknown as IChannelMediaAttributes;
	const layers = mediaContent?.image.layers;

	const defaultValues = useCallback(() => {
		return textLayersContent.reduce((acc: Record<string, string>, layer) => {
			acc[layer.name] = layer.content;
			return acc;
		}, {});
	}, [textLayersContent]);

	const formMethods = useForm({ defaultValues: defaultValues() });
	const { control } = formMethods;

	useEffect(() => {
		if (firstInputRef.current) {
			firstInputRef.current.focus();
		}
	}, []);

	useEffect(() => {
		if (
			JSON.stringify(prevDesignDirection) !== JSON.stringify(designDirection)
		) {
			if (textLayersContent) {
				formMethods.reset(defaultValues());
			}
		}

		setPrevDesignDirection(designDirection);
	}, [designDirection, textLayersContent, defaultValues, prevDesignDirection]);

	const handleTextLayersChange = () => {
		const formValues = formMethods.getValues();
		onLayersTextChange(formValues, textLayersContent ?? []);
	};

	const setFieldValue = useCallback(
		(field: string, value: string) => {
			setTextLayersContent((prevLayers: any[]) =>
				prevLayers.map((layer) =>
					layer.name === field ? { ...layer, content: value } : layer,
				),
			);
			formMethods.setValue(field, value);
			handleTextLayersChange();
		},
		[formMethods, handleTextLayersChange],
	);

	const assistant = useAssistantField();

	const handleOpenAssistant = (layer: any) =>
		assistant.openAssistant({
			type: ThreadTypesEnum.Campaign,
			reference: campaignId ?? '',
			fieldName: layer.name,
			control,
			variant: designDirection.variant,
			channel: designDirection.channel,
			displayName: layer.displayName,
			value: layer.content,
			setValue: (newValue) => setFieldValue(layer.name, newValue),
		});

	return (
		<Flex direction="column" gap={5}>
			<FormProvider {...formMethods}>
				{textLayersContent.map((layer, index) => {
					const toggleShowLayer = (layerId: string) => {
						setTextLayersContent((prevState: any[]) =>
							prevState.map((layer) =>
								layer.id === layerId
									? { ...layer, visible: !layer.visible }
									: layer,
							),
						);
					};
					return (
						<CustomInput
							key={layer.id}
							ref={index === 0 ? firstInputRef : null}
							control={formMethods.control}
							label={layer.displayName}
							name={layer.name}
							isLayerVisible={layer.visible}
							onCopilotClick={() => handleOpenAssistant(layer)}
							onShowClick={() => toggleShowLayer(layer.id)}
							onChange={(value) => setFieldValue(layer.name, value)}
						/>
					);
				})}
			</FormProvider>

			{mediaLayersContent.map((layer) => {
				const toggleShowLayer = (layerId: number) => {
					setMediaLayersContent((prevState: any) =>
						prevState.map((layer: any) =>
							layer.id === layerId
								? { ...layer, visible: !layer.visible }
								: layer,
						),
					);
				};
				return (
					<MediaInputHook
						key={layer.id}
						control={formMethods.control}
						onShowClick={() => toggleShowLayer(layer.id)}
						onChangePreviewPSD={onChangePreviewPSD}
						previewPSD={previewPSD}
						layer={layer}
						onChangingLayer={onChangingLayer}
					/>
				);
			})}
		</Flex>
	);
};

export default DDFields;
