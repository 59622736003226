import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { IoPlay } from 'react-icons/io5';
import ReactPlayer from 'react-player';
import CircularIcon from './CircularIcon';

interface IVideoPlayer {
	url: string;
	thumbnailUrl: string;
	thumbnailHeight?: string;
	width?: string | number;
	height?: string | number;
	buttonSize?: string | number;
}

const VideoPlayer: FC<IVideoPlayer> = ({
	url,
	thumbnailUrl,
	thumbnailHeight,
	width = '100%',
	height = '100%',
	buttonSize = '50px',
}) => {
	const customThumbnail = (
		<div
			style={{
				backgroundImage: `url(${thumbnailUrl})`,
				backgroundSize: 'cover',
				width: '100%',
				height: thumbnailHeight,
			}}
		/>
	);
	return (
		<ReactPlayer
			url={url}
			width={width}
			height={height}
			controls
			light={thumbnailHeight ? customThumbnail : thumbnailUrl}
			playIcon={
				<Flex
					position="absolute"
					top="50%"
					left="50%"
					transform="translate(-50%, -50%)"
					zIndex={1}
				>
					<CircularIcon
						size={buttonSize}
						fontSize="30px"
						icon={IoPlay}
						alignIcon="5px"
					/>
				</Flex>
			}
		/>
	);
};

export default VideoPlayer;
