import {
	IAppConfig,
	ISearchResponse,
	ICategoriesResponseArray,
} from 'src/lib/schemas';
import http from './http';

const apiVersion = 'v2';
const getAppUrl = `/${apiVersion}/apps`;
const searchUrl = `/${apiVersion}/apps/search`;

function getCategoryDescription(
	appId: string,
	categories: ICategoriesResponseArray,
): string | undefined {
	const category = categories.find((cat) => cat.id === appId);
	return category ? category.description : undefined;
}

export const getApps = async ({
	category,
	offset,
	limit,
}: {
	category: string | null;
	offset: number;
	limit: number;
}) => {
	const { data } = await http.post<ISearchResponse>(searchUrl, {
		category: category,
		offset: offset,
		limit: limit,
	});

	const categories = await getAppsCategories();

	data.results.forEach((app) => {
		const description = getCategoryDescription(app.category, categories);
		app.categoryDescription = description || '';
	});

	return { results: data.results, pagination: data.pagination };
};

export const getApp = async ({ id }: { id: string }) => {
	const { data } = await http.get<IAppConfig>(`${getAppUrl}/${id}`, {});
	return data;
};

export const getAppsCategories = async () => {
	const { data } = await http.get<ICategoriesResponseArray>(
		`${getAppUrl}/category/all`,
		{},
	);
	return data;
};
