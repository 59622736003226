import { useContext, useEffect, useState } from 'react';
import PreRegisterForm from './PreRegisterForm';
import AdGeneration from './AdGeneration';
import { Box, Flex, VStack } from '@chakra-ui/react';
import http from 'src/services/http';
import { useNavigate, useParams } from 'react-router-dom';
import { ICatalog } from 'src/lib/schemas';
import ScrollToTop from 'src/components/common/ScrollToTop';
import { registerByDomain as registerByDomainService } from 'src/services/scraping';
import UserContext from 'src/contexts/UserContext';
import FusionLoading from 'src/components/common/FusionLoading';
import useRoute from 'src/hooks/useRoute';
import DomainNotFound from './DomainNotFound';

const ScrappingPage = () => {
	const [step, setstep] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [campaignGenerationLoading, setCampaignGenerationLoading] =
		useState(false);
	const [catalog, setCatalog] = useState<ICatalog[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<ICatalog>();
	const [brand, setBrand] = useState<ICatalog>();
	const { setUser } = useContext(UserContext);
	const [domainRegistered, setDomainRegistered] = useState(false);
	const navigate = useNavigate();
	const { formatUrl, removeUrlPrefix } = useRoute();
	const { id } = useParams<{ id: string }>();
	const cleanUrl = (url: string) => {
		const decodedUrl = decodeURIComponent(url);
		const formatedUrl = formatUrl(decodedUrl);
		return formatedUrl;
	};
	const formatedUrl = id && cleanUrl(id);
	const [urlWithoutPrefix, setUrlWithoutPrefix] = useState<string>(
		formatedUrl ? removeUrlPrefix(formatedUrl) : 'your domain',
	);

	const handleProductChange = (product: ICatalog) => {
		setSelectedProduct(product);
	};

	const registerByDomain = async (url = formatedUrl) => {
		if (!url) return;
		try {
			const response = await registerByDomainService(url);
			response && setUser(response.user);
			setDomainRegistered(true);
		} catch (error) {
			setDomainRegistered(false);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const handlePopState = () => {
			setstep(1);
		};

		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	useEffect(() => {
		localStorage.removeItem('tokens');
		localStorage.removeItem('user');
		registerByDomain();
	}, []);

	const processGenerateCampaginCallback = async (callbackUrl: string) => {
		try {
			const response = await http.get(callbackUrl);
			const { status } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(
					processGenerateCampaginCallback.bind(null, callbackUrl),
					1500,
				);
			} else if (status === 'error' || status === 'failed') {
				setCampaignGenerationLoading(false);
			} else if (status === 'successful') {
				setCampaignGenerationLoading(false);
			}
		} catch (e) {
			setCampaignGenerationLoading(false);
		}
	};

	const createFirstCampaign = async (payload: any) => {
		setBrand(payload);
		setUrlWithoutPrefix(removeUrlPrefix(payload.websiteLink));
		setCampaignGenerationLoading(true);
		try {
			const { data } = await http.post(
				'/v2/apps/fusion_ai.campaign_handler/execute/create_campaign',
				{
					inputs: {
						...payload,
						promotedObject: selectedProduct,
					},
				},
			);

			processGenerateCampaginCallback(data.callback);
			setstep(2);
			window.history.pushState(null, '', '/complete-registration');
		} catch (error) {
			console.error('Error generating campaign:', error);
		}
	};

	const handleRetryDomain = (newDomain: string) => {
		if (!newDomain) return;
		navigate(`/welcome/${newDomain}`);
		setIsLoading(true);
		setDomainRegistered(false);
		const formatedUrl = cleanUrl(newDomain);
		registerByDomain(formatedUrl);
	};

	if (isLoading) return <FusionLoading isLoading={isLoading} />;
	if (!domainRegistered)
		return (
			<DomainNotFound
				onRetryDomain={handleRetryDomain}
				domain={urlWithoutPrefix}
			/>
		);
	return (
		formatedUrl && (
			<Flex
				width={{ base: '100%', sm: '80%', xl: '60%' }}
				direction="column"
				margin="0 auto"
			>
				<Box hidden={step !== 1}>
					<PreRegisterForm
						route={formatedUrl}
						onCreateFirstCampagin={createFirstCampaign}
						onSkip={() => navigate('/signup')}
						onProductChange={handleProductChange}
						selectedProduct={selectedProduct}
						catalog={catalog}
						setCatalog={setCatalog}
						onFetchDataFail={() => setDomainRegistered(false)}
					/>
				</Box>
				{step === 2 && (
					<VStack>
						<ScrollToTop />
						<AdGeneration
							urlWithoutPrefix={urlWithoutPrefix}
							pendingCampaginGeneration={campaignGenerationLoading}
							brandData={brand}
						/>
					</VStack>
				)}
			</Flex>
		)
	);
};

export default ScrappingPage;
