import React, { useContext } from 'react';
import { Box, Flex, Text, Circle, useBreakpointValue } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { CampaignContext } from 'src/contexts';
import { isDesktop } from './utils/constants';

interface CampaignStepperProps {
	handleOpenSection: (index: number) => void;
	designDirectionsRef: React.RefObject<HTMLDivElement>;
	scheduleRef: React.RefObject<HTMLDivElement>;
	scrollIntoRef: (
		ref: React.RefObject<HTMLDivElement>,
		offset?: number,
	) => void;
	currentOpenSectionIndex: number;
}

const CampaignStepper: React.FC<CampaignStepperProps> = ({
	handleOpenSection,
	scheduleRef,
	scrollIntoRef,
	currentOpenSectionIndex,
}) => {
	const { steps } = useContext(CampaignContext);
	const circleSize = useBreakpointValue({ base: '24px', md: '32px' });
	const fontSize = useBreakpointValue({ base: 'xs', md: 'sm' });

	const visibleSteps = steps.slice(0, 3);

	return (
		<Box
			bg="white"
			p={4}
			pb={0}
			borderRadius="md"
			boxShadow="sm"
			position="fixed"
			top="72px"
			left={0}
			width="full"
			zIndex="1000"
		>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				mb={4}
				width="100%"
				maxW="1600px"
				mx="auto"
				px={5}
			>
				{visibleSteps.map((step, index) => {
					const isClickable =
						(step.isCompleted || step.isActive) &&
						index !== currentOpenSectionIndex;

					return (
						<React.Fragment key={index}>
							<Flex
								direction="column"
								alignItems="center"
								flex="1"
								cursor={isClickable ? 'pointer' : 'default'}
								onClick={() => {
									if (isClickable) {
										handleOpenSection(index);
										if (index === 0) {
											window.scrollTo({ top: 0, behavior: 'smooth' });
										} else if (index === 2) {
											scrollIntoRef(scheduleRef);
										}
									}
								}}
							>
								<Circle
									size={circleSize}
									bg={step.isCompleted ? '#F7480B' : 'white'}
									borderColor={
										step.isCompleted
											? '#F7480B'
											: step.isActive
											? '#F7480B'
											: 'gray.300'
									}
									borderWidth={2}
									color={
										step.isCompleted
											? 'white'
											: step.isActive
											? '#F7480B'
											: 'gray.500'
									}
								>
									{step.isCompleted ? <CheckIcon color="white" /> : index + 1}
								</Circle>
								<Text
									mt={2}
									fontSize={fontSize}
									fontWeight="medium"
									color={
										step.isCompleted
											? '#F7480B'
											: step.isActive
											? 'black'
											: 'gray.500'
									}
								>
									{step.label}
								</Text>
							</Flex>
							{index < visibleSteps.length - 1 && (
								<Box
									flex="1"
									height="2px"
									bg={steps[index].isCompleted ? '#F7480B' : 'gray.300'}
								/>
							)}
						</React.Fragment>
					);
				})}
			</Flex>
		</Box>
	);
};

export default CampaignStepper;
