import {
	Box,
	Button,
	Center,
	Checkbox,
	Flex,
	IconButton,
	Input,
	Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { LayerDropDown } from './LayerDropDown';
import { ActionDropdown } from './ActionDropdown';

import {
	ILayerAction,
	ILayerSpec,
} from 'src/contexts/templates/TemplatesContext';
import { DeleteIcon } from '@chakra-ui/icons';

interface ConfigureLayersProps {
	action: ILayerAction;
	layer: ILayerSpec;
	index: number;
	onUpdateLayer: (layerSpec: ILayerSpec, index: number) => void;
	onRemoveLayer: (index: number) => void;
}
export const ConfigureLayers = ({
	action,
	layer,
	index,
	onUpdateLayer,
	onRemoveLayer,
}: ConfigureLayersProps) => {
	const [selectedLayer, setSelectedLayer] = useState<string>(layer.name!);
	const [selectedAction, setSelectedAction] = useState<ILayerAction>({
		id: action.id,
		arguments: {
			property: action.arguments?.property || '',
			value: action.arguments?.value || '',
		},
	});
	const [layerError, setLayerError] = useState(false);
	const [actionError, setActionError] = useState(false);
	const [propertyValue, setPropertyValue] = useState<string>(
		selectedAction.arguments?.value || '',
	);

	const handleLayerSelect = (layerName: string) => {
		setSelectedLayer(layerName);
		setLayerError(!layerName);

		if (layerName && selectedAction.id) {
			onUpdateLayer({ name: layerName, actions: [selectedAction] }, index);
		}
	};

	const handleActionSelect = (actionId: ILayerAction) => {
		setSelectedAction(actionId);
		setActionError(!actionId.id);

		if (selectedLayer && actionId.id) {
			onUpdateLayer({ name: selectedLayer, actions: [actionId] }, index);
		}
	};

	const handlePropertyValueChange = (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const newValue = e.target.value;
		setPropertyValue(newValue);

		if (selectedLayer && selectedAction.id) {
			onUpdateLayer(
				{
					name: selectedLayer,
					actions: [{ ...selectedAction, arguments: { value: newValue } }],
				},
				index,
			);
		}
	};



	return (
		<Flex justify="start" gap={10} alignItems="center">
			<Flex height={'50px'} alignItems={'end'}>
				<IconButton
					size="sm"
					colorScheme="red"
					aria-label="Remove Layer"
					icon={<DeleteIcon />}
					onClick={() => {
						onRemoveLayer(index);
					}}
				/>
			</Flex>
			<Flex gap={10} onClick={(e) => e.preventDefault()}>
				<Box>
					<LayerDropDown
						onSelect={handleLayerSelect}
						selectedLayer={selectedLayer}
						borderColor={layerError ? 'red' : 'gray'}
					/>
				</Box>
				<Box>
					<ActionDropdown
						onSelect={handleActionSelect}
						actionId={selectedAction.id}
						borderColor={actionError ? 'red' : 'gray'}
					/>
				</Box>
				{selectedAction.id === 'SET_PROPERTY' && (
					<Box>
						<Text pl={5}>Action</Text>
						<Input
							border="1px solid"
							borderColor={layerError ? 'red' : 'gray'}
							h="40px"
							borderRadius="md"
							textAlign="left"
							px={3}
							_hover={{ borderColor: 'gray.500', bg: 'gray.400' }}
							boxShadow="s"
							value={propertyValue}
							onChange={handlePropertyValueChange}
						/>
					</Box>
				)}
			</Flex>
		</Flex>
	);
};
