import { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import {
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	Input,
	Text,
	Radio,
	RadioGroup,
	Stack,
	FlexProps,
} from '@chakra-ui/react';
import { ICampaignPromotion } from 'src/lib/schemas';
import { InfoIcon } from '@chakra-ui/icons';

export interface PromoCodesInputProps {
	id?: string;
	value?: Array<ICampaignPromotion>;
	onChange?: (value: Array<ICampaignPromotion>) => void;
	label: string;
	required?: boolean;
	error?: ReactNode;
	requirementsLabel?: string;
	wrapperProps?: FlexProps;
	valuesContainerProps?: FlexProps;
	formLabelProps?: FormLabelProps;
	direction?: 'row' | 'column';
	withRadioOptions?: boolean;
	requirementsLabelStyle?: React.CSSProperties;
}

const PromoCodesInput: FC<PromoCodesInputProps> = ({
	value,
	onChange,
	label,
	required,
	error,
	requirementsLabel,
	requirementsLabelStyle,
	wrapperProps,
	formLabelProps,
	direction = 'column',
	withRadioOptions = false,
}) => {
	const [code, setCode] = useState('');
	const [description, setDescription] = useState('');
	const [radioGrupValue, setRadioGrupValue] = useState('no');

	const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
		setCode(e.target.value);
	};

	const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
		setDescription(e.target.value);
	};

	const handleGenerateCode = () => {
		onChange && onChange([{ code, description }]);
	};

	const handleRadioChange = (value: string) => {
		setRadioGrupValue(value);
		if (value === 'no') {
			setCode('');
			setDescription('');
			onChange && onChange([]);
		}
	};

	useEffect(() => {
		if (value && value.length > 0) {
			const lastValue = value[value.length - 1];
			setCode(lastValue.code);
			setDescription(lastValue.description);
			setRadioGrupValue('yes');
		} else {
			setRadioGrupValue('no');
		}
	}, [value]);

	const isValidPromoCode = Boolean(code) && Boolean(description);

	useEffect(() => {
		if (isValidPromoCode) {
			handleGenerateCode();
		}
	}, [code, description]);

	const renderLabelIndicator = () => {
		if (!required) return null;
		if (error) return <InfoIcon color="#e53e3e" />;
		return <Text color="#e53e3e">*</Text>;
	};

	return (
		<Flex direction={direction} w="full" gap={direction === 'row' ? 20 : 0}>
			<Flex flex={1}>
				<FormLabel fontSize="sm" fontWeight="medium" {...formLabelProps}>
					<Flex gap={1} alignItems="center">
						{label}
						{renderLabelIndicator()}
					</Flex>
					{requirementsLabel && (
						<Text fontSize="12px" color="#959595" style={requirementsLabelStyle}>
							{requirementsLabel}
						</Text>
					)}
				</FormLabel>
			</Flex>
			<Flex flex={1} direction="column" {...wrapperProps}>
				{withRadioOptions && (
					<Flex direction="column" mb={4}>
						<RadioGroup value={radioGrupValue} onChange={handleRadioChange}>
							<Stack direction="column">
								<Radio data-testid="radio-input" value="no">
									<Text fontSize="14px" color="black">
										No
									</Text>
								</Radio>
								<Radio data-testid="radio-input" value="yes">
									<Text fontSize="14px" color="black">
										Yes
									</Text>
								</Radio>
							</Stack>
						</RadioGroup>
					</Flex>
				)}
				{radioGrupValue === 'yes' && (
					<Flex gap={6}>
						<FormControl>
							<FormLabel display="inline-block" fontSize="sm">
								Code
							</FormLabel>
							<Input
								placeholder="Enter text"
								value={code}
								size="md"
								fontSize="14px"
								borderRadius={4}
								onChange={handleCodeChange}
								_placeholder={{ color: '#A0AEC0' }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display="inline-block" fontSize="sm">
								Description
							</FormLabel>
							<Input
								placeholder="Enter text"
								value={description}
								borderRadius={4}
								size="md"
								fontSize="14px"
								onChange={handleDescriptionChange}
								_placeholder={{ color: '#A0AEC0' }}
							/>
						</FormControl>
					</Flex>
				)}
				{error && <FormErrorMessage>{error}</FormErrorMessage>}
			</Flex>
		</Flex>
	);
};

export default PromoCodesInput;
