import {
	ECampaignPromotedType,
	ICampaign,
	ICampaignConfig,
	ICampaignForm,
	ICampaignGenAIPayload,
} from 'src/lib/schemas';
import { IOption } from 'src/lib/schemas/misc';

export const transformCampaignToGenAIPayload = (
	campaign: Omit<ICampaign, 'id'>,
	config: ICampaignConfig | null,
	metaOptions: IOption[],
	tonesOptions: IOption[],
	audienceOptions: IOption[],
): ICampaignGenAIPayload => {
	if (!config) throw new Error('Campaign config is not defined');

	const {
		title,
		description,
		goal,
		promotedObject,
		audience,
		tone,
		promotion,
		channels,
		budget,
	} = campaign;

	const mappedGoals = goal?.map(({ id }) => {
		const goal = config.goals.find((g) => g.value === id);
		if (!goal) return id;
		return goal.label;
	});

	const mappedTones = tone.map((id) => {
		const elem = tonesOptions.find((t) => t.value === id);
		if (!elem) return id;
		return elem.label;
	});

	const mappedAudience = audience.map((id) => {
		const elem = audienceOptions.find((a) => a.value === id);
		if (!elem) return id;
		return elem.label;
	});

	const mappedProducts = promotedObject.ids.map((id) => {
		const prod = metaOptions.find((p) => p.value === id);
		if (!prod) return id;
		return prod.label;
	});

	return {
		name: title,
		description,
		goals: mappedGoals ?? [],
		audiance: mappedAudience,
		tone: mappedTones,
		products: mappedProducts,
		promo_code: promotion?.[0]?.code ?? '',
		promo_description: promotion?.[0]?.description ?? '',
		channels,
		budget: null,
	};
};

export const transformPartialFormToCampaign = (
	form: Partial<ICampaignForm>,
	campaign?: Partial<ICampaign> | null,
): Partial<ICampaign> => {
	const {
		group,
		title,
		description,
		goal,
		promotedObject,
		destination,
		audience,
		tone,
		promotion,
		// channels,
		budget,
		placements,
	} = form;
	// const selectedChannels = channels
	// 	? Object.keys(channels)
	// 			.filter((platform) => channels[platform as keyof typeof channels])
	// 			.map((key) => key)
	// 	: [];

	return {
		title: title?.trim(),
		description: description?.trim(),
		group: group,
		goal: goal?.map(({ id, target }) => ({
			id: id,
			target: Number(target),
		})),
		promotedObject: {
			type: promotedObject?.type ?? ECampaignPromotedType.catalogs,
			ids: promotedObject?.value
				? promotedObject?.value?.map(({ value }) => value)
				: [],
		},
		destination: destination?.trim(),
		audience,
		tone,
		promotion,
		// channels: selectedChannels,
		budget: {
			...budget,
			currency: budget?.currency ?? 'USD',
			total: Number(budget?.total ?? 0),
			breakdown: campaign?.budget?.breakdown ?? [],
		},
		placements,
	};
};
