import { FC } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Flex, FlexProps, Icon, IconButton } from '@chakra-ui/react';

interface DataTablePaginationProps {
	page: number;
	pages: number;
	nextPage: () => void;
	prevPage: () => void;
	goToPage: (pageNumber: number) => void;
	containerProps?: FlexProps;
}

const DataTablePagination: FC<DataTablePaginationProps> = ({
	page,
	pages,
	nextPage,
	prevPage,
	goToPage,
	containerProps,
}) => {
	const canGoBack = page > 1;
	const canGoNext = page < pages;
	const pagesArr = Array.from(Array(pages).keys());

	if (pages <= 1) return null;

	return (
		<Flex mt={8} justify="flex-end" gap={2} {...containerProps}>
			<IconButton
				aria-label="Next"
				colorScheme="gray"
				onClick={prevPage}
				variant="outline"
				isDisabled={!canGoBack}
			>
				<Icon w={6} h={6} as={ChevronLeftIcon} />
			</IconButton>
			{pagesArr.map((num, index) => (
				<Button
					key={num}
					onClick={() => goToPage(index + 1)}
					colorScheme="gray"
					variant={page === index + 1 ? 'solid' : 'outline'}
				>
					{index + 1}
				</Button>
			))}
			<IconButton
				aria-label="Next"
				colorScheme="gray"
				bgSize="xs"
				onClick={nextPage}
				variant="outline"
				isDisabled={!canGoNext}
			>
				<Icon w={6} h={6} as={ChevronRightIcon} />
			</IconButton>
		</Flex>
	);
};

export default DataTablePagination;
