import {
	Box,
	Button,
	Flex,
	Text,
	Image,
	useBreakpointValue,
	Skeleton,
	Alert,
	AlertIcon,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { omit } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
	FileInput,
	MultiSelectCreateableInputHook,
	MultiSelectValuesHook,
	SelectSearchInputHook,
	StringInputHook,
} from 'src/components/common/form';
import MultiLevelDropdown from 'src/components/common/MultiLevelDropdown';
import ConfigFormModal from 'src/components/config/ConfigFormModal';
import { AppInputConfigContext, withAppConfigProvider } from 'src/contexts';
import UserContext from 'src/contexts/UserContext';
import useAccountConfigOptions from 'src/hooks/config/useAccountConfigOptions';
import useAccountConfig from 'src/hooks/useAccountConfig';
import useImage from 'src/hooks/useImage';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import { IAccountConfigOption, IOption } from 'src/lib/schemas/misc';
import { ISignupForm, SignupSchema } from 'src/lib/schemas/scrap/signup';
import { getIndustries, IIndustry } from 'src/services/industries';
import { scrapeCatalogs, scrapeData } from 'src/services/scraping';
import { ICatalog, ICatalogForm } from 'src/lib/schemas';
import http from 'src/services/http';
import Header from './Header';
import { toastError } from 'src/services/toast';
import { createAccountConfigOptions } from 'src/services/account';
import { ProductFormModal } from 'src/components/commerce/products';
import useCatalogKinds from 'src/hooks/useCatalogKinds';
import { IScrapeDataResponse } from 'src/lib/schemas/scrapping';

const IMAGE_NOT_VALID_MESSAGE = 'Image URL is not valid';

interface IPreRegisterForm {
	onCreateFirstCampagin: (data: any) => void;
	onSkip: () => void;
	onProductChange: (product: ICatalog) => void;
	route: string;
	selectedProduct?: ICatalog;
	catalog: ICatalog[];
	setCatalog: React.Dispatch<React.SetStateAction<ICatalog[]>>;
	onFetchDataFail: () => void;
}

const PreRegisterForm: FC<IPreRegisterForm> = ({
	onCreateFirstCampagin,
	onSkip,
	onProductChange,
	route,
	selectedProduct,
	catalog,
	setCatalog,
	onFetchDataFail,
}) => {
	const [industries, setIndustries] = useState<IIndustry[] | null>(null);
	const [isIndustriesLoading, setIsIndustriesLoading] = useState(true);
	const [customFonts, setCustomFonts] = useState<IOption[]>([]);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const [isExtraDataLoading, setIsExtraDataLoading] = useState(true);
	const { user } = useContext(UserContext);
	const { isBase64Image, isSvg } = useImage();
	const { config, isLoading: isConfigLoading } = useAccountConfig();
	const formToneToggle = useToggleWithPayload<IAccountConfigOption>();
	const formAudienceToggle = useToggleWithPayload<IAccountConfigOption>();
	const formProductToggle = useToggleWithPayload<Partial<ICatalog>>();
	const { catalogKinds } = useCatalogKinds();
	const { inputConfig, loadingInputs, loadedInputs } = useContext(
		AppInputConfigContext,
	);
	const { fetchConfig: fetchAudiences, createConfig: createAudience } =
		useAccountConfigOptions('Audience');
	const { fetchConfig: fetchTones, createConfig: createTone } =
		useAccountConfigOptions('Tone');

	useEffect(() => {
		if (!user || loadingInputs['Audience'] || loadedInputs['Audience']) return;
		fetchAudiences();
	}, [user]);
	useEffect(() => {
		if (!user || loadingInputs['Tone'] || loadedInputs['Tone']) return;
		fetchTones();
	}, [user]);

	const resetLoadingStates = () => {
		setIsDataLoading(false);
		setIsExtraDataLoading(false);
	};

	const isLoading = isDataLoading || isExtraDataLoading;
	const fontOptions = config?.fonts
		? [...config.fonts, ...customFonts]
		: [...customFonts];

	const formMethods = useForm<ISignupForm>({
		resolver: zodResolver(SignupSchema),
	});
	const { formState, setValue, setError, watch, clearErrors, setFocus } =
		formMethods;

	const name = watch('name');
	const industry = watch('industry');
	const fontType = watch('fontType');
	const logo = watch('logo');
	const audiences = formMethods.watch('audiences');
	const tones = formMethods.watch('tones');
	const catalogsFound = formMethods.watch('catalogsFound');
	const logoHasError = formState.errors['logo'];

	const handleSubmit = formMethods.handleSubmit(async (formData) => {
		if (logoHasError) {
			setFocus('logo');
			return;
		}
		try {
			onCreateFirstCampagin(formData);
			scrapeCatalogs(route);
		} catch (error) {
			console.error(error);
		}
	});

	const onExtraDataLoaded = (data: any) => {
		setValue('tones', data.tones);
		setValue('audiences', data.audiences);
	};

	const onDataLoaded = (data: IScrapeDataResponse) => {
		const { deduceTonesAudiencesExecution, catalogsFound, brand, title } =
			data.output;
		const { name, description, industry, logo, url, keywords } = brand;

		processCallback(deduceTonesAudiencesExecution, onExtraDataLoaded, {
			setIsExtraDataLoading,
		});

		name && setValue('name', name);
		url && setValue('websiteLink', url);
		title && setValue('title', title);
		description && setValue('description', description);
		industry && setValue('industry', industry);
		logo && setValue('logo', logo);
		setValue('catalogsFound', catalogsFound ?? 0);
		setValue('keywords', keywords);
	};

	const processCallback = async (
		callbackUrl: string,
		onSuccess: (data: any) => void,
		loadingStates: Partial<{
			setIsExtraDataLoading: (value: boolean) => void;
			setIsDataLoading: (value: boolean) => void;
		}>,
		isFromFetchData: boolean = false,
	) => {
		const setLoadingStates = (value: boolean) => {
			Object.values(loadingStates).forEach((setter) => {
				if (setter) setter(value);
			});
		};
		try {
			const response = await http.get(callbackUrl);
			const { status, body } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(
					() => processCallback(callbackUrl, onSuccess, loadingStates),
					1500,
				);
			} else if (status === 'error' || status === 'failed') {
				setLoadingStates(false);
				if (isFromFetchData) onFetchDataFail();
			} else if (status === 'successful') {
				onSuccess(body);
				setLoadingStates(false);
			}
		} catch (e) {
			setLoadingStates(false);
		}
	};

	const fetchData = async () => {
		try {
			const { callback } = await scrapeData(route);
			processCallback(callback, onDataLoaded, { setIsDataLoading }, true);
		} catch (error) {
			onFetchDataFail();
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		catalog && onProductChange(catalog[0]);
	}, [catalog]);

	const fetchIndustries = useCallback(async () => {
		if (!user) return;
		const response = await getIndustries();
		response && setIndustries(response);
		setIsIndustriesLoading(false);
	}, [user]);

	const loadCustomFont = useCallback(async () => {
		const customFont = new FontFace('CustomFont', `url(${fontType!.value})`);
		await customFont.load();
		document.fonts.add(customFont);
	}, [fontType]);

	useEffect(() => {
		fontType && loadCustomFont();
	}, [fontType, loadCustomFont]);

	useEffect(() => {
		fetchIndustries();
	}, [fetchIndustries]);

	const handleProductChange = useCallback(
		(product: ICatalog) => {
			onProductChange(product);
		},
		[onProductChange],
	);

	const handleLogoChange = useCallback(
		(url: string) => {
			setValue('logo', url);
		},
		[setValue],
	);

	const handleTypographyChange = useCallback(
		(url: any) => {
			setValue('fontType', url);
		},
		[setValue],
	);

	const handleIndustryChange = useCallback(
		(industry: IIndustry) => {
			setValue('industry', omit(industry, 'children', 'parentId', '_id'));
		},
		[setValue],
	);

	const onImageError = () => {
		setError('logo', { message: IMAGE_NOT_VALID_MESSAGE });
	};

	const checkImageErrors = (url = logo) => {
		if (url && (isBase64Image(url) || isSvg(url))) {
			setError('logo', { message: IMAGE_NOT_VALID_MESSAGE });
			return;
		}
		clearErrors('logo');
	};

	useEffect(() => {
		checkImageErrors();
	}, [logo]);

	const handleCreateAudience = async (payload: IAccountConfigOption) => {
		try {
			const newAudience = await createAudience(payload);
			formMethods.setValue('audiences', [...(audiences || []), newAudience.id]);
			clearErrors('audiences');
		} catch (error: any) {
			toastError('There was an issue creating the new audience');
		}
	};

	const handleCreateAudienceOption = async (name: string) => {
		if (!name.trim()) {
			formAudienceToggle.onOpen();
			return;
		}

		await handleCreateAudience({ name });
	};

	const handleCreateToneOption = async (name: string) => {
		if (!name.trim()) {
			formToneToggle.onOpen();
			return;
		}

		await handleCreateTone({ name });
	};

	const handleCreateTone = async (payload: IAccountConfigOption) => {
		try {
			const newTone = await createTone(payload);
			formMethods.setValue('tones', [...(tones || []), newTone.id]);
			clearErrors('tones');
		} catch (error: any) {
			toastError('There was an issue creating the new tone');
		}
	};

	const handleCreateProduct = async (payload: ICatalogForm) => {
		try {
			const createdCatalog = await createAccountConfigOptions(
				'catalogs',
				payload,
			);
			setCatalog((prevState) => [...prevState, createdCatalog]);
			onProductChange(createdCatalog);
			// formMethods.setValue('promotedObject.value', [
			// 	{
			// 		value: createdCatalog._id,
			// 		label: payload.name,
			// 	},
			// ]);
			// formMethods.setValue('destination', payload.url);
		} catch (error: any) {
			toastError(error);
		}
	};

	const flexDirection = useBreakpointValue<'column' | 'row'>({
		base: 'column',
		md: 'row',
	});
	const gapValue = useBreakpointValue({ base: '50px', lg: '100px' });

	return (
		<Flex direction="column">
			<Header
				step={1}
				title="Our AI detected the following information"
				subtitle="The information below will be used to generate your first ad"
			/>
			<FormProvider {...formMethods}>
				<form onSubmit={(e) => e.preventDefault()}>
					<Box p={{ base: '10px', md: '25px' }} mt={{ base: 5, md: 10 }}>
						<Flex direction="column" gap="35px">
							<Flex
								direction={flexDirection}
								gap={gapValue}
								alignItems="flex-start"
							>
								<Flex flex={1} w="full">
									<StringInputHook
										inputProps={{ defaultValue: name }}
										name="name"
										label="Name"
										formLabelProps={{ fontSize: '16px' }}
										placeholder="Enter company name"
										required
										isLoading={isDataLoading}
									/>
								</Flex>
								<Flex flex={1} w="full">
									<MultiLevelDropdown
										onSelect={handleIndustryChange}
										items={industries}
										label="Industry"
										formLabelProps={{ fontSize: '16px' }}
										initialValue={industry}
										isLoading={isDataLoading || isIndustriesLoading}
										required
									/>
								</Flex>
							</Flex>
							<Flex
								direction={flexDirection}
								gap={gapValue}
								alignItems="flex-start"
							>
								<Flex direction="column" flex={1}>
									<SelectSearchInputHook
										name="typography"
										label="Font (Optional)"
										placeholder="Select/search"
										options={fontOptions}
										onChange={handleTypographyChange}
										isLoading={isDataLoading}
										value={fontType}
										valueAsObject
										formLabelProps={{
											fontSize: '16px',
										}}
									/>
									<Flex
										justify="center"
										my="12px"
										h="8px"
										alignItems="center"
										gap={5}
									>
										<Box flex={1} h="1px" bg="#E2E8F0" />
										<Text fontSize="16px" fontWeight={400}>
											OR
										</Text>
										<Box flex={1} h="1px" bg="#E2E8F0" />
									</Flex>
									<Flex
										direction="column"
										textAlign={{ base: 'center', md: 'start' }}
										alignItems={{ base: 'center', md: 'flex-start' }}
									>
										<FileInput
											name="fontType"
											acceptFonts
											uploadButtonText="Upload font"
											uploadPath={`${user?.account}/fontType`}
											onUrlChange={handleTypographyChange}
										/>
										{isDataLoading && (
											<Skeleton h="100px" w="100px" borderRadius="md" mt={5} />
										)}
										{fontType && !isDataLoading && (
											<Text
												display="flex"
												h="full"
												wordBreak="break-word"
												mt={5}
												fontFamily="CustomFont"
												fontSize={18}
												alignItems="center"
												minH="100px"
											>
												ABCDEFGHIJKLMNOPQRSTUVWXYZ
												<br />
												abcdefghijklmnopqrstuvwxyz
											</Text>
										)}
									</Flex>
								</Flex>
								<Flex direction="column" flex={1}>
									<StringInputHook
										name="logo"
										label="Logo (Optional)"
										placeholder="Enter Logo URL"
										isLoading={isDataLoading}
										formLabelProps={{
											fontSize: '16px',
										}}
									/>
									<Flex
										justify="center"
										my="12px"
										h="8px"
										alignItems="center"
										gap={5}
									>
										<Box flex={1} h="1px" bg="#E2E8F0" />
										<Text fontSize="16px" fontWeight={400}>
											OR
										</Text>
										<Box flex={1} h="1px" bg="#E2E8F0" />
									</Flex>
									<Flex
										direction="column"
										alignItems={{ base: 'center', md: 'flex-start' }}
									>
										<FileInput
											name="logo"
											acceptImages
											uploadButtonText={logo ? 'Change image' : 'Upload image'}
											onUrlChange={handleLogoChange}
											uploadPath={`${user?.account}/logo`}
										/>
										{isDataLoading && (
											<Skeleton w="100px" h="100px" mt={5} borderRadius="md" />
										)}
										{logo && !logoHasError && (
											<Image
												mt={5}
												src={logo}
												alt="Account logo"
												maxW="100px"
												borderRadius="md"
												onError={onImageError}
												onLoad={(e: any) =>
													checkImageErrors(e.target.currentSrc)
												}
											/>
										)}
									</Flex>
								</Flex>
							</Flex>
							<Flex
								direction={flexDirection}
								gap={gapValue}
								alignItems="flex-start"
							>
								<Flex direction="column" flex={1}>
									<MultiSelectCreateableInputHook
										name="audiences"
										label="Who do you promote to?"
										placeholder="Select/search"
										options={inputConfig['Audience'] ?? []}
										isLoading={isExtraDataLoading}
										onCreateOption={handleCreateAudienceOption}
										isMulti
										required
										formLabelProps={{ fontSize: '16px' }}
									/>
									<MultiSelectValuesHook
										name="audiences"
										title="Target Audience"
										options={inputConfig['Audience'] ?? []}
										withLabel={false}
									/>
								</Flex>
								<Flex direction="column" flex={1}>
									<MultiSelectCreateableInputHook
										name="tones"
										label="What tone of voice do you use?"
										placeholder="Select/search"
										options={inputConfig['Tone'] ?? []}
										isLoading={isExtraDataLoading}
										onCreateOption={handleCreateToneOption}
										isMulti
										required
										formLabelProps={{ fontSize: '16px' }}
									/>
									<MultiSelectValuesHook
										name="tones"
										options={inputConfig['Tone'] ?? []}
										withLabel={false}
									/>
								</Flex>
							</Flex>
							{catalogsFound > 0 && (
								<Flex>
									<Alert borderRadius="md">
										<AlertIcon />
										We found products and services in your website, we will import them as well.
									</Alert>
								</Flex>
							)}
						</Flex>
					</Box>
					<Flex
						direction={flexDirection}
						pt="25px"
						justifyContent="end"
						gap={{ base: '15px', md: '25px' }}
					>
						<Button
							order={{ base: 2, md: 1 }}
							variant="orangeOutline"
							isLoading={formState.isSubmitting}
							_hover={{ bg: 'white' }}
							onClick={onSkip}
						>
							Skip, I just want to sign up
						</Button>
						<Button
							order={{ base: 1, md: 2 }}
							variant="orangeSolid"
							type="submit"
							isLoading={formState.isSubmitting || isLoading}
							onClick={() => {
								checkImageErrors();
								handleSubmit();
							}}
							_hover={{ bg: 'orange.base' }}
						>
							Generate ad for FREE
						</Button>
					</Flex>
				</form>
			</FormProvider>
			<ConfigFormModal
				isOpen={formToneToggle.isOpen}
				onClose={formToneToggle.onClose}
				onSubmit={handleCreateTone}
				initialValues={formToneToggle.payload}
				title="Tone"
			/>
			<ConfigFormModal
				isOpen={formAudienceToggle.isOpen}
				onClose={formAudienceToggle.onClose}
				onSubmit={handleCreateAudience}
				initialValues={formAudienceToggle.payload}
				title="Audience"
			/>
			<ProductFormModal
				isOpen={formProductToggle.isOpen}
				catalogKinds={catalogKinds}
				onClose={formProductToggle.onClose}
				onSubmit={handleCreateProduct}
				title="What are you promoting?"
				initialValues={formProductToggle.payload}
			/>
		</Flex>
	);
};

const PreRegisterWithAppConfigProvider = withAppConfigProvider(PreRegisterForm);

export default PreRegisterWithAppConfigProvider;
