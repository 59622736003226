import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
interface CircleArrowRightIconProps extends IconProps {
	filled?: boolean;
}

const CircleArrowRightIcon: FC<CircleArrowRightIconProps> = ({
	color,
	width = '40px',
	height = '42px',
	...props
}) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 40 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M0 21.0675C0 13.9901 3.73312 7.45715 9.955 3.87957C16.0991 0.301992 23.6431 0.301992 29.865 3.87957C36.0091 7.45715 39.82 13.9901 39.82 21.0675C39.82 28.2227 36.0091 34.7556 29.865 38.3332C23.6431 41.9108 16.0991 41.9108 9.955 38.3332C3.73312 34.7556 0 28.2227 0 21.0675ZM21.8543 31.1003L30.565 22.3896C31.2649 21.6897 31.2649 20.5231 30.565 19.7454L21.8543 11.1125C21.0766 10.3348 19.91 10.3348 19.21 11.1125C18.4323 11.8125 18.4323 12.9791 19.21 13.679L24.732 19.2009H10.5772C9.48836 19.2009 8.71062 20.0564 8.71062 21.0675C8.71062 22.1563 9.48836 22.9341 10.5772 22.9341L24.732 23.0118L19.21 28.5338C18.4323 29.2337 18.4323 30.4003 19.21 31.1003C19.91 31.878 21.0766 31.878 21.8543 31.1003Z"
				fill={color ? (color as string) : '#3182CE'}
			/>
		</Icon>
	);
};

export default CircleArrowRightIcon;
