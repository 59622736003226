import { useEffect, useState } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import { StringInputHook } from '../common/form';
import AlertMessage from '../common/AlertMessage';
import { PasswordSchema, IPasswordForm } from 'src/lib/schemas';
import authService from 'src/services/auth';

const SetPasswordForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const token = searchParams.get('token');
		if (!token) setError('Token is invalid or expired');
	}, []);

	const formMethods = useForm<IPasswordForm>({
		resolver: zodResolver(PasswordSchema),
	});

	const onSubmit = formMethods.handleSubmit(async (formData) => {
		setError(null);
		setIsLoading(true);
		const token = searchParams.get('token');
		if (!token) {
			setError('Token is invalid or expired');
			setIsLoading(false);
			return;
		}
		try {
			await authService.acceptInvitation(token, formData.password);
			window.location.href = '/home';
		} catch (error: any) {
			const { message } = error.response.data;
			setError(message);
		}
		formMethods.reset({ password: '', confirmPassword: '' });
		setIsLoading(false);
	});

	return (
		<FormProvider {...formMethods}>
			{error && <AlertMessage status="error">{error}</AlertMessage>}
			<form onSubmit={onSubmit}>
				<Flex direction="column" gap="28px">
					<StringInputHook
						label="Password"
						name="password"
						type={isPasswordVisible ? 'text' : 'password'}
						placeholder="Strong password"
						required
						inputProps={{ size: 'md', fontSize: '14px' }}
					/>
					<Flex direction="column" gap="3">
						<StringInputHook
							label="Confirm Password"
							name="confirmPassword"
							type={isPasswordVisible ? 'text' : 'password'}
							placeholder="Strong password"
							required
							inputProps={{ size: 'md', fontSize: '14px' }}
						/>
						<Flex alignItems="center">
							<input
								type="checkbox"
								name="showPassword"
								id="showPassword"
								onChange={() => setIsPasswordVisible(!isPasswordVisible)}
							/>
							<label htmlFor="showPassword">
								<Text fontSize="13px">&nbsp; Show password</Text>
							</label>
						</Flex>
					</Flex>
					<Button
						colorScheme="secondary"
						type="submit"
						loadingText="Resetting..."
						isLoading={isLoading}
					>
						Confirm
					</Button>
				</Flex>
			</form>
		</FormProvider>
	);
};
export default SetPasswordForm;
