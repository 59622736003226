import http from './http';
import {
	IAvailableChannels,
	ICompanyAccount,
	ICompanyAccountForm,
	ICompanyAccountUpdate,
} from 'src/lib/schemas';

const apiVersion = 'v1';
const accountUrl = `/${apiVersion}/account`;

const updateCompanyAccount = async (
	payload: ICompanyAccountForm | ICompanyAccountUpdate,
): Promise<ICompanyAccount> => {
	const { account, ...rest } = payload as any;
	if ('id' in rest) {
		delete rest.id;
	}
	const { data } = await http.patch<ICompanyAccount>(
		`${accountUrl}/id/${account}`,
		{ ...rest },
	);
	return data;
};

const getCompanyAccount = async (
	accountId: string,
): Promise<ICompanyAccount> => {
	const { data } = await http.get<ICompanyAccount>(
		`${accountUrl}/id/${accountId}`,
	);
	return data;
};

const getAvailableChannels = async (
	accountId?: string,
): Promise<IAvailableChannels> => {
	const { data } = await http.get<IAvailableChannels>(
		`${accountUrl}/id/${accountId}/available_channels`,
	);
	return data;
};

const getAccountConfigOptions = async <T>(configName: string): Promise<T[]> => {
	try {
		const { data } = await http.get(`${accountUrl}/${configName}`);
		return data;
	} catch (error) {
		return [];
	}
};

const createAccountConfigOptions = async <T>(
	configName: string,
	payload: T,
) => {
	const { data } = await http.post(`${accountUrl}/${configName}`, payload);
	return data;
};

const updateAccountConfigOptions = async <T extends { id?: string }>(
	configName: string,
	payload: T,
) => {
	const { id, ...rest } = payload;
	const { data } = await http.patch(`${accountUrl}/${configName}/${id}`, rest);
	return data;
};

const deleteAccountConfigOptions = async (configName: string, id: string) => {
	await http.delete(`${accountUrl}/${configName}/${id}`);
};

export {
	getCompanyAccount,
	updateCompanyAccount,
	getAvailableChannels,
	getAccountConfigOptions,
	createAccountConfigOptions,
	updateAccountConfigOptions,
	deleteAccountConfigOptions,
};
