import { useContext } from 'react';
import { AssistantChatContext } from 'src/contexts';
import { IField, IGetThreadParams, ThreadTypesEnum } from 'src/lib/schemas';

interface OpenAssistantParams {
	fieldName: string;
	reference: string;
	type: ThreadTypesEnum;
	control: any;
	variant: string;
	channel: string;
	displayName: string;
	value: string;
	setValue: (value: string) => void;
}

export const useAssistantField = () => {
	const { openAssistantChat } = useContext(AssistantChatContext);

	const openAssistant = ({
		fieldName,
		reference,
		type,
		channel,
		variant,
		displayName,
		value,
		setValue,
	}: OpenAssistantParams) => {
		const threadParams: IGetThreadParams = {
			type,
			reference,
			sub_reference: fieldName,
			property: fieldName,
		};
		const fieldParams: IField = {
			displayName,
			name: fieldName,
			channel,
			variant,
			content: value,
			id: fieldName,
			setValueV2: setValue,
		};
		openAssistantChat({ threadParams, fieldParams });
	};

	return { openAssistant };
};
