import { FC, useCallback, useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import DDTextField from './DDTextField';
import DDMediaField from './DDMediaField';
import {
	IChannelMediaAttributes,
	IDesignDirection,
	ImageLayer,
	TextLayer,
} from 'src/lib/schemas';
import Overlay from 'src/components/common/Overlay';
import { LockIcon } from 'src/assets/icons';

interface IPerview {
	flatFile: string;
	layeredFile: string;
	layers: (ImageLayer | TextLayer)[];
}
interface DDFieldsProps {
	designDirection: IDesignDirection;
	onLayersTextChange: (content: any, layers: any) => void;
	onChangePreviewPSD?: (
		flatFile: string,
		layeredFile: string,
		layers: (ImageLayer | TextLayer)[],
	) => void;
	previewPSD: IPerview;
	isChangingLayer: boolean;
	onChangingLayer: (status: boolean) => void;
}

const DDFields: FC<DDFieldsProps> = ({
	designDirection,
	onLayersTextChange,
	onChangePreviewPSD,
	previewPSD,
	isChangingLayer,
	onChangingLayer,
}) => {
	const { attributes, variant, locked: isLocked } = designDirection;
	const [prevDesignDirection, setPrevDesignDirection] =
		useState<IDesignDirection | null>(null);

	if (!attributes) return;

	const mediaContent = attributes as unknown as IChannelMediaAttributes;
	const layers = mediaContent?.image.layers;

	const filteredMediaLayers = layers?.filter(
		(layer) => layer.type === 'image' && layer.primary,
	) as ImageLayer[];

	const filteredTextLayers = layers?.filter(
		(layer) => layer.type === 'text' && layer.primary,
	) as TextLayer[];
	const defaultValues = useCallback(() => {
		return filteredTextLayers.reduce((acc: Record<string, string>, layer) => {
			acc[layer.name] = layer.content;
			return acc;
		}, {});
	}, [filteredTextLayers]);

	const formMethods = useForm({ defaultValues: defaultValues() });

	useEffect(() => {
		if (
			JSON.stringify(prevDesignDirection) !== JSON.stringify(designDirection)
		) {
			if (filteredTextLayers) {
				formMethods.reset(defaultValues());
			}
		}
		setPrevDesignDirection(designDirection);
	}, [designDirection, filteredTextLayers, defaultValues, prevDesignDirection]);

	const handleTextLayersChange = () => {
		const formValues = formMethods.getValues();

		onLayersTextChange(formValues, filteredTextLayers ?? []);
	};

	const setFieldValue = (field: string, value: string) => {
		formMethods.setValue(field, value);
		handleTextLayersChange();
	};

	return (
		<Flex
			w="50%"
			direction="column"
			minH="500px"
			gap={4}
			pb={8}
			position="relative"
		>
			{isLocked && <LockedOverlay />}
			<Flex>
				<Text fontSize="16px">Edit ad direction: &nbsp;</Text>
				<Text fontWeight="semibold" fontSize="16px">
					{' '}
					Option {variant}{' '}
				</Text>
			</Flex>
			<FormProvider {...formMethods}>
				<form>
					{filteredTextLayers.map((layer) => (
						<DDTextField
							key={layer.name}
							creativeData={designDirection}
							layer={layer}
							fieldChanges={handleTextLayersChange}
							isChangingLayer={isChangingLayer}
							setFieldValue={setFieldValue}
						/>
					))}
				</form>
			</FormProvider>

			{filteredMediaLayers.map((layer) => (
				<DDMediaField
					key={layer.name}
					layer={layer}
					variant={variant}
					previewPSD={previewPSD}
					onChangePreviewPSD={onChangePreviewPSD}
					isChangingLayer={isChangingLayer}
					onChangingLayer={onChangingLayer}
				/>
			))}
		</Flex>
	);
};

const LockedOverlay = () => {
	return (
		<Overlay
			zIndex={100}
			children={
				<Flex w="full" h="full" justify="center" align="center">
					<LockIcon color="white" w={6} h={6} />
				</Flex>
			}
		/>
	);
};

export default DDFields;
