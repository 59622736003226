import { FC } from 'react';
import { Button, Text, Flex, Image } from '@chakra-ui/react';

import { DownloadIcon } from 'src/assets/icons';
import { downloadFile } from 'src/lib/utils';
import { toastError } from 'src/services/toast';
import { IAppActionOutput } from 'src/lib/schemas';
import FusionLoading from 'src/components/common/FusionLoading';

interface MediaOutputWidgetProps {
	isParent?: boolean;
	output: IAppActionOutput;
	value: string;
	isProcessing: boolean;
	view?: string;
}

const MediaOutputWidget: FC<MediaOutputWidgetProps> = ({
	isParent,
	output,
	value,
	isProcessing,
	view,
}) => {
	const handleDownload = async () => {
		try {
			await downloadFile(value, output.name);
		} catch (error) {
			toastError(error);
		}
	};

	const isLoading = isProcessing && !value;

	return (
		<Flex direction="column" gap={2}>
			{view === 'text' ? (
				<>
					<Text fontWeight={isParent ? 'semibold' : 500}>{output.name}</Text>
					<FusionLoading isLoading={isLoading} />
					<Flex>
						<Button
							size="sm"
							rightIcon={<DownloadIcon width="16px" />}
							onClick={handleDownload}
						>
							Download
						</Button>
					</Flex>
				</>
			) : (
				<Flex justifyContent="center">
					<FusionLoading isLoading={isLoading} />
					{!isLoading && (
						<Image src={value} alt={output.name} width="auto" maxH="300px" />
					)}
				</Flex>
			)}
		</Flex>
	);
};

export default MediaOutputWidget;
