import { FC } from 'react';
import { IconProps } from '@chakra-ui/react';

const MiniGlobeIcon: FC<IconProps> = ({ color }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13"
			height="12"
			viewBox="0 0 13 12"
			fill="none"
		>
			<g clipPath="url(#clip0_1635_6319)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.3516 6.0003C12.3516 8.4228 10.8216 10.4876 8.67431 11.2773L8.72681 11.2503C9.60206 10.5003 10.0873 9.02504 10.2268 8.37479C10.3873 7.62479 10.4541 7.39979 9.85181 6.90479C9.61931 6.71504 9.34406 6.75781 9.07181 6.54781C8.88731 6.40531 8.80482 6.1053 8.61732 6.0003C8.17182 5.74755 7.78706 6.08279 7.15181 6.16754C6.86103 6.20027 6.5666 6.16879 6.28931 6.0753C6.28406 6.0753 6.28406 6.0753 6.27956 6.0723L6.21731 6.0498C6.09431 5.9883 6.15957 5.89529 6.22182 5.77979H6.21731C6.28181 5.65229 6.28182 5.4978 6.17982 5.38305C6.03732 5.22255 5.77482 5.22255 5.57682 5.21505C5.49732 5.2128 5.41931 5.21505 5.34206 5.21805L5.33981 5.21505C5.30981 5.21505 5.27681 5.21805 5.24681 5.21805H5.21906C4.97681 5.2233 4.71956 5.19255 4.54931 4.98255C4.35206 4.7178 4.33181 4.4328 4.62206 4.16505C4.83206 3.97005 5.26931 3.56505 5.97731 4.00005C6.42956 4.27755 6.85181 4.5003 7.22681 4.37505C7.47431 4.29255 7.58682 4.14781 7.29732 3.72256C7.18203 3.56415 7.1253 3.37063 7.13681 3.17505C7.18706 2.59305 7.65206 2.54504 8.00981 2.27504C8.35931 2.00804 8.51982 1.56779 8.41932 1.18229C8.33982 0.872539 8.03681 0.527555 7.45931 0.424805C8.81312 0.600879 10.0565 1.26394 10.957 2.29005C11.8575 3.31617 12.3528 4.63508 12.3516 6.0003Z"
					fill="#1A202C"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.30682 11.3973C7.4684 11.6431 6.58424 11.6901 5.72449 11.5346C4.86473 11.3792 4.05302 11.0255 3.35374 10.5017C2.65446 9.97796 2.08684 9.29845 1.69591 8.51709C1.30498 7.73573 1.10148 6.87403 1.10157 6.00033C1.10042 5.06403 1.33364 4.14233 1.77996 3.31925C2.22627 2.49618 2.8715 1.79792 3.65682 1.28809C3.09574 1.75141 2.66789 2.35552 2.41707 3.03857C1.85232 4.70132 2.25432 6.09483 4.13457 6.09483C4.75932 6.09483 5.18457 6.51558 5.13432 7.87608C5.12457 8.17833 5.52432 9.21107 6.97182 9.29357C7.49682 9.32357 7.85982 10.0833 7.96932 10.5986C8.01432 10.8161 8.06457 11.0861 8.16207 11.3261C8.17459 11.3526 8.19569 11.3741 8.22195 11.3872C8.24821 11.4003 8.27811 11.4034 8.30682 11.3973Z"
					fill="#1A202C"
				/>
				<path
					d="M12.7266 6C12.7266 7.5913 12.0944 9.11743 10.9692 10.2426C9.84398 11.3679 8.31786 12 6.72656 12C5.13526 12 3.60914 11.3679 2.48392 10.2426C1.3587 9.11743 0.726562 7.5913 0.726562 6C0.726562 4.4087 1.3587 2.88257 2.48392 1.75735C3.60914 0.632136 5.13526 0 6.72656 0C8.31786 0 9.84398 0.632136 10.9692 1.75735C12.0944 2.88257 12.7266 4.4087 12.7266 6ZM11.9766 6C11.9766 4.60761 11.4234 3.27226 10.4389 2.2877C9.45431 1.30313 8.11895 0.75 6.72656 0.75C5.33418 0.75 3.99882 1.30313 3.01425 2.2877C2.02969 3.27226 1.47656 4.60761 1.47656 6C1.47656 7.39239 2.02969 8.72774 3.01425 9.7123C3.99882 10.6969 5.33418 11.25 6.72656 11.25C8.11895 11.25 9.45431 10.6969 10.4389 9.7123C11.4234 8.72774 11.9766 7.39239 11.9766 6Z"
					fill="#1A202C"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1635_6319">
					<rect
						width="12"
						height="12"
						fill="white"
						transform="translate(0.726562)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default MiniGlobeIcon;
