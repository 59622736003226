import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Heading,
	Image,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tag,
	Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'src/components/common/DataTable/DataTable';
import FusionLoading from 'src/components/common/FusionLoading';
import { InvalidAccountBanner } from 'src/components/dashboard';
import UserContext from 'src/contexts/UserContext';
import usePagination from 'src/hooks/usePagination';
import CustomContainer from 'src/components/common/CustomContainer';
import { toastError } from 'src/services/toast';
import { ColumnDef } from '@tanstack/react-table';
import { getTemplateById, getTemplates } from 'src/services/templates';
import { ITemplate } from 'src/contexts/templates/TemplatesContext';
import DataTablePagination from 'src/components/common/DataTable/DataTablePagination';

const Templates: React.FC = () => {
	const [accountTemplates, setAccountTemplates] = useState<ITemplate[]>([]);
	const [publicTemplates, setPublicTemplates] = useState<ITemplate[]>([]);
	const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
	const { user, isLoading: isUserLoading, account } = useContext(UserContext);
	const pagination = usePagination();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchTemplates = async () => {
			setIsLoadingTemplates(true);
			if (!user) {
				setIsLoadingTemplates(false);
				return;
			}
			try {
				const templates = await getTemplates(user.account);
				setAccountTemplates(
					templates.map((template) => ({
						id: template.id,
						name: template.id,
						enabled: template.enabled,
						thumbnail:
							template.attributes?.thumbnail ||
							'https://via.placeholder.com/150',
						attributes: template.attributes,
						variations: template.variations,
					})),
				);

				pagination.setPages(1);
			} catch (error) {
				toastError(`Failed to fetch templates: ${error}`);
			} finally {
				setIsLoadingTemplates(false);
			}
		};

		if (!isUserLoading) {
			fetchTemplates();
		}
	}, [user, isUserLoading, pagination.page]);

	const handleEditTemplate = async (id: string) => {
		setIsLoadingTemplates(true);
		try {
			const detailedTemplate = await getTemplateById(user!.account, id);

			navigate(`/account/templates/edit/${id}`, {
				state: { template: detailedTemplate },
			});
		} catch (error) {
			toastError(`Failed to load template: ${error}`);
			setIsLoadingTemplates(false);
		}
	};

	const handleCreateTemplate = () => {
		navigate('/account/templates/new');
	};

	const columns: ColumnDef<any, any>[] = [
		{
			accessorKey: 'thumbnail',
			header: 'Thumbnail',
			cell: ({ row }) => (
				<Image
					src={row.original.thumbnail}
					alt="Template Thumbnail"
					boxSize="50px"
				/>
			),
		},
		{
			accessorKey: 'name',
			header: 'Name',
			cell: ({ row }) => <Text>{row.original.name}</Text>,
		},
		{
			accessorKey: 'enabled',
			header: 'Status',
			cell: ({ row }) => (
				<Tag
					colorScheme={row.original.enabled ? 'green' : 'gray'}
					variant={row.original.enabled ? 'solid' : 'outline'}
					borderRadius="full"
				>
					{row.original.enabled ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
		{
			id: 'actions',
			header: 'Actions',
			cell: ({ row }) => (
				<Flex direction="row" gap={2}>
					<Button
						size="sm"
						onClick={() => handleEditTemplate(row.original.id)}
						colorScheme="secondary"
					>
						Edit
					</Button>
				</Flex>
			),
		},
	];

	const showInvalidAccountBanner = !isUserLoading && !user?.account;
	const showTemplates = !isUserLoading && user?.account;

	return (
		<CustomContainer>
			<FusionLoading isLoading={isUserLoading && !user?.account} />
			{showInvalidAccountBanner && <InvalidAccountBanner />}
			{showTemplates && (
				<Flex direction="column" gap={6} pb={24}>
					<Flex alignItems="center" justifyContent="space-between">
						<Heading>Templates</Heading>
						<Button
							size="sm"
							onClick={handleCreateTemplate}
							colorScheme="secondary"
						>
							Create New Template
						</Button>
					</Flex>
					<Tabs>
						<TabList>
							<Tab>Your Templates</Tab>
							<Tab>Public Templates</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<DataTable
									columns={columns}
									data={accountTemplates}
									isLoading={isLoadingTemplates}
									tableProps={{
										border: '1px solid #F5F5F5',
										minH: '400px',
									}}
									trProps={{
										borderBottomWidth: '1px',
										borderColor: '#F5F5F5',
									}}
								/>
								<DataTablePagination {...pagination} />
							</TabPanel>
							<TabPanel>
								<DataTable
									columns={columns}
									data={publicTemplates}
									isLoading={isLoadingTemplates}
									tableProps={{
										border: '1px solid #F5F5F5',
										minH: '400px',
									}}
									trProps={{
										borderBottomWidth: '1px',
										borderColor: '#F5F5F5',
									}}
								/>
								<DataTablePagination {...pagination} />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Flex>
			)}
		</CustomContainer>
	);
};

export default Templates;
