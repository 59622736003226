import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { ICompanyAccount, IUser } from 'src/lib/schemas';
import { IMenuItem, getMenu } from 'src/services/menu';

interface IUserContext {
	user: IUser | null;
	account: ICompanyAccount | null;
	isLoading: boolean;
	navList: IMenuItem[];
	setUser: (user: IUser | null) => void;
	setAccount: (account: ICompanyAccount | null) => void;
	setNavList: (navList: IMenuItem[]) => void;
}

const UserContext = createContext<IUserContext>({
	user: null,
	account: null,
	isLoading: false,
	navList: [],
	setUser: () => {},
	setAccount: () => {},
	setNavList: () => {},
});

interface UserProviderProps {
	children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
	const [user, setUser] = useState<IUser | null>(null);
	const [account, setAccount] = useState<ICompanyAccount | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [navList, setNavList] = useState<IMenuItem[]>([]);

	useEffect(() => {
		const fetchMenu = async () => {
			try {
				const menuData = await getMenu();
				setNavList(menuData);
			} catch (error) {
				console.error('Error fetching menu:', error);
			}
		};

		if (user) {
			fetchMenu();
		}
	}, [user]);

	return (
		<UserContext.Provider
			value={{
				user,
				account,
				isLoading,
				navList,
				setUser,
				setAccount,
				setNavList,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export default UserContext;
