import { FC } from 'react';
import { Avatar, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';

interface UserAvatarProps {
	name: string;
}

const UserAvatar: FC<UserAvatarProps> = ({ name }) => {
	return (
		<Tag size="lg" bg="#F6F6F7" p="6px 8px" gap="4px">
			<TagLeftIcon as={() => <Avatar size="xs" name={name} />} />
			<TagLabel fontSize="12px" fontWeight={400} lineHeight="20px">
				{name}
			</TagLabel>
		</Tag>
	);
};

export default UserAvatar;
