import { FormEvent, useContext, useEffect, useState } from 'react';
import {
	FieldPath,
	FieldValues,
	get,
	useController,
	useFormContext,
} from 'react-hook-form';

import CurrencyInput, { CurrencyInputProps } from './CurrencyInput';
import { formatCurrency, parseCurrency } from 'src/lib/utils';
import { createOrUpdateCampaign } from 'src/services/campaign';
import { CampaignContext } from 'src/contexts';
import useDebounce from 'src/hooks/v2/useDebounce';
import { IChannelGroup } from 'src/lib/schemas';
import { ICampaignBudget } from 'src/lib/schemas/campaign/newFlowCampaign';
import { generateBudgetBreakdownByPlacements } from 'src/components/campaign/CampaignBudget/parts/utils';

export interface CurrencyInputHookProps<T extends FieldValues>
	extends CurrencyInputProps {
	name: FieldPath<T>;
	requirementsLabel?: string;
	availableChannelGroups: IChannelGroup[] | null;
	setCurrentBudget: (val: ICampaignBudget) => void;
	requirementsLabelStyle?: React.CSSProperties;
	setIsSaving?: React.Dispatch<React.SetStateAction<boolean>>;
}

function CurrencyInputHook<T extends FieldValues = FieldValues>({
	name,
	requirementsLabel,
	availableChannelGroups,
	requirementsLabelStyle,
	setCurrentBudget,
	setIsSaving,
	...props
}: CurrencyInputHookProps<T>) {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { campaign } = useContext(CampaignContext);

	const { field } = useController({ control, name });
	const { onChange } = field;

	const [currentValue, setCurrentValue] = useState(() => {
		return campaign?.budget?.total ? formatCurrency(campaign.budget.total) : '';
	});

	const debouncedValue = useDebounce(currentValue, 100);

	useEffect(() => {
		if (campaign?.budget?.total) {
			setCurrentValue(formatCurrency(campaign.budget.total));
		}
	}, [campaign?.budget?.total]);

	useEffect(() => {
		const updateCampaignBudget = async () => {
			if (!campaign || !campaign.budget || debouncedValue === '') return;

			const newTotal = parseFloat(parseCurrency(debouncedValue ?? '0')) || 0;

			if (isNaN(newTotal)) return;

			const newBreakdown = generateBudgetBreakdownByPlacements(
				campaign.placements ?? [],
				availableChannelGroups ?? [],
				newTotal,
			);

			const newBudget = {
				...campaign.budget,
				total: newTotal,
				breakdown: newBreakdown,
			};
			setCurrentBudget(newBudget);
			setCurrentValue(formatCurrency(newTotal));

			try {
				await createOrUpdateCampaign(
					{
						budget: newBudget,
					},
					campaign.id,
				);
				setIsSaving && setIsSaving(false);
			} catch (error) {
				console.error('Error updating campaign:', error);
				setIsSaving && setIsSaving(false);
			}
		};

		if (
			debouncedValue !== undefined &&
			debouncedValue !== '' &&
			debouncedValue !== '$'
		) {
			updateCampaignBudget();
		} else if (debouncedValue === '' || debouncedValue === '$') {
			setCurrentValue('0');
			updateCampaignBudget();
		}
	}, [debouncedValue, campaign, availableChannelGroups]);

	const handleChange = (event: FormEvent<HTMLInputElement>) => {
		setIsSaving && setIsSaving(true);

		const inputValue = event.currentTarget.value;

		setCurrentValue(inputValue);

		onChange(parseCurrency(inputValue));
	};

	const error = get(errors, name);

	return (
		<CurrencyInput
			id={name}
			error={error?.message}
			requirementsLabelStyle={requirementsLabelStyle}
			requirementsLabel={requirementsLabel}
			{...props}
			inputProps={{
				...props.inputProps,
				onChange: handleChange,
				value: currentValue,
			}}
			ref={register(name).ref}
		/>
	);
}

export default CurrencyInputHook;
