import { FC } from 'react';
import { Box, Icon, keyframes } from '@chakra-ui/react';
import { SyncIcon } from 'src/assets/icons';

interface GeneratingIconProps {
	isRotating?: boolean;
}

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const GeneratingIcon: FC<GeneratingIconProps> = ({ isRotating }) => {
	const animation = isRotating ? `${spin} infinite 2s linear` : '';
	return (
		<Box animation={animation}>
			<Icon as={SyncIcon} />
		</Box>
	);
};

export default GeneratingIcon;
