import { FC, useEffect, useState } from 'react';
import {
	Button,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Textarea,
} from '@chakra-ui/react';

interface DescriptionEditModalProps {
	isOpen: boolean;
	onClose: () => void;
	value: string;
	onApply: (val: string) => void;
}

const DescriptionEditModal: FC<DescriptionEditModalProps> = ({
	isOpen,
	onClose,
	value,
	onApply,
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [inputValue, setInputValue] = useState<string>(value);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const handleClose = () => {
		if (isSubmitting) return;
		onClose();
	};

	const handleInputChange = (e: any) => {
		const inputValue = e.target.value;
		setInputValue(inputValue);
	};

	const handleApplyChanges = async () => {
		setIsSubmitting(true);
		await onApply(inputValue);
		setIsSubmitting(false);
	};

	return (
		<Modal isOpen={isOpen} onClose={handleClose} size="xl">
			<ModalOverlay zIndex={1800} />
			<ModalContent
				margin="auto"
				containerProps={{
					zIndex: 1900,
				}}
			>
				<ModalHeader>
					<Heading fontWeight="semibold">Edit Description</Heading>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Textarea
						variant="outline"
						placeholder="Enter description"
						fontSize="sm"
						value={inputValue}
						onChange={handleInputChange}
						minH="250px"
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="secondary"
						loadingText="Submitting..."
						isLoading={isSubmitting}
						onClick={handleApplyChanges}
					>
						Submit
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DescriptionEditModal;
