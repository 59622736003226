import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import VideoPlayer from 'src/components/common/VideoPlayer';

interface VideoCardProps {
	videoUrl: string;
	backgroundUrl?: string;
	thumbnailUrl: string;
	width?: string | number;
	height?: string | number;
}

const VideoCard: React.FC<VideoCardProps> = ({
	videoUrl,
	backgroundUrl,
	thumbnailUrl,
	width,
	height,
}) => {
	return (
		<Box
			position="relative"
			width={width}
			height={height}
			borderRadius="15px"
			background="linear-gradient(180deg, #F7480B 0%, #FF8A62 100%)"
			p={1}
			overflow="hidden"
		>
			<Image
				src={backgroundUrl}
				alt="Background"
				position="absolute"
				top="-10px"
				left="10px"
				width="100%"
				height="100%"
				zIndex={-1}
			/>
			<Box
				position="relative"
				borderRadius="15px"
				overflow="hidden"
				width="100%"
				height="100%"
				display="flex"
				justifyContent="center"
				alignItems="center"
				bg="white"
			>
				<VideoPlayer url={videoUrl} thumbnailUrl={thumbnailUrl} />
			</Box>
		</Box>
	);
};

export default VideoCard;
