import { FC } from 'react';
import { Center, Heading, Text, VStack } from '@chakra-ui/react';

interface AuthHeaderProps {
	title: string;
	subTitle?: string;
	description?: string;
}

const AuthHeader: FC<AuthHeaderProps> = ({ title, subTitle, description }) => {
	return (
		<Center>
			<VStack gap={3}>
				<Heading as="h2" fontSize="26px" fontWeight={600} lineHeight="20px">
					{title}
				</Heading>
				{subTitle && <Text>{subTitle}</Text>}
				{description && <Text>{description}</Text>}
			</VStack>
		</Center>
	);
};

export default AuthHeader;
