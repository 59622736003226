import { FC, useEffect } from 'react';
import { CloseIcon, InfoIcon } from '@chakra-ui/icons';
import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	IconButton,
	Text,
} from '@chakra-ui/react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
	DragDropContext,
	Draggable,
	DropResult,
	Droppable,
} from 'react-beautiful-dnd';

import { IAssistantCreativeData, IAttribute } from 'src/lib/schemas';
import { CircledAddIcon, DragHandleIcon } from 'src/assets/icons';
import { TextareaInputHook } from 'src/components/common/form';

interface TextAreaArrayInputWidgetProps {
	input: IAttribute;
	validationRules: Record<string, any>;
	fieldChanges?: () => void;
	maxLength?: number;
	creativeData?: IAssistantCreativeData;
}

const TextAreaArrayInputWidget: FC<TextAreaArrayInputWidgetProps> = ({
	input,
	validationRules,
	fieldChanges,
	maxLength,
	creativeData,
}) => {
	const { control, watch, setValue } = useFormContext();
	const items: string[] = watch(input.property) ?? [];

	const { fields, append, remove } = useFieldArray({
		control,
		name: input.property,
	});

	useEffect(() => {
		if (fields.length === 0) {
			append('');
		}
	}, [fields, append]);

	const handleAdd = () => {
		const newItems: string[] = [...items, ''];
		setValue(input.property, newItems);
	};

	const reorder = (list: any[], startIndex: number, endIndex: number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) return;
		setValue(
			input.property,
			reorder(items, result.source.index, result.destination.index),
		);
		fieldChanges && fieldChanges();
	};

	const required = validationRules.required;
	const error = null;
	// TODO: Errors are not being handled properly yet
	const hasError = !!error;
	const renderLabelIndicator = () => {
		if (!required) return null;

		if (error) return <InfoIcon color="#e53e3e" />;

		return <Text color="#e53e3e">*</Text>;
	};

	const setFieldValue = (field: any, value: string) => {
		setValue(field, value);
		fieldChanges && fieldChanges();
	};

	return (
		<Controller
			name={input.property}
			control={control}
			rules={validationRules}
			render={() => {
				return (
					<Flex direction="column" gap={3}>
						<FormControl isInvalid={hasError}>
							{input.name && (
								<FormLabel
									fontSize="sm"
									fontWeight="medium"
									display="inline-block"
								>
									<Flex gap={1} alignItems="center">
										{input.name}
										{renderLabelIndicator()}
									</Flex>
								</FormLabel>
							)}

							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="droppable">
									{(provided: any) => (
										<Flex
											direction="column"
											gap={2}
											pl={2}
											borderLeft="1px solid #E1E1E1"
											ref={provided?.innerRef}
											{...provided?.droppableProps}
										>
											{fields.map((item, index) => {
												return (
													<Draggable
														key={`${input.property}.${index}`}
														draggableId={`${input.property}.${index}`}
														index={index}
													>
														{(provided: any) => (
															<Flex
																key={index}
																alignItems="center"
																justifyContent="space-between"
																ref={provided.innerRef}
																{...provided.dragHandleProps}
																{...provided.draggableProps}
															>
																<Flex gap={1} flex={1} alignItems="flex-start">
																	<Flex mt="13px">
																		<DragHandleIcon />
																	</Flex>
																	<TextareaInputHook
																		key={item.id}
																		id={item.id}
																		name={`${input.property}[${index}]`}
																		placeholder={`Enter ${input.name.toLowerCase()}`}
																		maxLength={maxLength}
																		formControlProps={{
																			minH: '105px',
																		}}
																		withAssistant
																		setFieldValue={setFieldValue}
																		creativeData={creativeData}
																		displayName={input.name}
																		textareaProps={{
																			bg: 'white',
																			onChange: () => {
																				fieldChanges && fieldChanges();
																			},
																		}}
																	/>
																	<IconButton
																		aria-label="remove"
																		bg="transparent"
																		onClick={() => {
																			remove(index);
																			fieldChanges && fieldChanges();
																		}}
																		_hover={{ bg: 'transparent' }}
																		isDisabled={fields.length <= 1}
																	>
																		<CloseIcon fontSize="xs" />
																	</IconButton>
																</Flex>
															</Flex>
														)}
													</Draggable>
												);
											})}
											{provided.placeholder}
										</Flex>
									)}
								</Droppable>
							</DragDropContext>
							<Flex alignItems="center" justifyContent={'flex-start'}>
								{error && <FormErrorMessage>{error}</FormErrorMessage>}
							</Flex>
							<Button
								size="sm"
								p={0}
								colorScheme="#F7480B"
								color="#F7480B"
								onClick={handleAdd}
								fontWeight={700}
								leftIcon={<CircledAddIcon color="#F7480B" />}
							>
								Add {input.name}
							</Button>
						</FormControl>
					</Flex>
				);
			}}
		/>
	);
};

export default TextAreaArrayInputWidget;
