import {
	Box,
	Button,
	Flex,
	Text,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Input,
	InputGroup,
	InputLeftElement,
	FormControl,
	FormErrorMessage,
	HStack,
	Alert,
	AlertIcon,
} from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { generateBudgetBreakdownByPlacements } from 'src/components/campaign/CampaignBudget/parts/utils';
import { CampaignContext } from 'src/contexts';
import { CHANNELS_NAME_MAPPING } from 'src/lib/constants';
import { IChannelGroup } from 'src/lib/schemas';
import { ICampaignBudget } from 'src/lib/schemas/campaign/newFlowCampaign';
import { createOrUpdateCampaign } from 'src/services/campaign';

interface IBudgetBreakdownProps {
	budget?: ICampaignBudget;
	availableChannelGroups: IChannelGroup[] | null;
	setIsValidCampaignBudget: (val: boolean) => void;
}

const formatAmount = (amount: number) => {
	return amount % 1 === 0 ? amount.toFixed(0) : amount.toFixed(2);
};

const BudgetBreakdown = ({
	budget,
	availableChannelGroups,
	setIsValidCampaignBudget,
}: IBudgetBreakdownProps) => {
	const { id: campaignId, campaign } = useContext(CampaignContext);
	const [isEditing, setIsEditing] = useState(false);
	const [editedChannels, setEditedChannels] = useState(
		() =>
			budget?.breakdown?.map((item) => ({
				id: item.id,
				name: CHANNELS_NAME_MAPPING[item.id],
				amount: item.budget,
			})) || [],
	);
	const [editedBreakdown, setEditedBreakdown] = useState(
		() => budget?.breakdown || [],
	);
	const [errorMessages, setErrorMessages] = useState<{
		[key: string]: string | null;
	}>({});

	const [allocationError, setAllocationError] = useState<string | null>(null);

	const calculateRemainingBudget = () => {
		const totalAssigned = editedChannels.reduce(
			(total, channel) => total + channel.amount,
			0,
		);
		const remaining = (budget?.total ?? 0) - totalAssigned;
		return remaining;
	};
	useEffect(() => {
		const hasErrors = Object.values(errorMessages).some((msg) => msg !== null);
		setIsValidCampaignBudget(!(hasErrors || !!allocationError));
	}, [errorMessages, allocationError, setIsValidCampaignBudget]);

	useEffect(() => {
		const remainingBudget = calculateRemainingBudget();
		if (remainingBudget > 0) {
			setAllocationError(
				`Pending to allocate $${formatAmount(remainingBudget)}`,
			);
		} else {
			setAllocationError(null);
		}
	}, [editedChannels, budget]);

	const generatedAIAllocation = useMemo(() => {
		return generateBudgetBreakdownByPlacements(
			campaign?.placements ?? [],
			availableChannelGroups ?? [],
			budget?.total ?? 0,
		);
	}, [campaign?.placements, availableChannelGroups, budget?.total]);

	const isBudgetItemVisible = (item: any) => {
		return !availableChannelGroups?.some((group) =>
			group.channels.some(
				(channel) => channel.hidden_budget && channel.id === item.id,
			),
		);
	};

	useEffect(() => {
		if (budget?.breakdown?.length) {
			setEditedChannels(
				budget.breakdown?.map((item) => ({
					id: item.id,
					name: CHANNELS_NAME_MAPPING[item.id],
					amount: item.budget,
					hidden_budget: item.hidden_budget,
				})) || [],
			);
			setEditedBreakdown(budget.breakdown || []);
		}
	}, [budget?.breakdown]);

	useEffect(() => {
		if (!campaignId || !budget) return;

		const newBreakdown = isEditing ? editedBreakdown : generatedAIAllocation;

		const updateCampaign = async () => {
			try {
				await createOrUpdateCampaign(
					{
						budget: {
							...budget,
							breakdown: newBreakdown,
						},
					},
					campaignId,
				);
			} catch (error) {
				console.error('Error updating campaign:', error);
			}
		};

		const timeoutId = setTimeout(() => {
			updateCampaign();
		}, 500);

		return () => clearTimeout(timeoutId);
	}, [editedBreakdown, campaignId, budget?.total]);

	const handleManualDefineClick = () => {
		if (isEditing) {
			setEditedChannels(
				generatedAIAllocation.map((item) => ({
					id: item.id,
					name: CHANNELS_NAME_MAPPING[item.id],
					amount: item.budget,
				})),
			);
			setEditedBreakdown(generatedAIAllocation);
		}
		setIsEditing(!isEditing);
	};

	const handleAmountChange = useCallback(
		(id: string, value: string) => {
			const parsedValue = value === '' ? 0 : parseFloat(value);

			const totalSpent = editedChannels.reduce(
				(total, channel) =>
					total + (channel.id === id ? parsedValue : channel.amount),
				0,
			);

			const remainingBudget = (budget?.total ?? 0) - totalSpent;

			const updatedErrorMessages =
				totalSpent > (budget?.total ?? 0)
					? {
							...errorMessages,
							[id]: `You can only allocate up to $${formatAmount(
								remainingBudget,
							)} more to this channel.`,
					  }
					: {
							...errorMessages,
							[id]: null,
					  };

			const updatedBreakdown = editedBreakdown.map((item) =>
				item.id === id ? { ...item, budget: parsedValue } : item,
			);

			const updatedChannels = editedChannels.map((channel) =>
				channel.id === id ? { ...channel, amount: parsedValue } : channel,
			);

			setErrorMessages(updatedErrorMessages);
			setEditedBreakdown(updatedBreakdown);
			setEditedChannels(updatedChannels);
		},
		[budget?.total, editedChannels, editedBreakdown, errorMessages],
	);

	const handleKeyDown = (e: React.KeyboardEvent) => {
		const invalidChars = ['e', 'E', '+', '-', '.'];

		if (invalidChars.includes(e.key)) {
			e.preventDefault();
		}
	};

	const getPercentage = (itemBudget: number) =>
		budget?.total ? (itemBudget * 100) / budget.total : 0;

	const channels = editedChannels.map((item) => ({
		...item,
		percentage: getPercentage(item.amount),
	}));

	const getTotalAmount = () => {
		return channels.reduce((total, channel) => {
			return total + (channel.amount || 0);
		}, 0);
	};

	const getTotalPercentage = () => {
		const totalPercentage = channels.reduce((total, channel) => {
			return total + getPercentage(channel.amount);
		}, 0);
		return Math.min(totalPercentage, 100);
	};

	return (
		<Box borderWidth="1px" borderRadius="md">
			<HStack
				justifyContent="space-between"
				w="full"
				p={4}
				borderBottomWidth="1px"
			>
				<Text size="md">Budget breakdown</Text>
				<Button variant="orangeOutline" onClick={handleManualDefineClick}>
					{isEditing ? 'Revert to AI allocation' : 'Define manually'}
				</Button>
			</HStack>
			<Box p={4} bg="gray.50" borderRadius="md">
				{allocationError && (
					<Alert status="error" mb={4}>
						<AlertIcon />
						{allocationError}
					</Alert>
				)}
				<Box borderRadius="md" overflow="hidden_budget" borderWidth="1px">
					<Table variant="simple">
						<Tbody bg="white">
							{channels
								.filter((channel) => isBudgetItemVisible(channel))
								.map((channel) => (
									<Tr key={channel.id}>
										<Td>{channel.name} Ads</Td>
										<Td isNumeric>
											{isEditing ? (
												<FormControl
													isInvalid={!!errorMessages[channel.id]}
													maxW="100px"
													float="right"
												>
													<InputGroup justifyContent="flex-end">
														<Input
															bg="white"
															borderRadius="md"
															type="number"
															value={
																channel.amount !== 0
																	? String(channel.amount)
																	: 0
															}
															textAlign="right"
															onChange={(e) =>
																handleAmountChange(channel.id, e.target.value)
															}
															onKeyDown={handleKeyDown}
															size="sm"
															errorBorderColor="red.300"
														/>
														<InputLeftElement
															alignSelf="center"
															pointerEvents="none"
															color="gray.300"
															fontSize="1em"
															children="$"
															top="50%"
															bottom="50%"
														/>
													</InputGroup>
													{errorMessages[channel.id] && (
														<FormErrorMessage>
															{errorMessages[channel.id]}
														</FormErrorMessage>
													)}
												</FormControl>
											) : (
												`$${formatAmount(channel.amount)}`
											)}
										</Td>
										<Td isNumeric>{formatAmount(channel.percentage)}%</Td>
									</Tr>
								))}
						</Tbody>
						<Tbody bg="gray.100">
							<Tr>
								<Td>Total</Td>
								<Td isNumeric>${formatAmount(getTotalAmount())}</Td>
								<Td isNumeric>{formatAmount(getTotalPercentage())}%</Td>
							</Tr>
						</Tbody>
					</Table>
				</Box>
			</Box>
		</Box>
	);
};

export default BudgetBreakdown;
