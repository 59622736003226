import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const LockIcon: FC<IconProps> = ({ color, ...props }) => {
	return (
		<Icon
			width="17px"
			height="17px"
			viewBox="0 0 17 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M5.34375 5.71875V7.5H11.2812V5.71875C11.2812 4.08594 9.94531 2.75 8.3125 2.75C6.64258 2.75 5.34375 4.08594 5.34375 5.71875ZM2.96875 7.5V5.71875C2.96875 2.78711 5.34375 0.375 8.3125 0.375C11.2441 0.375 13.6562 2.78711 13.6562 5.71875V7.5H14.25C15.5488 7.5 16.625 8.57617 16.625 9.875V17C16.625 18.3359 15.5488 19.375 14.25 19.375H2.375C1.03906 19.375 0 18.3359 0 17V9.875C0 8.57617 1.03906 7.5 2.375 7.5H2.96875Z"
				fill={color ? (color as string) : '#3182CE'}
			/>
		</Icon>
	);
};

export default LockIcon;
