import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { useFormContext } from 'react-hook-form';

import { MarketingChannelInputHook } from 'src/components/common/form';
import { IChannel } from 'src/lib/schemas';

interface MarketingChannelsFormProps {
	availableChannels: IChannel[];
	error?: string;
	requirementsLabel?: string;
	selectedChannels?: string[];
}

const MarketingChannelsForm: FC<MarketingChannelsFormProps> = ({
	error,
	availableChannels,
	requirementsLabel,
	selectedChannels,
}) => {
	const { register } = useFormContext();

	const isSelected = (id: string) =>
		selectedChannels ? selectedChannels.includes(id) : true;

	return (
		<Flex gap={16} ref={register('channels').ref}>
			<Flex direction="column" gap={2}>
				<Flex direction="column" alignItems="start" gap={1}>
					<Flex alignItems="center" gap={1}>
						<Text fontWeight={500}>Where do you want to show your ads?</Text>
						{error ? (
							<InfoIcon color="#e53e3e" />
						) : (
							<Text color="#e53e3e">*</Text>
						)}
					</Flex>
					<Text fontSize="12px" fontWeight={500} color="#959595">
						{requirementsLabel}
					</Text>
				</Flex>

				<Flex gap={5} alignItems="center" flexWrap="wrap">
					{availableChannels.map((item, index) => (
						<MarketingChannelInputHook
							key={index}
							label={item.name}
							name={`channels.${item.id}`}
							icon={item.icon}
							isSelected={isSelected(item.id)}
						/>
					))}
				</Flex>
				{error && <Text color="#E53E3E">{error}</Text>}
			</Flex>
			{/* <FacebookIntegrationModal isOpen={isOpen} onClose={onClose} /> */}
		</Flex>
	);
};

export default MarketingChannelsForm;
