import { useContext, useEffect, useState } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import FusionLoading from 'src/components/common/FusionLoading';
import UserContext from 'src/contexts/UserContext';
import {
	getFacebookAdAccounts,
	getFacebookPages,
	getInstagramPages,
} from 'src/services/integration';
import { IPage } from 'src/lib/schemas';
import { useLocation } from 'react-router-dom';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';
import IntegrationCard from './integrationCard';

interface IntegrationsProps {
	redirectedFromFB?: boolean;
	title?: string;
	isCampaignContext?: boolean;
}

const Integrations = ({
	redirectedFromFB = false,
	title,
	isCampaignContext = false,
}: IntegrationsProps) => {
	const { user } = useContext(UserContext);
	const { isLoading, integrations, mapPages } = useMyIntegrations();
	const { state } = useLocation();
	const [pages, setPages] = useState<IPage[]>([]);
	const [instagramPages, setInstagramPages] = useState<IPage[]>([]);
	const [pagesLoading, setPagesLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [adAccounts, setAdAccounts] = useState<any[]>([]);
	const [adAccountsLoading, setAdAccountsLoading] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);
	const [isConnectedToFb, setIsConnectedToFb] = useState(false);
	const [accessToken, setAccessToken] = useState();
	const [noPagesAvailable, setNoPagesAvailable] = useState(false);
	const [isIntegrationLoading, setIsIntegrationLoading] = useState();

	useEffect(() => {
		setAccessToken(
			integrations?.social?.fb?.attributes?.tokens?.accessToken ??
				state?.facebookTokens?.access_token,
		);
	}, [integrations, state]);

	useEffect(() => {
		setPagesLoading(true);
		const fetchFacebookPages = async (token: string) => {
			setPagesLoading(true);
			try {
				const data = await getFacebookPages(token);
				const mappedPages = mapPages(data);
				setPages(mappedPages);
				setError(null);

				if (!mappedPages || mappedPages.length === 0) {
					setNoPagesAvailable(true);
				}
			} catch (error: any) {
				console.error('Error fetching Facebook pages:', error);
				const errorMessage = error.response.data?.message || '';

				if (
					errorMessage.includes(
						'Error validating access token: The session has been invalidated because the user changed their password or Facebook has changed the session for security reasons',
					) ||
					error.response.status === 401
				) {
					setError(
						'Meta requires you to re-connect the integration, please click on “Connect” to re-enable the integration',
					);
				} else {
					setError(
						'There was an error connecting with Facebook, please try again.',
					);
				}
			} finally {
				setPagesLoading(false);
			}
		};
		const fetchFacebookAdAccounts = async (token: string) => {
			setAdAccountsLoading(true);
			try {
				const { data } = await getFacebookAdAccounts(token);
				setAdAccounts(data);
			} catch (error) {
				console.error('Error fetching Facebook ad accounts:', error);
			} finally {
				setAdAccountsLoading(false);
			}
		};
		const fetchInstagramPages = async (token: string) => {
			setPagesLoading(true);
			try {
				const response = await getInstagramPages(token);

				const businesses = response.businesses?.data;

				if (!businesses) {
					return;
				}

				const pages: IPage[] = businesses.flatMap(
					(business: any) =>
						business.instagram_business_accounts?.data.map((account: any) => ({
							id: account.id,
							name: account.username,
							image: account.profile_picture_url,
						})) || [],
				);
				setInstagramPages(pages);
			} catch (error) {
				console.error('Error fetching Instagram accounts:', error);
			} finally {
				setPagesLoading(false);
			}
		};

		if (accessToken) {
			setIsConnectedToFb(true);
			Promise.all([
				fetchFacebookPages(accessToken),
				fetchFacebookAdAccounts(accessToken),
				fetchInstagramPages(accessToken),
			]).finally(() => setInitialLoading(false));
		} else {
			setInitialLoading(false);
			setIsConnectedToFb(false);
		}
	}, [accessToken, mapPages]);

	if (!user) return null;

	const getTitle = () => {
		if (title) return title;
		if (isCampaignContext)
			return 'Before you can publish, complete the integrations';
		if (!isConnectedToFb && !error)
			return 'Before you can publish, complete the integrations';
		return 'Integrations';
	};

	return (
		<Box justifyContent="center" w="full">
			<FusionLoading isLoading={initialLoading} />
			{!initialLoading && (
				<Flex direction="column" gap={8}>
					<Heading fontSize="16px">{getTitle()}</Heading>
					<Flex
						gap={8}
						direction="column"
						flexWrap="wrap"
						display={!isLoading ? 'flex' : 'none'}
					>
						<>
							<IntegrationCard
								pages={pages}
								instagramPages={instagramPages ?? []}
								adAccounts={adAccounts}
								isConnetedToFb={isConnectedToFb}
								adAccountsLoading={adAccountsLoading}
								redirectedFromFB={redirectedFromFB}
								isCampaignContext={isCampaignContext}
								error={error}
								noPagesAvailable={noPagesAvailable}
							/>
						</>
					</Flex>
				</Flex>
			)}
		</Box>
	);
};

export default Integrations;
