import {
	FieldPath,
	FieldValues,
	get,
	useController,
	useFormContext,
} from 'react-hook-form';
import { Box } from '@chakra-ui/react';

import DatePickerInput, { DatePickerInputProps } from './DatePickerInput';

export interface DatePickerInputHookProps<T extends FieldValues>
	extends DatePickerInputProps {
	name: FieldPath<T>;
}

function DatePickerInputHook<T extends FieldValues = FieldValues>({
	name,
	value,
	...props
}: DatePickerInputHookProps<T>) {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	const { field } = useController({ control, name });
	const { onChange } = field;

	const error = get(errors, name);

	return (
		<Box className="customReactCampaignScheduleCalendar">
			<DatePickerInput
				id={name}
				name={name}
				value={value}
				error={error?.message}
				onChange={onChange}
				{...props}
			/>
		</Box>
	);
}

export default DatePickerInputHook;
