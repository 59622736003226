import { IAttribute } from 'src/lib/schemas';

export const getValidationRules = (input: IAttribute) => {
	const validationRules: any = {};
	if (input.validations && input.validations.length > 0)
		for (const validation of input.validations) {
			switch (validation.id) {
				case 'String.maxLength':
					validationRules['maxLength'] = {
						value: validation.value,
						message: `Maximum length is ${validation.value}`,
					};
					break;
				case 'multiline':
					validationRules['multiline'] = {
						value: validation.value,
						message: `${input.name} is required`,
					};
					break;
				case 'required':
					validationRules['required'] = {
						value: validation.value,
						message: `${input.name} is required`,
					};
					break;
			}
		}
	return validationRules;
};
