import { createColumnHelper } from '@tanstack/react-table';

import { IGroup } from 'src/lib/schemas';
import { DataTableActions, DataTableActionsProps } from '../common/DataTable';

export interface IGroupsListingColumn extends IGroup {
	action?: unknown;
}

const columnHelper = createColumnHelper<IGroupsListingColumn>();

export const getGroupsListingColumns = (
	onAction: DataTableActionsProps<IGroup>['onAction'],
) => {
	return [
		columnHelper.accessor('name', {
			header: 'Name',
		}),
		columnHelper.accessor('assignedCampaigns', {
			header: 'Assigned Campaigns',
		}),
		columnHelper.accessor('action', {
			header: 'Action',
			cell: (info) => {
				return (
					<DataTableActions
						data={info.row.original}
						actionsAllowed={['Edit', 'Delete']}
						onAction={onAction}
					/>
				);
			},
			meta: {
				ThProps: {
					width: '100px',
					textAlign: 'center',
				},
				TdProps: {
					textAlign: 'center',
				},
			},
		}),
	];
};
