import { FC, ReactNode, useEffect, useRef } from 'react';
import {
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	IconButton,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';

import { CalendarIcon } from 'src/assets/icons';
import 'react-datepicker/dist/react-datepicker.css';
import './reactDatePicker.css';

export interface DatePickerInputProps {
	label?: string;
	id?: string;
	value?: Date;
	placeholder?: string;
	error?: ReactNode;
	onChange?: (date: Date | null) => void;
	minDate?: Date;
	name: string;

	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
}

const DatePickerInput: FC<DatePickerInputProps> = ({
	value,
	onChange,
	error,
	label,
	name,
	minDate,
	placeholder,
	formControlProps,
	formLabelProps,
}) => {
	const datepickerRef = useRef<any>(null);

	const hasError = !!error;

	function handleCalendarIconClick() {
		const datepickerElement = datepickerRef.current;
		datepickerElement?.setFocus(true);
	}

	return (
		<FormControl isInvalid={hasError} {...formControlProps}>
			{label && (
				<FormLabel fontSize="sm" fontWeight={500} {...formLabelProps}>
					{label}
				</FormLabel>
			)}
			<DatePicker
				portalId="root"
				showIcon
				selected={value}
				onChange={(date) => {
					onChange && onChange(date);
				}}
				minDate={minDate}
				className="input"
				dateFormat="dd/MM/yyyy h:mm aa"
				placeholderText={placeholder}
				icon={
					<IconButton
						aria-label="Calendar"
						size="xs"
						bg="transparent"
						pos="absolute"
						right={0}
						minH={0}
						_hover={{
							bg: 'transparent',
						}}
						onClick={handleCalendarIconClick}
					>
						<CalendarIcon />
					</IconButton>
				}
				ref={datepickerRef}
				showTimeSelect
				shouldCloseOnSelect
			/>
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default DatePickerInput;
