import { createContext } from 'react';
import { IOption } from 'src/lib/schemas/misc';

export interface IAppInputConfig {
	[key: string]: IOption[];
}

export interface IAppInputLoading {
	[key: string]: boolean;
}

interface IAppInputConfigContext {
	loadingInputs: IAppInputLoading;
	loadedInputs: IAppInputLoading;
	inputConfig: IAppInputConfig;
	setInputConfig: (inputConfig: IAppInputConfig) => void;
	setLoadingInputConfig: (loadingInputs: IAppInputLoading) => void;
	setLoadedInputConfig: (loadedInputs: IAppInputLoading) => void;
}

const AppInputConfigContext = createContext<IAppInputConfigContext>({
	loadingInputs: {},
	loadedInputs: {},
	inputConfig: {},
	setInputConfig: (inputConfig: IAppInputConfig) => {},
	setLoadingInputConfig: (loadingInputs: IAppInputLoading) => {},
	setLoadedInputConfig: (loadedInputs: IAppInputLoading) => {},
});

export default AppInputConfigContext;
