import { ChangeEvent, useState, useEffect, useRef } from 'react';
import { Flex, Textarea } from '@chakra-ui/react';
import {
	FieldPath,
	FieldValues,
	useController,
	useFormContext,
} from 'react-hook-form';
import { TextareaInputProps } from '../common/form/TextareaInput/TextareaInput';

interface TextareaInputEditableHookProps<T extends FieldValues>
	extends TextareaInputProps {
	name: FieldPath<T>;
	isEditing: boolean;
	onSave: (value: string) => void;
	defaultValue?: string;
}

function TextareaInputEditableHook<T extends FieldValues = FieldValues>({
	name,
	isEditing,
	onSave,
	defaultValue = '',
	...props
}: TextareaInputEditableHookProps<T>) {
	const { control } = useFormContext();
	const { field } = useController({ control, name });
	const { value, onChange } = field;

	const [text, setText] = useState(defaultValue || value || '');
	const textareaRef = useRef<HTMLTextAreaElement | null>(null);

	useEffect(() => {
		if (!isEditing) {
			setText(defaultValue || value || '');
		}
	}, [defaultValue, value, isEditing]);

	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = 'auto';
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	}, [text]);

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setText(e.target.value);
		onChange(e.target.value);
	};

	return (
		<Flex direction="column">
			<Textarea
				ref={textareaRef}
				isReadOnly={!isEditing}
				value={text}
				onChange={handleChange}
				border={isEditing ? '1px solid' : 'none'}
				borderColor={isEditing ? 'gray.200' : 'transparent'}
				bg={isEditing ? 'white' : 'transparent'}
				resize="none"
				overflow="hidden"
			/>
		</Flex>
	);
}

export default TextareaInputEditableHook;
