import { Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface AspectRatioTextProps {
	imageUrl?: string;
}

export const AspectRatioText = ({ imageUrl }: AspectRatioTextProps) => {
	const useAspectRatio = (imageUrl: string | undefined) => {
		const [dimensions, setDimensions] = useState<
			{ width: number; height: number } | undefined
		>(undefined);

		useEffect(() => {
			if (!imageUrl) {
				setDimensions(undefined);
				return;
			}

			const img = new Image();
			img.src = imageUrl;
			img.onload = () => {
				setDimensions({ width: img.width, height: img.height });
			};
			img.onerror = () => {
				setDimensions(undefined);
			};

			return () => {
				setDimensions(undefined);
			};
		}, [imageUrl]);

		return dimensions;
	};

	const gcd = (a: number, b: number): number => {
		return b === 0 ? a : gcd(b, a % b);
	};

	const calculateAspectRatioText = (
		dimensions: { width: number; height: number } | undefined,
	) => {
		if (!dimensions) return '';
		const { width, height } = dimensions;

		const divisor = gcd(width, height);
		const aspectRatioWidth = width / divisor;
		const aspectRatioHeight = height / divisor;

		return `${aspectRatioWidth}:${aspectRatioHeight}`;
	};
	const dimensions = useAspectRatio(imageUrl);
	const aspectRatioText = calculateAspectRatioText(dimensions);

	return <Text>{aspectRatioText}</Text>;
};
