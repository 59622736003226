import { FC, ReactNode } from 'react';
import {
	Flex,
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	Select,
	SelectProps,
	Text,
} from '@chakra-ui/react';

import { IOption } from 'src/lib/schemas/misc';
import { InfoIcon } from '@chakra-ui/icons';

export interface SelectInputProps {
	label?: string;
	id?: string;
	placeholder?: string;
	error?: ReactNode;
	options: Array<IOption>;
	required?: boolean;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	selectProps?: SelectProps;
}

const SelectInput: FC<SelectInputProps> = ({
	error,
	label,
	placeholder,
	options,
	required,
	formControlProps,
	formLabelProps,
	selectProps
}) => {
	const hasError = !!error;

	const renderLabelIndicator = () => {
		if (!required) return null;

		if (error) return <InfoIcon color="#e53e3e" />;

		return <Text color="#e53e3e">*</Text>;
	};

	return (
		<FormControl isInvalid={hasError} {...formControlProps}>
			{label && (
				<FormLabel {...formLabelProps}>
					<Flex gap={1} alignItems="center">
						{label}
						{renderLabelIndicator()}
					</Flex>
				</FormLabel>
			)}
			<Select fontSize="14px" placeholder={placeholder} {...selectProps}>
				{options.map((option) => (
					<option key={`${option.value}-${option.label}`} value={option.value}>
						{option.label}
					</option>
				))}
			</Select>
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default SelectInput;
