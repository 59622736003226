import { FieldValues, FieldPath } from 'react-hook-form';
import MarketingChannelInput, {
	MarketingChannelInputProps,
} from './MarketingChannelInput';

interface MarketingChannelInputHookProps<T extends FieldValues>
	extends MarketingChannelInputProps {
	name: FieldPath<T>;
}

function MarketingChannelInputHook<T extends FieldValues = FieldValues>({
	name,
	...props
}: MarketingChannelInputHookProps<T>) {
	return <MarketingChannelInput {...props} name={name} />;
}

export default MarketingChannelInputHook;
