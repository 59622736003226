import axios from 'axios';
import config from 'src/config';

const axiosClient = axios.create({
	baseURL: config.app.genAiApiUrl,
});

export default {
	get: axiosClient.get,
	post: axiosClient.post,
	put: axiosClient.put,
	patch: axiosClient.patch,
	delete: axiosClient.delete,
};
