import React, { useContext } from 'react';
import { Flex, Button, Text, Spinner } from '@chakra-ui/react';
import { HiArrowUpTray, HiArrowDownTray } from 'react-icons/hi2';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { FaFont } from 'react-icons/fa';
import { IoSparklesSharp } from 'react-icons/io5';
import { AssistantChatContext } from 'src/contexts';

interface ActionsProps {
	onFontClick?: () => void;
	onCopilotClick?: () => void;
	onShowClick?: () => void;
	onUnlockClick?: () => void;
	onUploadClick?: (file: File) => void;
	onDownloadClick?: () => void;
	isLayerVisible?: boolean;
	isLoading?: boolean;
	layerName?: string;
}

const Actions: React.FC<ActionsProps> = ({
	onFontClick,
	onCopilotClick,
	onShowClick,
	onUnlockClick,
	onUploadClick,
	onDownloadClick,
	isLayerVisible,
	isLoading,
	layerName,
}) => {
	const { field } = useContext(AssistantChatContext);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file && onUploadClick) {
			onUploadClick(file);
		}
	};
	const isCopilotActive = layerName === field?.name;

	return (
		<Flex
			alignItems="center"
			bg="gray.50"
			borderBottomRadius="md"
			borderTopWidth={1}
			p={1}
		>
			{onUploadClick && (
				<Button
					as="label"
					cursor="pointer"
					size="sm"
					leftIcon={
						isLoading ? <Spinner size="sm" /> : <HiArrowUpTray size={18} />
					}
					variant="ghost"
					isDisabled={isLoading}
					spinnerPlacement="start"
				>
					<input
						type="file"
						accept="image/*"
						style={{ display: 'none' }}
						onChange={handleFileChange}
						aria-label="Upload an image"
					/>
					<Text fontWeight={500}>Upload</Text>
				</Button>
			)}
			{onDownloadClick && (
				<Button
					size="sm"
					leftIcon={
						isLoading ? <Spinner size="sm" /> : <HiArrowDownTray size={18} />
					}
					variant="ghost"
					onClick={onDownloadClick}
					isDisabled={isLoading}
					spinnerPlacement="start"
				>
					<Text fontWeight={500}>Download</Text>
				</Button>
			)}
			{onFontClick && (
				<Button
					size="sm"
					leftIcon={<FaFont size={18} />}
					variant="ghost"
					onClick={onFontClick}
				>
					<Text fontWeight={500}>Font</Text>
				</Button>
			)}
			{onCopilotClick && (
				<Button
					size="sm"
					leftIcon={<IoSparklesSharp size={18} color="#F7480B" />}
					variant="ghost"
					isActive={isCopilotActive}
					onClick={onCopilotClick}
				>
					<Text
						fontWeight={500}
						color={isCopilotActive ? 'orange.base' : 'black'}
					>
						Copilot
					</Text>
				</Button>
			)}
			{onShowClick && (
				<Button
					size="sm"
					leftIcon={
						isLayerVisible ? (
							<HiOutlineEyeOff size={18} />
						) : (
							<HiOutlineEye size={18} />
						)
					}
					variant="ghost"
					onClick={onShowClick}
				>
					<Text fontWeight={500}>{isLayerVisible ? 'Hide' : 'Show'}</Text>
				</Button>
			)}
			{/* {onUnlockClick && (
				<Button
					size="sm"
					leftIcon={<UnlockIcon />}
					variant="ghost"
					onClick={onUnlockClick}
				>
					<Text fontWeight={500}>{isLocked ? 'Lock' : 'Unlock'}</Text>
				</Button>
			)} */}
		</Flex>
	);
};

export default Actions;
