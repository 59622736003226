import { useEffect, useState } from 'react';

import { getCampaigns } from 'src/services/campaign';

const useFirstCampaign = (id?: string) => {
	const [isFirstCampaignWithCreatives, setisFirstCampaignWithCreatives] =
		useState(false);

	const fetchCampaigns = async () => {
		if (!id) return;
		const data = await getCampaigns('');
		const campaigns = data.results.filter((campaign) => campaign.id !== id);
		const hasCampaigns = campaigns.length > 0;
		if (!hasCampaigns) {
			setisFirstCampaignWithCreatives(true);
			return;
		}
		const existingCampaignsWithCreatives = campaigns.some(
			(campaign) =>
				campaign.designDirections?.some(
					(c) => c.channel === 'design-direction' && c.status === 'GENERATED',
				),
		);

		if (!existingCampaignsWithCreatives) setisFirstCampaignWithCreatives(true);
	};

	useEffect(() => {
		fetchCampaigns();
	}, [id]);

	return isFirstCampaignWithCreatives;
};

export default useFirstCampaign;
