import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { PromoCodesInputHook } from 'src/components/common/form';
import { IAppActionInput } from 'src/lib/schemas';

interface PromotionInputWidgetProps {
	input: IAppActionInput;
	validationRules: Record<string, any>;
}

const PromotionInputWidget: FC<PromotionInputWidgetProps> = ({
	input,
	validationRules,
}) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={input.property}
			control={control}
			rules={validationRules}
			render={() => (
				<PromoCodesInputHook
					name={input.property}
					label={input.name}
					required={validationRules.required}
				/>
			)}
		/>
	);
};

export default PromotionInputWidget;
