import { Container, Flex } from '@chakra-ui/react';

import { AuthHeader, ResetPasswordForm } from 'src/components/auth';

const ResetPassword = () => {
	return (
		<Container maxW="md">
			<Flex direction="column" gap={12}>
				<AuthHeader title="Reset Password" />
				<ResetPasswordForm />
			</Flex>
		</Container>
	);
};

export default ResetPassword;
