import { useState, useEffect, useRef } from 'react';
import { Box, Image, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Container from 'src/components/common/Container';
import { useTransition, animated } from '@react-spring/web';

interface BannerCarouselProps {
	images: string[];
}

const BannerCarousel = ({ images }: BannerCarouselProps) => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [imageHeight, setImageHeight] = useState(0);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const navigate = useNavigate();
	const imageRef = useRef<HTMLImageElement>(null);

	const transitions = useTransition(currentImageIndex, {
		keys: currentImageIndex,
		from: { transform: 'translateX(100%)' },
		enter: { transform: 'translateX(0%)' },
		leave: { transform: 'translateX(-100%)' },
		config: { duration: 800 },
		immediate: isFirstRender,
		onRest: () => setIsFirstRender(false),
	});

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
		}, 5000);

		return () => clearInterval(interval);
	}, [images.length]);

	useEffect(() => {
		setIsFirstRender(false);
	}, []);

	const handleDotClick = (index: number) => {
		setCurrentImageIndex(index);
	};

	const handleImageLoad = () => {
		if (imageRef.current) {
			setImageHeight(imageRef.current.clientHeight);
		}
	};

	return (
		<Box alignSelf="center" w={{ base: 'full', lg: '75%', '2xl': '75%' }}>
			<Container isBanner alignSelf="center">
				<Box
					position="relative"
					width="100%"
					overflow="hidden"
					height={`${imageHeight}px`}
				>
					{transitions((style, index) => (
						<animated.div
							key={index}
							style={{
								...style,
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
							}}
						>
							<Image
								ref={index === currentImageIndex ? imageRef : null}
								cursor="pointer"
								src={images[index]}
								alt={`banner-${index}`}
								onClick={() => navigate('/projects/campaigns/new')}
								borderRadius="lg"
								objectFit="cover"
								onLoad={handleImageLoad}
							/>
						</animated.div>
					))}
				</Box>
			</Container>
			<Flex justifyContent="center" mt={2}>
				{images.map((_, index) => (
					<Box
						key={index}
						w={2}
						h={2}
						bg={currentImageIndex === index ? 'orange.base' : 'gray.300'}
						borderRadius="50%"
						mx={1}
						cursor="pointer"
						onClick={() => handleDotClick(index)}
					/>
				))}
			</Flex>
		</Box>
	);
};

export default BannerCarousel;
