import { FC, useState } from 'react';
import {
	Button,
	Heading,
	Text,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { IUser } from 'src/lib/schemas';

export interface UserResendInviteConfirmationProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: (data: IUser) => void;
	data?: IUser;
}

const UserResendInviteConfirmation: FC<UserResendInviteConfirmationProps> = ({
	isOpen,
	onClose,
	onConfirm,
	data,
}) => {
	const [isResendingInvite, setIsResendingInvite] = useState(false);

	if (!data) return null;

	const handleClose = () => {
		if (isResendingInvite) return;
		onClose();
	};

	const handleResendInvite = async () => {
		setIsResendingInvite(true);
		await onConfirm(data);
		setIsResendingInvite(false);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Heading>Resend Invitation</Heading>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text mb={4}>
						Are you sure you want to resend invitation to &ldquo;{data.name}
						&rdquo;?
					</Text>
				</ModalBody>
				<ModalFooter>
					<Button variant="outline" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						colorScheme="secondary"
						ml={4}
						onClick={handleResendInvite}
						isLoading={isResendingInvite}
						loadingText="Resending invite..."
					>
						Resend
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default UserResendInviteConfirmation;
