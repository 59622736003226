import { ChangeEvent, useEffect, useState } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import {
	FieldPath,
	FieldValues,
	get,
	useController,
	useFormContext,
} from 'react-hook-form';
import { CloseIcon, CheckIcon } from '@chakra-ui/icons';

import StringInput, { StringInputProps } from './StringInput';
import { EditIcon } from 'src/assets/icons';

export interface StringInputEditableHookProps<T extends FieldValues>
	extends StringInputProps {
	name: FieldPath<T>;
}

function StringInputEditableHook<T extends FieldValues = FieldValues>({
	name,
	...props
}: StringInputEditableHookProps<T>) {
	const [text, setText] = useState('');
	const [isEditing, setIsEditing] = useState(false);
	const {
		control,
		register,
		formState: { errors },
	} = useFormContext();

	const { field } = useController({ control, name });
	const { value, onChange } = field;

	const error = get(errors, name);

	useEffect(() => {
		if (!value) setText('');
		else setText(value);
	}, [value]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const target = e.target;
		setText(target.value);
	};

	const handleAcceptValue = () => {
		onChange(text);
		setIsEditing(false);
	};

	const handleRejectValue = () => {
		setText(value);
		setIsEditing(false);
	};

	return (
		<Flex gap={2} w={isEditing ? '480px' : '444px'}>
			<StringInput
				id={name}
				error={error?.message}
				{...props}
				inputProps={{
					...props.inputProps,
					...register(name),
					onChange: handleChange,
					value: text,
					bg: isEditing ? 'inherit' : '#e8e8e8',
					placeholder: isEditing ? props.placeholder : '',
				}}
				formControlProps={{
					...props.formControlProps,
					isReadOnly: !isEditing,
				}}
			/>
			{!isEditing ? (
				<IconButton
					aria-label="Edit"
					bg="transparent"
					size="sm"
					mt="49px"
					onClick={() => setIsEditing(true)}
				>
					<EditIcon />
				</IconButton>
			) : (
				<Flex gap={1} mt="49px">
					<IconButton
						aria-label="Edit"
						bg="transparent"
						size="sm"
						onClick={handleRejectValue}
					>
						<CloseIcon w={3} color="#3182ce" />
					</IconButton>
					<IconButton
						aria-label="Edit"
						bg="transparent"
						size="sm"
						onClick={handleAcceptValue}
					>
						<CheckIcon w={5} color="#3182ce" />
					</IconButton>
				</Flex>
			)}
		</Flex>
	);
}

export default StringInputEditableHook;
