import {
	FieldValues,
	FieldPath,
	useController,
	useFormContext,
	get,
} from 'react-hook-form';
import MultiSelectInput, { MultiSelectInputProps } from './MultiSelectInput';

interface MultiSelectInputHookProps<T extends FieldValues>
	extends MultiSelectInputProps {
	name: FieldPath<T>;
	requirementsLabel?: string;
	direction?: 'row' | 'column';
}

function MultiSelectInputHook<T extends FieldValues = FieldValues>({
	name,
	requirementsLabel,
	direction ='column',
	...props
}: MultiSelectInputHookProps<T>) {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { field } = useController({ control, name });
	const { onChange, value } = field;
	const error = get(errors, name);

	return (
		<MultiSelectInput
			name={name}
			value={value}
			onChange={onChange}
			direction={direction}
			error={error?.message}
			requirementsLabel={requirementsLabel}
			{...props}
			ref={register(name).ref}
		/>
	);
	return (
		<MultiSelectInput
			name={name}
			value={value}
			onChange={onChange}
			error={error?.message}
			requirementsLabel={requirementsLabel}
			{...props}
			ref={register(name).ref}
		/>
	);
}

export default MultiSelectInputHook;
