import { FC, ReactNode } from 'react';
import {
	Flex,
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	Radio,
	RadioGroup,
	RadioGroupProps,
	Stack,
	Text,
} from '@chakra-ui/react';

import { IOption } from 'src/lib/schemas/misc';
import { InfoIcon } from '@chakra-ui/icons';

export interface RadioGroupInputProps {
	label?: string;
	id?: string;
	value?: string;
	error?: ReactNode;
	tooltip?: string;
	options: Array<IOption>;
	onChange?: (val: string | null) => void;
	required?: boolean;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	radioGroupProps?: RadioGroupProps;
}

const RadioGroupInput: FC<RadioGroupInputProps> = ({
	label,
	options,
	required,
	value,
	onChange,
	error,
	formControlProps,
	formLabelProps,
	radioGroupProps,
}) => {
	const renderLabelIndicator = () => {
		if (!required) return null;

		if (error) return <InfoIcon color="#e53e3e" />;

		return <Text color="#e53e3e">*</Text>;
	};
	return (
		<FormControl {...formControlProps}>
			{label && (
				<FormLabel fontSize="sm" fontWeight={500} {...formLabelProps}>
					<Flex gap={1} alignItems="center">
						<Text fontWeight={500}>{label}</Text>
						{renderLabelIndicator()}
					</Flex>
				</FormLabel>
			)}
			<RadioGroup value={value} onChange={onChange} {...radioGroupProps}>
				<Stack direction="row" py={2} gap={4}>
					{options.map((option, idx) => (
						<Radio key={idx} size="md" value={option.value as string}>
							<Text textTransform="capitalize" fontWeight={500}>
								{option.label}
							</Text>
						</Radio>
					))}
				</Stack>
			</RadioGroup>
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default RadioGroupInput;
