import { IPlacement } from '../account';
import { IUser } from '../user';

export interface ICampaignGoal {
	id?: string;
	target?: number;
}

export enum ECampaignPromotedType {
	catalogs = 'catalogs',
}

export interface ICampaignPromotedObject {
	type: ECampaignPromotedType;
	ids: Array<string>;
}

export interface ICampaignPromotion {
	code: string;
	description: string;
}

export interface ICampaignBudgetBreakdown {
	id: string;
	type: string;
	budget: number;
	hidden_budget?: boolean;
}

export interface ICampaignBudget {
	currency: string;
	total: number;
	breakdown?: Array<ICampaignBudgetBreakdown>;
}

export interface ICampaignBriefGoal {
	id: string;
	title: string;
	description: string;
}

export interface ICampaignBrief {
	title?: string;
	description: string;
	goals: Array<ICampaignBriefGoal>;
	prompt?: string;
}

export enum ECampaignScheduleType {
	'Evergreen' = 'Evergreen',
	'One-off' = 'One-off',
}

export interface ICampaignSchedule {
	type: ECampaignScheduleType;
	startDate: Date;
	endDate?: Date;
}

interface ImageDimension {
	width: string;
	height: string;
}

interface LayerPosition {
	top: number;
	left: number;
}

interface LayerDimension {
	width: number;
	height: number;
}

export interface ImageLayer {
	name: string;
	imageUrl: string;
	position: LayerPosition;
	dimensions: LayerDimension;
	type: string;
	primary: boolean;
	displayName: string;
	parentName: string;
	id?: number;
	visible?: boolean;
}

export interface TextLayer {
	id: string;
	name: string;
	content: string;
	fontSize: number;
	orientation: string;
	color: string;
	position: LayerPosition;
	dimensions: LayerDimension;
	type: string;
	primary: boolean;
	displayName: string;
	parentName: string;
	visible?: boolean;
}

export type CommonAttributes =
	| IChannelMediaAttributes
	| IChannelTextContent
	| IChannelEmailContent;

export interface IChannelMediaContent {
	dimensions: ImageDimension;
	layers: (ImageLayer | TextLayer)[];
	layeredFile: string;
	flatFile: string;
}
export interface IChannelMediaAttributes {
	primaryText?: string;
	headline?: string;
	cta?: string;
	image: IChannelMediaContent;
	callToAction?: string;
	output?: any;
}

export interface IChannelTextContent {
	id: string;
	text: string;
}

export interface IChannelEmailContent {
	id: string;
	subject: string;
	plainBody: string;
	htmlBody: string;
	CTA: string;
}

export interface ICampaign {
	id: string;
	group: string;
	status: string;
	title: string;
	description: string;
	goal?: Array<ICampaignGoal>;
	destination: string;
	audience: Array<string>;
	tone: Array<string>;
	promotedObject: ICampaignPromotedObject;
	promotion?: Array<ICampaignPromotion>;
	channels: Array<string>;
	budget: ICampaignBudget;
	brief?: ICampaignBrief;
	designDirections?: Array<IDesignDirection>;
	creatives?: Array<ICreative>;
	schedule?: Partial<ICampaignSchedule>;
	lastUpdatedBy?: IUser;
	updatedAt?: Date;
	placements?: string[];
}

export interface ICampaignsListingItem {
	id: string;
	title: string;
	group: string;
	status: string;
	progress: number;
}

export interface ICampaigns {
	limit: number;
	page: number;
	results: Array<ICampaign>;
	totalPages: number;
	totalResults: number;
}

export interface ICreativeRegeneratePayload {
	creativeId: string;
	properties?: {
		prompt: string;
	};
}

export interface ICreative {
	id: string;
	channel: string;
	prompt: string;
	mediaType: string;
	name: string;
	status: string;
	variant: string;
	designDirectionId?: string;
	content: {
		output: IChannelTextContent | IChannelEmailContent;
	};
	attributes: CommonAttributes;
	locked?: boolean;
	placements?: string[];
	app?: string;
}

export interface IDesignDirection {
	id: string;
	channel: string;
	mediaType: string;
	name: string;
	status: string;
	variant: string;
	content: {
		output: IChannelTextContent;
	};
	attributes: IChannelMediaAttributes | IChannelTextContent;
	locked?: boolean;
}
