import React, { useState } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Text,
} from '@chakra-ui/react';
import { ITag } from 'src/services/tags';

interface TagDeleteModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: (tag: ITag) => Promise<void>;
	tag?: ITag;
}

const TagDeleteModal: React.FC<TagDeleteModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	tag,
}) => {
	const [isDeleting, setIsDeleting] = useState(false);

	if (!tag) return null;

	const handleDelete = async () => {
		setIsDeleting(true);
		try {
			await onConfirm(tag);
			onClose();
		} catch (error) {
			console.error('Error deleting tag:', error);
		} finally {
			setIsDeleting(false);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay zIndex={1800} />
			<ModalContent
				containerProps={{
					zIndex: 1900,
				}}
			>
				<ModalHeader>Delete Tag</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>
						Are you sure you want to delete the tag &quot;{tag.name}&quot;?
					</Text>
				</ModalBody>
				<ModalFooter>
					<Button variant="ghost" mr={3} onClick={onClose}>
						Cancel
					</Button>
					<Button
						colorScheme="red"
						onClick={handleDelete}
						isLoading={isDeleting}
						loadingText="Deleting..."
					>
						Delete
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default TagDeleteModal;
