import React, { useEffect, useState } from 'react';
import {
	Button,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { StringInputHook } from 'src/components/common/form';
import AlertMessage from 'src/components/common/AlertMessage';
import {
	IAccountConfigOption,
	AccountConfigFormSchema,
} from 'src/lib/schemas/misc';

const INITIAL_STATE = {
	name: '',
};

interface ConfigFormProps {
	isOpen: boolean;
	onClose: () => void;
	initialValues?: IAccountConfigOption;
	onSubmit: (payload: IAccountConfigOption) => void;
	title: string;
}

const ConfigFormModal: React.FC<ConfigFormProps> = ({
	isOpen,
	onClose,
	onSubmit,
	initialValues,
	title,
}) => {
	const [error, setError] = useState<string | null>(null);
	const formMethods = useForm<IAccountConfigOption>({
		resolver: zodResolver(AccountConfigFormSchema),
	});
	const { formState, reset } = formMethods;

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		} else {
			reset(INITIAL_STATE);
		}
	}, [initialValues]);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [isOpen]);

	const handleClose = () => {
		if (formState.isSubmitting) return;
		reset(INITIAL_STATE);
		onClose();
	};

	const handleSubmit = formMethods.handleSubmit(async (payload) => {
		try {
			setError(null);
			await onSubmit(payload);
			formMethods.reset();
			onClose();
		} catch (error: any) {
			const { message } = error.response.data;
			setError(message);
		}
	});

	return (
		<Modal isOpen={isOpen} onClose={handleClose} size="lg">
			<ModalOverlay zIndex={1800} />
			<ModalContent
				containerProps={{
					zIndex: 1900,
				}}
			>
				<ModalHeader pb={2}>
					<Heading>Create {title}</Heading>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<FormProvider {...formMethods}>
						<form onSubmit={handleSubmit}>
							<Flex direction="column" gap={6}>
								{error && <AlertMessage status="error">{error}</AlertMessage>}
								<StringInputHook
									name="name"
									label={title}
									placeholder={`Enter name of the ${title.toLocaleLowerCase()}`}
									required
								/>
								<Flex justifyContent="right" gap={6}>
									<Button
										type="submit"
										colorScheme="secondary"
										isLoading={formState.isSubmitting}
										loadingText="Submitting..."
									>
										Submit
									</Button>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ConfigFormModal;
