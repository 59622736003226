import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const CheckCircleIcon: FC<IconProps> = ({ color, ...props }) => {
	return (
		<Icon
			width="20px"
			height="19px"
			viewBox="0 0 20 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10 18.25C6.76562 18.25 3.8125 16.5625 2.19531 13.75C0.578125 10.9727 0.578125 7.5625 2.19531 4.75C3.8125 1.97266 6.76562 0.25 10 0.25C13.1992 0.25 16.1523 1.97266 17.7695 4.75C19.3867 7.5625 19.3867 10.9727 17.7695 13.75C16.1523 16.5625 13.1992 18.25 10 18.25ZM13.9727 7.59766H13.9375C14.2891 7.28125 14.2891 6.75391 13.9375 6.40234C13.6211 6.08594 13.0938 6.08594 12.7773 6.40234L8.875 10.3398L7.22266 8.6875C6.87109 8.33594 6.34375 8.33594 6.02734 8.6875C5.67578 9.00391 5.67578 9.53125 6.02734 9.84766L8.27734 12.0977C8.59375 12.4492 9.12109 12.4492 9.47266 12.0977L13.9727 7.59766Z"
				fill={color ? (color as string) : '#2C6ECB'}
			/>
		</Icon>
	);
};

export default CheckCircleIcon;
