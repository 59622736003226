import {
	FieldValues,
	FieldPath,
	useController,
	useFormContext,
} from 'react-hook-form';
import PromoCodesInput, { PromoCodesInputProps } from './PromoCodesInput';

interface PromoCodesInputHookProps<T extends FieldValues>
	extends PromoCodesInputProps {
	name: FieldPath<T>;
	direction?: 'row' | 'column';
	requirementsLabelStyle?: React.CSSProperties;
}

function PromoCodesInputHook<T extends FieldValues = FieldValues>({
	name,
	direction,
	withRadioOptions,
	requirementsLabelStyle,
	...props
}: PromoCodesInputHookProps<T>) {
	const { control } = useFormContext();
	const { field } = useController({ control, name });
	const { onChange, value } = field;

	return (
		<PromoCodesInput
			onChange={onChange}
			value={value}
			direction={direction}
			requirementsLabelStyle={requirementsLabelStyle} 
			withRadioOptions={withRadioOptions}
			{...props}
		/>
	);
}

export default PromoCodesInputHook;
