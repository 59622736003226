import {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { isAfter, isValid } from 'date-fns';
import {
	Box,
	Button,
	Container,
	Flex,
	HStack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { debounce, isEmpty } from 'lodash';

import { CampaignContext } from 'src/contexts';
import { createOrUpdateCampaign, launchCampaign } from 'src/services/campaign';
import { toastError } from 'src/services/toast';
import { getScheduleDelta } from 'src/components/campaign/utils/delta';
import { getScheduleOptions } from 'src/components/campaign/utils/options';
import BudgetBreakdown from './BudgetBreakdown';
import CurrencyInputHook from '../CurrencyInput/CurrencyInputHook';
import {
	ECampaignScheduleType,
	ICampaignBudget,
	ICampaignSchedule,
	ICreative,
	IDesignDirection,
} from 'src/lib/schemas/campaign/newFlowCampaign';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';
import LaunchCampaign from '../LaunchCampaign';
import { IChannelGroup } from 'src/lib/schemas';
import { CampaignScheduleSchema } from 'src/lib/schemas/campaign/form';
import DatePickerInputHook from 'src/components/common/date-picker/DatePickerInputHook';
import FusionLoading from 'src/components/common/FusionLoading';
import { delay } from 'src/lib/utils';
import { countPendingCreativesByChannel } from '../utils/countPendingCreativesByChannel';

interface CampaignScheduleProps {
	schedule?: Partial<ICampaignSchedule>;
	onScheduleSubmit: (data: ICampaignSchedule) => void;
	availableChannelGroups: IChannelGroup[] | null;
	isValidCampaignConfig: boolean;
	requirementsLabelStyle?: React.CSSProperties;
	loadingCreatives?: ConstrainBoolean;
	designDirections: IDesignDirection[];
	creatives: ICreative[];
}

const CampaignSchedule: FC<CampaignScheduleProps> = ({
	schedule,
	onScheduleSubmit,
	availableChannelGroups,
	isValidCampaignConfig,
	requirementsLabelStyle,
	designDirections,
	creatives,
}) => {
	const { integrations } = useMyIntegrations();
	const {
		campaign,
		setCampaign,
		id: campaignId,
		completeStep,
	} = useContext(CampaignContext);
	const [isSaving, setIsSaving] = useState(false);
	const [isLaunchInitiated, setIsLaunchInitiated] = useState(false);
	const [showCreativesLoader, setShowCreativesLoader] = useState(false);
	const [pendingCreativesCount, setPendingCreativesCount] = useState(0);
	const [isValidCampaignBudget, setIsValidCampaignBudget] = useState(true);

	const [currentBudget, setCurrentBudget] = useState<
		ICampaignBudget | undefined
	>(campaign?.budget);
	const timeoutId = useRef<NodeJS.Timeout | null>(null);
	const isCreativesPending = creatives?.some(
		(c) => c.status === 'pending' || c.status === 'generating',
	);
	const formMethods = useForm<ICampaignSchedule>({
		resolver: zodResolver(CampaignScheduleSchema),
	});

	const { reset, setValue, handleSubmit, control } = formMethods;
	const watchScheduleType = useWatch({ control, name: 'type' });
	const watchedStartDate = useWatch({ control, name: 'startDate' });
	const watchedEndDate = useWatch({ control, name: 'endDate' });

	const hasPaidChannels = useMemo(() => {
		const paidPlacements = availableChannelGroups
			?.flatMap((group) => group.channels)
			.flatMap((channel) => channel.placements)
			.filter((placement: any) => placement.apportion > 0)
			.map((placement: any) => placement.id);

		return campaign?.placements
			?.filter((placement) => placement !== 'twitterSocialMedia')
			.some((placement) => paidPlacements?.includes(placement));
	}, [campaign?.channels, availableChannelGroups]);

	useEffect(() => {
		setCurrentBudget(campaign?.budget);
	}, [campaign?.budget]);

	useEffect(() => {
		if (watchScheduleType === ECampaignScheduleType.Evergreen) {
			setValue('endDate', undefined, { shouldValidate: true });
		}
	}, [watchScheduleType, setValue]);

	useEffect(() => {
		if (watchedEndDate) formMethods.trigger('endDate');
		if (isValid(new Date(watchedStartDate))) formMethods.trigger('startDate');
	}, [watchedStartDate, watchedEndDate, formMethods]);

	useEffect(() => {
		if (schedule) {
			reset({
				type: schedule.type,
				startDate: schedule.startDate
					? new Date(schedule.startDate)
					: undefined,
				endDate: schedule.endDate ? new Date(schedule.endDate) : undefined,
			});
		}
	}, [schedule, reset]);

	useEffect(() => {
		const count = countPendingCreativesByChannel(campaign, creatives);
		setPendingCreativesCount(count);
	}, [campaign]);

	useEffect(() => {
		setIsSaving(true);
		debouncedAutoSave();
		return () => {
			debouncedAutoSave.cancel();
		};
	}, [watchScheduleType, watchedStartDate, watchedEndDate]);

	const handleAutoSaveSchedule = async () => {
		if (!campaign) return;

		const newSchedule = {
			type: watchScheduleType,
			startDate: watchedStartDate ? new Date(watchedStartDate) : undefined,
			endDate:
				watchScheduleType === ECampaignScheduleType.Evergreen
					? undefined
					: watchedEndDate
					? new Date(watchedEndDate)
					: undefined,
		};

		const delta = getScheduleDelta(
			{
				type: schedule?.type ?? ECampaignScheduleType.Evergreen,
				startDate: schedule?.startDate
					? new Date(schedule.startDate)
					: undefined,
				endDate: schedule?.endDate ? new Date(schedule.endDate) : undefined,
			},
			newSchedule,
		);

		const isInvalidEndDate =
			newSchedule.endDate &&
			newSchedule.startDate &&
			isAfter(newSchedule.startDate, newSchedule.endDate);

		if (isEmpty(delta) || isInvalidEndDate) {
			setIsSaving(false);
			return;
		}

		//@ts-ignore
		setCampaign((prevCampaign) => ({
			...prevCampaign,
			schedule: newSchedule,
		}));

		try {
			await createOrUpdateCampaign({ schedule: newSchedule }, campaign.id);
		} catch (error) {
			toastError('Error saving schedule. Please try again.');
		} finally {
			setIsSaving(false);
		}
	};

	const debouncedAutoSave = debounce(() => {
		handleAutoSaveSchedule();
	}, 200);

	const handleScheduleSubmit = handleSubmit((data) => {
		clearTimeout(timeoutId.current as NodeJS.Timeout);
		completeStep(2);
		onScheduleSubmit(data);
	});

	useEffect(() => {
		if (!isSaving && isLaunchInitiated) {
			(async () => await handleLaunch())();
		}
	}, [isSaving, isLaunchInitiated]);

	const handleLaunch = async () => {
		if (!campaignId) return;
		setIsLaunchInitiated(true);
		while (isSaving) {
			await delay(500);
		}
		setIsLaunchInitiated(false);
		await launchCampaign(campaignId);
	};

	const handleChangeScheduleType = (type: ECampaignScheduleType) => {
		setValue('type', type);
		type === ECampaignScheduleType.Evergreen && setValue('endDate', undefined);
	};

	return (
		<Container w="full" maxW="full">
			<Flex direction="column" gap={10} minH="155px">
				{showCreativesLoader ? (
					<Box textAlign="center" h="250px">
						<FusionLoading isLoading={showCreativesLoader ?? false} />
						{pendingCreativesCount > 0 ? (
							<Text size="md" mt={8}>
								Please wait while we generate the content for{' '}
								{pendingCreativesCount}{' '}
								{pendingCreativesCount < 2 ? 'channel' : 'channels'}
							</Text>
						) : (
							<Text size="md" mt={8}>
								Please wait while we generate the content for your channels
							</Text>
						)}
					</Box>
				) : (
					<FormProvider {...formMethods}>
						<form onSubmit={handleScheduleSubmit}>
							<HStack spacing={20}>
								<VStack gap={0} alignItems="left">
									<Text color="black" fontWeight={500} fontSize={16}>
										Schedule
									</Text>
									<Text
										fontSize="12px"
										color="#959595"
										style={requirementsLabelStyle}
									>
										Define the period of this campaign
									</Text>
								</VStack>
								<Flex flex={2} gap={4} ml="104px">
									<Box>
										<DatePickerInputHook
											name="startDate"
											value={watchedStartDate}
											label="Start Date"
											placeholder="Select date"
										/>
									</Box>
									<Box>
										<DatePickerInputHook
											name="endDate"
											value={watchedEndDate}
											placeholder="Select date"
											isEndDate
											onChangeScheduleType={handleChangeScheduleType}
										/>
									</Box>
								</Flex>
							</HStack>

							{hasPaidChannels && (
								<Flex direction="column" gap={1} my={6}>
									<CurrencyInputHook
										name="budget.total"
										label="Budget"
										requirementsLabel="Total budget you want to spend in this campaign"
										requirementsLabelStyle={{
											color: '#718096',
											fontSize: '14px',
										}}
										placeholder="$0"
										direction="row"
										setCurrentBudget={setCurrentBudget}
										availableChannelGroups={availableChannelGroups}
										setIsSaving={setIsSaving}
									/>
								</Flex>
							)}

							{hasPaidChannels && currentBudget?.total !== 0 && (
								<BudgetBreakdown
									budget={currentBudget}
									availableChannelGroups={availableChannelGroups}
									setIsValidCampaignBudget={setIsValidCampaignBudget}
								/>
							)}
							<Box display="none" textAlign="center">
								<Button
									id="campaign-schedule-form-submit"
									colorScheme="secondary"
									fontWeight="medium"
									type="submit"
								/>
							</Box>
						</form>
					</FormProvider>
				)}
			</Flex>
			<Box hidden={showCreativesLoader ? true : false}>
				<LaunchCampaign
					isValidCampaignConfig={isValidCampaignConfig}
					isValidCampaignBudget={isValidCampaignBudget}
					isSocialEnabled={integrations?.social.fb.enabled}
					onLaunch={handleLaunch}
					setShowCreativesLoader={setShowCreativesLoader}
					pendingCreativesCount={pendingCreativesCount}
					designDirections={designDirections}
					creatives={creatives}
					setPendingCreativesCount={setPendingCreativesCount}
				/>
			</Box>
		</Container>
	);
};

export default CampaignSchedule;
