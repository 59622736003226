import {
	ECampaignPromotedType,
	ICampaign,
	ICampaignForm,
} from 'src/lib/schemas';

export const transformPartialFormToCampaign = (
	form: Partial<ICampaignForm>,
	campaign?: Partial<ICampaign> | null,
): Partial<ICampaign> => {
	const {
		group,
		title,
		description,
		goal,
		promotedObject,
		destination,
		audience,
		tone,
		promotion,
		channels,
		budget,
		placements,
	} = form;
	const selectedChannels = channels
		? Object.keys(channels)
				.filter((platform) => channels[platform as keyof typeof channels])
				.map((key) => key)
		: [];

	return {
		title: title?.trim(),
		description: description?.trim(),
		group: group,
		goal: goal?.map(({ id, target }) => ({
			id: id,
			target: Number(target),
		})),
		promotedObject: {
			type: promotedObject?.type ?? ECampaignPromotedType.catalogs,
			ids: promotedObject?.value
				? promotedObject?.value?.map(({ value }) => value)
				: [],
		},
		destination: destination?.trim(),
		audience,
		tone,
		promotion,
		channels: selectedChannels,
		budget: {
			...budget,
			currency: budget?.currency ?? 'USD',
			total: Number(budget?.total),
			breakdown: campaign?.budget?.breakdown ?? [],
		},
		placements,
	};
};
