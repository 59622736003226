import {
	FieldPath,
	FieldValues,
	get,
	useController,
	useFormContext,
} from 'react-hook-form';

import NumberInput, { CustomNumberInputProps } from './NumberInput';

export interface NumberInputHookProps<T extends FieldValues>
	extends CustomNumberInputProps {
	requirementsLabel?: string;
	name: FieldPath<T>;
}

function NumberInputHook<T extends FieldValues = FieldValues>({
	name,
	requirementsLabel,
	...props
}: NumberInputHookProps<T>) {
	const {
		control,
		register,

		formState: { errors },
	} = useFormContext();

	const error = get(errors, name);
	const { field } = useController({ control, name });
	const { value, onChange } = field;

	const handleChange = (_val: string, valNum: number) => {
		if (Number.isNaN(valNum)) onChange(undefined);
		else onChange(valNum);
	};

	return (
		<NumberInput
			id={name}
			error={error?.message}
			requirementsLabel={requirementsLabel}
			{...props}
			inputProps={{
				...props.inputProps,
				value: value === 0 ? undefined : value,
				onChange: handleChange,
			}}
			ref={register(name).ref}
		/>
	);
}

export default NumberInputHook;
