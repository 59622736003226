import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	Center,
	Flex,
	Link,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';

import SuccessCheckIcon from 'src/assets/icons/SuccessCheckIcon';

interface ICampaignSuccessModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
}

const CampaignSuccessModal: FC<ICampaignSuccessModalProps> = ({
	title,
	isOpen,
	onClose,
}) => {
	const navigate = useNavigate();

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="lg">
			<ModalOverlay zIndex={1800} />
			<ModalContent
				my="auto"
				containerProps={{
					zIndex: 1900,
				}}
			>
				<ModalBody
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					h="324px"
					gap={8}
					p={16}
				>
					<Flex direction="column" gap={2}>
						<SuccessCheckIcon color="green" />
						<Center>
							<Text fontSize="md">Success!</Text>
						</Center>
					</Flex>
					<Flex
						gap={2}
						direction="column"
						alignItems="center"
						textAlign="center"
					>
						<Text fontSize="md">
							You have successfully launched{' '}
							<Text as="span" fontSize="md" fontWeight="bold">
								{title}
							</Text>
						</Text>
						<Flex direction="column" alignItems="center" w="full">
							<Flex width="full" justifyContent="center" alignItems="center">
								<Text textAlign="center">
									Manage your post in{' '}
									<Link
										href="https://business.facebook.com/latest/content_calendar"
										color="blue.500"
										fontSize="14px"
										isExternal
									>
										Facebook Planner
									</Link>
								</Text>
							</Flex>
							<Flex width="full" justifyContent="center" alignItems="center">
								<Text textAlign="center">
									Manage your ads in{' '}
									<Link
										href="https://business.facebook.com/latest/ad_center"
										color="blue.500"
										fontSize="14px"
										isExternal
									>
										Facebook Ads Manager
									</Link>
								</Text>
							</Flex>
						</Flex>
					</Flex>
					<Flex gap={2} w="full" justify="space-between">
						<Button onClick={() => navigate('/projects/campaigns')} flex={1}>
							See campaigns
						</Button>
						<Button
							flex={1}
							colorScheme="secondary"
							onClick={() => {
								onClose();
								navigate('/refresh-page', {
									state: { to: '/projects/campaigns/new' },
								});
							}}
						>
							Create another campaign
						</Button>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default CampaignSuccessModal;
