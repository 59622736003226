import { Container, Flex, Text } from '@chakra-ui/react';
import { FC, useState } from 'react';
import Header from './Header';
import DomainForm from './DomainForm';
import { SignUpForm } from 'src/components/auth';

interface IDomainNotFound {
	domain: string;
	onRetryDomain: (newDomain: string) => void;
}

const DomainNotFound: FC<IDomainNotFound> = ({ domain, onRetryDomain }) => {
	const [showDomainForm, setShowDomainForm] = useState(false);

	const renderedSubtitle = showDomainForm ? (
		<>
			You can still{' '}
			<Text
				as="span"
				textDecoration="underline"
				fontSize={{ base: 14, md: 18 }}
				cursor="pointer"
				onClick={() => setShowDomainForm(false)}
			>
				sign up
			</Text>{' '}
			and create your first ad for FREE
		</>
	) : (
		<>
			You can still sign up and create your first ad for FREE or{' '}
			<Text
				as="span"
				textDecoration="underline"
				fontSize={{ base: 14, md: 18 }}
				cursor="pointer"
				onClick={() => setShowDomainForm(true)}
			>
				try with a different domain
			</Text>
		</>
	);

	return (
		<Flex p={0} direction="column">
			<Flex direction="column" gap={5}>
				<Header
					title={`We couldn’t obtain information about ${domain}`}
					subtitle={renderedSubtitle}
				/>
				<Container maxW="lg">
					<Flex alignSelf="center" justifyContent="center">
						{showDomainForm ? (
							<DomainForm prevDomain={domain} onDomainSubmit={onRetryDomain} />
						) : (
							<SignUpForm />
						)}
					</Flex>
				</Container>
			</Flex>
		</Flex>
	);
};

export default DomainNotFound;
