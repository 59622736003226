import React from 'react';
import { Box, Text, Image, Flex } from '@chakra-ui/react';

const EmptyState: React.FC = () => {
  return (
    <Flex direction="column" align="center" justify="center" mt={0} mx="auto" mb={50}>
      <Text fontSize="xl" fontWeight="bold">
        You don’t have any design yet
      </Text>
      <Text color="black" mt={2}>
        Click on Add design and start generating beautiful ads like these ones:
      </Text>
      <Flex mt={6} justify="center" gap={4}>
        <Box boxShadow="md" borderRadius="16px" overflow="hidden">
          <Image src="/images/template_example_1.svg" alt="Example Design 1" />
        </Box>
        <Box boxShadow="md" borderRadius="16px" overflow="hidden">
          <Image src="/images/template_example_2.svg" alt="Example Design 2" />
        </Box>
        <Box boxShadow="md" borderRadius="16px" overflow="hidden">
          <Image src="/images/template_example_3.svg" alt="Example Design 3" />
        </Box>
      </Flex>
    </Flex>
  );
};

export default EmptyState;
