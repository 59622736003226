import AccountIcon from 'src/assets/icons/AccountIcon';
import CampaignIcon from 'src/assets/icons/CampaignIcon';
import CommerceIcon from 'src/assets/icons/CommerceIcon';
import HomeCustomIcon from 'src/assets/icons/HomeCustomIcon';
import IntegrationsIcon from 'src/assets/icons/IntegrationsIcon';
import TasksIcon from 'src/assets/icons/TasksIcon';

export const iconMapper: Record<string, React.ElementType> = {
	'home-icon': HomeCustomIcon,
	'campaign-icon': CampaignIcon,
	'tasks-icon': TasksIcon,
	'account-icon': AccountIcon,
	'commerce-icon': CommerceIcon,
	'integrations-icon': IntegrationsIcon,
};
