import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const SuccessCheckIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			width="64px"
			height="60px"
			viewBox="0 0 84 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M42 80C27.625 80 14.5 72.5 7.3125 60C0.125 47.6562 0.125 32.5 7.3125 20C14.5 7.65625 27.625 0 42 0C56.2188 0 69.3438 7.65625 76.5312 20C83.7188 32.5 83.7188 47.6562 76.5312 60C69.3438 72.5 56.2188 80 42 80ZM59.6562 32.6562H59.5C61.0625 31.25 61.0625 28.9062 59.5 27.3438C58.0938 25.9375 55.75 25.9375 54.3438 27.3438L37 44.8438L29.6562 37.5C28.0938 35.9375 25.75 35.9375 24.3438 37.5C22.7812 38.9062 22.7812 41.25 24.3438 42.6562L34.3438 52.6562C35.75 54.2188 38.0938 54.2188 39.6562 52.6562L59.6562 32.6562Z"
				fill={color ? (color as string) : '#5DFF6D'}
			/>
		</Icon>
	);
};
export default SuccessCheckIcon;
