import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
interface ClipboardIconProps extends IconProps {
	filled?: boolean;
}
const ClipboardIcon: FC<ClipboardIconProps> = ({
	color,
	width = '20px',
	height = '20px',
	filled = true,
	...props
}) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox={filled ? '0 0 20 21' : '0 0 27 31'}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d={
					filled
						? 'M10.625 0.5H15.4688C15.9375 0.5 16.4453 0.734375 16.7969 1.08594L19.4141 3.70312C19.7656 4.05469 20 4.5625 20 5.03125V13.625C20 14.6797 19.1406 15.5 18.125 15.5H10.625C9.57031 15.5 8.75 14.6797 8.75 13.625V2.375C8.75 1.35938 9.57031 0.5 10.625 0.5ZM1.875 5.5H7.5V8H2.5V18H10V16.75H12.5V18.625C12.5 19.6797 11.6406 20.5 10.625 20.5H1.875C0.820312 20.5 0 19.6797 0 18.625V7.375C0 6.35938 0.820312 5.5 1.875 5.5Z'
						: 'M25.2173 4.75284C25.7415 5.27699 26.0909 5.97585 26.0909 6.73296V22.3409C26.0909 23.9134 24.8097 25.1364 23.2955 25.1364H18.6364V27.9318C18.6364 29.5043 17.3551 30.7273 15.8409 30.7273H2.79545C1.22301 30.7273 0 29.5043 0 27.9318V9.29546C0 7.78125 1.22301 6.5 2.79545 6.5H7.45455V3.70455C7.45455 2.19034 8.67756 0.909092 10.25 0.909092H20.267C21.0241 0.909092 21.723 1.25852 22.2472 1.78267L25.2173 4.75284ZM15.4915 27.9318C15.6662 27.9318 15.8409 27.8153 15.8409 27.5824V25.1364H10.25C8.67756 25.1364 7.45455 23.9134 7.45455 22.3409V9.29546H3.14489C2.91193 9.29546 2.79545 9.47017 2.79545 9.64489V27.5824C2.79545 27.8153 2.91193 27.9318 3.14489 27.9318H15.4915ZM22.946 22.3409C23.1207 22.3409 23.2955 22.2244 23.2955 21.9915V10.2273H18.1705C17.3551 10.2273 16.7727 9.64489 16.7727 8.82955V3.70455H10.5994C10.3665 3.70455 10.25 3.87926 10.25 4.05398V21.9915C10.25 22.2244 10.3665 22.3409 10.5994 22.3409H22.946ZM23.2955 7.43182V6.90767C23.2955 6.79119 23.2372 6.73296 23.179 6.67472L20.3253 3.82102C20.267 3.76279 20.2088 3.70455 20.0923 3.70455H19.5682V7.43182H23.2955Z'
				}
				fill={color ? (color as string) : '#5C5F62'}
			/>
		</Icon>
	);
};

export default ClipboardIcon;
