export const formatCurrency = (val: number | bigint) => {
	const dollars = Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
	});

	return dollars.format(val);
};

export const parseCurrency = (val: string) => {
	return val.replace(/[^0-9.-]+/g, '');
};

export const formatNumber = (val: number, decimals?: number) => {
	return Number(val.toFixed(decimals ?? 2));
};
