import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const InfoCircleIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			width="22px"
			height="22px"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.97648 2.0545C6.5492 2.0545 4.34846 3.34905 3.11864 5.42033C1.92118 7.52397 1.92118 10.0807 3.11864 12.152C4.34846 14.2556 6.5492 15.5178 8.97648 15.5178C11.3714 15.5178 13.5721 14.2556 14.802 12.152C15.9994 10.0807 15.9994 7.52397 14.802 5.42033C13.5721 3.34905 11.3714 2.0545 8.97648 2.0545ZM8.97648 17.0713C5.99901 17.0713 3.28046 15.5178 1.79172 12.9287C0.302991 10.372 0.302991 7.2327 1.79172 4.6436C3.28046 2.08686 5.99901 0.501035 8.97648 0.501035C11.9216 0.501035 14.6401 2.08686 16.1289 4.6436C17.6176 7.2327 17.6176 10.372 16.1289 12.9287C14.6401 15.5178 11.9216 17.0713 8.97648 17.0713ZM7.68193 11.3753H8.45866V9.30398H7.68193C7.22884 9.30398 6.9052 8.98034 6.9052 8.52725C6.9052 8.10652 7.22884 7.75052 7.68193 7.75052H9.23539C9.65612 7.75052 10.0121 8.10652 10.0121 8.52725V11.3753H10.271C10.6918 11.3753 11.0478 11.7313 11.0478 12.152C11.0478 12.6051 10.6918 12.9287 10.271 12.9287H7.68193C7.22884 12.9287 6.9052 12.6051 6.9052 12.152C6.9052 11.7313 7.22884 11.3753 7.68193 11.3753ZM8.97648 6.71488C8.39393 6.71488 7.94084 6.26178 7.94084 5.67924C7.94084 5.12905 8.39393 4.6436 8.97648 4.6436C9.52666 4.6436 10.0121 5.12905 10.0121 5.67924C10.0121 6.26178 9.52666 6.71488 8.97648 6.71488Z"
				fill={color ? (color as string) : '#F7480B'}
			/>
		</Icon>
	);
};

export default InfoCircleIcon;
