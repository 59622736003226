import http from './http';

const apiVersion = 'v1';
const accountUrl = `/${apiVersion}/account`;

export interface ITag {
	_id: string;
	name: string;
	scope: string;
	account: string;
	__v: number;
	catalogCount: number;
}

const getTags = async (): Promise<ITag[]> => {
	const { data } = await http.get<ITag[]>(`${accountUrl}/tags`);
	return data;
};

const createTag = async (newTag: { name: string }): Promise<ITag> => {
	const { data } = await http.post<ITag>(`${accountUrl}/tags`, newTag);
	return data;
};

const updateTag = async (
	tagId: string,
	updates: Partial<ITag>,
): Promise<ITag> => {
	const { data } = await http.patch<ITag>(
		`${accountUrl}/tags/${tagId}`,
		updates,
	);
	return data;
};

const deleteTag = async (tagId: string): Promise<void> => {
	await http.delete(`${accountUrl}/tags/${tagId}`);
};

const editTag = async (
	tagId: string,
	updates: Partial<ITag>,
): Promise<ITag> => {
	const { data } = await http.patch<ITag>(
		`${accountUrl}/tags/${tagId}`,
		updates,
	);
	return data;
};

export { getTags, createTag, updateTag, deleteTag, editTag };
