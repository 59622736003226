import { createContext } from 'react';
import { ICampaignConfig } from 'src/lib/schemas';

interface ICampaignConfigContext {
	isLoading: boolean;
	config: ICampaignConfig | null;
	retries: number;
	setIsLoading: (isLoading: boolean) => void;
	setConfig: (config: ICampaignConfig) => void;
}

const CampaignConfigContext = createContext<ICampaignConfigContext>({
	isLoading: false,
	config: null,
	retries: 0,
	setIsLoading: (isLoading: boolean) => {},
	setConfig: (config: ICampaignConfig) => {},
});

export default CampaignConfigContext;
