import { FC, useContext, useEffect, useState } from 'react';
import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import * as jsonDiff from 'jsondiffpatch';

import { CampaignContext } from 'src/contexts';
import BudgetChart from './parts/BudgetChart';
import { DataTable } from 'src/components/common/DataTable';
import { ICampaignBudgetBreakdown, IChannelGroup } from 'src/lib/schemas';
import {
	adjustChannelBudget,
	adjustGroupBudget,
	adjustOverallBudget,
	groupChannels,
	mapChannelsGroup,
	overallBudget,
} from './parts/utils';
import {
	IBudgetBreakdownItem,
	getBudgetBreakdownColumns,
} from './parts/columns';
import { createOrUpdateCampaign } from 'src/services/campaign';

interface ICampaignBudgetProps {
	availableChannelGroups: IChannelGroup[];
}

const CampaignBudget: FC<ICampaignBudgetProps> = ({
	availableChannelGroups,
}) => {
	const { campaign, setCampaign } = useContext(CampaignContext);
	const [totalBudget, setTotalBudget] = useState(campaign?.budget.total ?? 0);
	const [breakdown, setBreakdown] = useState<ICampaignBudgetBreakdown[]>(
		campaign?.budget.breakdown ?? [],
	);
	const [channelsBreakdown, setChannelsBreakdown] = useState<
		IBudgetBreakdownItem[]
	>([]);
	const [breakdownTable, setBreakdownTableData] = useState<
		IBudgetBreakdownItem[]
	>([]);

	useEffect(() => {
		if (!campaign?.budget) return;

		setTotalBudget(campaign.budget.total);
		if (campaign.budget.total === 0) {
			setBreakdown((prevState) =>
				prevState.map((item) => {
					return {
						...item,
						budget: 0,
					};
				}),
			);
		}
	}, [campaign?.budget.total]);

	const flatChannels = availableChannelGroups.flatMap((cg) => {
		return cg.channels.map((c) => {
			return {
				...c,
				groupId: cg.group_id,
				group: cg.name,
			};
		});
	});

	useEffect(() => {
		if (!campaign?.budget || !campaign?.channels) return;
		const mappedBreakdown = mapChannelsGroup(
			totalBudget,
			breakdown,
			availableChannelGroups,
			campaign.channels,
		);
		setChannelsBreakdown(mappedBreakdown);
	}, [breakdown]);

	useEffect(() => {
		if (!campaign) return;

		const currentChannels = breakdown.map((item) => item.id);
		const campaignChannels = campaign.channels ?? [];
		const channelsToAdd = campaignChannels.filter(
			(c) => !currentChannels.includes(c),
		);
		const channelsToRemove = currentChannels.filter(
			(c) => !campaignChannels.includes(c),
		);

		const newBreakdown = breakdown.filter(
			(item) => !channelsToRemove.includes(item.id),
		);
		const newChannels = channelsToAdd.map((channel) => {
			const channelData = flatChannels.find((c) => c.id === channel);
			return { id: channel, type: channelData?.groupId ?? '', budget: 0 };
		});

		setBreakdown([...newBreakdown, ...newChannels]);
	}, [campaign?.channels]);

	useEffect(() => {
		const overall = overallBudget(channelsBreakdown, totalBudget);
		const groups = groupChannels(channelsBreakdown);

		const groupsWithStatus = groups.map((group) => ({
			...group,
			isExpanded: breakdownTable.find((g) => g.id === group.id)?.isExpanded,
		}));

		const masterTable = [...overall, ...groupsWithStatus];

		groupsWithStatus.forEach((group) => {
			if (group.isExpanded) {
				const subTableData = channelsBreakdown.filter(
					(item) => item.groupId === group.id,
				);
				masterTable.splice(
					masterTable.findIndex((item) => item.id === group.id) + 1,
					0,
					...subTableData,
				);
			}
		});

		setBreakdownTableData(masterTable);
	}, [channelsBreakdown]);

	useEffect(() => {
		handleUpdateCampaignBreakdown();
	}, [channelsBreakdown]);

	const handleUpdateCampaignBreakdown = async () => {
		if (!campaign || channelsBreakdown.length === 0) return;
		const currentBreakDown = channelsBreakdown.map((item) => ({
			id: item.id,
			budget: item.budget,
			type: item.groupId,
		}));

		const delta = jsonDiff.diff(campaign.budget.breakdown, currentBreakDown);
		if (delta) {
			await createOrUpdateCampaign(
				{ budget: { ...campaign.budget, breakdown: currentBreakDown } },
				campaign.id,
			);
			setCampaign({
				...campaign,
				budget: { ...campaign.budget, breakdown: currentBreakDown },
			});
		}
	};

	const handleToggleSubtable = (row: IBudgetBreakdownItem) => {
		const masterTable = [...breakdownTable].filter(
			(item) => item.groupId !== row.id,
		);
		const index = masterTable.findIndex((item) => item.id === row.id);
		masterTable[index] = { ...row, isExpanded: !row.isExpanded };

		setBreakdownTableData(masterTable);
		setChannelsBreakdown([...channelsBreakdown]);
	};

	const handleBudgetChange = (
		id: string,
		value: number,
		level: 'group' | 'channel' | 'overall',
	) => {
		if (level === 'overall') {
			setTotalBudget(value);
			if (campaign) {
				setCampaign({
					...campaign,
					budget: { ...campaign.budget, total: value },
				});
			}
			const data = adjustOverallBudget(value, channelsBreakdown);
			setChannelsBreakdown(data);
		} else if (level === 'group') {
			const data = adjustGroupBudget(id, value, totalBudget, channelsBreakdown);
			setChannelsBreakdown(data);
		} else {
			const data = adjustChannelBudget(
				id,
				value,
				totalBudget,
				channelsBreakdown,
			);
			setChannelsBreakdown(data);
		}
	};

	const currentBudget = channelsBreakdown.reduce((acc, item) => {
		return acc + item.budget;
	}, 0);

	const columns = getBudgetBreakdownColumns({
		onToggleSubtable: handleToggleSubtable,
		onValueChange: handleBudgetChange,
		budget: totalBudget,
		currentBudget,
	});

	if (totalBudget === 0) {
		return null;
	}

	return (
		<Container maxW="7xl" px={0} py={5} m={0}>
			<Flex
				direction="column"
				borderRadius="lg"
				background="#F8F8F8"
				px={5}
				py={5}
				gap={4}
				boxShadow="0px 1px 6px 0px rgba(0, 0, 0, 0.15)"
			>
				<Heading mb={2}>Budget distribution</Heading>
				<Box w="full" bg="white" px={4} py={4} mt={2} borderRadius="lg">
					<DataTable
						columns={columns}
						data={breakdownTable}
						isLoading={false}
						lockedColumnsIndex={3}
					/>
				</Box>
			</Flex>
		</Container>
	);
};

export default CampaignBudget;
