export const attributeConfig = {
	headline: {
		label: 'Headline',
		inputType: 'string',
	},
	primaryText: {
		label: 'Primary text',
		inputType: 'textarea',
	},
	content: {
		label: 'Content',
		inputType: 'textarea',
	},
	emailSubject: {
		label: 'Subject',
		inputType: 'textarea',
	},
	plainEmailBody: {
		label: 'Body',
		inputType: 'textarea',
	},
	htmlEmailBody: {
		label: 'Body',
		inputType: 'textarea',
	},
	keywords: {
		label: 'Keywords',
		inputType: 'textarea',
	},
	callToAction: {
		label: 'Call to action',
		inputType: 'dropdown',
	},
};
