import {
	Box,
	Button,
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	Image,
	PopoverTrigger,
	Text,
	Drawer,
	DrawerCloseButton,
	DrawerOverlay,
	IconButton,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserLogo from 'src/components/common/UserLogo';
import UserContext from 'src/contexts/UserContext';
import authService from 'src/services/auth';
import { MdOutlineLogout } from 'react-icons/md';
import Logo from 'src/assets/svgs/logo.svg';
import { HamburgerIcon } from '@chakra-ui/icons';
import Sidebar from './Sidebar';

interface TopBarProps {
	dekstopForced: boolean;
}

const TopBar = ({ dekstopForced }: TopBarProps) => {
	const [isLoggingOut, setIsLoggingOut] = useState(false);
	const { user, setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const [isDrawerOpen, setDrawerOpen] = useState(false);
	const location = useLocation();

	const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);

	const handleLogout = async () => {
		setIsLoggingOut(true);
		await authService.logout();
		setUser(null);
		setIsLoggingOut(false);
		navigate('/login');
	};

	useEffect(() => {
		const parentPaths = [
			'/projects/campaigns',
			'/tasks',
			'/account/company',
			'/commerce/catalog',
		];
		const isParentPath = parentPaths.some((path) => location.pathname === path);

		const hasQueryParams = location.search.includes('?');

		if (!isParentPath || hasQueryParams) {
			setDrawerOpen(false);
		}
	}, [location.pathname, location.search]);

	const isCampaignPath = location.pathname.includes('/projects/campaigns/');

	return (
		<Box
			w="full"
			h="72px"
			bg="white"
			position="fixed"
			top={0}
			zIndex={1800}
			boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
		>
			<Flex
				ml={!dekstopForced && !isCampaignPath ? '20px' : 0}
				h="full"
				justifyContent="space-between"
				alignItems="center"
			>
				<Flex h="full" alignItems="center">
					{(dekstopForced || isCampaignPath) && (
						<>
							<Box
								cursor="pointer"
								aria-label="Open menu"
								onClick={toggleDrawer}
								bg="transparent"
								pl="20px"
								pr="10px"
								height="100%"
								display="flex"
								alignItems="center"
								justifyContent="center"
								zIndex={10000}
							>
								<Box zIndex={10000}>
									<HamburgerIcon fontSize="24px" height="30px" width="30px" />
								</Box>
							</Box>
							<Drawer
								isOpen={isDrawerOpen}
								placement="left"
								onClose={toggleDrawer}
							>
								<DrawerOverlay onClick={toggleDrawer} />
								<DrawerCloseButton />
								<Sidebar setDrawerOpen={setDrawerOpen} />
							</Drawer>
						</>
					)}
					<Link to="/home">
						<Image src={Logo} height="30px" />
					</Link>
				</Flex>

				{user && (
					<Flex gap={3} mr="30px">
						<Popover>
							<PopoverTrigger>
								<Button
									bg="transparent"
									_hover={{ bg: 'transparent' }}
									alignItems="center"
									gap={2}
								>
									<Text
										color="#414141"
										fontSize="14px"
										fontWeight={500}
										letterSpacing="0.2px"
										fontFamily="Noto Sans"
									>
										Hello {user.name}{' '}
									</Text>
									<UserLogo size={{ h: '33px', w: '33px' }} />
								</Button>
							</PopoverTrigger>
							<PopoverContent
								_focusVisible={{
									outline: 'none',
								}}
								boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.25)'}
								m={4}
								width="fit-content"
							>
								<PopoverBody bg="#FFF" borderRadius="6px" p="16px" minW="250px">
									<Flex
										direction="column"
										gap={3}
										p={3}
										borderRadius="8px"
										alignItems="center"
										bg="white"
									>
										<UserLogo size={{ h: '33px', w: '33px' }} />
										<Flex direction="column" alignItems="center">
											<Text color="#26272B" fontWeight={600} fontSize="12px">
												{user.name}
											</Text>
											<Text color="#757588" fontWeight={400} fontSize="12px">
												{user.email}
											</Text>
										</Flex>
									</Flex>
									<Flex mt={3}>
										<Button
											leftIcon={<MdOutlineLogout size={20} />}
											bg={'orange.primary'}
											color="white"
											onClick={handleLogout}
											isLoading={isLoggingOut}
											loadingText="Logging out..."
											_hover={{ bg: 'orange.primary' }}
											w="full"
										>
											<Text
												color="white"
												fontWeight={600}
												fontSize="12px"
												ml="-5px"
											>
												Logout
											</Text>
										</Button>
									</Flex>
								</PopoverBody>
							</PopoverContent>
						</Popover>
					</Flex>
				)}
			</Flex>
		</Box>
	);
};

export default TopBar;
