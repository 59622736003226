/* eslint-disable @typescript-eslint/no-explicit-any */

export const cleanObject_id: any = (value: any) => {
	if (value === null || value === undefined) {
		return value;
	} else if (Array.isArray(value)) {
		return value.map((v) => cleanObject_id(v));
	} else if (typeof value === 'object') {
		const obj: any = {};
		Object.keys(value).forEach((key) => {
			if (key !== '_id') {
				obj[key] = cleanObject_id(value[key]);
			}
		});
		return obj;
	}
	return value;
};
