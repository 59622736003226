import { FieldPath, FieldValues, get, useFormContext } from 'react-hook-form';

import SelectInput, { SelectInputProps } from './SelectInput';

export interface SelectInputHookProps<T extends FieldValues>
	extends SelectInputProps {
	name: FieldPath<T>;
}

function SelectInputHook<T extends FieldValues = FieldValues>({
	name,
	...props
}: SelectInputHookProps<T>) {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	const error = get(errors, name);

	return (
		<SelectInput
			id={name}
			error={error?.message}
			selectProps={{ ...props.selectProps, ...register(name) }}
			{...props}
		/>
	);
}

export default SelectInputHook;
