import {
	Box,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	Heading,
	Text,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Button,
	Flex,
	IconButton,
} from '@chakra-ui/react';
import { FC, useState, useRef, useEffect } from 'react';
import MountainSvg from './utils/header-svg';
import { HiLightBulb } from 'react-icons/hi';
import { FaCheckCircle } from 'react-icons/fa';
import { LuMaximize2 } from 'react-icons/lu';
import { CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import VideoCard from './components/VideoCard';

interface ISidebar {
	onClose: () => void;
}

const Sidebar: FC<ISidebar> = ({  onClose }) => {
	const [openIndexes, setOpenIndexes] = useState([0]);
	const navigate = useNavigate();
	const sidebarRef = useRef<HTMLDivElement>(null);

	const handleToggle = (index: number) => {
		setOpenIndexes((prevIndexes) =>
			prevIndexes.includes(index)
				? prevIndexes.filter((i) => i !== index)
				: [...prevIndexes, index],
		);
	};

	return (
		<Drawer
			isOpen={false}
			placement="right"
			onClose={onClose}
			variant="clickThrough"
			trapFocus={false}
			blockScrollOnMount={false}
		>
			<DrawerContent
				ref={sidebarRef}
				zIndex={1000}
				marginTop="72px"
				minW="385px"
				bg="white"
				boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
				color="gray.text"
			>
				<DrawerHeader minH="190px" bg="orange.base">
					<Flex position="absolute" right={3} top={3}>
						<IconButton
							aria-label="Close"
							icon={<CloseIcon />}
							onClick={onClose}
							variant="ghost"
							_hover={{}}
							_active={{ color: 'black' }}
							color="white"
						/>
					</Flex>

					<Flex position="absolute" right={0} top={10}>
						<MountainSvg />
					</Flex>
					<Heading
						alignContent="center"
						fontSize="24px"
						fontWeight={700}
						color="white"
						textAlign="center"
						mt="60px"
					>
						Getting Started
					</Heading>
				</DrawerHeader>
				<DrawerBody p={5} w="100%">
					<Box
						border="1px solid #F1F1F1"
						p={5}
						position="absolute"
						top="118px"
						w="90%"
						bg="white"
					>
						<Accordion
							allowMultiple
							index={openIndexes}
							onChange={(index: any) => setOpenIndexes(index)}
						>
							<AccordionItem border="0">
								<AccordionButton
									padding="0"
									_hover={{ padding: '0' }}
									onClick={() => handleToggle(0)}
								>
									<Box as="span" flex="1" textAlign="left">
										<Heading color="gray.text" _active={{ border: 'none' }}>
											<Flex gap={3} alignItems="center">
												<FaCheckCircle size={20} />
												<Text fontWeight={400} fontSize="15px">
													Create a marketing campaign
												</Text>
											</Flex>
										</Heading>
									</Box>
									<AccordionIcon fontSize={30} fontWeight="light" />
								</AccordionButton>
								<AccordionPanel>
									<Box position="relative" py={3}>
										<VideoCard
											width="278px"
											height="157px"
											videoUrl="videos/introduction.mp4"
											thumbnailUrl="images/home/video-thumbnail.png"
										/>
									</Box>
									<Text color="gray.text">
										Set goals, target the right people, craft message, choose
										channels, and track results for a winning marketing
										campaign.
									</Text>
									<Flex justifyContent="center" mt={5}>
										<Button
											bg="orange.base"
											color="white"
											_hover={{ bg: 'orange.base' }}
											onClick={() => navigate('/projects/campaigns/new')}
										>
											Show me
										</Button>
									</Flex>
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
					</Box>
				</DrawerBody>
				<Box
					width="full"
					height={16}
					position="absolute"
					bottom={0}
					textAlign="center"
					p={4}
					mb={10}
				>
					<Text
						fontSize="15px"
						color="orange.base"
						cursor="pointer"
						onClick={onClose}
					>
						Dismiss getting started
					</Text>
				</Box>
			</DrawerContent>
		</Drawer>
	);
};

export default Sidebar;
