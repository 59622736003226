import axios, { Axios, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import config from 'src/config';
import auth from './auth';
import http from './http';

const axiosClient = axios.create({
	baseURL: config.app.appsApiUrl,
});

http.setDefaultInterceptors(axiosClient)

export default {
	get: axiosClient.get,
	post: axiosClient.post,
	put: axiosClient.put,
	patch: axiosClient.patch,
	delete: axiosClient.delete
};
