import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';
const ChartBarIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			width="19px"
			height="13px"
			viewBox="0 0 19 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.57166 2.64453V2.67578C11.5092 2.67578 13.0717 4.23828 13.0717 6.14453C13.0717 8.08203 11.5092 9.64453 9.57166 9.64453C7.63416 9.64453 6.07166 8.08203 6.07166 6.14453C6.07166 5.80078 6.13416 5.48828 6.22791 5.17578C6.44666 5.33203 6.75916 5.39453 7.07166 5.39453C8.00916 5.39453 8.82166 4.61328 8.82166 3.64453C8.79041 3.36328 8.72791 3.05078 8.57166 2.80078C8.88416 2.70703 9.22791 2.67578 9.57166 2.64453ZM18.4467 5.70703C18.5092 5.83203 18.5404 5.98828 18.5404 6.17578C18.5404 6.33203 18.5092 6.48828 18.4467 6.61328C16.7592 9.92578 13.3842 12.1445 9.57166 12.1445C5.72791 12.1445 2.35291 9.92578 0.665405 6.61328C0.602905 6.48828 0.571655 6.33203 0.571655 6.14453C0.571655 5.98828 0.602905 5.83203 0.665405 5.70703C2.35291 2.39453 5.72791 0.144531 9.57166 0.144531C13.3842 0.144531 16.7592 2.39453 18.4467 5.70703ZM9.57166 10.6445C12.6342 10.6445 15.4779 8.92578 16.9779 6.14453C15.4779 3.36328 12.6342 1.64453 9.57166 1.64453C6.47791 1.64453 3.63416 3.36328 2.13416 6.14453C3.63416 8.92578 6.47791 10.6445 9.57166 10.6445Z"
				fill={color ? (color as string) : '#969B9D'}
			/>
		</Icon>
	);
};
export default ChartBarIcon;
