import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const WarningIcon: FC<IconProps> = ({ color, ...rest }) => {
	return (
		<Icon
			width="20px"
			height="19px"
			viewBox="0 0 20 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M9.6875 2.82031L1.95312 15.5156C1.875 15.6328 1.875 15.7109 1.875 15.8281C1.875 16.1406 2.10938 16.375 2.42188 16.375H17.5391C17.8516 16.375 18.125 16.1406 18.125 15.8281C18.125 15.7109 18.0859 15.6328 18.0078 15.5156L10.2734 2.82031C10.2344 2.70312 10.1172 2.625 10 2.625C9.84375 2.625 9.76562 2.70312 9.6875 2.82031ZM8.08594 1.84375C8.47656 1.17969 9.21875 0.75 10 0.75C10.7422 0.75 11.4844 1.17969 11.875 1.84375L19.6094 14.5391C19.8438 14.9297 20 15.3594 20 15.8281C20 17.1562 18.9062 18.25 17.5391 18.25H2.42188C1.09375 18.25 0 17.1562 0 15.8281C0 15.3594 0.117188 14.9297 0.351562 14.5391L8.08594 1.84375ZM11.25 13.875C11.25 14.5781 10.6641 15.125 10 15.125C9.29688 15.125 8.75 14.5781 8.75 13.875C8.75 13.2109 9.29688 12.625 10 12.625C10.6641 12.625 11.25 13.2109 11.25 13.875ZM10.9375 6.6875V10.4375C10.9375 10.9844 10.5078 11.375 10 11.375C9.45312 11.375 9.0625 10.9844 9.0625 10.4375V6.6875C9.0625 6.17969 9.45312 5.75 10 5.75C10.5078 5.75 10.9375 6.17969 10.9375 6.6875Z"
				fill={color ? (color as string) : '#ECA928'}
			/>
		</Icon>
	);
};

export default WarningIcon;
