import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const FacebookIcon: FC<IconProps> = ({ color, ...props }) => {
	return (
		<Icon
			width="21px"
			height="20px"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M20.3154 10.2998C20.3154 15.1436 16.7607 19.167 12.1123 19.8701V13.1123H14.3779L14.8076 10.2998H12.1123V8.50293C12.1123 7.72168 12.5029 6.97949 13.7139 6.97949H14.9248V4.59668C14.9248 4.59668 13.8311 4.40137 12.7373 4.40137C10.5498 4.40137 9.10449 5.76855 9.10449 8.19043V10.2998H6.64355V13.1123H9.10449V19.8701C4.45605 19.167 0.94043 15.1436 0.94043 10.2998C0.94043 4.94824 5.27637 0.612305 10.6279 0.612305C15.9795 0.612305 20.3154 4.94824 20.3154 10.2998Z"
				fill={color ? (color as string) : '#2C6ECB'}
			/>
		</Icon>
	);
};

export default FacebookIcon;
