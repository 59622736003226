import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const CalendarIcon: FC<IconProps> = ({ color }) => {
	return (
		<Icon
			width="18px"
			height="21px"
			viewBox="0 0 18 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.97412 1.43848V3.00098H11.5991V1.43848C11.5991 0.930664 11.9897 0.500977 12.5366 0.500977C13.0444 0.500977 13.4741 0.930664 13.4741 1.43848V3.00098H15.0366C16.4038 3.00098 17.5366 4.13379 17.5366 5.50098V6.12598V8.00098V18.001C17.5366 19.4072 16.4038 20.501 15.0366 20.501H2.53662C1.13037 20.501 0.0366211 19.4072 0.0366211 18.001V8.00098V6.12598V5.50098C0.0366211 4.13379 1.13037 3.00098 2.53662 3.00098H4.09912V1.43848C4.09912 0.930664 4.48975 0.500977 5.03662 0.500977C5.54443 0.500977 5.97412 0.930664 5.97412 1.43848ZM1.91162 8.00098V18.001C1.91162 18.3525 2.18506 18.626 2.53662 18.626H15.0366C15.3491 18.626 15.6616 18.3525 15.6616 18.001V8.00098H1.91162Z"
				fill={color ? (color as string) : '#2C6ECB'}
			/>
		</Icon>
	);
};

export default CalendarIcon;
