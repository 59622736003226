import { useMemo } from 'react';
import { IChannel } from 'src/lib/schemas';
import { ChannelType } from './ChannelType';

export const useChannelConfig = (availableChannels: IChannel[]) => {
	return useMemo(
		() => [
			{
				title: 'Social Media Marketing',
				type: ChannelType.SocialMediaMarketing,
				channels: availableChannels.filter(
					(channel) =>
						channel.placements?.some(
							(placement) =>
								placement.type === ChannelType.SocialMediaMarketing,
						),
				),
			},
			{
				title: 'Paid Advertising',
				type: ChannelType.PaidAd,
				channels: availableChannels.filter(
					(channel) =>
						channel.placements?.some(
							(placement) => placement.type === ChannelType.PaidAd,
						),
				),
			},
			{
				title: 'Direct Marketing',
				type: ChannelType.DirectMarketing,
				channels: availableChannels.filter(
					(channel) =>
						channel.placements?.some(
							(placement) => placement.type === ChannelType.DirectMarketing,
						),
				),
			},
			{
				title: 'Custom',
				type: ChannelType.Custom,
				channels: availableChannels.filter((channel) =>
				  channel.placements?.some(
					(placement) => placement.type === ChannelType.Custom,
				  ),
				),
			  },
		],
		[availableChannels],
	);
};
