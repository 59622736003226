import { FC, useState } from 'react';

import AccountConfigContext from './AccountConfigContext';
import { IAccountConfig } from 'src/lib/schemas';

interface AccountConfigContextProps {
	children: React.ReactNode;
}

const AccountConfigProvider: FC<AccountConfigContextProps> = ({ children }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [config, setConfig] = useState<IAccountConfig | null>(null);
	const [retries, setRetries] = useState<number>(0);

	const handleLoading = (loading: boolean) => {
		if (loading) {
			setRetries(retries + 1);
		}
		setIsLoading(loading);
	};

	return (
		<AccountConfigContext.Provider
			value={{
				isLoading,
				retries,
				setIsLoading: handleLoading,
				config,
				setConfig,
			}}
		>
			{children}
		</AccountConfigContext.Provider>
	);
};

export default AccountConfigProvider;
