import { useContext, useEffect, useState } from 'react';
import { Button, Flex, Heading } from '@chakra-ui/react';

import {
	ResendInviteModal,
	UserDeleteModal,
	UserFormModal,
	getUsersListingColumns,
} from 'src/components/user';
import {
	DataTable,
	DataTablePagination,
	TActions,
} from 'src/components/common/DataTable';
import usePagination from 'src/hooks/usePagination';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import {
	deleteUser,
	getUsers,
	inviteUser,
	updateUser,
} from 'src/services/users';
import { IUser, IUserForm } from 'src/lib/schemas';
import { toastError } from 'src/services/toast';
import UserContext from 'src/contexts/UserContext';
import CustomContainer from 'src/components/common/CustomContainer';

const ManageUsers = () => {
	const pagination = usePagination();
	const [isLoading, setIsLoading] = useState(true);
	const [users, setUsers] = useState<Array<IUser>>([]);
	const formToggle = useToggleWithPayload<IUser>();
	const deleteToggle = useToggleWithPayload<IUser>();
	const resendInviteToggle = useToggleWithPayload<IUser>();

	const { user } = useContext(UserContext);
	const handleDisable = (action: TActions, row: IUser) => {
		const isSelf = user?.id === row.id;
		const hideResendInviteButton = isSelf || !row.isInvited;
		if (isSelf && action === 'Delete') {
			return true;
		} else if (hideResendInviteButton && action === 'Resend invite')
			return true;
		return false;
	};

	const handleCreateUpdateUser = async (payload: IUserForm) => {
		if (payload.id) {
			await updateUser(payload);
		} else {
			await inviteUser(payload);
		}
		fetchUsers();
	};

	const handleDeleteUser = async (user: IUser) => {
		await deleteUser(user.id);
		fetchUsers();
	};

	const handleResentInvite = async (user: IUser) => {
		const { email, name, role } = user;
		await inviteUser({ email, name, role });
		fetchUsers();
	};

	const handleAction = (action: TActions, data: IUser) => {
		switch (action) {
			case 'Edit':
				formToggle.onOpen(users.find((u) => u.id === data.id));
				break;
			case 'Delete':
				deleteToggle.onOpen(users.find((u) => u.id === data.id));
				break;
			case 'Resend invite':
				resendInviteToggle.onOpen(users.find((u) => u.id === data.id));
				break;
			default:
				break;
		}
	};

	const columns = getUsersListingColumns(handleAction, handleDisable);

	const fetchUsers = async () => {
		setIsLoading(true);
		try {
			const query = `page=${pagination.page}`;
			const { results, totalPages } = await getUsers(query);
			setUsers(results);
			pagination.setPages(totalPages);
		} catch (error: any) {
			toastError(error);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		fetchUsers();
	}, [pagination.page]);

	return (
		<CustomContainer>
			<Flex direction="column" gap={4}>
				<Flex justify="space-between" alignItems="center">
					<Heading>Manage Users</Heading>
					<Button onClick={() => formToggle.onOpen()} variant="orangeSolid">
						Invite User
					</Button>
				</Flex>
				<DataTable
					columns={columns}
					data={users}
					isLoading={isLoading}
					tableProps={{
						border: '1px solid #F5F5F5',
						minH: '400px',
					}}
					trProps={{
						borderBottomWidth: '1px',
						borderColor: '#F5F5F5',
					}}
				/>
				<DataTablePagination {...pagination} />
				<UserFormModal
					isOpen={formToggle.isOpen}
					onClose={formToggle.onClose}
					onSubmit={handleCreateUpdateUser}
					title={`${formToggle.payload ? 'Edit' : 'Invite'} User`}
					initialValues={formToggle.payload}
				/>
				<UserDeleteModal
					isOpen={deleteToggle.isOpen}
					onClose={deleteToggle.onClose}
					onConfirm={handleDeleteUser}
					data={deleteToggle.payload}
				/>
				<ResendInviteModal
					isOpen={resendInviteToggle.isOpen}
					onClose={resendInviteToggle.onClose}
					onConfirm={handleResentInvite}
					data={resendInviteToggle.payload}
				/>
			</Flex>
		</CustomContainer>
	);
};

export default ManageUsers;
