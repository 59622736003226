import { FC, ReactNode, forwardRef } from 'react';
import {
	Flex,
	Text,
	FormControl,
	FormControlProps,
	FormLabel,
	FormLabelProps,
	FormErrorMessage,
} from '@chakra-ui/react';
import {
	ArrowForwardIcon,
	ChevronDownIcon,
	InfoIcon,
	SearchIcon,
} from '@chakra-ui/icons';
import CreatableSelect from 'react-select/creatable';
import {
	ControlProps,
	DropdownIndicatorProps,
	GroupBase,
	OptionProps,
	SingleValueProps,
	StylesConfig,
	components,
} from 'react-select';

import { IOption } from 'src/lib/schemas/misc';

export interface SelectSearchCreateableInputProps {
	name: string;
	label?: string;
	id?: string;
	placeholder?: string;
	error?: ReactNode;
	required?: boolean;
	options: Array<IOption>;
	value?: IOption['value'] | IOption;
	onChange?: (val: IOption['value'] | IOption) => void;
	onCreateOption?: (val: string) => void;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	isClearable?: boolean;
	valueAsObject?: boolean;
	onValueChangeCallback?: (val: IOption['value'] | IOption) => void;
	isLoading?: boolean;
	valuePrefix?: string;
}

const Control: FC<ControlProps> = ({ children, ...props }) => (
	<components.Control {...props}>
		<SearchIcon mx={2} color="#B8C2D0" /> {children}
	</components.Control>
);

const Option: FC<OptionProps> = ({ children, ...props }) => (
	<components.Option {...props}>
		<Flex justify="space-between">
			{children}
			<ArrowForwardIcon boxSize={4} color="#2C6ECB" alignSelf="right" />
		</Flex>
	</components.Option>
);

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<ChevronDownIcon w={5} h={5} />
		</components.DropdownIndicator>
	);
};

const SelectSearchCreateableInput = (
	{
		label,
		placeholder,
		options,
		value,
		onChange,
		onCreateOption,
		required,
		error,
		formControlProps,
		formLabelProps,
		isClearable,
		valueAsObject,
		isLoading,
		valuePrefix,
	}: SelectSearchCreateableInputProps,
	ref: any,
) => {
	const hasError = !!error;

	const renderLabelIndicator = () => {
		if (!required) return null;
		if (error) return <InfoIcon color="#e53e3e" />;
		return <Text color="#e53e3e">*</Text>;
	};

	const selectedValue = valueAsObject
		? value
		: options.find((o) => o.value === value);

	return (
		<FormControl isInvalid={hasError} {...formControlProps}>
			{label && (
				<FormLabel textTransform="capitalize" {...formLabelProps}>
					<Flex gap={1} alignItems="center">
						{label}
						{renderLabelIndicator()}
					</Flex>
				</FormLabel>
			)}
			<CreatableSelect
				isValidNewOption={() => true}
				formatCreateLabel={(value) => (
					<Text fontWeight={500}>
						{value ? `Create "${value}"` : 'Create a new one'}
					</Text>
				)}
				placeholder={placeholder}
				options={options}
				value={selectedValue || ''}
				createOptionPosition="first"
				onCreateOption={onCreateOption}
				onChange={(val) => {
					if (!onChange) return;
					const valueSelected = val as IOption;
					if (valueAsObject) {
						onChange(valueSelected);
						return;
					} else {
						onChange(valueSelected.value);
					}
				}}
				components={{
					DropdownIndicator,
					Control,
					Option,
					SingleValue: ({ children, ...props }: SingleValueProps) => {
						return (
							<components.SingleValue {...props}>
								{valuePrefix}
								{children}
							</components.SingleValue>
						);
					},
				}}
				styles={{
					...styles,
					...(hasError ? stylesWithErros : { control: styles.control }),
				}}
				ref={ref}
				isLoading={isLoading}
				// isClearable={isClearable}
				noOptionsMessage={() => `No element found`}
			/>
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

const styles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
	control: (baseStyles, state) => ({
		...baseStyles,
		padding: 0,
		minHeight: '40px',
		height: '40px',
		borderColor: '#E2E8F0',
		borderRadius: '5px',
		boxShadow: state.isFocused ? '0 0 0 1px #E2E8F0' : 'none',
		border: state.isFocused ? '1px solid #2C6ECB' : '1px solid #E2E8F0',
	}),
	valueContainer: (baseStyles) => ({
		...baseStyles,
		minHeight: '40px',
		height: '40px',
		padding: 0,
		paddingRight: '10px',
		fontSize: '14px',
		lineHeight: '20px',
		color: '#202223',
	}),
	input: (baseStyles) => ({
		...baseStyles,
		margin: 0,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#202223',
		padding: 0,
	}),
	placeholder: (baseStyles) => ({
		...baseStyles,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#A0AEC0',
		fontWeight: 400,
	}),
	indicatorsContainer: (baseStyles) => ({
		...baseStyles,
		padding: '0 10px',
	}),
	dropdownIndicator: () => ({
		color: '#202223',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	option: (baseStyles, { isSelected, isFocused }) => {
		let backgroundColor = 'inherit';
		if (isFocused) backgroundColor = '#f9f9fa';
		if (isSelected) backgroundColor = '#E2E8F0';

		return {
			...baseStyles,
			fontSize: '14px',
			lineHeight: '20px',
			color: '#202223',
			padding: '8px 16px 8px 16px',
			borderBottom: '1px solid #E2E8F0',
			backgroundColor,
			cursor: 'pointer',
		};
	},
};

const stylesWithErros: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
	control: (baseStyles) => ({
		...baseStyles,
		padding: 0,
		minHeight: '40px',
		height: '40px',
		borderRadius: '5px',
		boxShadow: '0 0 0 1px #A0AEC0',
		borderColor: '#E2E8F0',
		'&:hover': {
			boxShadow: '0 0 0 1px #A0AEC0',
			borderColor: '#A0AEC0',
		},
	}),
};

export default forwardRef(SelectSearchCreateableInput);
