import { useState, useEffect } from 'react';

const useDebouncedState = <T>(initialState: T, debounce = 500) => {
	const [debouncedState, setDebouncedState] = useState<T>(initialState);
	const [state, setState] = useState<T>(initialState);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedState(state);
		}, debounce);
		return () => {
			clearTimeout(handler);
		};
	}, [state, debounce]);

	return { debouncedState, setState, state };
};

export default useDebouncedState;
